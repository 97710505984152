import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getDishPdf = async dishId => {
  const response = await axios.get(ENDPOINT.GET_DISH_PDF(dishId));

  return response?.data ?? null;
};

const useDishPdf = ({ dishId, options = {} }) =>
  useQuery(['dishPdf', dishId], () => getDishPdf(dishId), {
    enabled: false,
    ...options,
  });

export default useDishPdf;
