import { createSlice } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import last from 'lodash/last';

import { format, formatArray } from '@services/Date.service';
import OrderFormService from '@services/OrderForm.service';
import { isDate } from '@utils/helpers';

import { resetTagsReducer, toggleTagReducer } from './orderForm.actions';

const initialState = {
  calendarVisibleDays: [],
  selectedDate: format(new Date()),
  selectedTags: [],
  userProceed: false,

  deliveryType: 'ADDRESS',
  address: {},
  pickupPoint: {},

  useDiscountCode: false,
  discountCode: { '@id': null, value: '' },
  useMoneyBox: false,
  useInvoice: false,
  invoice: {},
  paymentMethod: null,
  paymentMethodMetadata: {},
};

const orderFormSlice = createSlice({
  name: 'orderForm',
  initialState,
  reducers: {
    setInitialCalendarVisibleDays: (state, { payload }) => {
      state.calendarVisibleDays = payload;
    },
    addCalendarVisibleDays: (state, { payload }) => {
      const { dayNumber, disabledDays, disabledDaysOfWeek } = payload;

      const lastDate = new Date(last(state.calendarVisibleDays));

      if (isDate(lastDate)) {
        const startDay = addDays(lastDate, 1);

        const days = OrderFormService.getDaysRangeByCountWithoutDisabled(
          startDay,
          dayNumber,
          disabledDays,
          disabledDaysOfWeek
        );
        const formattedDays = formatArray(days);

        state.calendarVisibleDays.push(...formattedDays);
      }
    },
    changeDeliveryType: (state, { payload }) => {
      state.deliveryType = payload;
    },
    setAddress: (state, { payload }) => {
      state.address = payload ?? {};
      state.pickupPoint = {};
    },
    setPickupPoint: (state, { payload }) => {
      state.pickupPoint = payload;
      state.address = {};
    },
    setSelectedDate: (state, { payload }) => {
      state.selectedDate = payload;
    },
    setUserProceed: state => {
      state.userProceed = true;
    },
    resetOrderForm: () => initialState,
    resetTags: resetTagsReducer,
    toggleTag: toggleTagReducer,
  },
});

export const selectOrderForm = state => state.orderForm;

export const {
  addCalendarVisibleDays,
  changeDeliveryType,
  resetOrderForm,
  resetTags,
  setAddress,
  setInitialCalendarVisibleDays,
  setPickupPoint,
  setSelectedDate,
  setUserProceed,
  toggleTag,
} = orderFormSlice.actions;

export default orderFormSlice.reducer;
