import dynamic from 'next/dynamic';

import { isSameDay } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { Tooltip } from '@components';
import DAY_STATUSES from '@constants/dayStatuses';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig.js';
import { format } from '@services/Date.service.js';
import OrdersCalendarService from '@services/OrdersCalendar.service.js';

import calendarTileStyles from './CalendarTile.styles.js';

const CalendarUserIcon = dynamic(() => import('./CalendarUserIcon'));

const CalendarTile = ({
  date,
  status = DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER,
  isSelected = false,
  isSmall = false,
  onClick = () => {},
  styles = {},
}) => {
  const { showCalendarIcons } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const isToday = isSameDay(new Date(date), new Date());
  const {
    icon = null,
    defaultIcon = null,
    description = null,
    tooltip = null,
    type = 'default',
  } = OrdersCalendarService.getDayPropsByStatus(status);

  const hasIcon = !isEmpty(icon) || defaultIcon !== null;
  const SvgIcon = defaultIcon;

  const coreStyles = calendarTileStyles({
    isClicable: status !== DAY_STATUSES.DISABLED,
    isSelected,
    isToday,
    type,
    isSmall,
    styles,
  });

  return (
    <div css={coreStyles.wrapper} onClick={onClick}>
      {hasIcon && (
        <div css={coreStyles.iconWrapper}>
          {showCalendarIcons && !isEmpty(icon) && (
            <CalendarUserIcon icon={icon} />
          )}
          {(!showCalendarIcons || isEmpty(icon)) && defaultIcon !== null && (
            <SvgIcon css={coreStyles.svgIcon} />
          )}
        </div>
      )}
      <div css={coreStyles.text}>{format(date, 'd')}</div>
      {description && !isSmall && (
        <div css={coreStyles.descriptionWrapper}>
          <Tooltip content={tooltip ?? description}>
            <span css={coreStyles.descriptionSpan}>{description}</span>
          </Tooltip>
        </div>
      )}
      {isToday && <div css={coreStyles.todayDot} />}
    </div>
  );
};

CalendarTile.displayName = 'CalendarTile';

export default CalendarTile;
