import DisplayOn from './DisplayOn';

const DisplayUpSm = ({ children }) => (
  <DisplayOn size="sm" mode="up" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayUpSm.displayName = 'DisplayUpSm';

export default DisplayUpSm;
