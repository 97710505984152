import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const changeMenu = async ({ date, dietId, payload }) => {
  const response = await axios.put(
    ENDPOINT.CHANGE_MENU({ date, dietId }),
    payload
  );

  return response?.data ?? {};
};

export default {
  changeMenu,
};
