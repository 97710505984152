import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const updateUserDiet = async ({ id, payload }) => {
  const response = await axios.put(ENDPOINT.EDIT_DIET_V2(id), payload);

  return response?.data ?? {};
};

const useUserDietUpdate = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(updateUserDiet, {
    mutationKey: 'updateUserDiet',
    onSuccess: (response, request) => {
      if (request?.payload?.abandoned !== true) {
        queryClient.invalidateQueries(['userSubscription'], () => response);
      }
    },
    ...options,
  });

  return query;
};

export default useUserDietUpdate;
