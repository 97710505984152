import { Children, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw, { theme } from 'twin.macro';

import { TimesIcon } from '@assets/icons';
import { useEventListener, useOverflowBody } from '@hooks';
import { cssMerge } from '@utils/styleHelpers';

import mapPropsToChild from './mapPropsToChild';
import coreStyles from './Modal.styles';

const Modal = ({
  children,
  isOpen = false,
  onClose,
  disableClose = false,
  styles = {},
  size = 'sm',
  'data-cy': dataCy = '',
}) => {
  const selector = '#modalPortal';

  const closeBasketKeyboard = useCallback(e => {
    if (e.keyCode === 27 && !disableClose) {
      onClose();
    }
  }, []);

  useEventListener('keydown', closeBasketKeyboard, false);
  useOverflowBody([isOpen], isOpen);

  const handleClose = () => {
    if (!disableClose) {
      document.body.style.removeProperty('overflow');
      onClose();
    }
  };

  const childrenWithProps = Children.map(children, child =>
    mapPropsToChild(child, { onClose: handleClose, disableClose, size })
  );

  const twWrapperStyle = cssMerge({
    defaultCss: tw`relative z-10 mx-4 overflow-auto bg-white rounded-lg shadow-lg`,
    css: styles?.wrapper,
  });

  if (!isOpen) {
    return null;
  }

  const ModalComponent = (
    <AnimatePresence>
      <div
        tw="fixed inset-0 flex justify-center z-50 items-start"
        onClick={handleClose}
        role="dialog"
        aria-modal="true"
        {...(!isEmpty(dataCy) ? { 'data-cy': dataCy } : {})}
      >
        <motion.div
          tw="bg-black w-screen h-screen absolute z-0"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 0.6 },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        />
        <motion.div
          variants={{
            hidden: { opacity: 0, transform: 'scale(0)' },
            visible: {
              opacity: 1,
              transform: 'scale(1)',
              transition: { delay: 0.3 },
            },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          css={css`
            max-width: 800px;
            max-height: 90vh;

            margin-top: 5vh;
            margin-bottom: 5vh;
            ${twWrapperStyle};
            @media (min-width: ${theme`screens.md`}) {
              min-width: 500px;
            }
          `}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {childrenWithProps}
        </motion.div>
      </div>
    </AnimatePresence>
  );

  return createPortal(ModalComponent, document.querySelector(selector));
};

const Header = ({ children, noBorder, onClose, size, disableClose }) => {
  return (
    <header css={coreStyles.header({ noBorder, size })}>
      <h2 tw="mb-0 pr-11">{children}</h2>
      {!disableClose && (
        <button
          type="button"
          css={coreStyles.closeButton({ size })}
          onClick={onClose}
          data-cy="modal__close"
        >
          <TimesIcon tw="w-4 fill-current" />
        </button>
      )}
    </header>
  );
};

const Content = ({ children, size }) => {
  return <main css={coreStyles.content({ size })}>{children}</main>;
};

const Footer = ({ children, size }) => {
  return <footer css={coreStyles.footer({ size })}>{children}</footer>;
};

Modal.displayName = 'Modal';
Header.displayName = 'Header';
Content.displayName = 'Content';
Footer.displayName = 'Footer';

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;
