// import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAfter, isBefore } from 'date-fns';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

export const setActiveCurrentAndCollapseRest = ({ state, dietIri, day }) => {
  if (isEmpty(state.collapsed[dietIri])) {
    state.collapsed[dietIri] = { isOpen: true, days: {} };
  }

  if (isEmpty(state.collapsed[dietIri].days[day])) {
    state.collapsed[dietIri].days[day] = { isOpen: true };
  }

  Object.entries(state.collapsed).map(([dietIriKey, dietObj]) => {
    dietObj.isOpen = dietIriKey === dietIri;

    Object.entries(dietObj.days).map(([dateKey, dateObj]) => {
      dateObj.isOpen = dateKey === day;
    });
  });
};

const setOrderDetails = state => {
  const { dietElements = [], ...shopElements } = state.items;

  const {
    allDishesCount,
    allAddonsCount,
    allDaysCount,
    firstDeliveryDay,
    lastDeliveryDay,
  } = Object.entries(shopElements).reduce(
    (acc, [, days]) => {
      const { dietDishesCount, dietAddonsCount } = Object.entries(days).reduce(
        (acc, [, dayItems]) => {
          const { dayDishesCount, dayAddonsCount } = Object.entries(
            dayItems
          ).reduce(
            (acc, [key, quantity]) => {
              return {
                dayDishesCount: key.includes('dish-sizes')
                  ? acc.dayDishesCount + quantity
                  : acc.dayDishesCount,
                dayAddonsCount: key.includes('addons')
                  ? acc.dayAddonsCount + quantity
                  : acc.dayAddonsCount,
              };
            },
            { dayDishesCount: 0, dayAddonsCount: 0 }
          );

          return {
            dietDishesCount: acc.dietDishesCount + dayDishesCount,
            dietAddonsCount: acc.dietAddonsCount + dayAddonsCount,
          };
        },
        { dietDishesCount: 0, dietAddonsCount: 0 }
      );

      const sortedDays = [...Object.keys(days)].sort(
        (a, b) => new Date(a) - new Date(b)
      );
      const firstDeliveryDay = first(sortedDays);
      const lastDeliveryDay = last(sortedDays);

      return {
        allAddonsCount: acc.allAddonsCount + dietAddonsCount,
        allDaysCount: acc.allDaysCount + Object.keys(days).length,
        allDishesCount: acc.allDishesCount + dietDishesCount,
        firstDeliveryDay:
          acc.firstDeliveryDay === null
            ? firstDeliveryDay
            : isBefore(
                new Date(firstDeliveryDay),
                new Date(acc.firstDeliveryDay)
              )
            ? firstDeliveryDay
            : acc.firstDeliveryDay,
        lastDeliveryDay:
          acc.lastDeliveryDay === null
            ? lastDeliveryDay
            : isAfter(new Date(lastDeliveryDay), new Date(acc.lastDeliveryDay))
            ? lastDeliveryDay
            : acc.lastDeliveryDay,
      };
    },
    {
      allAddonsCount: 0,
      allDaysCount: 0,
      allDishesCount: 0,
      firstDeliveryDay: null,
      lastDeliveryDay: null,
    }
  );

  const hasDiets = !isEmpty(dietElements);
  const dietDelivery = dietElements.reduce(
    (acc, { deliveryDates = [] }) => {
      const sortedDays = [...deliveryDates].sort(
        (a, b) => new Date(a) - new Date(b)
      );
      const firstDeliveryDay = first(sortedDays);
      const lastDeliveryDay = last(sortedDays);

      return {
        firstDeliveryDay:
          acc.firstDeliveryDay === null
            ? firstDeliveryDay
            : isBefore(
                new Date(firstDeliveryDay),
                new Date(acc.firstDeliveryDay)
              )
            ? firstDeliveryDay
            : acc.firstDeliveryDay,
        lastDeliveryDay:
          acc.lastDeliveryDay === null
            ? lastDeliveryDay
            : isAfter(new Date(lastDeliveryDay), new Date(acc.lastDeliveryDay))
            ? lastDeliveryDay
            : acc.lastDeliveryDay,
      };
    },
    { firstDeliveryDay: null, lastDeliveryDay: null }
  );

  state.orderDetails.summary.diets = dietElements.length;
  state.orderDetails.summary.days = allDaysCount;
  state.orderDetails.summary.dishes = allDishesCount;
  state.orderDetails.summary.addons = allAddonsCount;

  state.orderDetails.summary.firstDeliveryDay =
    dietDelivery.firstDeliveryDay === null
      ? firstDeliveryDay
      : isBefore(
          new Date(firstDeliveryDay),
          new Date(dietDelivery.firstDeliveryDay)
        )
      ? firstDeliveryDay
      : dietDelivery.firstDeliveryDay;

  state.orderDetails.summary.lastDeliveryDay =
    dietDelivery.lastDeliveryDay === null
      ? lastDeliveryDay
      : isAfter(
          new Date(lastDeliveryDay),
          new Date(dietDelivery.lastDeliveryDay)
        )
      ? lastDeliveryDay
      : dietDelivery.lastDeliveryDay;

  state.isEmpty = !hasDiets && allDishesCount === 0 && allAddonsCount === 0;
};

export const toggleBasketDayReducer = (state, { payload }) => {
  const { dietIri = 'noDiet', date } = payload;
  state.collapsed[dietIri].days[date].isOpen =
    !state.collapsed[dietIri].days[date].isOpen;
};

export const toggleBasketGroupReducer = (
  state,
  { payload: dietIri = 'noDiet' }
) => {
  state.collapsed[dietIri].isOpen = !state.collapsed[dietIri].isOpen;
};

export const setRevalidateBasketReducer = (_, { payload }) => payload;

export const addDietReducer = (state, { payload }) => {
  state.activeOrderDietIri = payload?.['@id'] ?? null;
  state.items.dietElements.push(payload);

  setOrderDetails(state);
};

export const removeDietReducer = (state, { payload: dietIri }) => {
  const currentDietIndex = state.items.dietElements.findIndex(
    diet => diet['@id'] === dietIri
  );

  if (currentDietIndex !== -1) {
    state.items.dietElements.splice(currentDietIndex, 1);

    if (state.activeOrderDietIri === dietIri) {
      state.activeOrderDietIri = state.items.dietElements?.[0]?.['@id'] ?? null;
    }
  }

  setOrderDetails(state);
};

export const updateDietReducer = (state, { payload }) => {
  const { existingItem, ...restPayload } = payload;
  const dietIri = existingItem ?? state?.activeOrderDietIri;

  const currentDietIndex = state?.items?.dietElements?.findIndex(
    row => row['@id'] === dietIri
  );

  state.items.dietElements[currentDietIndex] = {
    ...state.items.dietElements[currentDietIndex],
    ...restPayload,
  };

  setOrderDetails(state);
};

export const addModifyDayToBasketReducer = () => {};

export const addItemToBasketReducer = (state, { payload }) => {
  const { iri, day, quantity, dietIri = 'noDiet' } = payload;

  if (isEmpty(state.items[dietIri])) {
    state.items[dietIri] = {};
  }

  if (isEmpty(state.items[dietIri][day])) {
    state.items[dietIri][day] = { [iri]: quantity };
  } else {
    state.items[dietIri][day][iri] = quantity;
  }

  setActiveCurrentAndCollapseRest({ state, dietIri, day });

  // clear store
  if (quantity === 0) {
    delete state.items[dietIri][day][iri];

    if (isEmpty(state.items[dietIri][day])) {
      delete state.items[dietIri][day];
      delete state.collapsed[dietIri].days[day];
      // delete state.orderDetails.summary[dietIri][day];

      if (isEmpty(state.items[dietIri])) {
        delete state.items[dietIri];
        delete state.collapsed[dietIri];
        // delete state.orderDetails.summary[dietIri];
      }
    }
  }

  setOrderDetails(state);
};
