import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/addressInputs';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { transformDeliveryHourToOption } from '@services/Api.service';
import UserService from '@services/User.service';
import { useDefaultSchemaYup } from '@utils/yupMethods';

const initialValuesFull = {
  [INPUT_IDS.ADDRESS_ID]: '',
  [INPUT_IDS.STREET]: '',
  [INPUT_IDS.STREET_OPTION]: { label: '', value: '' },
  [INPUT_IDS.HOUSE_NUMBER]: '',
  [INPUT_IDS.HOUSE_NUMBER_OPTION]: { label: '', value: '' },
  [INPUT_IDS.HOUSE_UNIT_NUMBER]: '',
  [INPUT_IDS.POSTAL_CODE]: '',
  [INPUT_IDS.CITY]: '',
  [INPUT_IDS.CITY_OPTION]: { label: '', value: '' },
  [INPUT_IDS.FLOOR]: '',
  [INPUT_IDS.STAIRCASE]: '',
  [INPUT_IDS.INTERCOM_CODE]: '',
  [INPUT_IDS.DELIVERY_HOURS]: { label: '', value: '' },
  [INPUT_IDS.ADDITIONAL_INFO]: '',
  [INPUT_IDS.SET_AS_DEFAULT]: false,
};

const initialValuesMini = {
  [INPUT_IDS.ADDRESS_ID]: '',
  [INPUT_IDS.POSTAL_CODE]: '',
  [INPUT_IDS.ADDRESS_LINE1]: '',
  [INPUT_IDS.ADDRESS_LINE2]: '',
  [INPUT_IDS.DELIVERY_HOURS]: { label: '', value: '' },
  [INPUT_IDS.SET_AS_DEFAULT]: false,
};

export const useInitialValues = (address, isFirstAddress = false) => {
  const { t } = useTranslation();
  const { useAddressesWithLessFields } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const addressAsDefaultObject = isFirstAddress
    ? { [INPUT_IDS.SET_AS_DEFAULT]: true }
    : {};

  if (isEmpty(address)) {
    return useAddressesWithLessFields
      ? {
          ...initialValuesMini,
          ...addressAsDefaultObject,
        }
      : { ...initialValuesFull, ...addressAsDefaultObject };
  }

  if (useAddressesWithLessFields) {
    const [addressLine1 = '', addressLine2 = ''] = address?.[
      INPUT_IDS.STREET
    ]?.split('; ') ?? ['', ''];

    return {
      [INPUT_IDS.ADDRESS_ID]: address[INPUT_IDS.ADDRESS_ID] ?? 0,
      [INPUT_IDS.POSTAL_CODE]: address[INPUT_IDS.POSTAL_CODE] ?? '',
      [INPUT_IDS.CITY]: address[INPUT_IDS.CITY] ?? '',
      [INPUT_IDS.CITY_OPTION]: address[INPUT_IDS.CITY]
        ? { label: address[INPUT_IDS.CITY], value: address[INPUT_IDS.CITY] }
        : { label: '', value: '' },
      [INPUT_IDS.ADDRESS_LINE1]: addressLine1 ?? '',
      [INPUT_IDS.ADDRESS_LINE2]: addressLine2 ?? '',
      [INPUT_IDS.DELIVERY_HOURS]: address?.selectedDeliveryHour
        ? transformDeliveryHourToOption(address.selectedDeliveryHour, t)
        : { label: '', value: null },
      [INPUT_IDS.SET_AS_DEFAULT]:
        address[INPUT_IDS.SET_AS_DEFAULT] ?? isFirstAddress,
    };
  }

  return {
    [INPUT_IDS.ADDRESS_ID]: address[INPUT_IDS.ADDRESS_ID] ?? 0,
    [INPUT_IDS.POSTAL_CODE]: address[INPUT_IDS.POSTAL_CODE] ?? '',
    [INPUT_IDS.CITY]: address[INPUT_IDS.CITY] ?? '',
    [INPUT_IDS.CITY_OPTION]: address[INPUT_IDS.CITY]
      ? { label: address[INPUT_IDS.CITY], value: address[INPUT_IDS.CITY] }
      : { label: '', value: '' },
    [INPUT_IDS.STREET]: address[INPUT_IDS.STREET] ?? '',
    [INPUT_IDS.STREET_OPTION]: address[INPUT_IDS.STREET]
      ? { label: address[INPUT_IDS.STREET], value: address[INPUT_IDS.STREET] }
      : { label: '', value: '' },
    [INPUT_IDS.HOUSE_NUMBER]: address[INPUT_IDS.HOUSE_NUMBER] ?? '',
    [INPUT_IDS.HOUSE_NUMBER_OPTION]: address[INPUT_IDS.HOUSE_NUMBER]
      ? {
          label: address[INPUT_IDS.HOUSE_NUMBER],
          value: address[INPUT_IDS.HOUSE_NUMBER],
        }
      : { label: '', value: '' },
    [INPUT_IDS.HOUSE_UNIT_NUMBER]: address[INPUT_IDS.HOUSE_UNIT_NUMBER] ?? '',
    [INPUT_IDS.FLOOR]: address[INPUT_IDS.FLOOR] ?? '',
    [INPUT_IDS.STAIRCASE]: address[INPUT_IDS.STAIRCASE] ?? '',
    [INPUT_IDS.INTERCOM_CODE]:
      UserService.transformIntercomCode(
        address[INPUT_IDS.INTERCOM_CODE],
        'textToHtml'
      ) ?? '',
    [INPUT_IDS.DELIVERY_HOURS]: address?.selectedDeliveryHour
      ? transformDeliveryHourToOption(address.selectedDeliveryHour, t)
      : { label: '', value: null },
    [INPUT_IDS.ADDITIONAL_INFO]: address[INPUT_IDS.ADDITIONAL_INFO] ?? '',
    [INPUT_IDS.SET_AS_DEFAULT]:
      address[INPUT_IDS.SET_AS_DEFAULT] ?? isFirstAddress,
  };
};

export const useValidationSchema = ({
  hasDeliveryHours,
  hasAllowedCities,
  hasAllowedStreets,
  hasAllowedBuildingNumbers,
}) => {
  const { stringRequired, isNotEmptyValid } = useDefaultSchemaYup();
  const { useAddressesWithLessFields } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  if (useAddressesWithLessFields) {
    return Yup.object({
      [INPUT_IDS.POSTAL_CODE]: stringRequired,
      ...(hasAllowedCities
        ? {
            [INPUT_IDS.CITY_OPTION]: isNotEmptyValid,
          }
        : {
            [INPUT_IDS.CITY]: stringRequired,
          }),
      [INPUT_IDS.ADDRESS_LINE1]: stringRequired,
      ...(hasDeliveryHours
        ? {
            [INPUT_IDS.DELIVERY_HOURS]: isNotEmptyValid,
          }
        : {}),
    });
  }

  return Yup.object({
    [INPUT_IDS.POSTAL_CODE]: stringRequired,
    ...(hasAllowedCities
      ? {
          [INPUT_IDS.CITY_OPTION]: isNotEmptyValid,
        }
      : {
          [INPUT_IDS.CITY]: stringRequired,
        }),
    ...(hasAllowedStreets
      ? { [INPUT_IDS.STREET_OPTION]: isNotEmptyValid }
      : { [INPUT_IDS.STREET]: stringRequired }),
    ...(hasAllowedBuildingNumbers
      ? { [INPUT_IDS.HOUSE_NUMBER_OPTION]: isNotEmptyValid }
      : {
          [INPUT_IDS.HOUSE_NUMBER]: stringRequired,
        }),
    ...(hasDeliveryHours
      ? {
          [INPUT_IDS.DELIVERY_HOURS]: isNotEmptyValid,
        }
      : {}),
  });
};
