import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

export const pushEventToDataLayer = event => {
  if (!window || !window.dataLayer || !process.browser) return;

  window.dataLayer.push({ ...event });
};

const getFormattedDietAddons = (diet, brandName) => {
  return (
    diet?.addons?.map(({ type, addon, quantity }) => ({
      id: addon?.['@id'],
      name: addon?.name,
      brand: brandName,
      price: addon?.clientCost,
      variant: addon?.type,
      category: type,
      quantity: quantity,
    })) ?? []
  );
};

const formatCartItem = ({ cartItem, brandName }) => {
  switch (cartItem?.['@type']) {
    case 'BasketItemDiet':
      return [
        {
          id: cartItem?.diet?.['@id'],
          name: cartItem?.diet?.name,
          brand: brandName,
          price: getIntegerIfIntegerOrFixed(cartItem?.price?.afterDiscount),
          variant: cartItem?.calorific?.name,
          category: cartItem?.variant?.name,
          quantity: cartItem?.quantity ?? 1,
          duration: cartItem?.dietLength,
          deliveryType: cartItem?.paymentMode,
          firstDeliveryDate: cartItem?.days[0],
        },
        ...getFormattedDietAddons(cartItem, brandName),
      ];
    case 'BasketItemDish':
      return [
        {
          id: cartItem?.dishSize?.['@id'],
          name: cartItem?.dishSize?.dish?.nameForClient,
          brand: brandName,
          price: cartItem?.price?.afterDiscount,
          variant: cartItem?.dishSize?.size?.nameForClient,
          category: cartItem?.day,
          quantity: cartItem?.quantity ?? 1,
        },
      ];
    case 'BasketItemAddon':
      return [
        {
          id: cartItem?.addon?.['@id'],
          name: cartItem?.addon?.name,
          brand: brandName,
          price: cartItem?.price?.afterDiscount,
          variant: cartItem?.addon?.type,
          category: cartItem?.day,
          quantity: cartItem?.quantity ?? 1,
        },
      ];
    default:
      return [];
  }
};

export const pushAddToCartGTMEvent = ({
  cartItems = [],
  brandName = '',
  currencyCode = '',
}) => {
  const products = cartItems.reduce(
    (acc, cartItem) => [...acc, ...formatCartItem({ cartItem, brandName })],
    []
  );

  const event = {
    event: 'addToCart',
    ecommerce: {
      currencyCode,
      add: { products },
    },
  };

  pushEventToDataLayer(event);
};

export const pushRemoveFromCartGTMEvent = ({
  cartItems = [],
  brandName = '',
  currencyCode = '',
}) => {
  const products = cartItems.reduce(
    (acc, cartItem) => [...acc, ...formatCartItem({ cartItem, brandName })],
    []
  );

  const event = {
    event: 'removeFromCart',
    ecommerce: {
      currencyCode,
      remove: {
        products,
      },
    },
  };

  pushEventToDataLayer(event);
};

export const pushCheckoutStepGTMEvent = ({
  step,
  cartItems = [],
  brandName = '',
}) => {
  const products = cartItems.reduce(
    (acc, cartItem) => [...acc, ...formatCartItem({ cartItem, brandName })],
    []
  );

  const event = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step },
        products: products,
      },
    },
  };

  pushEventToDataLayer(event);
};

export const pushCheckoutOptionGTMEvent = ({ step, checkoutOption = {} }) => {
  const event = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step, option: { ...checkoutOption } },
      },
    },
  };

  pushEventToDataLayer(event);
};

export const createProductSchemaFromItem = ({ item, brandName }) => {
  const itemType = item?.['@type'];
  let dietName, dietVariant, dietCalorific, dietPrice;

  switch (itemType) {
    case 'OrderItemDiet':
      dietName = item?.offerDiet?.name;
      dietVariant = item?.variant?.name;
      dietCalorific = item?.calorific?.name;
      dietPrice = item?.priceAfterDiscount;
      break;
    default:
      return { id: 'Unspecified order' };
  }

  return {
    id: `${dietName} - ${dietVariant} - ${dietCalorific}`,
    name: dietName,
    brand: brandName,
    price: dietPrice,
    variant: dietVariant,
    category: dietCalorific,
    quantity: 1,
  };
};

export const pushAuthenticationEvent = userId => {
  const event = {
    userId,
    event: 'authentication',
  };

  pushEventToDataLayer(event);
};

export const pushPurchaseGTMEvent = ({ orderDetails, brandName }) => {
  const { id, items, discountCode, paymentType, priceLeftToPay, pricePayed } =
    orderDetails;

  let revenue = '';

  if (paymentType === 'STRIPE_CARD') {
    revenue =
      priceLeftToPay === 0 && pricePayed !== 0 ? pricePayed : priceLeftToPay;
  } else {
    revenue =
      paymentType === 'BANK_WIRE' || paymentType === 'CASH'
        ? priceLeftToPay
        : pricePayed;
  }

  const isBagModification = items.some(item => item.type === 'BagModification');

  // ! BuyBag - subscription new bag order
  // ! BagModification - bag attribs change - including buying addons
  // ! New bag from subscription - not accounted for for now since we don't track this in GA

  const event = {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id,
          affiliation: '0',
          revenue: revenue,
          coupon: discountCode?.code || {},
          tax: '0',
          shipping: '0',
        },
        paymentType: orderDetails?.paymentType ?? null,
        products: !isBagModification
          ? items.map(item => createProductSchemaFromItem({ item, brandName }))
          : 'Bag/s Modification',
      },
    },
  };

  pushEventToDataLayer(event);
};

export const pushGTMPageViewEvent = linkToPage => {
  if (!process.browser || !history) return;

  const event = {
    event: 'pageView',
    link: linkToPage,
  };

  pushEventToDataLayer(event);
};
