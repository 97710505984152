import tw from 'twin.macro';

import { CircleInfoIcon } from '@assets/icons';
import { Image, Tooltip } from '@components';

import DietTileImage from './DietTileImage';
import DietTileWrapper from './DietTileWrapper';

const ProductTileDesktop = ({
  icon,
  name,
  image,
  children,
  isSelected,
  tooltip: tooltipProps,
  showImage = false,
  wrapperCustomStyles = [],
}) => {
  const imageUrl = showImage ? image ?? '/images/placeholder.jpeg' : image;
  const iconUrl = icon ?? null;

  return (
    <DietTileWrapper
      showImage={showImage}
      isSelected={isSelected}
      customStyles={wrapperCustomStyles}
    >
      <DietTileImage imageUrl={imageUrl} />
      <div
        tw="w-full flex p-4 relative items-center"
        css={[icon ? tw`justify-start py-1` : tw`justify-center`]}
      >
        {iconUrl && (
          <span tw="flex items-center mr-4">
            <Image src={iconUrl} alt="" loading="lazy" width={50} height={50} />
          </span>
        )}
        <p
          css={[
            tw`mb-0 text-center `,
            isSelected && tw`font-semibold text-primary`,
          ]}
        >
          {name}
        </p>
        {tooltipProps && (
          <div tw="absolute top-1 right-1">
            <Tooltip content={tooltipProps}>
              <span>
                <CircleInfoIcon tw="w-3 cursor-pointer" />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {children}
    </DietTileWrapper>
  );
};

export default ProductTileDesktop;
