import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getMenu = async payload => {
  const response = await axios.get(ENDPOINT.MENU_DISHES, {
    params: { ...payload },
  });

  return response?.data?.['hydra:member'] ?? {};
};

const useMenuDishes = (payload, options = {}) => {
  const query = useQuery(
    ['menuDishes', { ...payload }],
    () => getMenu(payload),
    {
      ...options,
    }
  );

  return query;
};

export default useMenuDishes;
