import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import { Modal } from '@components';

import BasketSummary from './BasketSummary';

const PaymentSummaryModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styles={{ wrapper: tw`w-full max-w-xs` }}
      size="sm"
    >
      <Modal.Header>
        {t(
          '$*components.paymentSummaryModal.title',
          '$$Podsumowanie płatności'
        )}
      </Modal.Header>
      <Modal.Content>
        <BasketSummary styles={{ basketPay: { css: tw`sticky bottom-6` } }} />
      </Modal.Content>
    </Modal>
  );
};

export default PaymentSummaryModal;
