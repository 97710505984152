import { createSlice } from '@reduxjs/toolkit';

import { initialState, reducers } from './basket.common';

const basketNewOrderSlice = createSlice({
  name: 'basketNewOrder',
  initialState,
  reducers,
});

export const selectors = {
  selectBasket: state => state.basketNewOrder,
  selectCurrentDiet: ({ basketNewOrder }) => {
    return (
      basketNewOrder.items.dietElements?.find(
        row => row['@id'] === basketNewOrder.activeOrderDietIri
      ) ?? {}
    );
  },
};

export const {
  addDiet,
  addItemToBasket,
  removeDiet,
  resetBasket,
  setActiveOrderDietIri,
  setDietName,
  setDiscountCode,
  setInvoice,
  setIsValid,
  setPaymentMethod,
  setPaymentMethodMetadata,
  setPayStatus,
  setStatusBasket,
  setUseMoneyBox,
  toggleBasketDay,
  toggleBasketGroup,
  updateDiet,
} = basketNewOrderSlice.actions;

export const actions = basketNewOrderSlice.actions;

export default basketNewOrderSlice.reducer;
