import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getAddonPage = async addonPageId => {
  const response = await axios.get(ENDPOINT.ADDON_PAGE(addonPageId), {
    withToken: false,
  });

  return response?.data ?? {};
};

const useAddonPage = (addonPageID, options = {}) =>
  useQuery(
    ['addonPage', addonPageID],
    () => getAddonPage(addonPageID),
    options
  );

export default useAddonPage;
