const CalendarIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2599 2599"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2279.214 206.319c176.033 0 319.24 143.207 319.24 319.24v1753.646c0 176.033-143.207 319.24-319.24 319.24H319.259c-176.033 0-319.24-143.207-319.24-319.24V525.56c0-176.033 143.207-319.24 319.24-319.24h258.425V61.364c0-33.883 27.47-61.354 61.354-61.354 33.883 0 61.354 27.47 61.354 61.354V206.32H1898.08V61.364c0-33.883 27.46-61.354 61.344-61.354 33.893 0 61.354 27.47 61.354 61.354V206.32h258.435zm0 2269.418c108.368 0 196.532-88.164 196.532-196.532V865.43H122.716v1413.775c0 108.368 88.165 196.532 196.543 196.532h1959.955zM319.259 329.017c-108.378 0-196.542 88.164-196.542 196.542v217.173h2353.029V525.56c0-108.378-88.164-196.542-196.532-196.542h-258.435V432.72c0 33.884-27.46 61.354-61.354 61.354-33.884 0-61.344-27.47-61.344-61.354V329.017H700.391V432.72c0 33.884-27.47 61.354-61.353 61.354-33.884 0-61.354-27.47-61.354-61.354V329.017H319.259z" />
  </svg>
);

export default CalendarIcon;
