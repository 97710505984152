// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import getConfig from 'next/config';

import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = publicRuntimeConfig.clientConfig.sentryDsn;
const SENTRY_DSN_WITH_PROTOCOL = SENTRY_DSN
  ? /^https?:\/\//.test(SENTRY_DSN)
    ? SENTRY_DSN
    : `https://${SENTRY_DSN}`
  : 'https://99f4b1d793a8467b900a8cf7b259094b@o1260273.ingest.sentry.io/6436223';

Sentry.init({
  dsn: SENTRY_DSN_WITH_PROTOCOL,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error', 'debug', 'assert'],
    }),
    new Sentry.Integrations.BrowserTracing({
      // custom options
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // release: `caterings-client-v2@${process.env.npm_package_version}`,
});
