import { useRouter } from 'next/router';

import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import tw from 'twin.macro';

import { Link } from '@components';

import useNavItems from './useNavItems';

const Nav = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const navItems = useNavItems();
  const router = useRouter();

  return (
    <nav>
      <ul tw="flex items-center -mx-3">
        {navItems.map(
          ({ listItem, link: { label, isProtected, ...linkProps } }, index) => {
            if (!isAuthenticated && isProtected) {
              return null;
            }

            return (
              <li {...listItem} key={index}>
                <Link
                  {...linkProps}
                  styles={
                    router.asPath === linkProps.href ? tw`font-semibold` : ''
                  }
                >
                  {label}
                </Link>
              </li>
            );
          }
        )}
      </ul>
    </nav>
  );
};

export default Nav;
