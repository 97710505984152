import dynamic from 'next/dynamic';

import { Fragment, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import { theme } from 'twin.macro';

import GlobalStyles from '@components/GlobalStyles';
import { BASE_URL } from '@constants/endpoint';
import { BasketMethodsProvider, MinScreenProvider } from '@contexts';
import { selectConfig, useAppConfigSelector } from '@hooks/app/useAppConfig';
import { appendElementToDocumentHead, slugify } from '@utils/helpers';

import { AppProvider } from './App.context';

const IS_SHOW_DEBUG_BREAKPOINT = false;
const ApiCrash = dynamic(() => import('@components/elements/ApiCrash'));
const CorsError = dynamic(() =>
  import('@components/modules/CorsError/CorsError')
);
const DefaultLayout = dynamic(() =>
  import('@components/layouts/DefaultLayout')
);
const DebugBreakPoint = dynamic(() =>
  import('@components/elements/DebugBreakPoint')
);

const App = ({ Component, pageProps }) => {
  const {
    branding: {
      backgroundColor,
      backgroundImage,
      primaryColor,
      secondaryColor,
    },
    modules: { Fonts },
  } = useAppConfigSelector(selectConfig);

  const isCorsError = Cookies.get('isCORSError');
  const Layout = Component.Layout || DefaultLayout;

  useEffect(() => {
    const { clientText, clientTitle } = Fonts;

    if ([clientText, clientTitle].some(({ font }) => font)) {
      import('webfontloader').then(WebFont => {
        const webFontObject = [clientText, clientTitle].reduce(
          (acc, { font, library, libraryParams }) => {
            if (library === 'google') {
              acc[library]?.families?.length > 0
                ? acc[library].families.push(font)
                : (acc[library] = { families: [font] });
            }

            if (library === 'typekit') {
              acc[library] = {
                typekit: libraryParams,
              };
            }

            if (library === 'custom') {
              const slug = slugify(font);
              const url = `${BASE_URL}/static/fonts/${slug}/${slug}.css`;

              let linkElement = document.createElement('link');
              linkElement.type = 'text/css';
              linkElement.rel = 'stylesheet';
              linkElement.href = url;

              appendElementToDocumentHead(linkElement);
            }

            return acc;
          },
          {}
        );

        WebFont.load(webFontObject);
      });
    }
  }, [Fonts]);

  const conditionalRender = () => {
    if (isCorsError) {
      return <CorsError />;
    }

    if (pageProps.configError) {
      return <ApiCrash />;
    }

    return (
      <MinScreenProvider screens={theme`screens`}>
        {IS_SHOW_DEBUG_BREAKPOINT && <DebugBreakPoint />}

        <AppProvider {...pageProps}>
          <BasketMethodsProvider {...pageProps}>
            <Layout>
              <Component {...pageProps} />
              <ToastContainer />
            </Layout>
          </BasketMethodsProvider>
        </AppProvider>
      </MinScreenProvider>
    );
  };

  return (
    <Fragment>
      <GlobalStyles
        rootVars={{
          backgroundColor,
          backgroundImage,
          primaryColor,
          secondaryColor,
          primaryFont: Fonts.clientText.font,
          secondaryFont: Fonts.clientTitle.font,
        }}
      />
      {conditionalRender()}
    </Fragment>
  );
};

export default App;
