import Head from 'next/head';

const PollsterTrackScript = () => {
  return (
    <Head>
      <script type="text/javascript" src="//cdn2.pollster.pl/nw.js"></script>
    </Head>
  );
};

export default PollsterTrackScript;
