const EllipsisVIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 510 2334"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M254.79 2333.6C114.16 2333.6 0 2219.4 0 2078.8 0 1938.1 114.16 1824 254.79 1824s254.8 114.1 254.8 254.8c0 140.6-114.17 254.8-254.8 254.8zm0-912C114.16 1421.6 0 1307.4 0 1166.8 0 1026.2 114.16 912 254.79 912s254.8 114.2 254.8 254.8c0 140.6-114.17 254.8-254.8 254.8zm0-912C114.16 509.6 0 395.4 0 254.8 0 114.2 114.16 0 254.79 0s254.8 114.2 254.8 254.8c0 140.6-114.17 254.8-254.8 254.8z" />
  </svg>
);

export default EllipsisVIcon;
