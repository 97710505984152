import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const HelperText = ({ children, styles = {} }) => {
  const twStyle = cssMerge({
    defaultCss: tw`mt-1 text-sm`,
    ...styles,
  });

  return (
    <div className="helper-text" css={twStyle}>
      {children}
    </div>
  );
};

HelperText.displayName = 'HelperText';

export default HelperText;
