const ArrowToBottomIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2213 2386"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2145.48 1558.831c37.093 0 67.159 30.048 67.159 67.094V2145.5c0 132.786-107.61 240.378-240.35 240.378H240.353c-132.74 0-240.35-107.592-240.35-240.378v-519.575c0-37.046 30.066-67.094 67.158-67.094 37.093 0 67.159 30.048 67.159 67.094V2145.5c0 58.601 47.469 106.098 106.033 106.098h1731.926c58.565 0 106.043-47.497 106.043-106.098v-519.575c0-37.046 30.066-67.094 67.158-67.094zm-560.41-66.72c26.633 25.755 27.407 68.12 1.774 94.808-.019 0-433.017 433.073-433.017 433.073-26.258 26.128-68.782 26.128-94.975-.187h-.01S634.43 1595.411 625.984 1586.92c-25.027-26.035-24.99-67.186.112-93.128 25.718-26.688 68.13-27.434 94.817-1.773 6.859 6.812 318.25 318.203 318.25 318.203V67.199c0-37.046 30.065-67.186 67.158-67.186 37.092 0 67.158 30.14 67.158 67.186l-.028 1743.022 318.417-318.203c26.035-25.008 67.215-25.008 93.203.094z" />
  </svg>
);

export default ArrowToBottomIcon;
