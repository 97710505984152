import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getAddons = async () => {
  const response = await axios.get(ENDPOINT.ADDONS);

  return response?.data?.['hydra:member'] ?? [];
};

const useAddons = () => useQuery('addons', getAddons);

export default useAddons;
