import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getCustomPage = async pageId => {
  const response = await axios.get(`${ENDPOINT.PAGES}/${pageId}`);

  return response?.data ?? {};
};

const useCustomPage = pageId => {
  const query = useQuery(['customPage', pageId], () => getCustomPage(pageId));

  return query;
};

export default useCustomPage;
