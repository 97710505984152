import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useFormikContext } from 'formik';

import INPUT_IDS from '@constants/inputs/discountCodeInputs';

const useFields = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const formFields = useMemo(() => {
    const fields = [
      {
        id: INPUT_IDS.CODE,
        type: 'text',
        label: t('$*input.discountCode.label', '$$Kod rabatowy'),
        required: true,
        onChange: ({ target }) => {
          const newValue = target.value.toUpperCase();

          formik.setFieldValue(target.name, newValue);
        },
      },
    ];

    return fields;
  }, [t]);

  return formFields;
};

export default useFields;
