import ROUTE_URLS from '@constants/routeUrls';
import { parseCookie } from '@services/Cookies.service';

const withProtectedRoute = gssp => {
  return async context => {
    const { req } = context;
    const { token } = parseCookie(req.headers.cookie);

    if (!token) {
      return {
        redirect: {
          destination: ROUTE_URLS.AUTH_LOGIN,
        },
      };
    }

    return await gssp(context); // Continue on to call `getServerSideProps` logic
  };
};

withProtectedRoute.displayName = 'withProtectedRoute';

export default withProtectedRoute;
