import getConfig from 'next/config';
import { i18n } from 'next-i18next';

import { unwrapResult } from '@reduxjs/toolkit';
import { refreshToken } from '@store/auth/auth.actions';
import { logout } from '@store/auth/auth.slice';
import store from '@store/store';
import axios from 'axios';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';

import { BASE_URL, ENDPOINT } from '@constants/endpoint';
import ROUTE_URLS from '@constants/routeUrls';

import showToast from './showToast';

const logoutFn = () => {
  store.dispatch(logout());

  return null;
};

const handelError401 = async error => {
  const originalRequest = error.config;
  const keepMeLoggedIn =
    Cookies.get('keepMeLoggedIn') === 'true' ? true : false;

  if (!originalRequest._retry && keepMeLoggedIn) {
    originalRequest._retry = true;

    return store
      .dispatch(refreshToken())
      .then(unwrapResult)
      .then(({ token }) => {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;

        return http(originalRequest);
      })
      .catch(() => {
        return logoutFn();
      });
  } else {
    return logoutFn();
  }
};

axios.defaults.withCredentials = true;
axios.defaults.withToken = true;

const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.request.use(
  request => {
    const token = Cookies.get('token') || null;
    const impersonateEmail = Cookies.get('impersonateEmail') || null;

    if (token && request.withToken) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }

    if (impersonateEmail) {
      request.headers.common['x-switch-user'] = impersonateEmail;
      request.headers.common.Authorization = `Bearer ${token}`;
    }

    request.headers['X-LOCALE'] = request.xLocale
      ? request.xLocale
      : i18n.language;

    return request;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  response => {
    // the code below checks version of app and refreshes without cache if app version under key x-cv is different
    if (
      response.config.method === 'get' &&
      Cookies.get('appv') !== response.headers['x-cv'] // version api
    ) {
      !isEmpty(Cookies.get('appv')) &&
        store.dispatch({ type: 'app/resetToInitial' });

      Cookies.set('appv', response.headers['x-cv']);
    }

    return response;
  },
  error => {
    if (error.response === undefined) {
      Cookies.set('isCORSError', true);
      return;
    }

    const { data, status } = error.response;
    const isError401 =
      status === 401 &&
      !window.location.href.includes(ROUTE_URLS.AUTH_LOGIN) &&
      ![ENDPOINT.REFRESH_LOGIN, ENDPOINT.LOGOUT, ENDPOINT.LOGIN].includes(
        error.config.url
      );

    if (error?.config?.errorHandle === false) {
      if (isError401) {
        handelError401(error);
      }

      return Promise.reject(error);
    }

    const collectErrorMessages400 = () => {
      const { 'hydra:description': description = '', violations = [] } = data;
      const allErrorMessages =
        violations.length > 0
          ? violations.reduce((acc, { message, propertyPath }) => {
              return [...acc, `${[propertyPath]}: ${message}`];
            }, []) || []
          : [description];
      const filteredAllErrorMessages = allErrorMessages.filter(Boolean);

      return ['Client: Bad Request', ...filteredAllErrorMessages];
    };

    const collectErrorMessages404 = () => {
      const message = data.detail
        ? `${data.detail}: ${data.title}`
        : `${data['hydra:description']}: ${data['hydra:title']}`;

      return message;
    };

    const toastMessages = {
      400: collectErrorMessages400(),
      403: `Authorization: ${data['hydra:description']}`,
      404: collectErrorMessages404(),
      422: collectErrorMessages400(),
      500: 'Server: Internal Server Error',
      undefined: 'Undefined Error',
    };

    const toastMessage = toastMessages[status] || toastMessages.undefined;

    if ([400, 403, 404, 500].includes(status)) {
      if (Array.isArray(toastMessage)) {
        toastMessage.map(message =>
          showToast(message, { toastId: `error${status}` })
        );
      } else {
        showToast(toastMessage, { toastId: `error${status}` });
      }
    } else if ([402, 409].includes(status)) {
      return Promise.reject(error);
    } else if (isError401) {
      handelError401(error);
    } else {
      showToast(toastMessages.undefined, { toastId: 'errorUndefined' });
    }

    return Promise.reject(error);
  }
);

const { publicRuntimeConfig } = getConfig();
const hasXCKey = (publicRuntimeConfig.xcKey ??= false);

if (hasXCKey) {
  http.defaults.headers.common['X-C-KEY'] = publicRuntimeConfig.xcKey;
} else {
  http.defaults.headers.common['X-DEV-FRONTEND-KEY'] =
    publicRuntimeConfig.devFrontendKey;
  http.defaults.headers.common['X-DEV-FRONTEND-ORIGIN'] =
    publicRuntimeConfig.devFrontendOrigin;
}

export default http;
