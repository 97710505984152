import tw, { styled } from 'twin.macro';

const MoneyBox = styled.div`
  ${tw`flex items-center `}
`;

const Label = styled.div`
  ${tw`pl-2.5 md:px-2.5 text-sm flex items-center font-normal`}
`;

const IconWrapper = styled.div`
  ${tw`flex items-center justify-center w-5 h-5 md:mr-2`}
`;

const CustomIcon = styled.div(({ iconUrl }) => [
  `background-image: url(${iconUrl});`,
  tw`w-full h-full bg-center bg-cover`,
]);

const Points = styled.div`
  ${tw`px-2.5 md:(border-l border-gray-300)`}
`;

const Styled = {
  MoneyBox,
  Label,
  CustomIcon,
  IconWrapper,
  Points,
};

export default Styled;
