import tw, { styled } from 'twin.macro';

export const EmojiItem = styled.li`
  ${tw`inline-block`}
`;

export const EmojiButton = styled.button`
  ${tw`flex p-1.5 relative`}

  &::before {
    ${tw`absolute inset-0 z-0 bg-gray-100 rounded-full opacity-0`}
    content: '';
  }

  &:hover::before {
    ${tw`opacity-100`}
  }
`;

export const EmojiSpan = styled.span(({ size, position }) => [
  tw`relative z-10 inline-block w-5 h-5`,
  `background-image: url('https://unpkg.com/emoji-datasource-apple@5.0.1/img/apple/sheets-256/64.png');
  background-size: ${size};
  background-position: ${position};`,
]);

export const EmojiImage = styled.img(({ size, position }) => [
  tw`inline-block w-5 h-5`,
  `background-image: url('https://unpkg.com/emoji-datasource-apple@5.0.1/img/apple/sheets-256/64.png');
  background-size: ${size};
  background-position: ${position};`,
]);
