import { Fragment } from 'react';

import { DisplayDownMd, DisplayUpMd } from '@components';

import ProductTileDesktop from './ProductTileDesktop';
import ProductTileMobile from './ProductTileMobile';

const ProductTile = () => {
  return (
    <Fragment>
      <DisplayUpMd>
        <ProductTileDesktop />
      </DisplayUpMd>
      <DisplayDownMd>
        <ProductTileMobile />
      </DisplayDownMd>
    </Fragment>
  );
};

export default ProductTile;
