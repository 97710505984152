const CrownIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2866 2245"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M11992.8 3354.28s437 812 513.2 953.76c3 5.56 8.2 9.75 14.4 11.64 6.2 1.89 12.9 1.34 18.7-1.55 115.8-57.97 660.8-330.68 827.2-413.92 8.6-4.28 18.9-3.33 26.5 2.43 7.6 5.77 10.9 15.27 8.6 24.29-66 253.13-357.1 1370.33-397.5 1525.33-2.8 10.51-12.6 17.87-23.8 17.87h-1974.4c-11.2 0-21-7.36-23.7-17.87-40.4-154.99-331.7-1272.19-397.7-1525.33-2.3-9.01 1-18.52 8.6-24.28 7.6-5.77 17.9-6.72 26.5-2.44 166.4 83.25 711.4 355.95 827.2 413.92 5.8 2.89 12.5 3.44 18.7 1.55 6.2-1.89 11.4-6.08 14.4-11.64l513.1-953.76Z"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeWidth: '127.29px',
      }}
      transform="matrix(.96366 0 0 1 -10124.43 -3291.8)"
    />
  </svg>
);

export default CrownIcon;
