import { Trans, useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import isEmpty from 'lodash/isEmpty';
import tw, { styled, theme } from 'twin.macro';

import { Link } from '@components';
import {
  selectModuleStripe,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import { INPUT_IDS } from './consts';

const CardElementInput = styled(CardElement)`
  ${tw`w-full px-5 py-3 border rounded-md focus-visible:(border-transparent ring-2 ring-primary)`};
`;

const cardOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: theme`colors.gray.600`,
      '::placeholder': {
        color: theme`colors.gray.400`,
      },
    },
    invalid: {
      color: theme`colors.gray.500`,
    },
  },
};

const useFields = isSubscriptionInCart => {
  const { t } = useTranslation();
  const { rulesLink } = useAppConfigSelector(selectModuleStripe);

  const formFields = useMemo(() => {
    const fields = [
      {
        id: INPUT_IDS.STRIPE_CARD,
        type: 'custom',
        label: t('$*input.stripeCard.label', '$$Dane karty'),
        Component: <CardElementInput options={cardOptions} />,
        required: true,
      },
      ...(isSubscriptionInCart
        ? [
            {
              id: INPUT_IDS.TERMS,
              type: 'checkbox',
              label: (
                <Trans
                  i18nKey="$*input.subscriptionTerms.label"
                  defaults="$$Akceptuję <0>regulamin płatności cyklicznych</0>"
                  components={[
                    ...(!isEmpty(rulesLink)
                      ? [
                          <Link
                            key="0"
                            href={rulesLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            tw="font-semibold"
                          />,
                        ]
                      : [<span key="0" />]),
                  ]}
                />
              ),
              required: true,
            },
          ]
        : []),
    ];

    return fields;
  }, [t, isSubscriptionInCart]);

  return formFields;
};

export default useFields;
