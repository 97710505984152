import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getValidateDiscountCode = async ({ code }) => {
  const response = await axios.get(ENDPOINT.VALIDATE_DISCOUNT_CODE, {
    params: { code },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useValidateDiscountCode = () => {
  const queryClient = useQueryClient();

  const query = useMutation(getValidateDiscountCode, {
    onSuccess: response => {
      queryClient.setQueryData('discountCode', () => response);
    },
  });

  return query;
};

export default useValidateDiscountCode;
