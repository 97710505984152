import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const FormGroup = ({ children, styles = {}, isInvalid = false }) => {
  const divProps = {
    ...(isInvalid ? { className: 'is-invalid' } : {}),
    css: cssMerge({
      defaultCss: tw`mb-3 md:mb-5`,
      ...styles,
    }),
  };

  return <div {...divProps}>{children}</div>;
};

FormGroup.displayName = 'FormGroup';

export default FormGroup;
