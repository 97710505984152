import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Button, Seo } from '@components';
import { useInterval } from '@hooks';

const INTERVAL_DELAY = 1000;
const TIME_TO_REFRESH_IN_SECONDS = 30;

const CorsError = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const [time, setTime] = useState(TIME_TO_REFRESH_IN_SECONDS);

  useInterval(
    () => {
      setTime(time - 1);
    },
    time > 0 ? INTERVAL_DELAY : null
  );

  useEffect(() => {
    time === 0 && reloadPage();
  }, [time]);

  useEffect(() => {
    return () => {
      Cookies.remove('isCORSError');
    };
  }, []);

  const reloadPage = () => {
    Cookies.remove('isCORSError');
    router.reload();
  };

  return (
    <Fragment>
      <Seo pageName="CORS error" />
      <div tw="flex justify-center items-center min-h-screen">
        <div tw="text-center">
          <h1>
            {t(
              '$*components.corsError.title',
              '$$🚧 Ups, coś poszło nie tak...'
            )}
          </h1>
          <h2>
            {t(
              '$*components.corsError.subtitle',
              '$$📶 Sprawdź proszę połączenie z internetem.'
            )}
          </h2>
          <p>
            {t(
              '$*components.corsError.paragraph1',
              '$$👍 Jeśli jest w porządku, to może nasz serwer ma chwilową przerwę 🔧'
            )}
            <br />
            {t('$*components.corsError.paragraph2', {
              defaultValue:
                '$$🔃 Automatyczne przeładowanie nastąpi za {{sec}}s',
              sec: time,
            })}
          </p>

          <div tw="flex justify-center mt-4">
            <Button color="success" onClick={reloadPage}>
              {t('$*common.refresh', '$$Odśwież')}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CorsError;
