import { useTranslation } from 'next-i18next';

import { Fragment, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useIsMutating } from 'react-query';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

import { BinIcon, TimesIcon } from '@assets/icons';
import { Button } from '@components';
import Tooltip from '@components/elements/Tooltip';
import { useBasketMethods, useEventListener, useOverflowBody } from '@hooks';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import BasketAction from './BasketAction';
import BasketContent from './BasketContent';

const MobileBasket = ({ isFixedButton = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    basketStore,
    basketQuery: { data: { price = {} } = {} },
    handleBasketClear,
    isEmptyBasket,
    setStatusBasket,
  } = useBasketMethods();
  const isOpenBasketMobile = basketStore.isOpenMobile;

  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const basketDishIsMutating = useIsMutating('basketAddDish');
  const basketAddonIsMutating = useIsMutating('basketAddAddon');
  const isMutatingBasket = [basketDishIsMutating, basketAddonIsMutating].some(
    Boolean
  );

  const handleOpenBasket = () => {
    dispatch(setStatusBasket(true));
  };

  const handleCloseBasket = () => {
    dispatch(setStatusBasket(false));
  };

  const closeBasketKeyboard = useCallback(
    e => {
      if (e.keyCode === 27) {
        handleCloseBasket();
      }
    },
    [isOpenBasketMobile]
  );

  useEventListener('keydown', closeBasketKeyboard);
  useOverflowBody([isOpenBasketMobile], isOpenBasketMobile);

  const basketVariants = {
    open: { transform: 'translateY(0%)', opacity: 1 },
    closed: { transform: 'translateY(100%)', opacity: 0 },
  };

  const basketTransition = { duration: 0.3 };

  if (isFixedButton) {
    return createPortal(
      <Fragment>
        <motion.div
          tw="bg-white h-full fixed left-0 right-0 bottom-0 z-50"
          animate={isOpenBasketMobile ? 'open' : 'closed'}
          initial="closed"
          exit="closed"
          variants={basketVariants}
          transition={basketTransition}
        >
          <div className="container">
            <div tw="py-4">
              <div tw="flex mb-4 pb-1.5 border-b border-gray-200 items-center justify-between font-semibold text-primary">
                <div tw="flex -mx-2">
                  <h2 className="h3" tw="mb-0 px-2">
                    {t('$*components.basket.title', '$$Koszyk')}
                  </h2>
                  {price?.afterDiscount > 0 && (
                    <div tw="text-lg px-2">
                      {price?.afterDiscount} {currencySymbol}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    tw="border-transparent text-gray-500 transform duration-300 flex items-center justify-center rounded-lg cursor-pointer outline-none hover:text-gray-700 focus-visible:(outline-none ring-gray-300)"
                    onClick={handleCloseBasket}
                  >
                    <TimesIcon tw="w-4 fill-current" />
                  </button>
                </div>
              </div>

              <div
                tw="overflow-auto px-2"
                css={css`
                  height: calc(100vh - 160px);
                `}
              >
                <BasketContent />
              </div>
            </div>
          </div>
        </motion.div>

        <div tw="fixed bottom-0 left-0 right-0 z-50">
          <div className="container" tw="relative mt-auto z-50">
            <div tw="py-4">
              {isOpenBasketMobile ? (
                <BasketAction isMobile={true} />
              ) : (
                <Button
                  fullWidth={true}
                  onClick={handleOpenBasket}
                  isLoading={isMutatingBasket}
                >
                  {t('$*components.mobileBasket.closedBasket.button', {
                    defaultValue: '$$Koszyk ({{price}} {{currencySymbol}})',
                    replace: {
                      price: getIntegerIfIntegerOrFixed(
                        price?.afterDiscount ?? 0
                      ),
                      currencySymbol,
                    },
                  })}
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* <AbandonedBasketModal /> */}
      </Fragment>,
      document.querySelector('#modalPortal')
    );
  }

  return (
    <Fragment>
      <div tw="pt-12">
        <Button
          fullWidth={true}
          onClick={handleOpenBasket}
          isLoading={isMutatingBasket}
        >
          {t('$*components.mobileBasket.button', {
            defaultValue: '$$Następny krok ({{price}} {{currencySymbol}})',
            replace: {
              price: getIntegerIfIntegerOrFixed(price?.afterDiscount ?? 0),
              currencySymbol,
            },
          })}
        </Button>
      </div>

      {createPortal(
        <Fragment>
          <motion.div
            tw="bg-white h-full fixed left-0 right-0 bottom-0 z-50"
            animate={isOpenBasketMobile ? 'open' : 'closed'}
            initial="closed"
            exit="closed"
            variants={basketVariants}
            transition={basketTransition}
          >
            <div className="container" tw="h-full flex flex-col">
              <div tw="py-4 flex-auto">
                <div tw="flex mb-4 pb-1.5 border-b border-gray-200 items-center justify-between font-semibold text-primary">
                  <div tw="flex items-center -mx-2">
                    <h2 className="h3" tw="mb-0 px-2">
                      {t('$*components.basket.title', '$$Koszyk')}
                    </h2>
                    {price?.afterDiscount > 0 && (
                      <div tw="text-lg px-2">
                        {price?.afterDiscount} {currencySymbol}
                      </div>
                    )}
                    {!isEmptyBasket && (
                      <Tooltip
                        content={t('$*common.clearBasket', '$$Wyczyść koszyk')}
                      >
                        <div tw="ml-3 text-red-500" onClick={handleBasketClear}>
                          <BinIcon tw="w-4 cursor-pointer" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      tw="border-transparent text-gray-500 transform duration-300 flex items-center justify-center rounded-lg cursor-pointer outline-none hover:text-gray-700 focus-visible:(outline-none ring-gray-300)"
                      onClick={handleCloseBasket}
                    >
                      <TimesIcon tw="w-4 fill-current" />
                    </button>
                  </div>
                </div>

                <div tw="overflow-auto px-2">
                  <BasketContent />
                </div>
              </div>

              <div tw="py-2">
                <BasketAction
                  isMobile={true}
                  buttonNextText={t('$*components.mobileBasket.button', {
                    defaultValue: '$$Dalej ({{price}} {{currencySymbol}})',
                    replace: {
                      price: getIntegerIfIntegerOrFixed(
                        price?.afterDiscount ?? 0
                      ),
                      currencySymbol,
                    },
                  })}
                />
              </div>
            </div>
          </motion.div>
          {/* <AbandonedBasketModal /> */}
        </Fragment>,
        document.querySelector('#modalPortal')
      )}
    </Fragment>
  );
};

export default MobileBasket;
