import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { loginWithUserData } from '@store/auth/auth.actions';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';

import { ExternalAuth } from '@components';
import { isFalsify } from '@utils/helpers';

import { initialValues, validationSchema } from './formikData';
import LoginFormFields from './LoginFormFields';

const LoginForm = ({ onAfterLogin = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getBruteforceTimeLeftMessage = bruteForceTime => {
    const plural =
      {
        0: '$*common.second_plural_genitive',
        1: '$*common.minute_singular_accusative',
        5: '$*common.minute_plural_genitive',
      }[bruteForceTime] || '$*common.minute_singular_genitive';

    return {
      apiErrorMessage: t('$*error.api.bruteForce.tooManyAttempts', {
        defaultValue:
          '$$Zbyt wiele nieudanych prób logowania, spróbuj ponownie za {{time}}.',
        replace: {
          /* i18next-extract-disable-next-line */
          time: `${bruteForceTime == 0 ? 30 : bruteForceTime} ${t(
            plural,
            '$$minuty'
          )}`,
        },
      }),
    };
  };

  const handleFormikSubmit = (
    values,
    { resetForm, setStatus, setSubmitting }
  ) => {
    const bruteForceTime = Cookies.get('bruteForceTime');

    //IF BRUTE FORCE COOKIE IS SET, PREVENT FROM BE LOGIN REQUEST AND JUST SHOW TIME LEFT
    if (!isFalsify(bruteForceTime)) {
      const timeLeftInMinutes = parseFloat(
        (new Date(bruteForceTime) - new Date()) / (1000 * 60)
      ).toFixed(0);

      setStatus(getBruteforceTimeLeftMessage(timeLeftInMinutes));

      return setSubmitting(false);
    }

    dispatch(loginWithUserData(values))
      .then(unwrapResult)
      .then(() => {
        setStatus();
        resetForm();
        onAfterLogin();
      })
      .catch(error => {
        const bruteForceTime = error?.time;

        if (!isFalsify(bruteForceTime)) {
          const inSetBrutForceTime = new Date(
            new Date().getTime() + bruteForceTime * 60 * 1000
          );

          Cookies.set('bruteForceTime', inSetBrutForceTime, {
            expires: inSetBrutForceTime,
          });

          setStatus(getBruteforceTimeLeftMessage(bruteForceTime));
        } else {
          /* i18next-extract-disable-next-line */
          setStatus({ apiErrorMessage: t(`$*error.api.${error.message}`) });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Fragment>
      <ExternalAuth onAfterLogin={onAfterLogin} />
      <div tw="py-6 text-center">
        {t('$*components.loginForm.mailContinue', '$$lub kontynuuj przez mail')}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={handleFormikSubmit}
      >
        <Form data-cy="login-form">
          <LoginFormFields />
        </Form>
      </Formik>
    </Fragment>
  );
};

export default LoginForm;
