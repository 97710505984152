// import produce from 'immer';
import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const paymentOrder = async ({ orderId, params = {} }) => {
  const response = await axios.put(ENDPOINT.ORDER_PAYMENT(orderId), params);

  return response?.data ?? {};
};

const usePaymentOrder = (options = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(paymentOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries('myOrders');

      showToast(
        t(
          '$*notification.paymentOrder.success',
          '$$Sposób płatności został poprawnie zainicjalizowany.'
        ),
        { type: 'success' }
      );
    },
    ...options,
  });

  return query;
};

export default usePaymentOrder;
