import { parseToRgb } from 'polished';
import tw, { styled, theme } from 'twin.macro';

const Card = styled.div``;

const blackRgb = parseToRgb(theme`colors.black`);

Card.Front = styled.div`
  box-shadow: 0 2px 16px 0
    rgba(${blackRgb.red}, ${blackRgb.green}, ${blackRgb.blue}, 0.1);
  background-image: url('/images/worldMap.png'),
    linear-gradient(
      to right bottom,
      ${theme`colors.primary`},
      ${theme`colors.primary`}
    );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 11rem;
  max-width: 20rem;
  ${tw`relative inline-block w-full p-4 rounded-lg`}
`;

Card.Remove = styled.div`
  border-radius: 0 7px;
  ${tw`absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-white bg-red-500 cursor-pointer duration-300 transform transition-all hover:(bg-red-600)`}
`;

Card.Brand = styled.div`
  ${tw`font-semibold text-left text-white uppercase`}
`;

Card.Number = styled.div`
  ${tw`my-5 text-xl font-semibold tracking-widest text-center text-white`};
  word-spacing: 4px;
`;

Card.InfoWrapper = styled.div`
  ${tw`flex flex-wrap justify-between`};
`;

Card.Left = styled.div`
  flex: 0 0 50%;
`;

Card.Right = styled.div`
  flex: 0 0 50%;
  ${tw`ml-auto text-right`}
`;

Card.Label = styled.div`
  ${tw`font-bold tracking-wider text-white uppercase text-xxs opacity-90`}
`;

Card.Info = styled.div`
  line-height: 18px;
  ${tw`mt-1 mb-0 font-semibold tracking-wider text-white uppercase break-all`}
`;

export default Card;
