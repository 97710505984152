const StopIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 512.095 512.095"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M256.047 0C114.636 0 0 114.636 0 256.047s114.636 256.047 256.047 256.047 256.047-114.636 256.047-256.047S397.458 0 256.047 0zM63.734 256.047c0-106.212 86.102-192.313 192.313-192.313 41.614 0 80.139 13.221 111.61 35.687L99.421 367.658c-22.466-31.471-35.687-69.996-35.687-111.611zm192.313 192.314c-41.589 0-80.092-13.204-111.552-35.644l268.221-268.221c22.441 31.46 35.644 69.964 35.644 111.552.001 106.211-86.101 192.313-192.313 192.313z" />
  </svg>
);

export default StopIcon;
