import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getAllowedBuildingNumbers = async payload => {
  const response = await axios.get(ENDPOINT.ALLOWED_BUILDING_NUMBERS, {
    params: { ...payload },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useAllowedBuildingNumbers = (payload, options = {}) => {
  return useQuery(
    ['allowedBuildingNumbers', payload],
    () => getAllowedBuildingNumbers(payload),
    {
      initialStale: true,
      ...options,
    }
  );
};

export default useAllowedBuildingNumbers;
