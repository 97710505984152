import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { createContext, useContext } from 'react';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { getUserData } from '@store/auth/auth.actions';
import { selectIsAuthenticated, selectUser } from '@store/auth/auth.slice';
import {
  actions as basketEditOrderActions,
  selectors as basketEditOrderSelectors,
} from '@store/basket/basketEditOrder.slice';
import {
  actions as basketNewOrderActions,
  selectors as basketNewOrderSelectors,
} from '@store/basket/basketNewOrder.slice';
import { resetMyDiet } from '@store/myDiet/myDiet.slice';
import { resetOrderForm } from '@store/orderForm/orderForm.slice';
import store from '@store/store';

import APP_MODES from '@constants/appModes';
import ROUTE_URLS from '@constants/routeUrls';
import { changeStepAndResetValid } from '@features/orderTabs/orderTabsSlice';
import { useCreateBasket } from '@hooks';
import {
  selectModuleAppMode,
  selectModulePollsterTrack,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { pushAuthenticationEvent } from '@utils/gtm';
import { pushAuthenticationPollsterEvent } from '@utils/pollsterTrack';
import showToast from '@utils/showToast';

const AppContext = createContext([{}, () => {}]);

const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const basketNewOrderStore = useSelector(basketNewOrderSelectors.selectBasket);
  const basketEditOrderStore = useSelector(
    basketEditOrderSelectors.selectBasket
  );

  const { catering, shop } = useAppConfigSelector(selectModuleAppMode);
  const { GID: pollsterTrackGID } = useAppConfigSelector(
    selectModulePollsterTrack
  );
  const isDietMini = catering === APP_MODES.MINI;
  const isDietFull = catering === APP_MODES.FULL;
  const isShop = shop === APP_MODES.DISHES;
  const isRestaurant = shop === APP_MODES.RESTAURANT;

  const prevIsAuthenticated = usePrevious(isAuthenticated);

  const { mutate: createBasketNewOrder } = useCreateBasket('basketNewOrder');

  // const dietId = items?.diet?.dietId;
  // const [showAbandonedModal, setShowAbandonedModal] = useState(true);

  // const { enabled: isShop } = useAppConfigSelector(selectModuleShop);

  // const validateBasket = basket => {
  //   if (!basket) return false;

  //   return (
  //     // Shop basket is invalid when diet is inside
  //     !(
  //       isShop &&
  //       basket?.rows.find(item => item['@type'] === BASKET_ROWS_TYPES.DIET_ITEM)
  //     ) &&
  //     // V2 mini basket is invalid when dishes or addons are in basket or basket is empty and diet is avaliable in local storage
  //     !(
  //       (!isShop &&
  //         basket?.rows.find(item =>
  //           [
  //             'BasketItemDish',
  //             'BasketItemAddon',
  //             'BasketItemDayDelivery',
  //           ].includes(item['@type'])
  //         )) ||
  //       (!isShop && (!basket?.rows || basket?.rows?.length === 0) && dietId)
  //     )
  //   );
  // };

  const resetAppBasketNewOrder = () => {
    queryClient.setQueryData('basketNewOrder', {});

    dispatch(basketNewOrderActions.resetBasket());
    dispatch(resetOrderForm());

    createBasketNewOrder();
    dispatch(changeStepAndResetValid(0));
  };

  const resetAppBasketEditOrder = () => {
    queryClient.setQueryData('basketEditOrder', {});

    dispatch(basketEditOrderActions.resetBasket());
  };

  const resetReactQueryToInitialRequired = () => {
    const appConfig = queryClient.getQueryData('appConfig');
    const orderForm = queryClient.getQueryData('orderForm');
    const languages = queryClient.getQueryData('languages');

    queryClient.clear();
    queryClient.setQueryData('appConfig', appConfig);
    queryClient.setQueryData('orderForm', orderForm);
    queryClient.setQueryData('languages', languages);
  };

  const resetAppToInititalRequired = () => {
    dispatch(basketNewOrderActions.resetBasket());
    dispatch(basketEditOrderActions.resetBasket());
    dispatch(resetOrderForm());
    dispatch(resetMyDiet());

    if (router.asPath.includes(ROUTE_URLS.NEW_ORDER_FORM)) {
      createBasketNewOrder();
      dispatch(changeStepAndResetValid(0));
    } else {
      router.push(ROUTE_URLS.AUTH_LOGIN);
    }
  };

  store.subscribe(() => {
    if (store.getState().lastAction === 'app/resetToInitial') {
      resetReactQueryToInitialRequired();
      resetAppToInititalRequired();

      showToast(
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              '$*notification.updateApiVersion.info',
              '$$Usprawniliśmy funkcjonowanie panelu klienta 😊<br/>Konieczne było wyczyszczenie Twoich wyborów.'
            ),
          }}
        />,
        { type: 'info' }
      );
    }
  });

  useEffect(() => {
    if (
      basketNewOrderStore.isPayActionSuccess &&
      basketNewOrderStore.isExternalPayment
    ) {
      resetAppBasketNewOrder();
    }

    if (
      basketEditOrderStore.isPayActionSuccess &&
      basketEditOrderStore.isExternalPayment
    ) {
      resetAppBasketEditOrder();
    }

    if (isAuthenticated && router.pathname !== ROUTE_URLS.LOGIN_AS_USER) {
      dispatch(getUserData());
    }
  }, []);

  useEffect(() => {
    if (
      basketNewOrderStore.isPayActionSuccess &&
      !basketNewOrderStore.isExternalPayment
    ) {
      resetAppBasketNewOrder();
    }

    if (
      basketEditOrderStore.isPayActionSuccess &&
      !basketEditOrderStore.isExternalPayment
    ) {
      resetAppBasketEditOrder();
    }
  }, [
    basketNewOrderStore.isPayActionSuccess,
    basketEditOrderStore.isPayActionSuccess,
  ]);

  // const validateBasket = basket => {
  //   if (!basket || isEmpty(basket)) return false;

  //   return (
  //     // Shop basket is invalid when diet is inside
  //     !(
  //       isShop &&
  //       basket?.rows.find(item => item['@type'] === BASKET_ROWS_TYPES.DIET_ITEM)
  //     ) &&
  //     // V2 mini basket is invalid when dishes or addons are in basket or basket is empty and diet is avaliable in local storage
  //     !(
  //       (!isShop &&
  //         basket?.rows.find(item =>
  //           [
  //             'BasketItemDish',
  //             'BasketItemAddon',
  //             'BasketItemDayDelivery',
  //           ].includes(item['@type'])
  //         )) ||
  //       (!isShop && (!basket?.rows || basket?.rows?.length === 0) && dietId)
  //     )
  //   );
  // };

  // on logout clear RQ && RTK
  useEffect(() => {
    if (isAuthenticated && user) {
      pushAuthenticationEvent(user?.id);
      pushAuthenticationPollsterEvent({
        gid: pollsterTrackGID,
        userId: user?.id,
      });
    }

    if (prevIsAuthenticated && !isAuthenticated) {
      resetReactQueryToInitialRequired();
      resetAppToInititalRequired();
    }
  }, [isAuthenticated]);

  const value = { isDietFull, isDietMini, isRestaurant, isShop };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
