const LoadedWrapper = ({ isLoading = false, isError = false, children }) => {
  if (isLoading || isError) {
    return null;
  }

  return children;
};

LoadedWrapper.displayName = 'LoadedWrapper';

export default LoadedWrapper;
