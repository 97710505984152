import tw from 'twin.macro';

const arrow = tw`cursor-pointer bg-gray-200 hover:(bg-primary text-white) rounded-full w-6 h-6 p-0.5 flex justify-center items-center transform transition-colors `;

const coreStyles = {
  wrapper: tw`flex items-center justify-between w-full pt-10`,
  arrowLeft: [tw`left-0 -rotate-90`, arrow],
  arrowRight: [tw`right-0 rotate-90`, arrow],
};

export default coreStyles;
