import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import { format } from '@services/Date.service';
import axios from '@utils/http';

const getMenu = async ({ variant, diet, dateFrom, dateTo, disabledDays }) => {
  const response = await axios.get(ENDPOINT.MENU, {
    params: {
      'date[after]': dateFrom,
      'date[before]': dateTo,
      draft: false,
      properties: ['date', 'variant', 'mealType', 'diet'],
      'properties[nameForClient]': 'dish',
      pagination: false,
      variant,
      diet,
    },
  });

  const data = response?.data?.['hydra:member'];
  const formattedDisabledDays = disabledDays.map(date =>
    format(new Date(date), 'ddMMyy')
  );

  const sortedMeals = data
    .map(mealDay => ({
      ...mealDay,
      order: format(new Date(mealDay.date), 'ddMMyy'),
    }))
    .filter(mealDay => !formattedDisabledDays.includes(mealDay.order))
    .sort((a, b) => parseInt(a.order) - parseInt(b.order));

  return sortedMeals ?? {};
};

const useMenu = (payload, options = {}) => {
  const query = useQuery('menu', () => getMenu(payload), {
    ...options,
  });

  return query;
};

export default useMenu;
