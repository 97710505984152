import ROUTE_URLS from '@constants/routeUrls';
import { parseCookie } from '@services/Cookies.service';

const withUserAuthenticated = gssp => {
  return async context => {
    const { req } = context;
    const { token } = parseCookie(req.headers.cookie);

    if (token) {
      return {
        redirect: {
          destination: ROUTE_URLS.MY_ORDERS,
        },
      };
    }

    return await gssp(context); // Continue on to call `getServerSideProps` logic
  };
};

withUserAuthenticated.displayName = 'withUserAuthenticated';

export default withUserAuthenticated;
