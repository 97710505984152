import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useFormikContext } from 'formik';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex } from '@components';
import ROUTE_URLS from '@constants/routeUrls';

import useLoginFields from './useLoginFields';

const LoginFormFields = () => {
  const { t } = useTranslation();

  const { isSubmitting, dirty, status } = useFormikContext();
  const [fields, [keepMeLoggedInField]] = useLoginFields();

  return (
    <Fragment>
      {fields.map(({ id, ...restProps }) => {
        return <InputsIndex key={id} id={id} {...restProps} />;
      })}

      <div tw="flex flex-wrap justify-between -mx-2">
        <div tw="px-2">
          <InputsIndex
            id={keepMeLoggedInField.id}
            {...keepMeLoggedInField}
            formGroupProps={{ styles: { css: tw`mb-0` } }}
          />
        </div>
        <div tw="font-semibold px-2">
          <Link href={ROUTE_URLS.RESET_PASSWORD}>
            {t('$*loginPage.notRememberPassword', '$$Nie pamiętasz hasła?')}
          </Link>
        </div>
      </div>

      {status?.apiErrorMessage && (
        <Alert styles={{ css: tw`mt-4` }}>{status.apiErrorMessage}</Alert>
      )}

      <div tw="mt-4">
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !dirty}
          fullWidth={true}
          data-cy="login-form__submit"
        >
          {t('$*loginPage.logIn', '$$Zaloguj się')}
        </Button>
      </div>
    </Fragment>
  );
};

export default LoginFormFields;
