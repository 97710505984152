const ContainerIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2844 2031"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2843.6 243.7C2843.6 109.2 2734.4 0 2599.9 0H243.7C109.2 0 0 109.2 0 243.7v1543.39c0 134.5 109.2 243.7 243.7 243.7h2356.2c134.5 0 243.7-109.2 243.7-243.7V243.7ZM1177.4 125v1780.79H243.7c-65.5 0-118.7-53.19-118.7-118.7V243.7c0-65.51 53.2-118.7 118.7-118.7h933.7Zm125 0h1297.5c65.5 0 118.7 53.19 118.7 118.7v1543.39c0 65.51-53.2 118.7-118.7 118.7H1302.4V125Z" />
  </svg>
);

export default ContainerIcon;
