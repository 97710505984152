import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getUserBag = async bagId => {
  const response = await axios.get(ENDPOINT.GET_BAG(bagId));

  return response?.data ?? {};
};

const useUserBag = ({ bagId }, options = {}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return useQuery(['userBag', { bagId }], () => getUserBag(bagId), {
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });
};

export default useUserBag;
