import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

import { useToggle } from '@hooks';

import MenuToggle from './MenuToggle';
import NavMenu from './NavMenu';

const MenuContainerOverlay = styled(motion.div)`
  ${tw`fixed top-0 right-0 w-full h-full`};
  z-index: 90;
`;

const MenuContainer = styled(motion.div)`
  min-width: 300px;
  max-width: 44%;
  ${tw`h-full px-8 ml-auto bg-white shadow-lg py-7`};
  transform: translateX(4em);
  user-select: none;
`;

const menuOverlayVariants = {
  open: {
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'initial',
  },
  closed: {
    background: 'rgba(0, 0, 0, 0)',
    transitionEnd: {
      display: 'none',
    },
  },
};

const menuVariants = {
  open: {
    transform: 'translateX(3%)',
  },
  closed: {
    transform: 'translateX(103%)',
  },
};

const menuTransition = {
  type: 'spring',
  duration: 0.5,
  stiffness: 50,
};

const overlayTransition = {
  type: 'spring',
  duration: 0.5,
};

const HamburgerMenu = () => {
  const [isOpenMenu, toggleOpenMenu] = useToggle(false);

  return (
    <div tw="flex">
      <MenuToggle toggle={toggleOpenMenu} isOpen={isOpenMenu} />
      <MenuContainerOverlay
        initital="closed"
        animate={isOpenMenu ? 'open' : 'closed'}
        variants={menuOverlayVariants}
        onClick={toggleOpenMenu}
        transition={overlayTransition}
      >
        <MenuContainer
          initial="closed"
          animate={isOpenMenu ? 'open' : 'closed'}
          variants={menuVariants}
          transition={menuTransition}
          onClick={e => e.stopPropagation()}
        >
          <NavMenu isOpen={isOpenMenu} toggle={toggleOpenMenu} />
        </MenuContainer>
      </MenuContainerOverlay>
    </div>
  );
};

export default HamburgerMenu;
