import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getDishPage = async dishPageId => {
  const response = await axios.get(ENDPOINT.PRODUCT_DISH_PAGE(dishPageId), {
    withToken: false,
  });

  return response?.data ?? {};
};

const useDishPage = (dishPageId, options = {}) =>
  useQuery(['dishPage', dishPageId], () => getDishPage(dishPageId), options);

export default useDishPage;
