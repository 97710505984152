import { cssVar } from 'polished';
import { css, theme } from 'twin.macro';

export const screen = Object.entries(theme`screens`).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]: (...args) => css`
        @media (min-width: ${value}) {
          ${css(...args)}
        }
      `,
    };
  },
  {}
);

export const flatCss = styles => {
  const flattenMergedCss = Array.isArray(styles)
    ? styles.flat().reduce((acc, item) => ({ ...acc, ...item }), {})
    : styles;

  return flattenMergedCss;
};

export const cssMerge = ({ mode = 'merge', defaultCss = null, css = null }) => {
  if (mode === 'replace') {
    return css === null ? null : css;
  }

  const flattenMergedCss = flatCss(defaultCss);

  return defaultCss === null && css === null
    ? null
    : { ...flattenMergedCss, ...css };
};

export const cssTwVar = twVar => {
  const clearVar = twVar.replace('var(', '').replace(')', '');

  return cssVar(clearVar);
};
