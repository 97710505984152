import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import { OverflowContent } from '@components';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import DishMacros from './DishMacros';
import { useDishTileContext } from './DishTile.context';

const DishSizeInfo = () => {
  const { t } = useTranslation();
  const { showInformation = {} } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const showCalorific = showInformation?.dishCalorific ?? false;
  const showIngredients = showInformation?.dishComposition ?? false;
  const showAllergens = showInformation?.allergens ?? false;
  const showMacroIngredients = showInformation?.macroIngredients ?? false;
  const showGlycemicIndex = showInformation?.glycemicIndex?.show ?? false;

  const {
    allergens,
    ingredients,
    macrosAfterProcessing,
    portionDivision = 1,
    toggleOpenMenu,
  } = useDishTileContext();

  const ingredientsNames = !isEmpty(ingredients)
    ? ingredients.map(({ name }) => name).join(', ')
    : '-';

  const allergensNames = !isEmpty(allergens)
    ? allergens.map(({ value }) => value).join(', ')
    : '-';

  return (
    <div tw="text-xs" onClick={toggleOpenMenu}>
      {showCalorific && (
        <div>
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.kcal', '$$kcal')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              {portionDivision
                ? parseFloat(
                    (macrosAfterProcessing?.calories ?? 0) / portionDivision
                  ).toFixed(0)
                : parseFloat(macrosAfterProcessing?.calories ?? 0).toFixed(0)}
            </div>
          </div>
        </div>
      )}

      {showIngredients && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.dishComposition', '$$Skład')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              <OverflowContent lineClamp={5} overflowMode="expand">
                {ingredientsNames}
              </OverflowContent>
            </div>
          </div>
        </div>
      )}

      {showAllergens && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.allergens', '$$Alergeny')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              <OverflowContent lineClamp={2}>{allergensNames}</OverflowContent>
            </div>
          </div>
        </div>
      )}

      {showMacroIngredients && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.macros', '$$Makroskładniki')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              <DishMacros {...macrosAfterProcessing} />
            </div>
          </div>
        </div>
      )}

      {showGlycemicIndex && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.glycemicIndexShort', '$$IG')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              {macrosAfterProcessing?.glycemicIndex}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DishSizeInfo;
