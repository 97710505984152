import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getStripeKeys = async () => {
  const response = await axios.get(`${ENDPOINT.CARDS}?createIntent=true`); // new endpoint
  delete response?.data?.cards;

  return response?.data;
};

const useStripeKeys = (options = {}) =>
  useQuery('stripeKeys', getStripeKeys, options);

export default useStripeKeys;
