import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/discountCodeInputs';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const useInitialValues = ({ useDiscountCode, discountCode }) => {
  if (useDiscountCode) {
    return {
      [INPUT_IDS.CODE]: discountCode?.value,
    };
  }

  return {
    [INPUT_IDS.CODE]: '',
  };
};

export const useValidationSchema = () => {
  const { stringRequired } = useDefaultSchemaYup();

  return Yup.object({
    [INPUT_IDS.CODE]: stringRequired,
  });
};
