import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import PropTypes from 'prop-types';

import { Tooltip } from '@components';
import { cssMerge } from '@utils/styleHelpers';

const OverflowContent = ({
  as: Component = 'div',
  children,
  lineClamp = 1,
  styles = {},
  tooltipProps = {},
  overflowMode = 'tooltip',
}) => {
  const { t } = useTranslation();
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const ref = useRef(null);

  const isOneLine = lineClamp === 1;
  const css = cssMerge({
    defaultCss: isOneLine
      ? {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          OTextOverflow: 'ellipsis',
          wordBreak: 'break-word',
        }
      : {
          display: ['-moz-box', '-ms-box', '-webkit-box', 'box'],
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          OTextOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: lineClamp,
          wordBreak: 'break-word',
        },
    ...styles,
  });

  useEffect(() => {
    const width = ref.current?.offsetWidth;
    const height = ref.current?.offsetHeight;
    const scrollWidth = ref.current?.scrollWidth;
    const scrollHeight = ref.current?.scrollHeight;

    const isOverflowHeight = height < scrollHeight;
    const isOverflowWidth = width < scrollWidth;
    const isOverflow = isOverflowWidth || isOverflowHeight;

    setIsOverflowed(isOverflow);
  }, [ref]);

  if (isOverflowed && overflowMode === 'tooltip') {
    return (
      <Tooltip content={children} {...tooltipProps}>
        <Component ref={ref} css={css}>
          {children}
        </Component>
      </Tooltip>
    );
  }

  if (isOverflowed && overflowMode === 'expand') {
    return (
      <Fragment>
        <Component
          ref={ref}
          css={isExpanded ? styles?.css ?? {} : css}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {children}
        </Component>
        <span
          onClick={e => {
            e.stopPropagation();
            toggleIsExpanded();
          }}
          tw="underline font-semibold"
        >
          {isExpanded
            ? t('$*common.showLess', '$$Zobacz mniej')
            : t('$*common.showMore', '$$Zobacz więcej')}
        </span>
      </Fragment>
    );
  }

  return (
    <Component ref={ref} css={css}>
      {children}
    </Component>
  );
};

OverflowContent.displayName = 'OverflowContent';

OverflowContent.propTypes = {
  overflowMode: PropTypes.oneOfType([
    PropTypes.oneOf(['tooltip', 'expand']),
    PropTypes.string,
  ]),
};

export default OverflowContent;
