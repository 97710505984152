import { useQuery, useQueryClient } from 'react-query';

const useDiscountCode = (options = {}) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    'discountCode',
    () => queryClient.getQueryData('discountCode'),
    {
      ...options,
      initialData: () => {
        return queryClient.getQueryData('discountCode') ?? {};
      },
    }
  );

  return query;
};

export default useDiscountCode;
