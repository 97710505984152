import { useTranslation } from 'next-i18next';

import { isToday } from 'date-fns';
import tw from 'twin.macro';

import { CircleInfoIcon } from '@assets/icons';
import { Carousel, Tooltip } from '@components';
import { useMobileDetect } from '@hooks';
import { format, isSameDate } from '@services/Date.service';

import 'swiper/css';

const MenuForWeek = ({
  days = [],
  mealTypes = [],
  currentMenu = [],
  daysPerView = 7,
  showDishIngredients = false,
}) => {
  const { t } = useTranslation();
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const getDayString = day => {
    const shortDayName = format(new Date(day?.date), 'EEEEEE');

    return `${
      shortDayName.charAt(0).toUpperCase() + shortDayName.slice(1)
    } ${format(new Date(day?.date), 'dd.MM')}`;
  };

  return (
    <div tw="relative w-full mt-7">
      <Carousel
        className="swiper--weekly-menu"
        options={{
          spaceBetween: 0,
          slidesPerView: daysPerView,
          slidesPerGroup: daysPerView,
          allowTouchMove: isMobile,
          ...(!isMobile
            ? {
                keyboard: {
                  enabled: true,
                  onlyInViewport: false,
                },
              }
            : { slidesPerView: 1, slidesPerGroup: 1 }),
        }}
      >
        {Slide =>
          days.map(day => {
            return (
              <Slide
                key={day?.label}
                onClick={e => {
                  e.preventDefault();
                }}
              >
                <div
                  css={[
                    tw`w-full p-3 rounded-lg text-primary`,
                    isToday(new Date(day?.date)) && tw`text-white bg-primary`,
                  ]}
                >
                  <div style={{ minHeight: '50px' }}>{getDayString(day)}</div>
                  {mealTypes.map((mealType, index) => {
                    const currentMeal = currentMenu.find(el => {
                      return (
                        isSameDate(new Date(el.date), new Date(day.date)) &&
                        el.mealType === mealType?.['@id']
                      );
                    });

                    const textToShow = currentMeal?.dish?.nameForClient;
                    const ingredientsText =
                      currentMeal?.dish?.lowestDishSize?.ingredientsSortedByUsage
                        ?.map(({ name }) => name)
                        .join(', ');

                    return (
                      <div key={index} style={{ minHeight: '175px' }}>
                        <div tw="flex items-center">
                          <p tw="text-base font-bold mb-0 break-words">
                            {mealType?.name}
                          </p>
                          {showDishIngredients && ingredientsText && (
                            <div tw="ml-1">
                              <Tooltip
                                content={`${t(
                                  '$*common.dishComposition',
                                  '$$Skład'
                                )}: ${ingredientsText}`}
                              >
                                <span>
                                  <CircleInfoIcon
                                    fill={
                                      isToday(new Date(day?.date))
                                        ? 'white'
                                        : 'currentColor'
                                    }
                                    tw="w-3 cursor-pointer"
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        <p tw="text-sm break-words">
                          {currentMeal && !day.disabled
                            ? textToShow
                            : day.disabled
                            ? t('$*menu.noDelivery', 'Brak dostaw')
                            : t('$*menu.availableSoon', 'Dostępne wkrótce')}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </Slide>
            );
          })
        }
      </Carousel>
    </div>
  );
};

export default MenuForWeek;
