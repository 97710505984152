import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import ADDON_DELIVERY_MODES from '@constants/addonDeliveryModes';
import { useToggle } from '@hooks';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { checkImageExists, getIntegerIfIntegerOrFixed } from '@utils/helpers';

import AddonDeliveryModeSwitch from './AddonDeliveryModeSwitch';
import AddonImageMobile from './AddonImageMobile';
import AddonInfoWrapperCollapsed from './AddonInfoWrapperCollapsed';
import AddonInfoWrapperExpanded from './AddonInfoWrapperExpanded';
import AddonName from './AddonName';
import { MobileTile } from './AddonTile.styles';

const AddonTileMobile = ({
  '@id': addonIri,
  basketItemsDeliveryModes,
  basketItemsQuantity,
  clientCost,
  deliveryModesEnabled,
  dishDate,
  image = null,
  isSelected = false,
  maxCount = null,
  minCount = null,
  name,
  onChangeQuantity = () => {},
  selectedDietIri,
  shortDescription,
}) => {
  const [isExistImage, setIsExistImage] = useState(false);
  const [isToggled, toggle] = useToggle(false);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  useEffect(() => {
    checkImageExists(image)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [image]);

  const imageUrl = isExistImage ? image : '/images/placeholder.jpeg';
  const currentAddonQuantity = basketItemsQuantity()?.[addonIri] ?? 0;
  const currentAddonDeliveryMode = deliveryModesEnabled
    ? basketItemsDeliveryModes?.[addonIri] ?? ADDON_DELIVERY_MODES.FOR_EVERY_BAG
    : null;

  const handleDeliveryModeChange = newDeliveryMode => {
    if (newDeliveryMode === currentAddonDeliveryMode) return;

    onChangeQuantity({
      addonIri,
      quantity: currentAddonQuantity,
      deliveryMode: newDeliveryMode,
      day: dishDate,
      dietIri: selectedDietIri,
    });
  };

  return (
    <MobileTile isSelected={isSelected} isMobile={true} data-cy="addon-tile">
      <div onClick={toggle}>
        <div tw="flex w-full justify-between flex-wrap">
          <AddonImageMobile imageUrl={imageUrl} isOpen={isToggled} />
          <AddonInfoWrapperCollapsed isOpen={isToggled}>
            <div css={[tw`h-full p-3`]}>
              <div tw="flex flex-col h-full">
                <AddonName name={name} isSelected={isSelected} />
                <div tw="text-xs pt-2 mt-auto">
                  {`${getIntegerIfIntegerOrFixed(
                    clientCost
                  )} ${currencySymbol}`}
                </div>
              </div>
            </div>
          </AddonInfoWrapperCollapsed>
        </div>

        <AddonInfoWrapperExpanded isOpen={isToggled}>
          <div tw="flex flex-col h-full">
            <div tw="p-3">
              <div className="row">
                <div className="col">
                  <AddonName name={name} isSelected={isSelected} />
                </div>
                <div className="col-auto">
                  <div tw="flex flex-col items-center">
                    <div onClick={toggle}>
                      <button
                        className="group"
                        tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                      >
                        <ArrowDownIcon
                          className="group-focus:(fill-current)"
                          tw="w-4 transform transition-colors rotate-180 duration-300 hover:(duration-300)"
                          title="Ukryj szczegóły"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              tw="relative px-3 pb-3 flex-auto"
              onClick={e => e.stopPropagation()}
            >
              <div tw="overflow-auto max-h-52">
                <MobileTile.Description
                  dangerouslySetInnerHTML={{ __html: shortDescription }}
                />
              </div>

              <div tw="flex justify-end items-center pt-4">
                <div tw="mr-2">
                  {`${getIntegerIfIntegerOrFixed(
                    clientCost
                  )} ${currencySymbol}`}
                </div>

                <CheckboxCounter
                  count={currentAddonQuantity}
                  min={minCount}
                  max={maxCount}
                  onChange={(e, quantity) => {
                    e.stopPropagation();

                    onChangeQuantity({
                      addonIri,
                      quantity,
                      day: dishDate,
                      dietIri: selectedDietIri,
                      isRemove: currentAddonQuantity > quantity,
                      deliveryMode: currentAddonDeliveryMode,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </AddonInfoWrapperExpanded>
      </div>

      {isSelected && currentAddonDeliveryMode && (
        <AddonDeliveryModeSwitch
          selectedMode={currentAddonDeliveryMode}
          onSwitch={handleDeliveryModeChange}
        />
      )}
    </MobileTile>
  );
};

export default AddonTileMobile;
