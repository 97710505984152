import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, Modal } from '@components';
import { useDeleteUserAddress } from '@hooks';
import UserService from '@services/User.service';

import Address from './Address';
import AddressModal from './AddressModal';

const AddressesList = ({
  isFetched = false,
  addresses = [],
  onClickAddress = () => {},
  onSuccessAddAddress = () => {},
  onSuccessUpdateAddress = () => {},
  canAddNewAddress = false,
  selectedAddresses = [],
  styles = {},
}) => {
  const { t } = useTranslation();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isDeliveryAddressModalOpen, setIsDeliveryAddressModalOpen] =
    useState(false);
  const [typeModal, setTypeModal] = useState('editAddress');
  const [newAddress, setNewAddress] = useState({});
  const [editAddress, setEditAddress] = useState({});
  const [deleteAddressId, setDeleteAdressId] = useState(0);
  const { mutate: deleteUserAddress, isLoading: deleteAddresIsLoading } =
    useDeleteUserAddress();

  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);
  // const openDeliveryAddressModal = () => setIsDeliveryAddressModalOpen(true);
  const closeDeliveryAddressModal = () => setIsDeliveryAddressModalOpen(false);

  const isEditAddress = typeModal === 'editAddress';
  const isAddAddress = typeModal === 'addAddress';

  useEffect(() => {
    if (isFetched && isEmpty(addresses)) {
      handleClickAddAddress();
    }
  }, [isFetched]);

  const handleClickAddress = address => {
    onClickAddress(address);
  };

  const handleClickEditAddress = (e, address) => {
    e.stopPropagation();
    setTypeModal('editAddress');
    setEditAddress(address);
    openAddressModal();
  };

  const handleClickDeleteAddress = (e, addressId) => {
    e.stopPropagation();
    setDeleteAdressId(addressId);
    deleteUserAddress(addressId);
  };

  const handleClickAddAddress = () => {
    console.log('handleClickAddAddress');
    setTypeModal('addAddress');
    setEditAddress({});
    openAddressModal();
  };

  const handleSuccessAddAddress = address => {
    onSuccessAddAddress(address);
    closeAddressModal();
    setNewAddress(address);
    // openDeliveryAddressModal();
  };

  const handleSuccessUpdateAddress = address => {
    onSuccessUpdateAddress(address);
    closeAddressModal();
  };

  const handleSetAddressToAllDelivery = () => {
    // TODO: API
    console.log(newAddress);
    closeDeliveryAddressModal();
  };

  if (isFetched && isEmpty(addresses)) {
    return (
      <Fragment>
        <p>
          {t(
            '$*components.addressesList.noAdress.paragraph',
            '$$Nie posiadasz jeszcze żadnego adresu dostawy kliknij przycisk poniżej, aby dodać adres domyślny.'
          )}
        </p>

        <Button
          onClick={handleClickAddAddress}
          variant="outlined"
          fullWidth={true}
          styles={{ button: tw`mt-5` }}
          data-cy="addresses-list__add-new"
        >
          {t('$*components.addressesList.addAddress', '$$+ Dodaj adres')}
        </Button>

        <AddressModal
          address={editAddress}
          isAddAddress={isAddAddress}
          isEditAddress={isEditAddress}
          isFirstAddress={true}
          isNewAddress={isAddAddress}
          isOpen={isAddressModalOpen}
          onClose={closeAddressModal}
          onSuccessAddAddress={handleSuccessAddAddress}
          onSuccessUpdateAddress={handleSuccessUpdateAddress}
        />
      </Fragment>
    );
  }

  return (
    <div>
      <div tw="flex -m-3 flex-wrap" data-cy="addresses-list">
        {UserService.sortAddressesByDefaultFirst(addresses).map(
          (address, index) => {
            return (
              <Address
                key={address.id}
                itemNumber={index + 1}
                address={address}
                deleteAddressId={deleteAddressId}
                selectedAddresses={selectedAddresses}
                onClickAddress={handleClickAddress}
                onClickEditAddress={handleClickEditAddress}
                onClickDeleteAddress={handleClickDeleteAddress}
                deleteAddresIsLoading={deleteAddresIsLoading}
                styles={styles?.address}
              />
            );
          }
        )}
      </div>

      {canAddNewAddress && (
        <Button
          onClick={handleClickAddAddress}
          variant="outlined"
          fullWidth={true}
          styles={{ button: tw`mt-8` }}
          data-cy="addresses-list__add-new"
        >
          {t('$*components.addressesList.addAddress', '$$+ Dodaj adres')}
        </Button>
      )}

      <AddressModal
        address={editAddress}
        isAddAddress={isAddAddress}
        isEditAddress={isEditAddress}
        isNewAddress={isAddAddress}
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        onSuccessAddAddress={handleSuccessAddAddress}
        onSuccessUpdateAddress={handleSuccessUpdateAddress}
      />

      <Modal
        isOpen={isDeliveryAddressModalOpen}
        onClose={closeDeliveryAddressModal}
      >
        <Modal.Header>
          {t(
            '$*components.addressesList.modal.title.changeDelivery',
            '$$Zmiana adresu dostaw'
          )}
        </Modal.Header>
        <Modal.Content>
          <p>
            {t(
              '$*components.addressesList.modal.content.changeDelivery',
              '$$Czy chcesz zmienić adres dla wszystkich aktywnych dostaw?'
            )}
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button color="default" size="sm" onClick={closeDeliveryAddressModal}>
            {t('$*common.cancel', '$$Anuluj')}
          </Button>
          <Button
            color="success"
            size="sm"
            // isLoading={isLoading}
            onClick={handleSetAddressToAllDelivery}
          >
            {t('$*common.confirm', '$$Potwierdź')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddressesList;
