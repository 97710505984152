import { useTranslation } from 'next-i18next';

import { AnimatePresence } from 'framer-motion';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import ConditionalWrapper from '@components/elements/ConditionalWrapper';
import Checkbox from '@components/elements/Form/Checkbox';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import MenuDishImage from './MenuDishImage';
import MenuDishInfo from './MenuDishInfo';
import MenuDishInfoWrapper from './MenuDishInfoWrapper';
import MenuDishName from './MenuDishName';
import MenuDishTags from './MenuDishTags';
import { useMenuDishTileContext } from './MenuDishTile.context';
import MenuDishWrapper from './MenuDishWrapper';

const MenuDishTile = () => {
  const { t } = useTranslation();
  const {
    dishIsSelected,
    handleClickDish,
    handleToggleOpenMenu,
    image,
    isExistImage,
    isOpenMenu,
    isOpenMenuOnClick,
    name,
    showDishImage,
    showTags,
    tags,
  } = useMenuDishTileContext();
  const { '@resources': resources, dishImagePlaceholder } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const imageUrl =
    showDishImage && isExistImage
      ? image || ''
      : resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg';

  return (
    <MenuDishWrapper
      onClick={
        showDishImage
          ? isOpenMenuOnClick
            ? handleToggleOpenMenu
            : handleClickDish
          : handleToggleOpenMenu
      }
    >
      <MenuDishImage showImage={showDishImage} imageUrl={imageUrl} />

      <MenuDishInfoWrapper>
        <div tw="flex flex-col h-full">
          <div css={[tw`p-3`, !isOpenMenu && tw`h-full`]}>
            <div className="row" css={[!isOpenMenu && tw`h-full`]}>
              <div className="col">
                <ConditionalWrapper
                  condition={!isOpenMenu}
                  wrapper={children => (
                    <div css={showDishImage && tw`flex flex-col h-full`}>
                      {children}
                    </div>
                  )}
                >
                  <MenuDishName name={name} />
                </ConditionalWrapper>

                <AnimatePresence>
                  {isOpenMenu && showTags && (
                    <div tw="pt-4 flex -my-0.5 -mx-1 flex-wrap">
                      <MenuDishTags tags={tags} />
                    </div>
                  )}
                </AnimatePresence>
              </div>

              <div className="col-auto">
                <div tw="flex flex-col items-center">
                  <button
                    className="group"
                    tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                  >
                    {isOpenMenu ? (
                      <ArrowDownIcon
                        className="group-focus:(fill-current)"
                        css={[
                          tw`w-4 transform transition-colors duration-300 hover:(duration-300)`,
                          showDishImage ? tw`` : tw`rotate-180`,
                        ]}
                        title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                      />
                    ) : (
                      <ArrowDownIcon
                        className="group-focus:(fill-current)"
                        css={[
                          tw`w-4 transform transition-colors duration-300 hover:(duration-300)`,
                          showDishImage ? tw`rotate-180` : tw``,
                        ]}
                        title={t('$*common.showDetails', '$$Pokaż szczegóły')}
                      />
                    )}
                  </button>
                </div>

                <div tw="flex flex-col items-center mt-3">
                  <Checkbox
                    value={dishIsSelected}
                    readOnly
                    onClick={e => {
                      e.stopPropagation();
                      handleClickDish();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <AnimatePresence>
            {isOpenMenu && (
              <div
                css={[
                  tw`relative px-3 pb-3 flex-auto`,
                  showDishImage && tw`overflow-auto`,
                ]}
                onClick={handleToggleOpenMenu}
                data-cy=""
              >
                <MenuDishInfo />
              </div>
            )}
          </AnimatePresence>
        </div>
      </MenuDishInfoWrapper>
    </MenuDishWrapper>
  );
};

export default MenuDishTile;
