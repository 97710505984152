import { useMutation, useQueryClient } from 'react-query';
import produce from 'immer';

const useQueryUpdate = (keyStore, options = {}) => {
  const queryClient = useQueryClient();
  const query = useMutation(
    callback => queryClient.setQueryData(keyStore, produce(callback)),
    {
      ...options,
    }
  );

  return query;
};

export default useQueryUpdate;
