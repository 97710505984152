import { css } from '@emotion/react';
import tw from 'twin.macro';

import { useProductTileContext } from './ProductTile.context';

const ProductInfoWrapper = ({ children }) => {
  const { isOpenMenu, showImage } = useProductTileContext();

  return (
    <div
      css={css`
        ${showImage ? tw`absolute bottom-0 left-0 right-0` : ''}
        ${tw`overflow-hidden duration-300 transform bg-white rounded-lg`};
        ${isOpenMenu ? 'height: 366px;' : 'height: 110px'}
      `}
    >
      {children}
    </div>
  );
};

export default ProductInfoWrapper;
