import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getPaypoLastAddress = async () => {
  const response = await axios.get(ENDPOINT.PAYPO_LAST_ADDRESS);

  return response?.data ?? {};
};

const usePaypoLastAddress = (options = {}) => {
  const query = useQuery('paypoLastAddress', getPaypoLastAddress, {
    ...options,
  });

  return query;
};

export default usePaypoLastAddress;
