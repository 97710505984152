import Head from 'next/head';

const PayUScript = ({ sandbox = true }) => {
  if (sandbox) {
    return (
      <Head>
        <script
          id="payu-sandbox-script"
          type="text/javascript"
          src="https://secure.snd.payu.com/javascript/sdk"
        />
      </Head>
    );
  }

  return (
    <Head>
      <script
        id="payu-script"
        type="text/javascript"
        src="https://secure.payu.com/javascript/sdk"
      />
    </Head>
  );
};

export default PayUScript;
