import { useState } from 'react';
import Select from 'react-select';
import kebabCase from 'lodash/kebabCase';
import { theme } from 'twin.macro';

const InputSelect = ({
  name,
  options,
  value,
  isOpenedOnInit = false,
  onBlur = () => {},
  onChange = () => {},
  styles = null,
  disabled = false,
  ...restProps
}) => {
  const [isSelectOpened, setIsSelectOpened] = useState(isOpenedOnInit);
  const customStyles = styles || {
    control: (provided, { isDisabled }) => ({
      ...provided,
      padding: '4px 0 4px 20px',
      background: isDisabled ? theme`colors.gray.200` : provided.background,
      'border-color': isDisabled
        ? theme`colors.gray.300`
        : provided['border-color'],
      cursor: isDisabled ? 'not-allowed' : provided.cursor,
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme`colors.gray.600` : provided.color,
    }),
    placeholder: provided => ({
      ...provided,
      margin: 0,
    }),
    indicatorSeparator: (provided, { isDisabled }) => ({
      ...provided,
      'background-color': isDisabled
        ? theme`colors.gray.400`
        : provided['background-color'],
    }),
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme`colors.gray.400` : provided.color,
    }),
    menuList: provided => ({
      ...provided,
      background: theme`colors.white`,
    }),
    option: provided => ({
      ...provided,
      padding: '8px 20px',
      '&:hover': {
        cursor: 'pointer',
        color: theme`colors.white`,
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 99 }),
  };

  const onInputChange = (options, { action }) => {
    if (action === 'menu-close') {
      setIsSelectOpened(false);
    }
  };

  const onFocus = () => {
    setIsSelectOpened(true);
  };

  return (
    <Select
      id="select"
      className={`select--${kebabCase(name)}`}
      isSearchable={false}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      value={value}
      name={name}
      onFocus={onFocus}
      blurInputOnSelect
      onInputChange={onInputChange}
      menuIsOpen={isSelectOpened || undefined}
      menuPortalTarget={document.querySelector('#selectPortal')}
      isDisabled={disabled}
      {...restProps}
      styles={customStyles}
      theme={defaultTheme => ({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary25: theme`colors.primary-hover`,
          primary: theme`colors.primary`,
          primary50: theme`colors.primary-hover`,
          neutral50: 'red',
        },
      })}
    />
  );
};

InputSelect.displayName = 'InputSelect';

export default InputSelect;
