import DisplayOn from './DisplayOn';

const DisplayDownSm = ({ children }) => (
  <DisplayOn size="sm" mode="down" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayDownSm.displayName = 'DisplayDownSm';

export default DisplayDownSm;
