const CardIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 4134 3049"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M4133.822 925.75v1733.876c-.936 214.053-174.243 387.411-388.357 389.242H919.91c-214.083-.915-386.352-175.19-387.309-389.242v-153.214h-151.21C170.34 2505.496-.912 2334.376-.007 2123.274V389.204C-.007 176.108 171.174-.007 384.27-.007h2824.782c213.055 0 382.303 176.156 382.303 389.211V542.46h154.242c213.056 0 388.225 170.245 388.225 383.29zm-129.439 1734.182v-972.19H662.03v971.884c0 143.041 114.595 258.715 257.534 259.834h2826.308c142.817-1.119 257.382-116.691 258.511-259.528zm-406.08-602.481h.031c147.263 100.007 186.615 300.833 86.66 448.045-99.956 147.314-300.763 186.584-448.026 86.68-11.527-7.834-26.665-7.834-38.171 0-53.493 36.624-115.918 55.242-180.103 55.242-178.496 0-322.148-145.482-322.148-323.113 0-177.53 143.672-322.097 322.148-322.097 64.185 0 126.61 18.618 180.103 55.243 11.476 7.834 26.573 7.834 38.1.102 109.763-74.064 252.54-74.166 361.407-.102zm-34.823 392.192c68.265-81.389 57.511-202.353-23.929-270.617-81.45-68.265-202.414-57.583-270.678 23.907-.02 0-.041 0-.062.102-3.326 3.968-6.602 7.325-9.929 9.97-.081.102-.153.102-.224.204-27.855 22.687-68.641 18.007-91.328-9.869-35.994-44.356-91.115-70.706-148.636-70.706-107.719 0-193.818 86.17-192.709 193.909.102 106.212 87.096 192.28 193.34 192.484 57.612 1.12 112.845-25.23 148.9-69.689 12.26-14.752 30.358-22.992 49.698-22.992.397 0 .784-.102 1.17-.102 18.333-.61 36.625 7.732 48.63 22.28.214.204.417.509.63.712 8.343 9.564 15.709 17.702 25.13 24.926 81.409 67.654 201.915 56.768 269.997-24.519zM532.6 925.75c0-213.025 174.203-383.29 387.238-383.29h2543.064V389.204c0-140.945-110.78-259.772-252.864-259.772H384.27c-141.993 0-254.838 118.909-254.838 259.772v1738.14c1.14 138.971 116.06 250.677 255.011 249.66h148.158V925.75zm3471.782 632.605V1234.09H662.03v324.264h3342.353zm.997-453.702V925.75c0-142.085-116.711-253.852-258.796-253.852H919.961C777.907 670.84 663.17 784.54 662.041 925.475c0 .092-.011.183-.011.275v178.903h3343.35z"
      fillRule="nonzero"
    />
  </svg>
);

export default CardIcon;
