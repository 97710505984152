import { ENDPOINT } from '@constants/endpoint';
import useInfiniteQueryMiddleware from '@hooks/useInfiniteQueryMiddleware';

const useInfiniteMoneyBoxHistory = () => {
  const query = useInfiniteQueryMiddleware(
    'moneyBoxHistory',
    ENDPOINT.MONEY_BOX_HISTORY,
    { itemsPerPage: 50 }
  );

  return query;
};

export default useInfiniteMoneyBoxHistory;
