import * as Yup from 'yup';

import { useDefaultSchemaYup } from '@utils/yupMethods';

import { INPUT_IDS } from './consts';

export const useInitialValues = (isSubscriptionInCart = false) => {
  return {
    [INPUT_IDS.CARD_NAME]: '',
    ...(isSubscriptionInCart ? { [INPUT_IDS.TERMS]: false } : {}),
  };
};

export const useValidationSchema = isSubscriptionInCart => {
  const { booleanRequired } = useDefaultSchemaYup();

  return isSubscriptionInCart
    ? Yup.object({
        [INPUT_IDS.TERMS]: booleanRequired,
      })
    : null;
};
