import isArray from 'lodash/isArray';

export const MONTHS = {
  pl: [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ],
  fi: [
    'Tammikuu',
    'Helmikuu',
    'Maaliskuu',
    'Huhtikuu',
    'Toukokuu',
    'Kesäkuu',
    'Heinäkuu',
    'Elokuu',
    'Syyskuu',
    'Lokakuu',
    'Marraskuu',
    'Joulukuu',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
};

export const WEEKDAYS_SHORT = {
  pl: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
  fi: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
};

export const WEEKDAYS_LONG = {
  pl: [
    'Niedziela',
    'Poniedziałek',
    'Wtorek',
    'Środa',
    'Czwartek',
    'Piątek',
    'Sobota',
  ],
  fi: [
    'Sunnuntai',
    'Maanantai',
    'Tiistai',
    'Keskiviikko',
    'Torstai',
    'Perjantai',
    'Lauantai',
  ],
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  de: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
};

export const FIRST_DAY_OF_WEEK = {
  pl: 1,
  fi: 1,
  en: 0, // monday
  de: 1,
};

// Translate aria-labels
export const LABELS = {
  pl: { nextMonth: 'Następny miesiąc', previousMonth: 'Poprzedni miesiąc' },
  fi: { nextMonth: 'Ensikuussa', previousMonth: 'Edellinen kuukausi' },
  en: { nextMonth: 'Next month', previousMonth: 'Previous month' },
  de: { nextMonth: 'Nächster Monat', previousMonth: 'Vorheriger Monat' },
};

export const getFormattedDisabledDaysOfWeek = disabledDaysOfWeek => {
  if (!disabledDaysOfWeek || !isArray(disabledDaysOfWeek)) return [];

  const formattedArray = disabledDaysOfWeek.map(dayIndex => {
    if (dayIndex === 7) {
      return 0;
    }
    return dayIndex;
  });

  return formattedArray;
};
