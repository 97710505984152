import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { ExclamationIcon } from '@assets/icons';
import {
  Addons,
  ApiLoadingBoundary,
  Button,
  Countdown,
  Tooltip,
} from '@components';
import { useAddons, useBasketMethods } from '@hooks';

import { useSelectAddonsSectionContext } from './SelectAddonsSection.context';

const SelectAddonsSection = ({
  isHeaderSticky = false,
  deadlineBuyAddons,
  isSaveChangesModeActive,
  isCurrentDateBeforeDeadlineBuyAddons,
  headerTitle = null,
}) => {
  const { t } = useTranslation();
  const {
    data: items = [],
    isError,
    isLoading,
    isFetched,
    refetch,
  } = useAddons();

  const {
    selectedDate,
    onSwitchMode,
    selectedAddons,
    selectedDietIri,
    handleSaveChanges,
    activeOrderDietIri,
    checkIsAddonSelected,
    isSaveChangesEnabled,
    isSaveChangesLoading,
    deliveryModesEnabled,
    handleChangeAddonQuantity,
  } = useSelectAddonsSectionContext();
  const { basketStore } = useBasketMethods();

  const basketItems = basketStore?.items;

  const showHeader =
    headerTitle ||
    (deadlineBuyAddons && isCurrentDateBeforeDeadlineBuyAddons) ||
    (isSaveChangesModeActive && !deadlineBuyAddons);

  const currentDiet = selectedDate
    ? {}
    : basketItems?.[selectedDietIri]?.find(
        row => row['@id'] === activeOrderDietIri
      );

  if (isEmpty(items) && !isLoading && isFetched && !isError) {
    return (
      <div tw="flex justify-center">
        {t('$*components.selectAddonsSection.emptyAddons', '$$Brak dodatków')}
      </div>
    );
  }

  return (
    <>
      {showHeader && (
        <div
          css={[
            tw`flex flex-wrap items-baseline justify-center pb-1 mb-3 -mx-1 -mt-1 border-b border-gray-200 md:justify-between`,
            isHeaderSticky && tw`sticky z-10 pt-2 bg-white`,
            isHeaderSticky && { top: '79px' },
          ]}
        >
          {headerTitle && (
            <h2 tw="mb-0 font-semibold py-1 px-2">{headerTitle}</h2>
          )}
          {deadlineBuyAddons && isCurrentDateBeforeDeadlineBuyAddons ? (
            <div tw="flex w-full md:w-auto flex-col md:flex-row flex-wrap items-center px-2 py-1">
              <div tw="pr-3 flex items-center">
                {t('$*common.leftTo', '$$Pozostało')}{' '}
                <Tooltip
                  content={
                    <div>
                      {t(
                        '$*common.leftTo.buyAddons',
                        '$$Pozostały czas do złożenia zamówienia na dodatki'
                      )}
                    </div>
                  }
                >
                  <div tw="ml-2">
                    <ExclamationIcon tw="w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </div>
              <Countdown
                endDate={deadlineBuyAddons}
                isCurrentDateBeforeEnd={isCurrentDateBeforeDeadlineBuyAddons}
              />
              {isSaveChangesModeActive && (
                <Button
                  tw="mt-5 md:ml-2 md:mt-0"
                  size="sm"
                  onClick={handleSaveChanges}
                  isLoading={isSaveChangesLoading}
                  disabled={isSaveChangesLoading || !isSaveChangesEnabled}
                >
                  {t('$*common.saveChanges', '$$Zapisz zmiany')}
                </Button>
              )}
            </div>
          ) : (
            <>
              {isSaveChangesModeActive && !deadlineBuyAddons && (
                <Button
                  tw="mt-5 md:ml-2 md:mt-0"
                  size="sm"
                  onClick={handleSaveChanges}
                  isLoading={isSaveChangesLoading}
                  disabled={isSaveChangesLoading || !isSaveChangesEnabled}
                >
                  {t('$*common.saveChanges', '$$Zapisz zmiany')}
                </Button>
              )}
            </>
          )}
        </div>
      )}
      <ApiLoadingBoundary
        isError={isError}
        isLoading={isLoading}
        refetch={refetch}
        justify="left"
        styles={{
          loading: { wrapper: tw`pt-6` },
          error: { wrapper: { css: tw`pt-6` } },
        }}
      >
        <div tw="pt-3">
          <Addons
            items={items}
            checkIsAddonSelected={checkIsAddonSelected}
            onSwitchMode={onSwitchMode}
            onChangeQuantity={handleChangeAddonQuantity}
            dishDate={selectedDate}
            basketItemsQuantity={() => {
              if (isSaveChangesModeActive && !isEmpty(selectedAddons)) {
                return selectedAddons.reduce(
                  (acc, addon) => ({
                    ...acc,
                    [addon?.addonIri]: addon?.quantity,
                  }),
                  {}
                );
              }

              if (isSaveChangesModeActive && isEmpty(selectedAddons)) {
                return {};
              }

              if (selectedDate) {
                return basketItems?.[selectedDietIri]?.[selectedDate] || {};
              }

              return currentDiet?.addons.reduce(
                (acc, { addon, quantity }) => ({
                  ...acc,
                  [addon]: quantity,
                }),
                {}
              );
            }}
            selectedDietIri={selectedDietIri}
            deliveryModesEnabled={deliveryModesEnabled}
            basketItemsDeliveryModes={
              deliveryModesEnabled
                ? currentDiet?.addons.reduce(
                    (acc, { addon, type }) => ({
                      ...acc,
                      [addon]: type,
                    }),
                    {}
                  )
                : {}
            }
          />
        </div>
      </ApiLoadingBoundary>
    </>
  );
};

export default SelectAddonsSection;
