const SpinnerIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 69 72"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M35.63 59.85c-1.57 0-2.92.56-4.03 1.67-1.11 1.12-1.67 2.46-1.67 4.03 0 1.58.56 2.92 1.67 4.03 1.11 1.12 2.46 1.67 4.03 1.67 1.57 0 2.92-.55 4.03-1.67 1.11-1.11 1.67-2.45 1.67-4.03 0-1.57-.56-2.91-1.67-4.03-1.11-1.11-2.46-1.67-4.03-1.67zm-19.95-9.26c-1.78 0-3.3.62-4.54 1.87-1.25 1.25-1.87 2.76-1.87 4.54 0 1.78.62 3.3 1.87 4.54 1.24 1.25 2.76 1.88 4.54 1.88 1.75 0 3.26-.63 4.52-1.88 1.26-1.24 1.89-2.76 1.89-4.54 0-1.78-.63-3.29-1.89-4.54-1.26-1.25-2.77-1.87-4.52-1.87zm39.9 1.42c-1.37 0-2.54.49-3.52 1.47s-1.47 2.16-1.47 3.52c0 1.37.49 2.54 1.47 3.52s2.15 1.47 3.52 1.47 2.54-.49 3.52-1.47 1.47-2.15 1.47-3.52c0-1.36-.49-2.54-1.47-3.52s-2.15-1.47-3.52-1.47zM14.26 37.05c0-1.96-.7-3.63-2.1-5.03-1.39-1.4-3.07-2.09-5.03-2.09-1.96 0-3.64.69-5.03 2.09C.7 33.41.01 35.09.01 37.05c0 1.96.69 3.64 2.09 5.03 1.39 1.4 3.07 2.1 5.03 2.1 1.96 0 3.64-.7 5.03-2.1 1.4-1.39 2.1-3.07 2.1-5.03zm52.9-3.03c-.83-.83-1.84-1.24-3.03-1.24-1.19 0-2.2.41-3.03 1.24-.83.83-1.24 1.84-1.24 3.03 0 1.19.41 2.2 1.24 3.03.83.83 1.84 1.25 3.03 1.25 1.19 0 2.2-.42 3.03-1.25.83-.83 1.25-1.84 1.25-3.03 0-1.19-.42-2.2-1.25-3.03zM15.68 9.26c-2.17 0-4.01.77-5.54 2.3-1.53 1.53-2.3 3.37-2.3 5.54s.77 4.02 2.3 5.55c1.53 1.53 3.37 2.29 5.54 2.29s4.02-.77 5.54-2.29c1.53-1.53 2.3-3.38 2.3-5.55s-.77-4.01-2.3-5.54c-1.52-1.53-3.37-2.3-5.54-2.3zm39.9 11.4c.98 0 1.82-.35 2.52-1.04.69-.7 1.04-1.54 1.04-2.52s-.35-1.82-1.04-2.52c-.7-.69-1.54-1.04-2.52-1.04s-1.82.35-2.52 1.04c-.69.7-1.04 1.54-1.04 2.52s.35 1.82 1.04 2.52c.7.69 1.54 1.04 2.52 1.04zM35.63 0c-2.37 0-4.39.83-6.06 2.49-1.66 1.67-2.49 3.69-2.49 6.06 0 2.38.83 4.39 2.49 6.06 1.67 1.66 3.69 2.49 6.06 2.49 2.38 0 4.39-.83 6.06-2.49 1.66-1.66 2.49-3.68 2.49-6.06 0-2.37-.83-4.39-2.49-6.06C40.02.83 38.01 0 35.63 0z" />
  </svg>
);

export default SpinnerIcon;
