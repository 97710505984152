import { useMutation, useQueryClient } from 'react-query';

const useDeleteValidateDiscountCode = () => {
  const queryClient = useQueryClient();
  const initial = {};

  const query = useMutation(() => initial, {
    onSuccess: () => {
      queryClient.setQueryData('discountCode', () => initial);
    },
  });

  return query;
};

export default useDeleteValidateDiscountCode;
