import DAY_STATUSES from '@constants/dayStatuses';
import { useAppContext } from '@views/App/App.context';

const getOrderDetailsComponentsConfig = ({
  bagState = null,
  bagType = null,
}) => {
  const { isShop } = useAppContext();
  const canRate = [
    DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE,
    DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE,
    DAY_STATUSES.DELIVERED_RATED_CAN_RATE,
  ];

  const showRateMenu = [...canRate].includes(bagState);
  const showRateDelivery = [
    ...canRate,
    DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED,
    DAY_STATUSES.DELIVERED_RATED_BLOCKED,
    DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED,
  ].includes(bagState);

  const showModule = {
    menuDetails: [
      DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL,
      DAY_STATUSES.NOT_DELIVERED_BLOCKED,
      DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED,
      DAY_STATUSES.DELIVERED_RATED_BLOCKED,
      DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED,
    ].includes(bagState),
    rateMenu: showRateMenu,
    rateDelivery: showRateDelivery,
    noOrders: [DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER].includes(bagState),
    noOrdersPlanned: [DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER].includes(bagState),
    notDelivery: [DAY_STATUSES.DISABLED].includes(bagState),
    menuSoon: [
      DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU,
    ].includes(bagState),
    disabledWithBag: [DAY_STATUSES.DISABLED_WITH_BAG].includes(bagState),

    shopElements: [
      DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL,
      DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU,
      DAY_STATUSES.NOT_DELIVERED_BLOCKED,
      DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED,
      DAY_STATUSES.DELIVERED_RATED_BLOCKED,
      DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED,
    ].includes(bagState),

    menuPlan: [
      DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER,
      DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER,
      DAY_STATUSES.SUBSCRIPTION_SUSPENDED,
    ].includes(bagState),

    deliveryDetails: ![
      DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER,
      DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER,
      DAY_STATUSES.DISABLED,
    ].includes(bagState),
    orderBasket:
      bagType !== 'SubscriptionIntent' &&
      [
        ...(isShop ? [DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER] : []),
        DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL,
        DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU,
        DAY_STATUSES.NOT_DELIVERED_BLOCKED,
      ].includes(bagState),
  };

  return showModule;
};

// check that NEW_STATE need fetch bagStatus
const isDayStatusNeedUserBagItems = ({ bagState = null }) => {
  return [
    DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL,
    DAY_STATUSES.NOT_DELIVERED_BLOCKED,
    DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED,
    DAY_STATUSES.DELIVERED_RATED_BLOCKED,
    DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED,
    DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE,
    DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE,
    DAY_STATUSES.DELIVERED_RATED_CAN_RATE,
    DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU,
  ].includes(bagState);
};

export default {
  getOrderDetailsComponentsConfig,
  isDayStatusNeedUserBagItems,
};
