import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const createBasketOrder = async ({ id, payload = {} }) => {
  const response = await axios.put(ENDPOINT.BASKET_CREATE_ORDER(id), payload, {
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useCreateBasketOrder = (options = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(createBasketOrder, {
    onSuccess: response => {
      queryClient.setQueryData('order', () => response);

      showToast(
        t(
          '$*notification.createBasketOrder.success',
          '$$Zamówienie zostało pomyślnie utworzone'
        ),
        { type: 'success' }
      );
    },
    ...options,
  });

  return query;
};

export default useCreateBasketOrder;
