import RouterLink from 'next/link';

import { css } from '@emotion/react';
import tw from 'twin.macro';

import { Button } from '@components';

const ProductCard = ({
  image,
  hideImage = false,
  anchorLink,
  anchorText,
  buttonText,
  productName,
  onCardClick,
  onButtonClick,
}) => {
  return (
    <div
      onClick={onCardClick}
      css={css`
        width: 100%;
        min-height: 325px;
      `}
    >
      {!hideImage && (
        <div
          css={css`
            background: url(${image ?? '/images/placeholder.jpeg'}) no-repeat
              center center / cover;
            height: 250px;
            ${tw`w-full rounded-lg`}
          `}
        />
      )}
      <div
        css={css`
          ${tw`p-2 -mt-2 overflow-hidden bg-white `}
          border-radius: 8px;
        `}
      >
        <h3
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          tw="text-center text-lg overflow-hidden px-2"
        >
          {productName}
        </h3>
        {anchorText && (
          <div tw="flex justify-center">
            <RouterLink href="/diets/[id]" as={anchorLink} passHref>
              <a tw="mx-auto pb-2 underline">{anchorText}</a>
            </RouterLink>
          </div>
        )}
        {buttonText && (
          <Button fullWidth onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
