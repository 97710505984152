import { Trans, useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateUserData } from '@store/auth/auth.actions';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex, Modal } from '@components';
import showToast from '@utils/showToast';

import { initialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const MissingFullNameModal = ({ isOpen, onClose, disableClose = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fields = useFields();
  const validationSchema = useValidationSchema();

  const hancleClickSubmit = (values, { setSubmitting, setStatus }) => {
    dispatch(updateUserData(values))
      .then(unwrapResult)
      .then(() => {
        onClose();
        showToast(
          t(
            '$*notification.update.success',
            '$$Dane zostały pomyślnie zaktualizowane'
          ),
          { type: 'info' }
        );
      })
      .catch(error => {
        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      disableClose={disableClose}
      styles={{ wrapper: tw`w-full max-w-lg` }}
    >
      <Modal.Header>
        {t('$*components.missingFullNameModal.title', '$$Uwaga')}
      </Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="$*components.missingFullNameModal.description"
          defaults="<0>$$Zalogowałeś się korzystając z autoryzacji Apple.<br/>Proszę uzupełnij swoje dane!</0>"
          components={[<p key="0" tw="mb-6" />]}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={hancleClickSubmit}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <div className="row">
                {fields.map(
                  ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
                    return (
                      <div className={colClass} key={id} css={colCss}>
                        <InputsIndex id={id} {...restProps} />
                      </div>
                    );
                  }
                )}
              </div>

              {status?.apiErrorMessage && (
                <Alert styles={{ css: tw`mt-4` }}>
                  {/* i18next-extract-disable-next-line */}
                  {t(`$*error.api.${status.apiErrorMessage}`)}
                </Alert>
              )}

              <div tw="pt-3">
                <Button
                  type="submit"
                  color="success"
                  variant="outlined"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  fullWidth={true}
                >
                  {t('$*common.save', '$$Zapisz')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

MissingFullNameModal.displayName = 'MissingFullNameModal';

export default MissingFullNameModal;
