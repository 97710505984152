import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import tw from 'twin.macro';
import { v4 as uuidv4 } from 'uuid';

import INPUT_IDS from '@constants/inputs/addressInputs';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import {
  transformAllowedBuildingNumbersToArrayOptions,
  transformAllowedCitiesToArrayOptions,
  transformAllowedStreetsToArrayOptions,
  transformDeliveryHoursToArrayOptions,
} from '@services/Api.service';

const useFields = address => {
  const { t } = useTranslation();
  const { useAddressesWithLessFields } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const disableEditField = address?.containsActiveDiets ?? false;

  const formFields = useMemo(() => {
    if (useAddressesWithLessFields) {
      const fields = [
        [
          {
            id: INPUT_IDS.ADDRESS_ID,
            type: 'hidden',
            formGroupProps: { styles: { mode: 'replace', css: tw`mb-0` } },
          },
          {
            colClass: 'sm:col-6',
            id: INPUT_IDS.POSTAL_CODE,
            type: 'text',
            label: t('$*input.postal.label', '$$Kod pocztowy'),
            required: true,
            autoComplete: uuidv4(),
            disabled: disableEditField,
          },
          {
            colClass: 'sm:col-6',
            id: INPUT_IDS.CITY,
            type: 'text',
            label: t('$*input.city.label', '$$Miasto'),
            required: true,
            autoComplete: uuidv4(),
            disabled: disableEditField,
          },
          {
            colClass: 'sm:col-6',
            id: INPUT_IDS.CITY_OPTION,
            type: 'select',
            label: t('$*input.city.label', '$$Miasto'),
            required: true,
            options: transformAllowedCitiesToArrayOptions(
              address.allowedCities,
              t
            ),
            disabled: disableEditField,
          },
          {
            id: INPUT_IDS.ADDRESS_LINE1,
            type: 'text',
            label: t('$*input.addressLine1.label', '$$Wiersz adresu 1'),
            required: true,
            autoComplete: uuidv4(),
            disabled: disableEditField,
          },
          {
            colClass: 'sm:col-6',
            id: INPUT_IDS.ADDRESS_LINE2,
            type: 'text',
            label: t('$*input.addressLine2.label', '$$Wiersz adresu 2'),
            required: false,
            autoComplete: uuidv4(),
          },
          {
            colClass: 'sm:col-6',
            id: INPUT_IDS.DELIVERY_HOURS,
            type: 'select',
            label: t('$*input.deliveryHours.label', '$$Godziny dostawy'),
            required: true,
            options: transformDeliveryHoursToArrayOptions(
              address.deliveryHourOptions,
              t
            ),
            disabled: disableEditField,
          },
        ],
        [
          {
            id: INPUT_IDS.SET_AS_DEFAULT,
            type: 'checkbox',
            label: t(
              '$*input.setAsDefault.label',
              '$$Ustaw jako adres domyślny'
            ),
            required: false,
            formGroupProps: { styles: { mode: 'replace', css: tw`mb-0` } },
          },
        ],
      ];

      return fields;
    }

    const fields = [
      [
        {
          id: INPUT_IDS.ADDRESS_ID,
          type: 'hidden',
          formGroupProps: { styles: { mode: 'replace', css: tw`mb-0` } },
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.POSTAL_CODE,
          type: 'text',
          label: t('$*input.postal.label', '$$Kod pocztowy'),
          required: true,
          autoComplete: uuidv4(),
          disabled: disableEditField,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.CITY,
          type: 'text',
          label: t('$*input.city.label', '$$Miasto'),
          required: true,
          autoComplete: uuidv4(),
          disabled: disableEditField,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.CITY_OPTION,
          type: 'select',
          label: t('$*input.city.label', '$$Miasto'),
          required: true,
          isSearchable: true,
          options: transformAllowedCitiesToArrayOptions(
            address.allowedCities ?? {}
          ),
          disabled: disableEditField,
        },
        {
          colClass: 'col-12',
          id: INPUT_IDS.STREET,
          type: 'text',
          label: t('$*input.street.label', '$$Ulica'),
          required: true,
          autoComplete: uuidv4(),
          disabled: disableEditField,
        },
        {
          colClass: 'col-12',
          id: INPUT_IDS.STREET_OPTION,
          type: 'select',
          label: t('$*input.street.label', '$$Ulica'),
          required: true,
          isSearchable: true,
          options: transformAllowedStreetsToArrayOptions(
            address.allowedStreets ?? {}
          ),
          disabled: disableEditField,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.HOUSE_NUMBER,
          type: 'text',
          label: t('$*input.houseNumber.label', '$$Numer'),
          required: true,
          disabled: disableEditField,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.HOUSE_NUMBER_OPTION,
          type: 'select',
          label: t('$*input.houseNumber.label', '$$Numer'),
          required: true,
          isSearchable: true,
          options: transformAllowedBuildingNumbersToArrayOptions(
            address.allowedBuildingNumbers ?? {}
          ),
          disabled: disableEditField,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.HOUSE_UNIT_NUMBER,
          type: 'text',
          label: t('$*input.houseUnitNumber.label', '$$Lokal'),
          required: false,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.FLOOR,
          type: 'number',
          label: t('$*input.floor.label', '$$Piętro'),
          required: false,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.STAIRCASE,
          type: 'text',
          label: t('$*input.stage.label', '$$Klatka schodowa'),
          required: false,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.INTERCOM_CODE,
          type: 'textEmoji',
          label: t('$*input.intercomCode.label', '$$Kod domofonu'),
          maxLength: 20,
          required: false,
        },
        {
          colClass: 'sm:col-6',
          id: INPUT_IDS.DELIVERY_HOURS,
          type: 'select',
          label: t('$*input.deliveryHours.label', '$$Godziny dostawy'),
          required: true,
          options: transformDeliveryHoursToArrayOptions(
            address.deliveryHourOptions,
            t
          ),
          disabled: disableEditField,
        },
        {
          id: INPUT_IDS.ADDITIONAL_INFO,
          type: 'text',
          label: t(
            '$*input.additionalInfo.label',
            '$$Dodatkowe uwagi do adresu (np. numer telefonu)'
          ),
          required: false,
          maxLength: 400,
          formGroupProps: { styles: { mode: 'replace', css: tw`mb-0` } },
        },
      ],
      [
        {
          id: INPUT_IDS.SET_AS_DEFAULT,
          type: 'toggle',
          label: t('$*input.setAsDefault.label', '$$Ustaw jako adres domyślny'),
          required: false,
          formGroupProps: { styles: { mode: 'replace', css: tw`mb-0` } },
        },
      ],
    ];

    return fields;
  }, [t, useAddressesWithLessFields, disableEditField]);

  return formFields;
};

export default useFields;
