import { useTranslation } from 'next-i18next';

import { cloneElement, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import {
  Alert,
  Button,
  InputsIndex,
  Spinner,
  withStripeConsumer,
} from '@components';
import { useBasketMethods, usePaymentCards } from '@hooks';

import { useInitialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const StripeCardForm = ({ stripeSecret }) => {
  const { t } = useTranslation();
  const [isReadyFetchCards, setIsReadyFetchCards] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const { isSubscriptionInCart } = useBasketMethods();

  const stripe = useStripe();
  const elements = useElements();

  const fields = useFields(isSubscriptionInCart);
  const initialValues = useInitialValues(isSubscriptionInCart);
  const validationSchema = useValidationSchema(isSubscriptionInCart);

  usePaymentCards({ enabled: isReadyFetchCards });

  const handleFormikSubmit = async (_, { setSubmitting, setStatus }) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error } = await stripe.confirmCardSetup(stripeSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      setStatus({
        // i18next-extract-disable-next-line
        apiErrorMessage: t(
          `$*error.stripe.${error.code}`,
          `$$${error.message}`
        ),
      });
      setSubmitting(false);
    } else {
      cardElement.update({ disabled: true });
      setStatus();
      setSubmitting(true);
      setIsReadyFetchCards(true);
    }
  };

  if (!stripe) {
    return <Spinner text="$*components.paymentCardForm.stripe.isLoading" />;
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting, status }) => (
          <Form>
            {fields.map(({ id, Component, ...restProps }) => {
              if (id === 'stripeCard') {
                const CloneElementWithProps = cloneElement(Component, {
                  onChange: ({ complete }) => setIsCardComplete(complete),
                });

                return (
                  <InputsIndex
                    key={id}
                    id={id}
                    Component={CloneElementWithProps}
                    {...restProps}
                  />
                );
              }

              return <InputsIndex key={id} id={id} {...restProps} />;
            })}

            {status?.apiErrorMessage && (
              <Alert styles={{ css: tw`mt-4` }}>{status.apiErrorMessage}</Alert>
            )}

            <div tw="mt-8">
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={
                  isSubscriptionInCart
                    ? isSubmitting || !dirty
                    : isSubmitting || !isCardComplete
                }
                fullWidth={true}
              >
                {t('$*components.paymentCardForm.saveCard', '$$Zapisz kartę')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withStripeConsumer(StripeCardForm);
