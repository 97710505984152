import { format } from '@services/Date.service';

import calendarTileManagmentStyles from './CalendarTileManagment.styles.js';

const CalendarTileManagment = ({
  date,
  selectedDate = false,
  plannedDates = false,
  today = false,
  disabled = false,
  onClick = () => {},
  styles = {},
}) => {
  const coreStyles = calendarTileManagmentStyles({
    isSelected: selectedDate,
    isPlannedDate: plannedDates,
    isToday: today,
    isClicable: (!disabled && !plannedDates) || selectedDate,
    styles,
  });

  return (
    <div css={coreStyles.wrapper} onClick={onClick}>
      <div css={coreStyles.text}>{format(date, 'd')}</div>
      {today && <div css={coreStyles.todayDot} />}
    </div>
  );
};

export default CalendarTileManagment;
