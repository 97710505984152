import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import ProductImage from './ProductImage';
import ProductTags from './ProductTags';
import ProductWrapper from './ProductWrapper';
import PurchasedProductInfoWrapper from './PurchasedProductInfoWrapper';
import PurchasedProductName from './PurchasedProductName';
import PurchasedProductOptionsPopover from './PurchasedProductOptionsPopover';
import PurchasedProductSizes from './PurchasedProductSizes';
import { usePurchasedProductTileContext } from './PurchasedProductTile.context';

const ProductTileDesktop = () => {
  const { t } = useTranslation();
  const {
    isExistImage,
    isOpenMenu,
    showImage,
    toggleOpenMenu,
    product,
    productIsSelected,
  } = usePurchasedProductTileContext();
  const { '@resources': resources, dishImagePlaceholder } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const { dish, sizes = [] } = product;
  const imageUrl =
    showImage && isExistImage
      ? dish?.image || ''
      : resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg';

  return (
    <ProductWrapper isSelected={productIsSelected} onClick={toggleOpenMenu}>
      <ProductImage showImage={showImage} imageUrl={imageUrl} />
      <PurchasedProductInfoWrapper>
        <div tw="flex flex-col h-full">
          <div css={[tw`p-3`, !isOpenMenu && tw`h-full`]}>
            <div className="row" css={[!isOpenMenu && tw`h-full`]}>
              <div className="col">
                {!isOpenMenu && (
                  <div tw="flex flex-col h-full">
                    <PurchasedProductName name={dish?.nameForClient} />

                    <div tw="pt-3">
                      <div
                        css={css`
                          max-height: 120px;
                          ${tw`text-sm -my-0.5 overflow-auto`}
                        `}
                      >
                        {sizes.map(({ quantity, dishSize }) => (
                          <div key={dishSize.id} tw="py-0.5">
                            {`${quantity} x ${
                              dishSize.size.name
                            } (${getIntegerIfIntegerOrFixed(
                              dishSize?.macrosAfterProcessing?.calories ?? 0,
                              0
                            )} ${t('$*common.kcal', '$$kcal')})`}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {isOpenMenu && (
                  <Fragment>
                    <PurchasedProductName name={dish?.nameForClient} />
                    <div tw="pt-4 flex -my-0.5 -mx-1 flex-wrap">
                      <ProductTags tags={dish?.tags ?? []} />
                    </div>
                  </Fragment>
                )}
              </div>
              {isOpenMenu && (
                <div className="col-auto">
                  <div tw="flex flex-col items-center">
                    <div>
                      <button
                        className="group"
                        tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                      >
                        <ArrowDownIcon
                          className="group-focus:(fill-current)"
                          tw="w-4 transform transition-colors  duration-300 hover:(duration-300)"
                          title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                        />
                      </button>
                    </div>
                    <div>
                      <PurchasedProductOptionsPopover />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isOpenMenu && (
            <div
              tw="relative px-3 pb-3 flex-auto overflow-auto"
              onClick={e => e.stopPropagation()}
            >
              <PurchasedProductSizes sizes={sizes} />
            </div>
          )}
        </div>
      </PurchasedProductInfoWrapper>
    </ProductWrapper>
  );
};

export default ProductTileDesktop;
