const PAYMENT_TYPES = {
  BANK_WIRE: 'BANK_WIRE',
  BLUE_MEDIA_CARD: 'BLUE_MEDIA_CARD',
  BLUE_MEDIA: 'BLUE_MEDIA',
  CASH: 'CASH',
  PAYPO: 'PAYPO',
  PAYU: 'PAYU',
  PAYU_CARD: 'PAYU_CARD',
  STRIPE_CARD: 'STRIPE_CARD',
  STRIPE_LINK: 'STRIPE_LINK',
  TPAY: 'TPAY',
};

export default PAYMENT_TYPES;
