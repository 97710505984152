import { useTranslation } from 'next-i18next';

import { isValidPhoneNumber } from 'react-phone-number-input';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

// prettier-ignore
// eslint-disable-next-line
const emailRegexRFC5322 = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

const passwordRegex = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

Yup.addMethod(Yup.string, 'emailRFC5322', function (message) {
  return this.matches(emailRegexRFC5322, message);
});

Yup.addMethod(Yup.string, 'password', function (message) {
  return this.matches(passwordRegex, message);
});

Yup.addMethod(Yup.string, 'repassword', function (refInputName, message) {
  return this.oneOf([Yup.ref(refInputName), null], message);
});

Yup.addMethod(Yup.mixed, 'phone', function (message) {
  return this.test('invalidNumber', message, function ({ number }) {
    return isValidPhoneNumber(number ?? '');
  });
});

Yup.addMethod(Yup.mixed, 'isNotEmpty', function (message) {
  return this.test('requiredField', message, function ({ value }) {
    return !isEmpty(value);
  });
});

export const useDefaultSchemaYup = () => {
  const { t } = useTranslation();

  const stringRequired = Yup.string().required(t('$*error.field.required'));
  const passwordRequired = stringRequired.password(
    t('$*error.field.password.invalid', { replace: { count: 8 } })
  );

  return {
    stringRequired,
    numberRequired: Yup.number().required(t('$*error.field.required')),
    emailRequired: stringRequired.email(t('$*error.field.email.invalid')),
    passwordRequired,
    repasswordRequired: refInputName =>
      passwordRequired.repassword(
        refInputName,
        t('$*error.field.password.isDiffrent')
      ),
    phoneValid: Yup.mixed().phone(t('$*error.field.phone.invalid')),
    booleanRequired: Yup.boolean()
      .required()
      .oneOf([true], t('$*error.field.required')),
    isNotEmptyValid: Yup.mixed().isNotEmpty(t('$*error.field.required')),
  };
};

export default Yup;
