import { DishTileProvider } from './DishTile.context';
import DishTileView from './DishTile.view';

const DishTile = props => {
  return (
    <DishTileProvider {...props}>
      <DishTileView />
    </DishTileProvider>
  );
};

export default DishTile;
