import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { CheckIcon, GiftVoucherIcon } from '@assets/icons';
import { Collapse } from '@components';
import {
  useDeleteValidateDiscountCode,
  useDiscountCode,
  useToggle,
} from '@hooks';
import { transformApiErrors } from '@services/Api.service';

import DiscountCodeForm from './DiscountCodeForm';
import { useInitialValues, useValidationSchema } from './formikData';

const DiscountCode = ({
  useDiscountCodeStore,
  commonSetDiscountCode,
  updateOrderBasketModify,
  setDiscountCode,
  discountCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const initialValues = useInitialValues({
    useDiscountCode: useDiscountCodeStore,
    discountCode,
  });
  const validationSchema = useValidationSchema();

  const { data: discount = {} } = useDiscountCode();
  const { mutate: deleteDiscountCode } = useDeleteValidateDiscountCode();

  const handleFormikSubmit = (values, { setStatus, setSubmitting }) => {
    const newCode = values.code?.replace(/  +/g, ' ')?.trim();

    commonSetDiscountCode(newCode)
      .then(() => {
        setStatus();
      })
      .catch(err => {
        const error = err?.response?.data;
        const apiFieldErrors = transformApiErrors(error);

        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
          apiFieldErrors,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRemoveUseDiscountCode = resetForm => {
    deleteDiscountCode();
    dispatch(
      setDiscountCode({
        useDiscountCode: false,
        discountCode: { '@id': null, value: '' },
      })
    );
    toggleIsExpanded();
    resetForm();

    updateOrderBasketModify({ payload: {} });
  };

  return (
    <div
      css={[
        tw`p-3.5 my-4 overflow-hidden border-2 rounded-lg`,
        useDiscountCodeStore ? tw`border-primary` : tw`border-gray-200`,
      ]}
    >
      <motion.header
        initial={false}
        onClick={toggleIsExpanded}
        data-cy="discount-code__collapse"
      >
        <div tw="cursor-pointer flex items-center justify-between">
          <div tw="flex items-center">
            <GiftVoucherIcon
              css={css`
                flex: 0 0 auto;
                ${tw`w-5`}
              `}
            />
            <div tw="px-4">
              {t(
                '$*newOrderCreatePage.orderForm.summary.useDiscountCode',
                '$$Użyj kodu rabatowego'
              )}
            </div>
            {useDiscountCodeStore && (
              <div tw="text-primary">
                <CheckIcon tw="w-4" />
              </div>
            )}
          </div>
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </div>
      </motion.header>
      <Collapse isExpanded={isExpanded}>
        <div tw="pt-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
            enableReinitialize={true}
          >
            <Form data-cy="discount-code-form">
              <DiscountCodeForm
                discount={discount}
                handleRemoveUseDiscountCode={handleRemoveUseDiscountCode}
                useDiscountCode={useDiscountCodeStore}
              />
            </Form>
          </Formik>
        </div>
      </Collapse>
    </div>
  );
};

export default DiscountCode;
