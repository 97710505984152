const HalfStarIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 78 75"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M76.6 31.533a2.916 2.916 0 00-1.695-5.135l-24.234-1.594-9.144-22.97a2.916 2.916 0 00-5.42 0l-9.144 22.97-24.234 1.594a2.917 2.917 0 00-1.695 5.136l18.613 15.778-6.112 23.99a2.917 2.917 0 004.384 3.185L38.817 61.28l20.897 13.208a2.917 2.917 0 004.385-3.186l-6.112-23.988L76.6 31.533zm-9.272.213L52.824 44.042a2.917 2.917 0 00-.94 2.945l4.753 18.654-16.262-10.277a2.914 2.914 0 00-1.558-.452c-.541 0 0-44.114 0-44.114l7.115 17.875a2.916 2.916 0 002.518 1.831l18.878 1.242z" />
  </svg>
);

export default HalfStarIcon;
