import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const updateDiet = async ({ dietId, payload }) => {
  const response = await axios.put(ENDPOINT.EDIT_DIET(dietId), payload);

  return response?.data ?? {};
};

// const getDefaultDiet = () => {};

export default {
  updateDiet,
};
