import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import { isEmptyObject } from '@utils/helpers';

import ProductSize from './ProductSize';
import { useProductTileContext } from './ProductTile.context';

const ProductSizes = () => {
  const { t } = useTranslation();
  const { product, dishDate } = useProductTileContext();
  const { sizes = [] } = product;

  if (isEmpty(dishDate)) {
    return (
      <div>
        {t(
          '$*components.productTile.emptyDishDate',
          '$$Najpierw wybierz dzień dostawy aby dodać danie do koszyka'
        )}
      </div>
    );
  }

  const sizesWithSizeObj = sizes?.filter(
    ({ size }) => !isEmpty(size) && !isEmptyObject(size)
  );

  if (isEmpty(sizesWithSizeObj)) {
    return (
      <div>
        {t(
          '$*components.productTile.noDishSize',
          '$$Brak wielkości przypisanych do dania'
        )}
      </div>
    );
  }

  return sizesWithSizeObj?.map(
    ({
      '@id': iri,
      size,
      cost,
      macrosAfterProcessing,
      components,
      allergens,
    }) => (
      <ProductSize
        key={iri}
        dishIri={iri}
        allergens={allergens}
        components={components}
        cost={cost}
        macrosAfterProcessing={macrosAfterProcessing}
        name={size.nameForClient}
      />
    )
  );
};

export default ProductSizes;
