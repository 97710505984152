import { Children, cloneElement } from 'react';
import tw from 'twin.macro';

import { format } from '@services/Date.service';
import { cssMerge } from '@utils/styleHelpers';

const SmallDayTile = ({
  isSelected = false,
  date = new Date(),
  onClick = () => {},
  children = null,
}) => {
  const defaultChildren = children
    ? children
    : [
        <SmallDayTile.Month key="0" />,
        <SmallDayTile.NumberDay key="1" />,
        <SmallDayTile.WeekDay key="2" />,
      ];

  const childrenWithProps = Children.map(defaultChildren, child =>
    cloneElement(child, { isSelected, date, onClick })
  );

  return (
    <div tw="p-2 mx-auto text-center" onClick={onClick}>
      {childrenWithProps}
    </div>
  );
};

const Month = ({ isSelected, date }) => {
  return (
    <div css={[tw`text-xs uppercase`, isSelected && tw`text-sm font-medium`]}>
      {format(date, 'LLL')}
    </div>
  );
};

const NumberDay = ({ isSelected, onClick, date, styles = {} }) => {
  const twStyle = cssMerge({
    defaultCss: [
      tw`flex items-center justify-center mx-auto my-1 border border-gray-400 rounded-md`,
      isSelected
        ? tw`w-12 h-12 text-white bg-primary border-primary`
        : tw`w-10 h-10`,
    ],
    ...styles,
  });

  return (
    <div css={twStyle} onClick={onClick} data-cy="day-tile day-tile--small">
      <div css={[tw`text-base`, isSelected && tw`py-1 text-lg font-medium`]}>
        {format(date, 'dd')}
      </div>
    </div>
  );
};

const WeekDay = ({ isSelected, date }) => {
  return (
    <div css={[tw`text-xs uppercase`, isSelected && tw`text-sm font-medium`]}>
      {format(date, 'eeeeee')}
    </div>
  );
};

SmallDayTile.displayName = 'SmallDayTile';
Month.displayName = 'Month';
NumberDay.displayName = 'NumberDay';
WeekDay.displayName = 'WeekDay';

SmallDayTile.Month = Month;
SmallDayTile.NumberDay = NumberDay;
SmallDayTile.WeekDay = WeekDay;

export default SmallDayTile;
