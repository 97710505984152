import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getOrderPaymentTypes = async orderId => {
  const response = await axios.get(ENDPOINT.ORDER_PAYMENT_TYPES(orderId));

  return {
    items: response?.data?.['hydra:member'] ?? [],
    totalItems: response?.data?.['hydra:totalItems'] ?? 0,
  };
};

const useOrderPaymentTypes = (orderId, options = {}) => {
  const query = useQuery(
    ['orderPaymentTypes', orderId],
    () => getOrderPaymentTypes(orderId),
    {
      enabled: !!orderId,
      ...options,
    }
  );

  return query;
};

export default useOrderPaymentTypes;
