import DisplayOn from './DisplayOn';

const DisplayUpXl = ({ children }) => {
  return (
    <DisplayOn size="xl" mode="up" defaultState={true}>
      {children}
    </DisplayOn>
  );
};

DisplayUpXl.displayName = 'DisplayUpXl';

export default DisplayUpXl;
