import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getUserDietCalendar = async ({ dateFrom, dateTo, dietId }) => {
  const response = await axios.get(
    ENDPOINT.GET_CALENDAR({ dateFrom, dateTo, dietId })
  );

  return response?.data ?? {};
};

const useUserDietCalendar = ({ dateFrom, dateTo, dietId }, options = {}) => {
  return useQuery(
    ['userDietCalendar', { dateFrom, dateTo, dietId }],
    () => getUserDietCalendar({ dateFrom, dateTo, dietId }),
    {
      enabled: !!dietId,
      ...options,
    }
  );
};

export default useUserDietCalendar;
