import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import AddonName from '@components/modules/Addons/AddonName';
import { Tile } from '@components/modules/Addons/AddonTile.styles';
import { checkImageExists } from '@utils/helpers';

const PurchasedAddonTileDesktop = ({ addon, quantity }) => {
  const [isExistImage, setIsExistImage] = useState(null);

  useEffect(() => {
    checkImageExists(addon.image)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [addon.image]);

  const imageUrl = isExistImage ? addon.image : '/images/placeholder.jpeg';

  return (
    <Tile>
      <Tile.Content>
        <Tile.ContentInnerWrapper>
          <Tile.Image src={imageUrl} />
          <div tw="px-3 pt-4">
            <AddonName
              name={`${quantity} x ${addon?.name ?? ''}`}
              styles={{ css: tw`text-sm` }}
            />
            <Tile.Description>{addon?.shortDescription ?? ''}</Tile.Description>
          </div>
        </Tile.ContentInnerWrapper>
      </Tile.Content>
    </Tile>
  );
};

export default PurchasedAddonTileDesktop;
