import { Trans, useTranslation } from 'next-i18next';

import { addDays, format, getISODay, subDays } from 'date-fns';
import produce from 'immer';
import first from 'lodash/first';

import {
  CheckIcon,
  DeliveryCarIcon,
  HalfStarIcon,
  PencilIcon,
  PlusIcon,
  SpinnerIcon,
  StarIcon,
  StopIcon,
  WarningIcon,
} from '@assets/icons';
import DAY_STATUSES from '@constants/dayStatuses';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { useAppContext } from '@views/App/App.context';

import ApiService from './Api.service';

const getDayPropsByStatus = status => {
  const { t } = useTranslation();
  const { isShop } = useAppContext();

  const {
    calendarIconDelivered,
    calendarIconRated,
    calendarIconRatedPartial,
    calendarIconCanBeRated,
    calendarIconDetails,
    calendarIconChangeMenu,
    calendarIconMenuSoon,
    // calendarIconSubscriptionOrdered,
    calendarIconSubscriptionAddDelivery,
    '@resources': resources,
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const [
    iconDelivered,
    iconRated,
    iconRatedPartial,
    iconCanBeRated,
    iconDetails,
    iconChangeMenu,
    iconMenuSoon,
    iconSubscriptionAddDelivery,
  ] = [
    calendarIconDelivered,
    calendarIconRated,
    calendarIconRatedPartial,
    calendarIconCanBeRated,
    calendarIconDetails,
    calendarIconChangeMenu,
    calendarIconMenuSoon,
    // calendarIconSubscriptionOrdered,
    calendarIconSubscriptionAddDelivery,
  ].map(icon => ApiService.getImageUrlFromResources(icon, resources));

  switch (status) {
    case DAY_STATUSES.DISABLED_WITH_BAG:
      return {
        icon: null, // TODO: ADMIN
        defaultIcon: WarningIcon,
        description: t('$*components.myOrderCalendar.caution', '$$Uwaga!'),
        tooltip: (
          <Trans
            i18nKey="$*components.myOrderCalendar.caution.tooltip"
            defaults="<0>$$Uwaga!<br />Dzień został wyłączony z dostawy.<br /><br /> Zmień termin dostawy,<br /> aby twoje zamówienie nie przepadło.</0>"
            components={[<p key="0" tw="text-center" />]}
          />
        ),
      };
    case DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED:
    case DAY_STATUSES.DELIVERED_RATED_BLOCKED:
    case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED:
      return {
        icon: iconDelivered,
        defaultIcon: CheckIcon,
        description: t(
          '$*components.myOrderCalendar.delivered',
          '$$Dostarczone'
        ),
        type: 'primary',
      };
    case DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE:
      return {
        icon: iconCanBeRated,
        defaultIcon: StarIcon,
        description: t('$*components.myOrderCalendar.rateMenu', '$$Oceń menu'),
        type: 'primary',
      };
    case DAY_STATUSES.DELIVERED_RATED_CAN_RATE:
      return {
        icon: iconRated,
        defaultIcon: HalfStarIcon,
        description: t('$*components.myOrderCalendar.rated', '$$Ocenione'),
        type: 'primary',
      };
    case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE:
      return {
        icon: iconRatedPartial,
        defaultIcon: HalfStarIcon,
        description: t('$*components.myOrderCalendar.rateMenu', '$$Oceń menu'),
        type: 'primary',
      };
    case DAY_STATUSES.NOT_DELIVERED_BLOCKED:
      return {
        icon: iconDetails,
        defaultIcon: DeliveryCarIcon,
        description: t('$*components.myOrderCalendar.details', '$$Szczegóły'),
        type: 'primary',
      };
    case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL:
      return {
        icon: iconChangeMenu,
        defaultIcon: PencilIcon,
        description: isShop
          ? t('$*components.myOrderCalendar.orderMore', '$$Zamów więcej')
          : t('$*components.myOrderCalendar.edit', '$$Edytuj'),
        type: 'primary',
      };
    case DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER:
      return {
        icon: iconSubscriptionAddDelivery,
        defaultIcon: PlusIcon,
        description: t('$*components.myOrderCalendar.order', '$$Zamów'),
        type: 'addOrder',
      };
    case DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER:
      return { type: 'disabled' };
    case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
      return {
        icon: iconMenuSoon,
        defaultIcon: SpinnerIcon,
        description: t(
          '$*components.myOrderCalendar.menuComingSoon',
          '$$Menu wkrótce'
        ),
        type: 'primary',
      };
    case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
      return {
        icon: iconSubscriptionAddDelivery,
        defaultIcon: StopIcon,
        description: t('$*components.myOrderCalendar.holded', '$$Wstrzymano'),
        type: 'warning',
      };

    default:
      return {};
  }
};

const getDayItemsDataWithoutState = (
  { shopItems = [], dietItems = [], subscriptionItems = [] },
  excludeStatuses = []
) => {
  const items = { shopItems, dietItems, subscriptionItems };

  const { filteredItems, filteredItemsCount } = Object.entries(items).reduce(
    (acc, [key, value]) => {
      const filteredItem = value.filter(
        ({ state }) => !excludeStatuses.includes(state)
      );

      return produce(acc, draftState => {
        draftState.filteredItems[key] = filteredItem;
        draftState.filteredItemsCount += filteredItem.length;
      });
    },
    { filteredItems: {}, filteredItemsCount: 0 }
  );

  const isEmpty = filteredItemsCount === 0;
  const isSingular = filteredItemsCount === 1;
  let newItems = {};
  if (isSingular) {
    newItems = Object.values(filteredItems).reduce((acc, item) => {
      if (item.length > 0) {
        return first(item);
      }

      return acc;
    }, {});
  }

  if (!isEmpty && !isSingular) {
    newItems = filteredItems;
  }

  return {
    isEmpty,
    isSingular,
    items: newItems,
  };
};

const generateSideDays = (
  {
    selectedDay,
    numberOfDays,
    disabledDays = [],
    permanentlyDisabledDays = [],
  },
  changerFunction
) => {
  let daysToAdd = 1;
  const arr = [];

  while (arr.length < numberOfDays && permanentlyDisabledDays.length < 7) {
    const subsequentDay = changerFunction(selectedDay, daysToAdd);

    if (
      !disabledDays.includes(format(subsequentDay, 'yyyy-MM-dd')) &&
      !permanentlyDisabledDays.includes(getISODay(subsequentDay))
    ) {
      arr.push(subsequentDay);
    }

    daysToAdd += 1;
  }

  return arr;
};

const getVisibleDays = ({ numberOfDays, ...restProps }) => {
  const leftDays = generateSideDays(
    { ...restProps, numberOfDays: numberOfDays.start },
    subDays
  );

  const rightDays = generateSideDays(
    { ...restProps, numberOfDays: numberOfDays.end },
    addDays
  );

  return [...leftDays.reverse(), new Date(restProps.selectedDay), ...rightDays];
};

const getDirectionCenteredDay = ({
  direction = 'prev',
  currentCenteredDay,
  offset,
  disabledDays = [],
  permanentlyDisabledDays = [],
}) => {
  let offsetIterator = 0;
  let dayIterator = 1;
  let iteratedDay = format(new Date(currentCenteredDay), 'yyyy-MM-dd');
  const directionDaysFunction = direction === 'prev' ? subDays : addDays;

  while (offsetIterator < offset && permanentlyDisabledDays.length < 7) {
    iteratedDay = format(
      directionDaysFunction(new Date(currentCenteredDay), dayIterator),
      'yyyy-MM-dd'
    );

    if (
      !disabledDays.includes(iteratedDay) &&
      !permanentlyDisabledDays.includes(getISODay(new Date(iteratedDay)))
    ) {
      offsetIterator++;
    }

    dayIterator++;
  }

  return iteratedDay;
};

export default {
  getDayPropsByStatus,
  getDayItemsDataWithoutState,
  generateSideDays,
  getVisibleDays,
  getDirectionCenteredDay,
};
