import ReactDOMServer from 'react-dom/server';
import * as Sentry from '@sentry/nextjs';
import produce from 'immer';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import runes from 'runes';

import emojis from '@components/elements/Form/InputEmoji/emojiData.json';
import { EmojiImage } from '@components/elements/Form/InputEmoji/EmojiList.styles';
import { ENDPOINT } from '@constants/endpoint';
import ADDRESS_INPUT_IDS from '@constants/inputs/addressInputs';
import REGISTER_INPUT_IDS from '@constants/inputs/registerInputs';
import axios from '@utils/http';

const login = async requestPayload => {
  const { type, keepMeLoggedIn, ...credentials } = requestPayload;
  const isAppleAuth = type === 'apple';

  const endpointUrl =
    {
      facebook: ENDPOINT.FACEBOOK_LOGIN,
      google: ENDPOINT.GOOGLE_LOGIN,
    }[type] || ENDPOINT.LOGIN;

  let user = {};
  let data = {};

  if (isAppleAuth) {
    const { token, refreshToken } = requestPayload;

    user = decode(token);
    data = { token, refreshToken };
  } else {
    const response = await axios.post(endpointUrl, credentials, {
      errorHandle: false,
    });
    data = response?.data ?? {};
    user = decode(data?.token);
  }

  if (user?.email) {
    Sentry.configureScope(scope => {
      scope.setUser({ email: user.email });
    });
  }

  if (user?.id) {
    // const userId = parseInt(user.id.replace('/clients/', ''));
    // userLogin(userId); // utils/gtm
    // setUserGA(userId); // utils/ga
  }

  return {
    ...data,
    user,
    keepMeLoggedIn,
  };
};

const transformRegisterFormData = formData => {
  const newFormData = produce(formData, draftState => {
    const gender = draftState[REGISTER_INPUT_IDS.GENDER].value;
    draftState[REGISTER_INPUT_IDS.GENDER] = gender || null;
    draftState[REGISTER_INPUT_IDS.REFERRER] =
      draftState[REGISTER_INPUT_IDS.REFERRER].value;
    delete draftState[REGISTER_INPUT_IDS.KEEP_ME_LOGGED_IN];
    delete draftState[REGISTER_INPUT_IDS.REPEAT_PASSWORD];
  });

  return newFormData;
};

const register = async requestPayload => {
  const requestData = transformRegisterFormData(requestPayload);
  const response = await axios.post(ENDPOINT.REGISTER, requestData, {
    errorHandle: false,
  });

  return response?.data ?? {};
};

const getData = async requestPayload => {
  const response = await axios.get(ENDPOINT.USER_DATA, requestPayload);

  return response?.data ?? {};
};

const resetPassword = async requestPayload => {
  const response = await axios.post(
    ENDPOINT.SEND_PASSWORD_RESET_EMAIL,
    requestPayload,
    {
      errorHandle: false,
    }
  );

  return response?.data ?? {};
};

const setNewPassword = async requestPayload => {
  const response = await axios.put(ENDPOINT.RESET_PASSWORD, requestPayload);

  return response?.data ?? {};
};

const refreshToken = async () => {
  const response = await axios.put(ENDPOINT.REFRESH_LOGIN, {
    refreshToken: Cookies.get('refreshToken'),
  });

  const user = response?.data?.token ? decode(response?.data?.token) : {};

  return { ...(response?.data ?? {}), user };
};

const updateData = async requestPayload => {
  const response = await axios.put(ENDPOINT.USER_DATA, requestPayload);

  return response?.data ?? {};
};

const changePassword = async requestPayload => {
  const response = await axios.put(ENDPOINT.CHANGE_PASSWORD, requestPayload);

  return response?.data ?? {};
};

const isEmoji = str => {
  const ranges = [
    '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
  ];

  return str.match(ranges.join('|')) ? true : false;
};

const transformIntercomCode = (intercomCode = '', mode = 'htmlToText') => {
  if (mode === 'textToHtml') {
    if (isEmpty(intercomCode)) return '';

    const convertedToHtml = runes(intercomCode).map(char => {
      if (isEmoji(char)) {
        const emojiItems = emojis.filter(({ symbol }) => symbol === char);
        if (!isEmpty(emojiItems)) {
          const emojiItem = emojiItems[0];
          const EmojiImageReact = (
            <EmojiImage
              size={emojiItem.size}
              position={emojiItem.position}
              data-emoji={emojiItem.symbol}
              src={emojiItem.image}
            />
          );

          const emojiHtml =
            ReactDOMServer.renderToStaticMarkup(EmojiImageReact);

          return emojiHtml;
        }
      }

      return char;
    });

    return convertedToHtml.join('');
  } else {
    let clearIntercomCode = '';
    const domParser = new DOMParser();
    const domParsed = domParser.parseFromString(intercomCode, 'text/html');
    const childNodes =
      domParsed.documentElement.querySelector('body').childNodes;
    const htmlElements = [...childNodes];

    htmlElements.map(child => {
      if (child.nodeName === 'IMG') {
        clearIntercomCode += child.dataset.emoji;
      } else {
        clearIntercomCode += child.data;
      }
    });

    return clearIntercomCode;
  }
};

const transformAddressFormData = ({
  formData,
  allowedFieldsSettings,
  useAddressesWithLessFields,
}) => {
  const newState = produce(formData, draftState => {
    let intercomCode = draftState[ADDRESS_INPUT_IDS.INTERCOM_CODE];
    if (!isEmpty(intercomCode)) {
      draftState[ADDRESS_INPUT_IDS.INTERCOM_CODE] =
        transformIntercomCode(intercomCode);
    }

    if (!isEmpty(draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS]?.value)) {
      const selectedDeliveryHour = JSON.parse(
        draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS].value
      );
      const { hourFrom, hourTo } = selectedDeliveryHour;
      draftState.deliveryHourFrom = hourFrom;
      draftState.deliveryHourTo = hourTo;
      draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS] = selectedDeliveryHour;
    } else {
      draftState.deliveryHourFrom = '';
      draftState.deliveryHourTo = '';
    }

    if (useAddressesWithLessFields) {
      const addressLine1 = draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE1];
      const addressLine2 = draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE2];

      draftState[ADDRESS_INPUT_IDS.STREET] = `${addressLine1}; ${addressLine2}`;
      draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER] = '0';

      delete draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE1];
      delete draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE2];
    } else {
      draftState[ADDRESS_INPUT_IDS.FLOOR] = parseInt(draftState.floor);
    }

    if (allowedFieldsSettings?.hasAllowedCities) {
      const city = draftState[ADDRESS_INPUT_IDS.CITY_OPTION];

      draftState[ADDRESS_INPUT_IDS.CITY] = city?.value;

      delete draftState[ADDRESS_INPUT_IDS.CITY_OPTION];
    }

    if (allowedFieldsSettings?.hasAllowedStreets) {
      const street = draftState[ADDRESS_INPUT_IDS.STREET_OPTION];

      draftState[ADDRESS_INPUT_IDS.STREET] = street?.value;

      delete draftState[ADDRESS_INPUT_IDS.STREET_OPTION];
    }

    if (allowedFieldsSettings?.hasAllowedBuildingNumbers) {
      const buildNumber = draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER_OPTION];

      draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER] = buildNumber?.value;

      delete draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER_OPTION];
    }

    delete draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS];
  });

  const newFormData = Object.entries(newState).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: typeof value === 'string' && isEmpty(value) ? null : value,
    };
  }, {});

  return newFormData;
};

const sortAddressesByDefaultFirst = array => {
  const newArr = [
    ...array.filter(item => item.isDefault === true),
    ...array.filter(item => item.isDefault === false),
  ];

  return newArr;
};

export default {
  changePassword,
  getData,
  login,
  refreshToken,
  register,
  resetPassword,
  setNewPassword,
  updateData,
  transformAddressFormData,
  sortAddressesByDefaultFirst,
  transformIntercomCode,
};
