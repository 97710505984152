const ShoppingBasketIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2835 2334"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2751.466 833.401h-452.863L1822.826 40.436c-23.597-39.39-74.794-52.25-114.345-28.566-39.478 23.685-52.256 74.872-28.57 114.35l424.308 707.181H731.783l424.308-707.186c23.685-39.478 10.908-90.665-28.57-114.35-39.634-23.685-90.66-10.824-114.345 28.565L537.399 833.396H84.53c-111.298 0-114.116 166.683 0 166.683h180.888l211.73 1129.427c22.137 118.262 125.497 204.047 245.796 204.047h1390.114c120.293 0 223.653-85.785 245.712-203.958l211.803-1129.516h180.899c111.193 0 110.6-166.678-.006-166.678zM2194.931 2098.91c-7.324 39.39-41.754 67.965-81.878 67.965H722.943c-40.123 0-74.549-28.57-81.956-68.043L434.951 1000.08h1966.1l-206.12 1098.83z" />
  </svg>
);

export default ShoppingBasketIcon;
