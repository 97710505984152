import Head from 'next/head';

const SalesManagoScript = ({ clientId = null, endpoint = null }) => {
  return (
    <Head>
      <script
        id="salesmanago-script"
        dangerouslySetInnerHTML={{
          __html: `
          var _smid = "${clientId}";
          (function(w, r, a, sm, s ) {
            w['SalesmanagoObject'] = r;
            w[r] = w[r] || function () {( w[r].q = w[r].q || [] ).push(arguments)};
            sm = document.createElement('script');
            sm.type = 'text/javascript';
            sm.async = true;
            sm.src = a;
            s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(sm, s);
          })(window, 'sm', ('https:' == document.location.protocol ? 'https://' : 'http://') + '${endpoint}/static/sm.js');`,
        }}
      />
    </Head>
  );
};

export default SalesManagoScript;
