import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw, { theme } from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const createStyles = ({
  isClicable = false,
  isSelected = false,
  isSmall = false,
  isToday = false,
  styles,
  type = 'default',
}) => {
  const isTypeEqual = typeString => type === typeString;
  const isTypePrimary = isTypeEqual('primary');
  const isTypeDisabled = isTypeEqual('disabled');
  const isTypeWarning = isTypeEqual('warning');
  const isTypeAddOrder = isTypeEqual('addOrder');

  const wrapperSmallStyle = cssMerge({
    defaultCss: tw`w-auto sm:w-12 px-0.5 py-1 h-12`,
    ...styles?.wrapperSmall,
  });

  const wrapperSmallSelectedStyle = cssMerge({
    defaultCss: tw`w-auto h-16 sm:w-16`,
    ...styles?.wrapperSmallSelected,
  });

  const wrapperStyle = cssMerge({
    defaultCss: tw`h-24 px-1 py-2 text-xl w-28`,
    ...styles?.wrapper,
  });

  const wrapperSelectedStyle = cssMerge({
    defaultCss: tw`w-auto xl:w-32 h-28`,
    ...styles?.wrapperSelected,
  });

  //w-8
  const coreStyles = {
    wrapper: [
      isSmall ? wrapperSmallStyle : wrapperStyle,
      tw`relative flex flex-col text-center border-2 border-transparent rounded-lg`,
      isClicable && tw`cursor-pointer`,
      isSmall && (isToday || isSelected) && [wrapperSmallSelectedStyle],
      !isSmall && (isToday || isSelected) && [wrapperSelectedStyle],
      isTypePrimary && tw`text-white bg-primary border-primary`,
      isTypeDisabled && tw`bg-gray-50 border-gray-50`,
      isTypeWarning && tw`text-white bg-orangeDixie border-orangeDixie`,
      isTypeAddOrder && tw`text-primary border-primary`,
    ].filter(Boolean),
    iconWrapper: [
      isSmall
        ? tw`top-0 w-5 h-5 p-1 transform -translate-x-1/2 -translate-y-1/2 rounded-full left-1/2 bg-primary`
        : tw`h-6 top-2 right-2 left-2`,
      tw`absolute flex justify-end`,
      isTypeAddOrder && [
        !isSmall && tw`w-6`,
        tw`top-0 p-1 transform -translate-x-1/2 -translate-y-1/2 rounded-full left-1/2 bg-primary`,
        'right: initial;',
      ],
    ].filter(Boolean),
    svgIcon: [
      tw`h-full fill-current`,
      isTypeAddOrder && `fill: ${theme`colors.white`}`,
    ],
    text: [
      isSmall ? tw`mt-2` : tw`mt-6 mb-1 text-xl`,
      tw`overflow-hidden whitespace-nowrap overflow-ellipsis`,
      isSmall && (isToday || isSelected) && tw`mt-3 text-xl font-semibold`,
      !isSmall && (isToday || isSelected) && tw`text-2xl font-semibold`,
    ].filter(Boolean),
    descriptionWrapper: [
      tw`overflow-hidden text-sm whitespace-nowrap overflow-ellipsis`,
      (isToday || isSelected) && tw`font-semibold`,
    ].filter(Boolean),
    descriptionSpan: [tw`py-0.5`],
    todayDot: [
      tw`absolute w-2 h-2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-400 rounded-full bottom-1 left-1/2`,
      (isTypePrimary || isTypeWarning) && tw`bg-white`,
      isTypeAddOrder && tw`bg-primary`,
    ].filter(Boolean),
  };

  const concatedStyles = Object.entries(coreStyles).reduce(
    (acc, [key, value]) => {
      const overrideStyleKey = styles[key];
      const hasOverrideStyleKey = !isEmpty(overrideStyleKey);
      const style = hasOverrideStyleKey
        ? cssMerge({
            defaultCss: value,
            ...overrideStyleKey,
          })
        : value;

      return {
        ...acc,
        [key]: style,
      };
    },
    {}
  );

  return concatedStyles;
};

const memoStyles = ({
  isClicable,
  isSelected,
  isSmall,
  isToday,
  styles,
  type,
}) =>
  useMemo(
    () =>
      createStyles({ isClicable, isSelected, isSmall, isToday, styles, type }),
    [isClicable, isSelected, isSmall, isToday, styles, type]
  );

export default memoStyles;
