import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Tag } from '@components';
import {
  selectModuleShop,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

const ProductTags = ({ tags = [] }) => {
  const { showTags } = useAppConfigSelector(selectModuleShop);

  if (isEmpty(tags) && !showTags) {
    return null;
  }

  return tags.map(tag => (
    <Tag
      label={tag.value}
      key={tag.id}
      variant="outline"
      styles={{
        css: tw`rounded-none text-xxs m-0.5 py-1 px-1.5 font-normal`,
      }}
    />
  ));
};

export default ProductTags;
