import PropTypes from 'prop-types';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Alert = ({ children = null, styles = {}, type = 'error' }) => {
  const twWrapperStyle = cssMerge({
    defaultCss: [
      tw`px-4 py-2 text-sm border rounded`,
      {
        error: tw`text-red-700 bg-red-100 border-red-700`,
        info: tw`text-blue-500 bg-blue-100 border-blue-500`,
        success: tw`text-green-700 bg-green-100 border-green-700`,
        warning: tw`text-orange-700 bg-orange-100 border-orange-700`,
        default: tw`border-gray-200 bg-gray-50`,
      }[type],
    ],
    ...styles,
  });

  return <div css={twWrapperStyle}>{children}</div>;
};

Alert.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    PropTypes.string,
  ]),
};

export default Alert;
