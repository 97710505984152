import { useContext } from 'react';

import { defaultValue, MinScreenContext } from '@contexts/MinScreen.context';

const useMinScreen = () => {
  const context = useContext(MinScreenContext);

  if (context === defaultValue)
    throw new Error('useMinScreen must be used within a MinScreenProvider');

  return { min: size => context[size] };
};

export default useMinScreen;
