import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Pagination } from '@components';
import { isFalsify } from '@utils/helpers';

import Rating from './Rating';
import Review from './Review';

const ReviewsSection = ({
  avgRating = 0,
  reviews = [],
  reviewsPerPage = 10,
}) => {
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    if (!isEmpty(reviews)) {
      setCurrentItems(reviews.slice(0, reviewsPerPage));
    }
  }, []);

  return (
    <div>
      <Rating value={avgRating} starSize="lg" isReadOnly />
      <div tw="mt-10">
        {!isFalsify(currentItems) &&
          currentItems.map(({ id, ...review }) => (
            <Review key={id} {...review} />
          ))}
      </div>

      <Pagination
        elements={reviews}
        itemsPerPage={reviewsPerPage}
        setCurrentItems={setCurrentItems}
      />
    </div>
  );
};

export default ReviewsSection;
