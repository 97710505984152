import { css } from '@emotion/react';
import tw from 'twin.macro';

const coreStyles = {
  wrapper: css`
    counter-reset: listCounter;
  `,
  item: ({ isActive = false, isCompleted = false }) => css`
    ${tw`relative flex gap-4 pb-8`}
    counter-increment: listCounter;

    &::before {
      ${tw`flex items-center justify-center h-8 rounded-full`}
      ${isActive
        ? tw`font-semibold text-white bg-primary`
        : tw`text-gray-300 bg-gray-50`}
      ${isCompleted && tw`text-white bg-green-600 border-green-600`}
      content: counter(listCounter);
      flex: 0 0 32px;
    }

    &:not(:last-child) {
      &::after {
        ${tw`absolute left-0 bottom-0 top-8 w-0.5`}
        ${isCompleted ? tw`bg-green-600` : tw`bg-gray-100`}
        content: '';
        transform: translateX(calc(32px / 2));
      }
    }

    h3 {
      ${!isActive && tw`text-gray-300`}
      ${isCompleted && tw`text-green-600`}
    }

    p {
      ${!isActive && tw`text-gray-300`}
    }
  `,
  itemContent: tw`max-w-2xl`,
  itemTitle: tw`text-lg mt-0.5`,
  itemDescription: tw`text-gray-400`,
};

export default coreStyles;
