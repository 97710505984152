import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import { isEmptyObject } from '@utils/helpers';

import PurchasedProductSize from './PurchasedProductSize';

const PurchasedProductSizes = ({ sizes = [] }) => {
  const { t } = useTranslation();

  const sizesWithSizeObj = sizes?.filter(
    ({ dishSize }) => !isEmpty(dishSize.size) && !isEmptyObject(dishSize.size)
  );

  if (isEmpty(sizesWithSizeObj)) {
    return (
      <div>
        {t(
          '$*components.productTile.noDishSize',
          '$$Brak wielkości przypisanych do dania'
        )}
      </div>
    );
  }

  return sizesWithSizeObj?.map(
    ({
      dishSize: {
        '@id': iri,
        size,
        macrosAfterProcessing,
        components,
        allergens,
      },
      quantity,
    }) => (
      <PurchasedProductSize
        key={iri}
        allergens={allergens}
        components={components}
        quantity={quantity}
        macrosAfterProcessing={macrosAfterProcessing}
        name={size.name}
      />
    )
  );
};

export default PurchasedProductSizes;
