import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { SpinnerThirdIcon } from '@assets/icons';
import AutoSave from '@components/elements/Form/AutoSave';
import InputsIndex from '@components/elements/Form/InputsIndex';
import { useBasketMethods } from '@hooks';
import showToast from '@utils/showToast';

import { useInitialValues } from './formikData';
import useFields from './useFields';

const BasketDietName = ({ existingItem = null, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fields = useFields();
  const initialValues = useInitialValues({ name });

  const { handleUpdateBasketDiet, setDietName } = useBasketMethods();

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    handleUpdateBasketDiet({ existingItem, ...values })
      .then(() => {
        showToast(
          t(
            '$*notification.userDietName.update.success',
            '$$Nazwa diety została pomyślnie zaktualizowana'
          ),
          { type: 'success', toastId: `userDietName_${existingItem}` }
        );

        dispatch(setDietName({ existingItem, ...values }));
        setStatus();
      })
      .catch(error => {
        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            {fields.map(
              ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
                return (
                  <div className={colClass} key={id} css={colCss}>
                    <InputsIndex id={id} {...restProps} />

                    {isSubmitting && (
                      <div tw="absolute top-1/2 right-0 -translate-x-full -translate-y-1/2">
                        <SpinnerThirdIcon tw="w-5 text-primary animate-spin" />
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>

          <AutoSave debounceMs={1000} showText={false} />
        </Form>
      )}
    </Formik>
  );
};

export default BasketDietName;
