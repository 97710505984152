import isNumber from 'lodash/isNumber';
import tw from 'twin.macro';

import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

const PriceWrapper = ({ beforeDiscount, afterDiscount, styles = {} }) => {
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const twStyle = cssMerge({
    defaultCss: tw`flex items-center -m-1`,
    ...styles?.wrapper,
  });

  return (
    <div css={twStyle}>
      {isNumber(beforeDiscount) && beforeDiscount !== afterDiscount && (
        <div tw="p-1 text-red-500 line-through">
          {getIntegerIfIntegerOrFixed(beforeDiscount)} {currencySymbol}
        </div>
      )}
      {isNumber(afterDiscount) && (
        <div tw="p-1">
          {getIntegerIfIntegerOrFixed(afterDiscount)} {currencySymbol}
        </div>
      )}
    </div>
  );
};

PriceWrapper.displayName = 'PriceWrapper';

export default PriceWrapper;
