import { useTranslation } from 'next-i18next';

import { AnimatePresence } from 'framer-motion';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import {
  selectModuleConfigClientPanel,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import ProductImageMobile from './ProductImageMobile';
import ProductInfoWrapperCollapsed from './ProductInfoWrapperCollapsed';
import ProductInfoWrapperExpanded from './ProductInfoWrapperExpanded';
import ProductName from './ProductName';
import ProductOptionsPopover from './ProductOptionsPopover';
import ProductSizes from './ProductSizes';
import ProductTags from './ProductTags';
import { useProductTileContext } from './ProductTile.context';
import ProductWrapper from './ProductWrapper';

const ProductTileMobile = () => {
  const { t } = useTranslation();
  const {
    isExistImage,
    showImage,
    toggleOpenMenu,
    product,
    productIsSelected,
  } = useProductTileContext();

  const { name, image = '', tags = [], sizes = [], minCost = 0 } = product;
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { '@resources': resources, dishImagePlaceholder } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const imageUrl =
    showImage && isExistImage
      ? image
      : resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg';

  return (
    <AnimatePresence initial={false}>
      <ProductWrapper isSelected={productIsSelected} onClick={toggleOpenMenu}>
        <div tw="flex w-full justify-between flex-wrap">
          <ProductImageMobile showImage={showImage} imageUrl={imageUrl} />
          <ProductInfoWrapperCollapsed>
            <div css={[tw`h-full p-3`]}>
              <div tw="flex flex-col h-full">
                <ProductName name={name} styles={{ css: tw`text-xs` }} />
                <div tw="text-xs pt-2 mt-auto">
                  {t('$*components.productTile.priceFrom', {
                    defaultValue: '$$od {{price}} {{currencySymbol}}',
                    replace: {
                      price: getIntegerIfIntegerOrFixed(minCost),
                      currencySymbol,
                    },
                  })}
                </div>
              </div>
            </div>
          </ProductInfoWrapperCollapsed>
        </div>

        <ProductInfoWrapperExpanded>
          <div tw="flex flex-col h-full">
            <div tw="p-3">
              <div className="row">
                <div className="col">
                  <ProductName name={name} styles={{ css: tw`text-xs` }} />
                  <div tw="pt-4 flex -my-0.5 -mx-1 flex-wrap">
                    <ProductTags tags={tags} />
                  </div>
                </div>
                <div className="col-auto">
                  <div tw="flex flex-col items-center">
                    <div onClick={toggleOpenMenu}>
                      <button
                        className="group"
                        tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                      >
                        <ArrowDownIcon
                          className="group-focus:(fill-current)"
                          tw="w-4 transform transition-colors rotate-180 duration-300 hover:(duration-300)"
                          title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                        />
                      </button>
                    </div>
                    <div>
                      <ProductOptionsPopover />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              tw="relative px-3 pb-3 flex-auto overflow-auto max-h-52"
              onClick={e => e.stopPropagation()}
            >
              <ProductSizes sizes={sizes} />
            </div>
          </div>
        </ProductInfoWrapperExpanded>
      </ProductWrapper>
    </AnimatePresence>
  );
};

export default ProductTileMobile;
