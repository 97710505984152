const MonitorIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2599 2344"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2453.592.068c79.873 0 144.85 65.018 144.85 144.83v1522.17c0 79.812-64.977 144.83-144.85 144.83H1694.36v394.733h422.12c37.682 0 68.228 30.597 68.228 68.238 0 37.642-30.546 68.238-68.228 68.238H481.968c-37.682 0-68.228-30.596-68.228-68.238 0-37.641 30.546-68.238 68.228-68.238h422.119v-394.733H144.852c-79.869 0-144.848-65.018-144.848-144.83V144.898C.004 65.086 64.984.068 144.852.068h2308.74zm-895.699 2206.563v-394.733h-517.34v394.733h517.34zm895.7-531.21c-1.973 0 1.278.504 4.005-.905 1.037-.604 2.255-1.41 3.271-3.12.151-.302 1.107-1.208 1.228-4.73.312-8.555-.03-121.782-.12-1521.768 0-4.63-3.755-8.354-8.385-8.354H144.852c-4.632 0-8.387 3.724-8.387 8.354v1522.17c0 4.63 3.755 8.354 8.387 8.354h2308.74z" />
  </svg>
);

export default MonitorIcon;
