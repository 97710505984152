import { useTranslation } from 'next-i18next';

import { createContext } from 'react';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

export const ClientPanelConfigContext = createContext([{}, () => {}]);

const ClientPanelConfigProvider = ({ children }) => {
  const { t } = useTranslation();

  const {
    defaultPaymentMode,
    isOneTimePayPaymentMode,
    isSubscriptionPaymentMode,
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const getPaymentModeOptions = () => {
    let paymentModeOptions = [];

    if (isSubscriptionPaymentMode) {
      paymentModeOptions.push({
        label: t('$*common.paymentMode.subscription'),
        value: 1,
      });
    }
    if (isOneTimePayPaymentMode) {
      paymentModeOptions.push({
        label: t('$*common.paymentMode.singlePayment'),
        value: 2,
      });
    }

    return paymentModeOptions;
  };

  const isDefaultPaymentModeValid = () => {
    switch (defaultPaymentMode) {
      case '1':
        return isSubscriptionPaymentMode;
      case '2':
        return isOneTimePayPaymentMode;
      default:
        return true;
    }
  };

  const transformBasketFormatToSelectOption = paymentMode => {
    switch (paymentMode) {
      case BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT:
        return {
          label: t('$*common.paymentMode.subscription'),
          value: 1,
        };
      case BASKET_PAYMENT_MODES.SINGLE_PAYMENT:
        return {
          label: t('$*common.paymentMode.singlePayment'),
          value: 2,
        };
      default: {
        return {};
      }
    }
  };

  const transformNumberPaymentModeToBasketFormat = number => {
    switch (number?.toString()) {
      case '1':
        return BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;
      case '2':
        return BASKET_PAYMENT_MODES.SINGLE_PAYMENT;
      default:
        return BASKET_PAYMENT_MODES.SINGLE_PAYMENT;
    }
  };

  const isSubscription = paymentMode => {
    return paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;
  };

  const value = {
    isSubscription,
    defaultPaymentMode: isDefaultPaymentModeValid() ? defaultPaymentMode : 2,
    getPaymentModeOptions,
    transformBasketFormatToSelectOption,
    transformNumberPaymentModeToBasketFormat,
  };

  return (
    <ClientPanelConfigContext.Provider value={value}>
      {children}
    </ClientPanelConfigContext.Provider>
  );
};

export default ClientPanelConfigProvider;
