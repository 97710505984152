import { SelectDishesSectionProvider } from './SelectDishesSection.context';
import SelectDishesSectionView from './SelectDishesSection.view';

const SelectDishesSection = ({
  handleChangeProductQuantity,
  checkIsProductSelected,
  selectedDate,
  selectedDietIri,
  selectedTags,
  toggleTag,
  resetTags,
  basketKey,
  ...viewProps
}) => {
  const providerProps = {
    handleChangeProductQuantity,
    checkIsProductSelected,
    selectedDate,
    selectedDietIri,
    selectedTags,
    toggleTag,
    resetTags,
    basketKey,
  };

  return (
    <SelectDishesSectionProvider {...providerProps}>
      <SelectDishesSectionView {...viewProps} />
    </SelectDishesSectionProvider>
  );
};

SelectDishesSection.displayName = 'SelectDishesSectionContainer';

export default SelectDishesSection;
