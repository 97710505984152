import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import { Button, Modal, PaymentCardWrapper } from '@components';
import PAYMENT_TYPES from '@constants/paymentTypes';
import { useMediaQuery } from '@hooks';
import { down } from '@utils/screens';

const StripeCardModal = ({ isOpen, closeModal, onClickConfirm }) => {
  const { t } = useTranslation();

  const isDown500 = useMediaQuery(down('500px'), true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      styles={{ wrapper: tw`w-full max-w-xl` }}
      data-cy="stripe-card-modal"
    >
      <Modal.Header>
        {t(
          '$*components.paymentTypes.stripeCard.modal.title',
          '$$Karta płatnicza'
        )}
      </Modal.Header>
      <Modal.Content>
        <PaymentCardWrapper
          styles={{ paymentCardWrapper: { css: tw`text-center` } }}
          paymentType={PAYMENT_TYPES.STRIPE_CARD}
        >
          <PaymentCardWrapper.AfterPaymentCard>
            <div tw="pt-6 flex flex-wrap justify-between -mx-2 -my-1">
              <Button
                type="button"
                color="error"
                variant="outlined"
                styles={{
                  button: [
                    tw`mx-2 my-1`,
                    isDown500 && tw`justify-center flex-auto`,
                  ],
                }}
                onClick={closeModal}
                data-cy="stripe-card-modal__cancel"
              >
                {t('$*common.cancel', '$$Anuluj')}
              </Button>
              <Button
                type="submit"
                variant="outlined"
                styles={{
                  button: [
                    tw`mx-2 my-1`,
                    isDown500 && tw`justify-center flex-auto`,
                  ],
                }}
                onClick={onClickConfirm}
                data-cy="stripe-card-modal__confirm"
              >
                {t('$*common.confirm', '$$Potwierdź')}
              </Button>
            </div>
          </PaymentCardWrapper.AfterPaymentCard>
        </PaymentCardWrapper>
      </Modal.Content>
    </Modal>
  );
};

StripeCardModal.displayName = 'StripeCardModal';

export default StripeCardModal;
