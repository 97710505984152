import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import { css } from '@emotion/react';
import { faCircleNotch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

import { useDeletePaymentCard } from '@hooks';
import { format } from '@services/Date.service';

import Card from './PaymentCard.styles';

const PaymentCard = ({
  id,
  brand,
  last4,
  expireAt,
  provider,
  validatedByProvider,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: deleteCard } = useDeletePaymentCard();

  const removeCard = () => {
    setIsLoading(true);
    deleteCard({ id, provider }).finally(() => setIsLoading(false));
  };

  return (
    <Card>
      <Card.Front>
        <Card.Remove onClick={removeCard}>
          <FontAwesomeIcon
            icon={isLoading ? faCircleNotch : faTrash}
            tw="text-xs"
            css={css`
              ${isLoading && tw`animate-spin`}
            `}
          />
        </Card.Remove>
        <Card.Brand>{brand}</Card.Brand>
        <Card.Number>**** **** **** {last4}</Card.Number>
        <Card.InfoWrapper>
          {!(provider === 'PAYU_CARD' && !validatedByProvider) && (
            <Card.Right>
              <Card.Label>
                {t('$*components.paymentCard.expiredAt', '$$Data wygaśnięcia')}
              </Card.Label>
              <Card.Info>{format(expireAt, 'MM/yy')}</Card.Info>
            </Card.Right>
          )}
        </Card.InfoWrapper>
      </Card.Front>
    </Card>
  );
};

export default PaymentCard;
