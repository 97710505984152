import { darken } from 'polished';

import { selectBranding, useAppConfigSelector } from '@hooks/app/useAppConfig';

const AnimatedCook = props => {
  const { primaryColor } = useAppConfigSelector(selectBranding);
  const colors = [
    primaryColor, // lid
    darken(0.1, primaryColor), // hoop
    primaryColor, // pot
    'rgb(195, 57, 56)', // fire
  ];

  return (
    <svg
      {...props}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      width="180px"
    >
      <g
        style={{
          transformOrigin: '50% 50%',
          transform: 'rotate(0deg) scale(0.8, 0.8)',
        }}
      >
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g
                    style={{
                      transformOrigin: '50% 50%',
                      transform: 'matrix(1, 0, 0, 1, 0, 0)',
                      animation:
                        '20s linear -10.7692s infinite normal forwards running tremble',
                    }}
                  >
                    <path
                      strokeMiterlimit="10"
                      stroke="#333"
                      fill="none"
                      strokeWidth="3.525"
                      d="M42.7 19.4v-4c0-3 2.4-5.4 5.4-5.4h3.8c3 0 5.4 2.4 5.4 5.4v4"
                      style={{ stroke: colors[0] }}
                    ></path>
                  </g>
                </g>
                <g>
                  <g
                    style={{
                      transformOrigin: '50px 50px',
                      transform: 'matrix(1, 0, 0, 1, 0, 0)',
                      animation:
                        '20s linear -11.5385s infinite normal forwards running tremble',
                    }}
                  >
                    <path
                      fill="#666766"
                      d="M79.4 28.3H20.6c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h58.8c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6z"
                      style={{ fill: colors[1] }}
                    ></path>
                  </g>
                </g>
                <g>
                  <g
                    style={{
                      transformOrigin: '50px 50px',
                      transform: 'matrix(1, 0, 0, 1, 0, 0)',
                      animation:
                        '20s linear -12.3077s infinite normal forwards running tremble',
                    }}
                  >
                    <path
                      fill="#333"
                      d="M49.9 15.9c-15.5 0-28.1 5.6-28.1 12.6h56.3c0-7-12.6-12.6-28.2-12.6z"
                      style={{ fill: colors[0] }}
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g
                    style={{
                      transformOrigin: '50px 50px',
                      transform: 'matrix(1, 0, 0, 1, 0, 0)',
                      animation:
                        '20s linear -13.0769s infinite normal forwards running tremble',
                    }}
                  >
                    <path
                      fill="#e0e0e0"
                      d="M67.7 81.1H32.3c-5.7 0-10.4-4.7-10.4-10.4V35.5H78v35.1c.1 5.8-4.6 10.5-10.3 10.5z"
                      style={{ fill: colors[2] }}
                    ></path>
                  </g>
                </g>
                <g>
                  <g
                    style={{
                      transformOrigin: '50px 50px',
                      transform: 'matrix(1, 0, 0, 1, 0, 0)',
                      animation:
                        '20s linear -13.8462s infinite normal forwards running tremble',
                    }}
                  >
                    <path
                      d="M27.9 41.9v16.7"
                      strokeMiterlimit="10"
                      fill="none"
                      strokeWidth="3.525"
                      strokeLinecap="round"
                      style={{ stroke: 'rgb(255, 255, 255)' }}
                    ></path>
                  </g>
                </g>
                <g>
                  <g
                    style={{
                      transformOrigin: '50px 50px',
                      transform: 'matrix(1, 0, 0, 1, 0, 0)',
                      animation:
                        '20s linear -14.6154s infinite normal forwards running tremble',
                    }}
                  >
                    <path
                      d="M27.9 67v4"
                      strokeMiterlimit="10"
                      fill="none"
                      strokeWidth="3.525"
                      strokeLinecap="round"
                      style={{ stroke: 'rgb(255, 255, 255)' }}
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -15.3846s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M32.6 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1 0-2.6-2.7-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -16.1538s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M38.8 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1 0-2.6-2.7-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -16.9231s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M45 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1 0-2.6-2.8-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -17.6923s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M51.1 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1.1-2.6-2.7-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -18.4615s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M57.3 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1.1-2.6-2.7-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -19.2308s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M63.5 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1 0-2.6-2.7-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
          <g>
            <g
              style={{
                transformOrigin: '50px 50px',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                animation:
                  '20s linear -20s infinite normal forwards running tremble',
              }}
            >
              <path
                fill="#c33938"
                d="M69.7 78.5c-2.5 1.3-4.8 3.8-4.8 6.6 0 .7.1 1.4.3 2.1 1 3.6 5.7 3.6 6.7 0 .2-.7.3-1.4.3-2.1 0-2.6-2.7-4.2-2.5-6.6z"
                style={{ fill: colors[3] }}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AnimatedCook;
