import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getAllowedStreets = async payload => {
  const response = await axios.get(ENDPOINT.ALLOWED_STREETS, {
    params: { ...payload },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useAllowedStreets = (payload, options = {}) => {
  return useQuery(
    ['allowedStreets', payload],
    () => getAllowedStreets(payload),
    {
      initialStale: true,
      ...options,
    }
  );
};

export default useAllowedStreets;
