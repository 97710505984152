const StarIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 78 75"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M76.59 31.533a2.916 2.916 0 00-1.695-5.135l-24.234-1.594-9.144-22.97a2.916 2.916 0 00-5.42 0l-9.144 22.97-24.234 1.594a2.917 2.917 0 00-1.695 5.136l18.613 15.778-6.112 23.99a2.917 2.917 0 004.384 3.185L38.807 61.28l20.897 13.208a2.917 2.917 0 004.385-3.186l-6.112-23.988 18.613-15.78zm-9.272.213L52.814 44.042a2.917 2.917 0 00-.94 2.945l4.753 18.654-16.262-10.277a2.917 2.917 0 00-3.116 0L20.987 65.64l4.753-18.655a2.917 2.917 0 00-.94-2.945L10.296 31.746l18.877-1.242a2.916 2.916 0 002.519-1.831l7.115-17.875 7.115 17.875a2.916 2.916 0 002.518 1.831l18.878 1.242z" />
  </svg>
);

export default StarIcon;
