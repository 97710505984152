import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, Carousel } from '@components';
import { useMediaQuery, useMobileDetect } from '@hooks';
import { down } from '@utils/screens';

const SelectVariantSection = ({
  className,
  variantsPerView = 9,
  avaliableVariants = [],
  selectVariant = () => {},
  selectedVariantId = null,
}) => {
  const { t } = useTranslation();
  const [variantsBatches, setVariantsBatches] = useState([]);
  const [initialBatchSlide, setInitialBatchSlide] = useState(0);
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const isDown500 = useMediaQuery(down('500px'), true);

  useEffect(() => {
    if (avaliableVariants && avaliableVariants.length > variantsPerView) {
      const batches = chunk(avaliableVariants, variantsPerView);
      const initialBatchIndex = batches
        .map(batch => {
          const batchIncludingSelectedVariant = batch.find(variant =>
            variant?.['@id'] === selectedVariantId ? true : false
          );

          return !isEmpty(batchIncludingSelectedVariant);
        })
        .indexOf(true);

      setVariantsBatches(batches);
      setInitialBatchSlide(initialBatchIndex > 0 ? initialBatchIndex : 0);
    }
  }, [avaliableVariants]);

  const renderVariantsBatch = (variants = []) => {
    return (
      <div tw="flex -mx-1 flex-wrap">
        {variants.map(variant => (
          <div
            key={variant['@id']}
            tw="px-1"
            css={[isDown500 ? tw`w-1/2` : tw`w-1/3`]}
          >
            <Button
              type="button"
              color="primary"
              fullWidth={true}
              tooltip={variant?.description}
              variant="outlined"
              styles={{
                button: [
                  tw`flex-auto my-1 text-xs shadow hover:(bg-white text-primary)`,
                  variant['@id'] !== selectedVariantId &&
                    tw`border-transparent`,
                  variant['@id'] === selectedVariantId &&
                    tw`font-bold border-2 `,
                  { minHeight: '65px' },
                ],
              }}
              onClick={() => selectVariant(variant)}
            >
              {variant?.name}
            </Button>
          </div>
        ))}
      </div>
    );
  };

  if (isEmpty(avaliableVariants)) {
    return null;
  }

  return (
    <div>
      <h2 tw="text-lg">
        {t(
          '$*newOrderCreatePage.orderForm.diets.selectVariant.title',
          '$$Wariant'
        )}
      </h2>
      {variantsBatches.length > 0 ? (
        <div tw="relative w-full">
          <Carousel
            className={className}
            options={{
              initialSlide: initialBatchSlide,
              slidesPerView: 3,
              spaceBetween: 0,
              allowTouchMove: isMobile,
              ...(!isMobile
                ? {
                    keyboard: {
                      enabled: true,
                      onlyInViewport: false,
                    },
                  }
                : {}),
              breakpoints: {
                500: { slidesPerView: 4, slidesPerGroup: 4 },
                650: { slidesPerView: 5, slidesPerGroup: 5 },
                1200: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1400: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
              },
            }}
          >
            {Slide =>
              variantsBatches.map((variantsBatch, index) => {
                return (
                  <Slide
                    key={index}
                    onClick={e => {
                      e.preventDefault();
                    }}
                  >
                    {renderVariantsBatch(variantsBatch)}
                  </Slide>
                );
              })
            }
          </Carousel>
        </div>
      ) : (
        <div tw="w-full">{renderVariantsBatch(avaliableVariants)}</div>
      )}
    </div>
  );
};

export default SelectVariantSection;
