import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import {
  CardIcon,
  CashIcon,
  ExclamationIcon,
  StripeCardIcon,
  TransferIcon,
} from '@assets/icons';
import { DisplayDownSm, DisplayUpSm, Tooltip } from '@components';
import PAYMENT_TYPES from '@constants/paymentTypes';
import { useBasketMethods, usePaymentCards } from '@hooks';
import { cssMerge } from '@utils/styleHelpers';

import PaymentType from './PaymentType';

const PayPoModal = dynamic(() => import('./PayPoModal/PayPoModal'));
const StripeCardModal = dynamic(() =>
  import('./StripeCardModal/StripeCardModal')
);
const PayUCardModal = dynamic(() => import('./PayUCardModal/PayUCardModal'));

const PaymentTypes = forwardRef(
  (
    {
      children = null,
      onClickConfirmPayPo = () => {},
      onClickConfirmStripeCard = () => {},
      onClickConfirmPayUCard = () => {},
      onClickPayment = () => {},
      payments = [],
      selectedPaymentType = null,
      styles = {},
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: cards = [] } = usePaymentCards();
    const { setPaymentMethodMetadata } = useBasketMethods();

    const [isStripeCardModalOpen, setIsStripeCardModalOpen] = useState(null);
    const openStripeCardModal = () => setIsStripeCardModalOpen(true);
    const closeStripeCardModal = () => setIsStripeCardModalOpen(false);

    const [isPayUCardModalOpen, setIsPayUCardModalOpen] = useState(null);
    const openPayUCardModal = () => setIsPayUCardModalOpen(true);
    const closePayUCardModal = () => setIsPayUCardModalOpen(false);

    const [isPayPoModalOpen, setIsPayPoModalOpen] = useState(null);
    const openPayPoModal = () => setIsPayPoModalOpen(true);
    const closePayPoModal = () => setIsPayPoModalOpen(false);

    const isSelectedProviderCardAvaliable = !isEmpty(
      cards?.find(({ provider }) => provider === selectedPaymentType)
    );

    useImperativeHandle(ref, () => ({
      clickPaymentByType: paymentType => {
        const item = sortedPaymentTypes.find(type => type === paymentType);
        handleClickPayment(item);
      },
    }));

    const sortPaymentTypes = paymentTypes => {
      const paymentsOrder = [
        PAYMENT_TYPES.PAYU,
        PAYMENT_TYPES.PAYU_CARD,
        PAYMENT_TYPES.BLUE_MEDIA,
        PAYMENT_TYPES.TPAY,
        PAYMENT_TYPES.STRIPE_LINK,
        PAYMENT_TYPES.PAYPO,
        PAYMENT_TYPES.BLUE_MEDIA_CARD,
        PAYMENT_TYPES.STRIPE_CARD,
        PAYMENT_TYPES.BANK_WIRE,
        PAYMENT_TYPES.CASH,
      ];

      const sortedPaymentsTypes = paymentsOrder.reduce((acc, type) => {
        const paymentType = paymentTypes.find(
          paymentType => paymentType === type
        );

        if (!isEmpty(paymentType)) {
          return [...acc, paymentType];
        }

        return [...acc];
      }, []);

      return sortedPaymentsTypes;
    };

    const sortedPaymentTypes = useMemo(
      () => sortPaymentTypes(payments),
      [payments]
    );

    const paymentTypeProps = {
      [PAYMENT_TYPES.PAYU]: {
        image: (
          <Fragment>
            <img src="/images/payment/payu.png" alt="" loading="lazy" />
            <DisplayDownSm>
              <img src="/images/payment/card-blik.png" alt="" loading="lazy" />
            </DisplayDownSm>
          </Fragment>
        ),
        text: t(
          '$*components.paymentTypes.payu.label',
          '$$PayU - Karta, blik lub szybki przelew'
        ),
        rightCol: (
          <DisplayUpSm>
            <img
              src="/images/payment/card-blik.png"
              alt=""
              css={css`
                ${tw`pl-3 ml-auto`};
                max-width: 125px;
              `}
            />
          </DisplayUpSm>
        ),
      },
      [PAYMENT_TYPES.PAYU_CARD]: {
        image: <CardIcon tw="w-10" />,
        text: t('$*components.paymentTypes.payuCard.label', '$$Płatność kartą'),
        rightCol: null,
      },
      [PAYMENT_TYPES.BLUE_MEDIA]: {
        image: (
          <img src="/images/payment/blue-media.png" alt="" loading="lazy" />
        ),
        text: t('$*components.paymentTypes.blueMedia.label', '$$Blue Media'),
        rightCol: null,
      },
      [PAYMENT_TYPES.TPAY]: {
        image: <img src="/images/payment/tpay.png" alt="" loading="lazy" />,
        text: t('$*components.paymentTypes.tpay.label', '$$Tpay'),
        rightCol: null,
      },
      [PAYMENT_TYPES.STRIPE_LINK]: {
        image: <img src="/images/payment/stripe.png" alt="" loading="lazy" />,
        text: t('$*components.paymentTypes.stripeOnline.label', '$$Stripe'),
        rightCol: null,
      },
      [PAYMENT_TYPES.PAYPO]: {
        image: <img src="/images/payment/paypo.png" alt="" loading="lazy" />,
        text: t(
          '$*components.paymentTypes.paypo.label',
          '$$PayPo - Kup teraz, zapłać za 30 dni'
        ),
        rightCol: (
          <Tooltip
            content={
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    '$*components.paymentTypes.paypo.tooltip',
                    '$$Zamów teraz, zapłać później z PayPo!<br/> Kup teraz, zapłać później, nawet za 30 dni.<br/> Bezpieczna forma płatności odroczonej.<br/><br/> Zaznacz ten rodzaj płatności, w nastęonym kroku wypełnij krótki formularz, podaj swoje podstawowe dane i potwierdź transakcję kodem SMS, a PayPo zapłaci Twój rachunek.'
                  ),
                }}
              />
            }
          >
            <div tw="ml-auto pl-3">
              <ExclamationIcon tw="w-4 cursor-pointer" />
            </div>
          </Tooltip>
        ),
      },
      [PAYMENT_TYPES.BLUE_MEDIA_CARD]: {
        image: <CardIcon tw="w-10" />,
        text: t(
          '$*components.paymentTypes.blueMediaCard.label',
          '$$Płatność kartą'
        ),
        rightCol: null,
      },
      [PAYMENT_TYPES.STRIPE_CARD]: {
        image: <StripeCardIcon tw="w-10" />,
        text: t(
          '$*components.paymentTypes.stripeCard.label',
          '$$Płatność kartą'
        ),
        rightCol: null,
      },
      [PAYMENT_TYPES.BANK_WIRE]: {
        image: <TransferIcon tw="w-10" />,
        text: t(
          '$*components.paymentTypes.bankWire.label',
          '$$Przelew tradycyjny'
        ),
        rightCol: null,
      },
      [PAYMENT_TYPES.CASH]: {
        image: <CashIcon tw="w-10" />,
        text: t(
          '$*components.paymentTypes.cash.label',
          '$$Płatność w punkcie odbioru'
        ),
        rightCol: null,
      },
    };

    const handleClickPayment = paymentType => {
      if (paymentType === PAYMENT_TYPES.STRIPE_CARD) {
        return openStripeCardModal();
      }
      if (paymentType === PAYMENT_TYPES.PAYU_CARD) {
        return openPayUCardModal();
      }

      if (paymentType === PAYMENT_TYPES.PAYPO) {
        return openPayPoModal();
      }

      return onClickPayment({ paymentType });
    };

    const handleClickConfirmStripeCard = () => {
      closeStripeCardModal();
      onClickConfirmStripeCard();
    };

    const handleClickConfirmPayUCard = () => {
      closePayUCardModal();
      onClickConfirmPayUCard();
    };

    const handleClickConfirmPaymentPayPo = (values, { setSubmitting }) => {
      closePayPoModal();

      dispatch(setPaymentMethodMetadata(values));
      setSubmitting(false);

      onClickConfirmPayPo(values);
    };

    if (isEmpty(payments)) {
      return null;
    }

    const twStyle = cssMerge({
      defaultCss: tw`-my-4`,
      ...styles?.wrapper,
    });

    const hasPaymentType = paymentType =>
      payments.some(type => type === paymentType);
    const hasPayPoPayment = hasPaymentType(PAYMENT_TYPES.PAYPO);
    const hasPayUCardPayment = hasPaymentType(PAYMENT_TYPES.PAYU_CARD);
    const hasStripeCardPayment = hasPaymentType(PAYMENT_TYPES.STRIPE_CARD);

    return (
      <Fragment>
        <div css={twStyle} data-cy="payment-types">
          {!isEmpty(sortedPaymentTypes) ? (
            sortedPaymentTypes.map((paymentType, index) => {
              const typeComponents = paymentTypeProps[paymentType];
              const handleClickPaymentItem = () =>
                handleClickPayment(paymentType);

              if (typeof children === 'function') {
                return children({
                  paymentType,
                  typeComponents,
                  onClickPaymentItem: handleClickPaymentItem,
                });
              }

              return (
                <PaymentTypeItem
                  key={index}
                  onClick={handleClickPaymentItem}
                  isSelected={
                    selectedPaymentType === paymentType &&
                    ([
                      PAYMENT_TYPES.PAYU_CARD,
                      PAYMENT_TYPES.STRIPE_CARD,
                    ].includes(selectedPaymentType)
                      ? isSelectedProviderCardAvaliable
                      : true)
                  }
                  styles={styles?.paymentType}
                  type={paymentType}
                  {...typeComponents}
                />
              );
            })
          ) : (
            <span>
              {t(
                '$*components.paymentTypes.noPaymentTypes',
                '$$Brak metod płatności, skontaktuj się z BOK.'
              )}
            </span>
          )}
        </div>

        {hasStripeCardPayment && (
          <StripeCardModal
            isOpen={isStripeCardModalOpen}
            closeModal={closeStripeCardModal}
            onClickConfirm={handleClickConfirmStripeCard}
          />
        )}
        {hasPayUCardPayment && (
          <PayUCardModal
            isOpen={isPayUCardModalOpen}
            closeModal={closePayUCardModal}
            onClickConfirm={handleClickConfirmPayUCard}
          />
        )}
        {hasPayPoPayment && (
          <PayPoModal
            isOpen={isPayPoModalOpen}
            closeModal={closePayPoModal}
            onClickConfirm={handleClickConfirmPaymentPayPo}
          />
        )}
      </Fragment>
    );
  }
);

const PaymentTypeItem = ({ image, text, rightCol, ...restProps }) => {
  return (
    <PaymentType {...restProps}>
      <PaymentType.Image>{image}</PaymentType.Image>
      <PaymentType.Text>{text}</PaymentType.Text>
      <PaymentType.RightCol>{rightCol}</PaymentType.RightCol>
    </PaymentType>
  );
};

PaymentTypeItem.displayName = 'PaymentTypeItem';
PaymentTypes.Item = PaymentTypeItem;

PaymentTypes.displayName = 'PaymentTypes';

export default PaymentTypes;
