import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex, Spinner } from '@components';
import { useAddPaymentCard, useBasketMethods } from '@hooks';
import {
  selectModulePayU,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import showToast from '@utils/showToast';

import { useInitialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const PayUCardForm = ({
  isCVVForm = false,
  paymentIntentURL = '',
  cvvHandleCallback = () => {},
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [submitting, setSubmitting] = useState(false);
  const [waitingCounter, setWaitingCounter] = useState(1);
  const [isPayUFormRendered, setIsPayUFormRendered] = useState(false);
  const { isSubscriptionInCart } = useBasketMethods();

  const fields = useFields(isSubscriptionInCart);
  const initialValues = useInitialValues(isSubscriptionInCart);
  const validationSchema = useValidationSchema(isSubscriptionInCart);

  const { posId } = useAppConfigSelector(selectModulePayU);
  const { mutateAsync: addPaymentCard } = useAddPaymentCard();

  const formType = isCVVForm ? 'cvv' : 'card';

  const optionsForms = {
    current: 'gt500',
    profiles: {
      gt500: {
        style: {
          basic: {
            fontSize: '16px',
          },
        },
        cardIcon: true,
      },
      lt500: {
        style: {
          basic: {
            fontSize: '14px',
          },
        },
        cardIcon: false,
      },
    },
    lang: router?.locale,
  };

  const renderError = errors => {
    let messages = [];
    errors.forEach(error => {
      messages.push(error.message);
    });

    showToast(messages.join(', '), {
      type: 'error',
    });
  };

  const handleSuccess = async response => {
    setSubmitting(true);

    const mask = response?.mask ?? '';
    const payload = {
      mask: mask.toString().substr(mask.length - 4),
      index: response.token,
      provider: 'PAYU_CARD',
    };

    try {
      await addPaymentCard(payload);
      setSubmitting(false);
    } catch (exception) {
      setSubmitting(false);
      showToast(
        t(
          '$*error.addingPaymentCardFailed',
          '$$Wystąpił błąd podczas dodawania karty płatniczej'
        ),
        {
          type: 'error',
        }
      );
    }
  };

  const handleCVVVerification = async ({ responseElement, payuSdkForms }) => {
    try {
      responseElement.innerText = '';

      const refReqId = await payuSdkForms.extractRefReqId(paymentIntentURL);
      const result = await payuSdkForms.sendCvv(refReqId);

      result?.status === 'SUCCESS'
        ? cvvHandleCallback()
        : renderError(result?.error?.messages);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCardTokenization = cardProps => {
    const { responseElement, payuSdkForms } = cardProps;
    responseElement.innerText = '';

    try {
      payuSdkForms.tokenize('MULTI').then(result => {
        result?.status === 'SUCCESS'
          ? handleSuccess(result?.body)
          : renderError(result?.error?.messages);
      });
    } catch (e) {
      console.log(e); // tech errors
    }
  };

  useEffect(() => {
    if (isPayUFormRendered) {
      return;
    }

    if (!posId || !window?.PayU || !document.getElementById('payu-card')) {
      // Sometimes when internet connection is slow, it takes some time to get PayU function avaliable, so thats why we force retry after 3 sec by setting waitingCounter
      return setTimeout(() => {
        setWaitingCounter(waitingCounter + 1);
      }, 3000);
    }

    const payuSdkForms = window.PayU(posId);
    const secureForms = payuSdkForms.secureForms();

    const card = secureForms.add(formType, {
      ...optionsForms.profiles[window.innerWidth > 500 ? 'gt500' : 'lt500'],
      lang: router?.locale,
    });

    card.render('#payu-card');

    const tokenizeButton = document.getElementById('tokenizeButton');
    const responseElement = document.getElementById('responseTokenize');

    tokenizeButton?.addEventListener(
      'click',
      isCVVForm
        ? () => {
            handleCVVVerification({ responseElement, payuSdkForms });
          }
        : () => {
            handleCardTokenization({ responseElement, payuSdkForms });
          }
    );

    window.addEventListener('resize', () => {
      let newProfile = window.innerWidth > 500 ? 'gt500' : 'lt500';
      if (newProfile !== optionsForms.current) {
        optionsForms.current = newProfile;
        card?.update(optionsForms.profiles[optionsForms.current]);
      }
    });
    setIsPayUFormRendered(true);
  }, [waitingCounter]);

  if (submitting) {
    return <Spinner text="$*components.paymentCardForm.payU.isLoading" />;
  }

  return (
    <div>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ dirty, isSubmitting, status }) => (
          <Form>
            {!isPayUFormRendered && (
              <Spinner text="$*components.paymentCardForm.payU.isLoading" />
            )}
            <div style={{ display: isPayUFormRendered ? 'block' : 'none' }}>
              {fields.map(({ id, ...restProps }) => {
                return <InputsIndex key={id} id={id} {...restProps} />;
              })}
              {status?.apiErrorMessage && (
                <Alert styles={{ css: tw`mt-4` }}>
                  {status.apiErrorMessage}
                </Alert>
              )}

              <div tw="mt-8">
                <Button
                  id="tokenizeButton"
                  isLoading={isSubmitting}
                  disabled={
                    isSubscriptionInCart ? isSubmitting || !dirty : isSubmitting
                  }
                  fullWidth={true}
                >
                  {isCVVForm
                    ? t('$*components.paymentCardForm.submit', '$$Zatwierdź')
                    : t(
                        '$*components.paymentCardForm.saveCard',
                        '$$Zapisz kartę'
                      )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PayUCardForm;
