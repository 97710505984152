import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getUserDiets = async () => {
  const response = await axios.get(ENDPOINT.USER_DIETS_LIST);

  return response?.data?.['hydra:member'] ?? [];
};

const useUserDiets = (options = {}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return useQuery('userDiets', getUserDiets, {
    enabled: isAuthenticated,
    ...options,
  });
};

export default useUserDiets;
