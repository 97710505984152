import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getDishTags = async payload => {
  const response = await axios.get(ENDPOINT.DISH_TAGS, {
    params: { ...payload },
  });

  return response?.data?.['hydra:member'] ?? [];
};

const useDishTags = (payload, options = {}) => {
  const query = useQuery(
    ['dishTags', { ...payload }],
    () => getDishTags(payload),
    options
  );

  return query;
};

export default useDishTags;
