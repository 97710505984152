import tw from 'twin.macro';

import { OverflowContent } from '@components';
import { cssMerge } from '@utils/styleHelpers';

import { useDishTileContext } from './DishTile.context';

const DishName = ({ name, styles = {} }) => {
  const { dishIsSelected } = useDishTileContext();

  const twStyle = cssMerge({
    defaultCss: [
      tw`text-sm font-semibold max-h-16`,
      dishIsSelected && tw`text-primary`,
    ],
    ...styles,
  });

  return (
    <OverflowContent lineClamp={3} styles={{ css: twStyle }}>
      {name}
    </OverflowContent>
  );
};

export default DishName;
