import { useState } from 'react';
import Tippy from '@tippyjs/react/headless'; // different import path!

const Popover = ({ children, content, ...tooltipProps }) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const tooltipPortal = document.querySelector('#tooltipPortal');

  return (
    <Tippy
      render={attrs => (
        <div className="box" tabIndex="-1" {...attrs}>
          {content}
        </div>
      )}
      interactive={true}
      visible={visible}
      onClickOutside={hide}
      appendTo={tooltipPortal}
      {...tooltipProps}
    >
      {children({ visible, show, hide })}
    </Tippy>
  );
};

Popover.displayName = 'Popover';

export default Popover;
