import { Trans, useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import injectHtml from 'html-react-parser';
import tw from 'twin.macro';

import { Link } from '@components';
import INPUT_IDS from '@constants/inputs/registerInputs';
import { useMediaQuery } from '@hooks';
import {
  selectDictionaries,
  selectModuleLinks,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { isFalsify, sortByPosition } from '@utils/helpers';
import { up } from '@utils/screens';

const useRegisterFields = () => {
  const { t } = useTranslation();
  const isUpMd = useMediaQuery(up('md'), true);
  const { sex = [], howKnow = [] } = useAppConfigSelector(selectDictionaries);
  const { appTerms, marketingTerms } = useAppConfigSelector(selectModuleLinks);
  const formik = useFormikContext();

  const genderOptions = sortByPosition(sex)?.map(({ '@id': iriId, value }) => ({
    label: value,
    value: iriId,
  }));

  const referrerOptions = sortByPosition([
    ...howKnow,
    {
      value: t('$*input.knowAboutUs.option.other.label', '$$Inne'),
      position: 9999,
    },
  ])?.map(({ value }) => ({
    label: value,
    value: value,
  }));

  const registerFields = useMemo(() => {
    const fields = [
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.FIRST_NAME,
        type: 'text',
        label: t('$*input.firstName.label', '$$Imię'),
        required: true,
        ...(isUpMd ? { autoFocus: true } : {}),
      },
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.LAST_NAME,
        type: 'text',
        label: t('$*input.lastName.label', '$$Nazwisko'),
        required: true,
      },
      // {
      //   colClass: 'sm:col-6 md:col-4',
      //   colCss: tw`order-3`,
      //   id: INPUT_IDS.GENDER,
      //   type: 'select',
      //   label: t('$*input.gender.label', '$$Płeć'),
      //   required: false,
      //   options: genderOptions,
      //   isSearchable: false,
      // },
      {
        id: INPUT_IDS.EMAIL,
        type: 'text',
        label: t('$*input.email.label', '$$E-mail'),
        required: true,
        onChange: ({ target }) => {
          const newValue = target.value.toLowerCase();

          formik.setFieldValue(target.name, newValue);
        },
      },
      {
        id: INPUT_IDS.PHONE,
        type: 'phone',
        label: t('$*input.phone.label', '$$Telefon'),
        required: true,
      },
      {
        id: INPUT_IDS.PASSWORD,
        type: 'password',
        label: t('$*input.password.label', '$$Hasło'),
        autoComplete: 'new-password',
        required: true,
      },
      // {
      //   colCss: tw`order-8`,
      //   id: INPUT_IDS.REFERRER,
      //   type: 'select',
      //   label: t('$*input.knowAboutUs.label', '$$Skąd o nas wiesz?'),
      //   options: referrerOptions,
      //   placeholder: '',
      //   required: true,
      //   isSearchable: false,
      // },
      // {
      //   colCss: tw`order-9`,
      //   id: INPUT_IDS.REFERRER_TEXT,
      //   type: 'text',
      //   placeholder: '',
      //   required: true,
      // },
      ...(appTerms?.enabled
        ? [
            {
              colCss: tw`order-10`,
              id: INPUT_IDS.SERVICE_AGREEMENT,
              type: 'checkbox',
              label: !isFalsify(appTerms.label) ? (
                injectHtml(appTerms.label)
              ) : (
                <Trans
                  i18nKey="$*input.appTerms.label"
                  defaults="$$Akceptuję <0>regulamin serwisu</0>."
                  components={[
                    <Link
                      key="0"
                      href={appTerms.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="font-semibold"
                    />,
                  ]}
                />
              ),
              required: true,
              formGroupProps: { styles: { mode: 'replace', css: tw`my-0.5` } },
            },
          ]
        : []),
      ...(marketingTerms?.enabled
        ? [
            {
              colCss: tw`order-11`,
              id: INPUT_IDS.MARKETING_AGREEMENT,
              type: 'checkbox',
              label: !isFalsify(marketingTerms.label) ? (
                injectHtml(marketingTerms.label)
              ) : (
                <Trans
                  i18nKey="$*input.marketingTerms.label"
                  defaults="$$Chcę otrzymywać informacje o <0>aktualnych ofertach oraz promocjach<0>."
                  components={[
                    <Link
                      key="0"
                      href={marketingTerms.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="font-semibold"
                    />,
                  ]}
                />
              ),
              required: false,
              formGroupProps: { styles: { mode: 'replace', css: tw`my-0.5` } },
            },
          ]
        : []),
      {
        colCss: tw`order-12`,
        id: INPUT_IDS.KEEP_ME_LOGGED_IN,
        type: 'checkbox',
        label: t('$*input.keepMeLoggedIn.label', '$$Nie wylogowuj mnie'),
        required: false,
        formGroupProps: { styles: { mode: 'replace', css: tw`my-0.5` } },
      },
    ];

    return fields;
  }, [t, genderOptions, referrerOptions]);

  return registerFields;
};

export default useRegisterFields;
