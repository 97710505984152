import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/paypoInputs';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const initialValues = {
  [INPUT_IDS.PAYPO_ADDRESS_POST_CODE]: '',
  [INPUT_IDS.PAYPO_ADDRESS_CITY]: '',
  [INPUT_IDS.PAYPO_ADDRESS_STREET]: '',
  [INPUT_IDS.PAYPO_ADDRESS_BUILD_NUMBER]: '',
  [INPUT_IDS.PAYPO_ADDRESS_PLACE_NUMBER]: '',
};

export const getInitialValues = address => {
  return {
    [INPUT_IDS.PAYPO_ADDRESS_POST_CODE]:
      address[INPUT_IDS.ADDRESS_POST_CODE] ??
      address[INPUT_IDS.PAYPO_ADDRESS_POST_CODE] ??
      '',
    [INPUT_IDS.PAYPO_ADDRESS_CITY]:
      address[INPUT_IDS.ADDRESS_CITY] ??
      address[INPUT_IDS.PAYPO_ADDRESS_CITY] ??
      '',
    [INPUT_IDS.PAYPO_ADDRESS_STREET]:
      address[INPUT_IDS.ADDRESS_STREET] ??
      address[INPUT_IDS.PAYPO_ADDRESS_STREET] ??
      '',
    [INPUT_IDS.PAYPO_ADDRESS_BUILD_NUMBER]:
      address[INPUT_IDS.ADDRESS_BUILD_NUMBER] ??
      address[INPUT_IDS.PAYPO_ADDRESS_BUILD_NUMBER] ??
      '',
    [INPUT_IDS.PAYPO_ADDRESS_PLACE_NUMBER]:
      address[INPUT_IDS.ADDRESS_PLACE_NUMBER] ??
      address[INPUT_IDS.PAYPO_ADDRESS_PLACE_NUMBER] ??
      '',
  };
};

export const useValidationSchema = () => {
  const { stringRequired } = useDefaultSchemaYup();

  return Yup.object({
    [INPUT_IDS.PAYPO_ADDRESS_POST_CODE]: stringRequired,
    [INPUT_IDS.PAYPO_ADDRESS_CITY]: stringRequired,
    [INPUT_IDS.PAYPO_ADDRESS_STREET]: stringRequired,
    [INPUT_IDS.PAYPO_ADDRESS_BUILD_NUMBER]: stringRequired,
  });
};
