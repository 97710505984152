import { useMutation } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const createUserBagRate = async ({ dietId, date, rate }) => {
  const response = await axios.put(ENDPOINT.BAG_RATE({ dietId, date }), rate, {
    errotHandle: false,
  });

  return response?.data ?? {};
};

const useCreateUserBagRate = (options = {}) => {
  const query = useMutation(createUserBagRate, {
    mutationKey: 'userBagRate',
    ...options,
  });

  return query;
};

export default useCreateUserBagRate;
