import tw from 'twin.macro';

import { OverflowContent } from '@components';
import { cssMerge } from '@utils/styleHelpers';

import { useProductTileContext } from './ProductTile.context';

const ProductName = ({ name, styles = {} }) => {
  const { productIsSelected } = useProductTileContext();

  const twStyle = cssMerge({
    defaultCss: [
      tw`text-sm font-semibold max-h-14`,
      productIsSelected && tw`text-primary`,
    ],
    ...styles,
  });

  return (
    <OverflowContent lineClamp={3} styles={{ css: twStyle }}>
      {name}
    </OverflowContent>
  );
};

export default ProductName;
