import DisplayOn from './DisplayOn';

const DisplayDownMd = ({ children }) => (
  <DisplayOn size="md" mode="down" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayDownMd.displayName = 'DisplayDownMd';

export default DisplayDownMd;
