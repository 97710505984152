import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Box } from '@components';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import { useBasketMethods } from '@hooks';
import { useAppContext } from '@views/App/App.context';

import AddonPageConfigurator from './AddonPageConfigurator/AddonPageConfigurator';
import DishPageConfigurator from './DishPageConfigurator/DishPageConfigurator';
import BasketAction from './BasketAction';
import BasketContent from './BasketContent';
import BasketHeader from './BasketHeader';

const Basket = ({
  styles = {},
  dishPageSettings = {},
  addonPageSettings = {},
}) => {
  const { isShop } = useAppContext();
  const { currentStepIndex, stepsCount } = useSelector(selectTabs);
  const { isBasketNewOrder } = useBasketMethods();

  const isLastStep = currentStepIndex === stepsCount - 1;

  const wrapperProps = {
    size: 'md',
    styles: styles?.box,
    onClick: e => e.stopPropagation(),
  };

  if (!isEmpty(dishPageSettings)) {
    return (
      <Box {...wrapperProps}>
        <DishPageConfigurator {...dishPageSettings} />
      </Box>
    );
  }

  if (!isEmpty(addonPageSettings)) {
    return (
      <Box {...wrapperProps}>
        <AddonPageConfigurator {...addonPageSettings} />
      </Box>
    );
  }

  return (
    <Box {...wrapperProps}>
      <BasketHeader />
      <BasketContent />
      <div
        css={[tw`pt-8`, isBasketNewOrder && isLastStep && !isShop && tw`pt-0`]}
      >
        <BasketAction />
      </div>
      {/* <AbandonedBasketModal /> */}
    </Box>
  );
};

export default Basket;
