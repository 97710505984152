import { css } from '@emotion/react';
import tw from 'twin.macro';

import { useMediaQuery } from '@hooks';
import { up } from '@utils/screens';

import { usePurchasedProductTileContext } from './PurchasedProductTile.context';

const ProductWrapper = ({ children, isSelected, onClick = () => {} }) => {
  const isUpMd = useMediaQuery(up('md'), true);
  const { showImage } = usePurchasedProductTileContext();

  return (
    <div
      css={[
        tw`rounded-lg w-full relative border-2 transition-colors duration-300 cursor-pointer hover:(border-primary duration-300)`,
        isSelected && tw`border-primary`,
        showImage && isUpMd
          ? css`
              min-height: 370px;
            `
          : '',
      ]}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ProductWrapper;
