import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import { PriceWrapper, Tooltip } from '@components';
import { useBasketMethods, useDiscountTranslations } from '@hooks';

const BasketDiscountTooltip = ({ children }) => {
  const { t } = useTranslation();
  const {
    basketQuery: { data: { price = {} } = {} },
  } = useBasketMethods();
  const discountTranslations = useDiscountTranslations();

  return (
    <Tooltip
      content={
        <div>
          <div tw="text-center pb-1">
            {t('$*components.basket.usedDiscount', '$$Udzielone rabaty')}
          </div>
          {Object.entries(price?.discountParts).map(([key, value]) => (
            <div
              key={key}
              tw="flex justify-between text-sm -mx-4 font-semibold"
            >
              <div tw="px-4">{discountTranslations[key]}</div>
              <PriceWrapper
                afterDiscount={value * -1}
                styles={{ wrapper: { css: tw`px-4` } }}
              />
            </div>
          ))}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default BasketDiscountTooltip;
