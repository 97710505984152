import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex } from '@components';
import { useMediaQuery } from '@hooks';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { down } from '@utils/screens';

import useFields from './useFields';

const DiscountCodeForm = ({
  discount,
  handleRemoveUseDiscountCode,
  useDiscountCode,
}) => {
  const { t } = useTranslation();
  const isDown500 = useMediaQuery(down('500px'), true);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const fields = useFields();

  const hasDiscount = !isEmpty(discount);
  const { isSubmitting, resetForm, status } = useFormikContext();

  return (
    <div>
      <div className="row">
        {fields.map(({ id, colClass = 'col-12', ...restProps }) => {
          return (
            <div className={colClass} key={id}>
              <InputsIndex id={id} {...restProps} />
            </div>
          );
        })}
      </div>

      {hasDiscount && useDiscountCode && (
        <Fragment>
          <div tw="font-semibold flex items-center">
            <div tw="mr-3">
              {t(
                '$*newOrderCreatePage.orderForm.summary.grantedDiscount',
                '$$Udzielony rabat'
              )}
              :
            </div>
            {discount?.isPercentage ? (
              <div tw="border-blue-500 border-2 rounded-lg text-blue-500 px-2 py-0.5">{`-${discount.discount}%`}</div>
            ) : (
              <div tw="border-blue-500 border-2 rounded-lg text-blue-500 px-2 py-0.5">{`-${discount.discount} ${currencySymbol}`}</div>
            )}
          </div>
          {discount?.applyToAddons === false && (
            <div tw="mt-1">
              <span tw="text-sm bg-orangeDixie text-white px-2 py-0.5 rounded-md">
                {t(
                  '$*components.discountCode.noApplyToAddons',
                  '$$Rabat nie obejmuje dodatków.'
                )}
              </span>
            </div>
          )}
        </Fragment>
      )}

      {status?.apiErrorMessage && (
        <Alert styles={{ css: tw`mb-4` }}>{status.apiErrorMessage}</Alert>
      )}

      <div tw="pt-4 flex flex-wrap justify-between -mx-2 -my-1">
        <Button
          type="button"
          color="error"
          variant="outlined"
          onClick={() => handleRemoveUseDiscountCode(resetForm)}
          disabled={!useDiscountCode}
          styles={{
            button: [tw`mx-2 my-1`, isDown500 && tw`justify-center flex-auto`],
          }}
          data-cy="discount-code-form__cancel"
        >
          {t('$*components.discountCode.cancel', '$$Anuluj')}
        </Button>
        <Button
          type="submit"
          variant="outlined"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          styles={{
            button: [tw`mx-2 my-1`, isDown500 && tw`justify-center flex-auto`],
          }}
          data-cy="discount-code-form__submit"
        >
          {t('$*components.discountCode.load', '$$Wczytaj')}
        </Button>
      </div>
    </div>
  );
};

export default DiscountCodeForm;
