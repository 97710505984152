import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import AddonImageMobile from '@components/modules/Addons/AddonImageMobile';
import AddonInfoWrapperCollapsed from '@components/modules/Addons/AddonInfoWrapperCollapsed';
import AddonInfoWrapperExpanded from '@components/modules/Addons/AddonInfoWrapperExpanded';
import AddonName from '@components/modules/Addons/AddonName';
import { MobileTile } from '@components/modules/Addons/AddonTile.styles';
import { useToggle } from '@hooks';
import { checkImageExists } from '@utils/helpers';

const PurchasedAddonTileMobile = ({ addon, quantity }) => {
  const [isExistImage, setIsExistImage] = useState(null);
  const [isToggled, toggle] = useToggle(false);

  useEffect(() => {
    checkImageExists(addon.image)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [addon.image]);

  const imageUrl = isExistImage ? addon.image : '/images/placeholder.jpeg';

  return (
    <MobileTile isMobile={true}>
      <div onClick={toggle}>
        <div tw="flex w-full justify-between flex-wrap">
          <AddonImageMobile imageUrl={imageUrl} isOpen={isToggled} />
          <AddonInfoWrapperCollapsed isOpen={isToggled}>
            <div css={[tw`h-full p-3`]}>
              <div tw="flex flex-col h-full">
                <AddonName name={`${quantity} x ${addon?.name ?? ''}`} />
              </div>
            </div>
          </AddonInfoWrapperCollapsed>
        </div>

        <AddonInfoWrapperExpanded isOpen={isToggled}>
          <div tw="flex flex-col h-full">
            <div tw="p-3">
              <div className="row">
                <div className="col">
                  <AddonName name={`${quantity} x ${addon?.name ?? ''}`} />
                </div>
                <div className="col-auto">
                  <div tw="flex flex-col items-center">
                    <div onClick={toggle}>
                      <button
                        className="group"
                        tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                      >
                        <ArrowDownIcon
                          className="group-focus:(fill-current)"
                          tw="w-4 transform transition-colors rotate-180 duration-300 hover:(duration-300)"
                          title="Ukryj szczegóły"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              tw="relative px-3 pb-3 flex-auto"
              onClick={e => e.stopPropagation()}
            >
              <div tw="overflow-auto max-h-52">
                <MobileTile.Description>
                  {addon?.shortDescription ?? ''}
                </MobileTile.Description>
              </div>
            </div>
          </div>
        </AddonInfoWrapperExpanded>
      </div>
    </MobileTile>
  );
};

export default PurchasedAddonTileMobile;
