import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import { CalendarIcon } from '@assets/icons';
import { Button } from '@components';
import ROUTE_URLS from '@constants/routeUrls';
import { format } from '@services/Date.service';
import { getDayName } from '@utils/helpers';

import DietConfigurator from './DietConfigurator';
import ShopConfigurator from './ShopConfigurator';

const DishPageConfigurator = ({
  dishName,
  dietOptions = {},
  shopOptions = {},
}) => {
  const { t } = useTranslation();

  const getDishAvaliability = avaliableDate => {
    return (
      <div>
        <p tw="mb-0">
          {t('$*dishPage.dishAvaliableAt', '$$Danie dostępne w dniu')}
        </p>
        <div tw="flex items-center">
          <CalendarIcon tw="w-5 text-primary" />
          <span tw="ml-2 text-lg font-medium text-primary">{`${
            /* i18next-extract-disable-next-line */
            t(getDayName(new Date(avaliableDate)))
          }, ${format(new Date(avaliableDate), 'dd.MM.yyyy')}`}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div tw="flex items-center">
          <div tw="w-1/4 mr-4" style={{ borderTop: '1px solid' }} />
          <div tw="w-1/2 text-center">{dishName}</div>
          <div tw="w-1/4 ml-4" style={{ borderTop: '1px solid' }} />
        </div>
      </div>
      <div tw="mt-5">
        {!isEmpty(dietOptions) && (
          <>
            {getDishAvaliability(dietOptions?.avaliableDate)}
            <DietConfigurator {...dietOptions} />
          </>
        )}
        {!isEmpty(shopOptions) && (
          <>
            {getDishAvaliability(shopOptions?.avaliableDate)}
            <ShopConfigurator {...shopOptions} />
          </>
        )}
        {isEmpty(shopOptions) && isEmpty(dietOptions) && (
          <>
            <p>
              {t(
                '$*dishPage.dishIsNotCurrentlyAvaliable',
                '$$Danie nie jest aktualnie dostępne'
              )}
            </p>
            <Link href={ROUTE_URLS.NEW_ORDER_FORM}>
              <Button fullWidth>
                {t('$*common.checkCurrentMenu', '$$Zobacz aktualną ofertę')}
              </Button>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default DishPageConfigurator;
