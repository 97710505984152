import { Image } from '@components';

const DishImage = ({ showImage = false, imageUrl = null }) => {
  if (!showImage) {
    return null;
  }

  return (
    <Image
      src={imageUrl}
      width={246}
      height={246}
      alt=""
      loading="lazy"
      objectFit="cover"
      quality={100}
      layout="responsive"
      css={{ borderRadius: '5px 5px 0 0' }}
    />
  );
};

export default DishImage;
