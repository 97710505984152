import { css } from '@emotion/react';
import tw from 'twin.macro';

const MenuDishImage = ({ showImage = false, imageUrl = null }) => {
  if (!showImage) {
    return null;
  }

  return (
    <div
      css={css`
        background: url(${imageUrl}) no-repeat center center / cover;
        height: 250px;
        ${tw`w-full rounded-lg`}
      `}
    />
  );
};

export default MenuDishImage;
