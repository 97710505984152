import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import tw from 'twin.macro';

import {
  DisplayDownXxl,
  DisplayUpSm,
  DisplayUpXxl,
  LanguageSelector,
  Logo,
} from '@components';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import MoneyBoxBalance from './MoneyBoxBalance/MoneyBoxBalance';
import Nav from './Nav/Nav';
import AuthButtons from './AuthButtons';

const Header = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { clientAllowedToChangeLanguage } =
    useAppConfigSelector(selectMultinational);

  return (
    <header
      id="header"
      tw="py-2.5 bg-white border-b border-grayPorcelain h-20 flex items-center"
    >
      <div className="container">
        <div className="row" tw="items-center justify-between">
          <DisplayUpXxl>
            <div className="col-auto">
              <div tw="flex items-center">
                <Logo />
                <Nav />
              </div>
            </div>
            <div className="col-auto">
              <div
                css={[
                  tw`flex items-center`,
                  isAuthenticated ? tw`-mx-2` : tw`-ml-2 -mr-3`,
                ]}
              >
                {isAuthenticated && (
                  <MoneyBoxBalance styles={{ link: { css: tw`mx-2` } }} />
                )}
                {clientAllowedToChangeLanguage && (
                  <div tw="mx-2">
                    <LanguageSelector />
                  </div>
                )}

                <AuthButtons />
              </div>
            </div>
          </DisplayUpXxl>
          <DisplayDownXxl>
            <div className="col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <div tw="flex items-center">
                <DisplayUpSm>
                  {isAuthenticated && (
                    <MoneyBoxBalance styles={{ link: { css: tw`mr-4` } }} />
                  )}
                </DisplayUpSm>
                <HamburgerMenu />
              </div>
            </div>
          </DisplayDownXxl>
        </div>
      </div>
    </header>
  );
};

export default Header;
