import { useTranslation } from 'next-i18next';

import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateDiet } from '@store/myDiet/myDiet.actions';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex, Modal } from '@components';
import { useMediaQuery } from '@hooks';
import { down } from '@utils/screens';
import showToast from '@utils/showToast';

import { getInitialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const EditDietNameModal = ({ isOpen, onClose, diet }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isDown500 = useMediaQuery(down('500px'), true);
  const fields = useFields();
  const validationSchema = useValidationSchema();
  const initialValues = getInitialValues(diet);

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    dispatch(updateDiet({ dietId: diet.id, payload: values }))
      .then(unwrapResult)
      .then(response => {
        queryClient.setQueryData('userDiets', diets => {
          const dietIndex = diets.findIndex(({ id }) => id === diet.id);
          diets[dietIndex] = { ...diets[dietIndex], ...response };

          return diets;
        });

        onClose();
        showToast(
          t(
            '$*notification.update.success',
            '$$Dane zostały pomyślnie zaktualizowane'
          ),
          { type: 'info' }
        );
      })
      .catch(error => {
        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styles={{ wrapper: tw`w-full max-w-xs` }}
      size="sm"
    >
      <Modal.Header>
        {t('$*components.editDietName.modal.title', {
          defaultValue: '$$Edycja diety #{{ dietId }}',
          replace: { dietId: diet.id },
        })}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <div className="row">
                {fields.map(
                  ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
                    return (
                      <div className={colClass} key={id} css={colCss}>
                        <InputsIndex id={id} {...restProps} />
                      </div>
                    );
                  }
                )}
              </div>

              {status?.apiErrorMessage && (
                <Alert styles={{ css: tw`mt-4` }}>
                  {/* i18next-extract-disable-next-line */}
                  {t(`$*error.api.${status.apiErrorMessage}`)}
                </Alert>
              )}

              <div tw="pt-6 flex flex-wrap justify-between -mx-2 -my-1">
                <Button
                  type="button"
                  color="error"
                  variant="outlined"
                  styles={{
                    button: [
                      tw`mx-2 my-1`,
                      isDown500 && tw`justify-center flex-auto`,
                    ],
                  }}
                  onClick={onClose}
                >
                  {t('$*common.cancel', '$$Anuluj')}
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  styles={{
                    button: [
                      tw`mx-2 my-1`,
                      isDown500 && tw`justify-center flex-auto`,
                    ],
                  }}
                >
                  {t('$*common.save', '$$Zapisz')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

EditDietNameModal.displayName = 'EditDietNameModal';

export default EditDietNameModal;
