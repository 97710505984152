import { ENDPOINT } from '@constants/endpoint';
import useInfiniteQueryMiddleware from '@hooks/useInfiniteQueryMiddleware';

const useInfiniteOrders = () => {
  const query = useInfiniteQueryMiddleware('myOrders', ENDPOINT.MY_ORDERS, {
    itemsPerPage: 5,
  });

  return query;
};

export default useInfiniteOrders;
