const CirclePauseIcon = ({ title = '', ...props }) => (
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm-1.5 14.4a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6V7.6a.6.6 0 0 1 .6-.6h.8a.6.6 0 0 1 .6.6v8.8Zm5 0a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6V7.6a.6.6 0 0 1 .6-.6h.8a.6.6 0 0 1 .6.6v8.8Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default CirclePauseIcon;
