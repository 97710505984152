import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, InputsIndex } from '@components';
import INPUT_IDS from '@constants/inputs/addressInputs';
import { useMediaQuery } from '@hooks';
import { up } from '@utils/screens';

import { useAddressFormContext } from './AddressForm.context';
import useFields from './useFields';

const AddressForm = ({
  address,
  deleteButtonWithValues,
  isDefaultAddress,
  isFirstAddress,
  isNewAddress,
  showDeleteAddress = false,
}) => {
  const { t } = useTranslation();

  const [fields, [fieldSetAsDefault]] = useFields(address);
  const isUpMd = useMediaQuery(up('md'), true);
  const {
    hours,
    status,
    errors,
    values,
    isFormDirty: dirty,
    allowedCities,
    isFormSubmitting: isSubmitting,
    allowedStreets,
    hasDeliveryHours,
    hasAllowedCities,
    hasAllowedStreets,
    isCityFieldEnabled,
    isStreetFieldEnabled,
    deliveryHoursIsLoading,
    allowedBuildingNumbers,
    isBuildNumberFieldEnabled,
    hasAllowedBuildingNumbers,
  } = useAddressFormContext();

  const DynamicHeader = () => {
    const Title = isDefaultAddress && (
      <h2 tw="mb-0 text-primary">
        {t('$*components.addressForm.defaultAddress', '$$Adres domyślny')}
      </h2>
    );

    const DeleteButton = !isDefaultAddress && showDeleteAddress && (
      <div tw="self-end">{deleteButtonWithValues(values)}</div>
    );

    if (isEmpty(Title) && isEmpty(DeleteButton)) {
      return null;
    }

    return (
      <div tw="flex flex-col mb-4">
        {Title}
        {DeleteButton}
      </div>
    );
  };

  return (
    <Fragment>
      <DynamicHeader />

      {address?.containsActiveDiets && (
        <p>
          {t(
            '$*components.addressForm.cannotEditAddressWithOtherDiet',
            '$$Nie możesz edytować niektórych pól adresu, na którym masz aktywną inną dietę.'
          )}
        </p>
      )}

      <div className="row">
        <input autoComplete="off" name="hidden" type="search" tw="hidden" />

        {fields.map(
          ({
            colClass = 'col-12',
            colCss = tw`order-none`,
            id,
            ...restProps
          }) => {
            let additionalProps = {};
            let newColClass =
              !hasDeliveryHours &&
              [INPUT_IDS.ADDRESS_LINE2, INPUT_IDS.INTERCOM_CODE].includes(id)
                ? 'col-12'
                : colClass;
            if (id === INPUT_IDS.DELIVERY_HOURS) {
              additionalProps = {
                ...(hasDeliveryHours ? { options: hours } : {}),
                deliveryHoursIsLoading,
              };

              return (
                hasDeliveryHours && (
                  <div className={newColClass} key={id} css={colCss}>
                    <InputsIndex id={id} {...restProps} {...additionalProps} />
                  </div>
                )
              );
            }

            if ([INPUT_IDS.CITY, INPUT_IDS.CITY_OPTION].includes(id)) {
              additionalProps = {
                ...(hasAllowedCities ? { options: allowedCities } : {}),
              };

              return (
                ((hasAllowedCities && id === INPUT_IDS.CITY_OPTION) ||
                  (!hasAllowedCities && id === INPUT_IDS.CITY)) && (
                  <div className={newColClass} key={id} css={colCss}>
                    <InputsIndex
                      id={id}
                      {...restProps}
                      {...additionalProps}
                      autoComplete="off"
                      disabled={!isCityFieldEnabled}
                    />
                  </div>
                )
              );
            }

            if ([INPUT_IDS.STREET, INPUT_IDS.STREET_OPTION].includes(id)) {
              additionalProps = {
                ...(hasAllowedStreets ? { options: allowedStreets } : {}),
              };

              return (
                ((hasAllowedStreets && id === INPUT_IDS.STREET_OPTION) ||
                  (!hasAllowedStreets && id === INPUT_IDS.STREET)) && (
                  <div className={newColClass} key={id} css={colCss}>
                    <InputsIndex
                      id={id}
                      {...restProps}
                      {...additionalProps}
                      autoComplete="off"
                      disabled={!isStreetFieldEnabled}
                    />
                  </div>
                )
              );
            }

            if (
              [INPUT_IDS.HOUSE_NUMBER, INPUT_IDS.HOUSE_NUMBER_OPTION].includes(
                id
              )
            ) {
              additionalProps = {
                ...(hasAllowedBuildingNumbers
                  ? { options: allowedBuildingNumbers }
                  : {}),
              };

              return (
                ((hasAllowedBuildingNumbers &&
                  id === INPUT_IDS.HOUSE_NUMBER_OPTION) ||
                  (!hasAllowedBuildingNumbers &&
                    id === INPUT_IDS.HOUSE_NUMBER)) && (
                  <div className={newColClass} key={id} css={colCss}>
                    <InputsIndex
                      id={id}
                      {...restProps}
                      {...additionalProps}
                      autoComplete="off"
                      disabled={!isBuildNumberFieldEnabled}
                    />
                  </div>
                )
              );
            }

            return (
              <div className={newColClass} key={id} css={colCss}>
                <InputsIndex id={id} {...restProps} {...additionalProps} />
              </div>
            );
          }
        )}
      </div>

      {errors?.apiErrorMessage && (
        <div tw="mt-4 bg-red-100 border border-red-700 text-red-700 px-4 py-2 rounded text-sm">
          {/* i18next-extract-disable-next-line */}
          {t(`$*error.api.${errors.apiErrorMessage}`)}
        </div>
      )}

      <div className="row" tw="mt-4 justify-between items-center">
        <div className="sm:col-auto">
          {!isDefaultAddress && !isFirstAddress && (
            <InputsIndex {...fieldSetAsDefault} />
          )}
        </div>
        <div className="sm:col-auto">
          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={
              isSubmitting || !dirty || !isEmpty(status?.apiFieldErrors)
            }
            fullWidth={!isUpMd}
            styles={{ button: tw`mt-5 sm:mt-0` }}
            data-cy="address-form__submit"
          >
            {isNewAddress
              ? t('$*components.addressForm.saveAddress', '$$Zapisz adres')
              : t('$*common.updateData', '$$Zaktualizuj dane')}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default AddressForm;
