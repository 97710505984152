import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getConfig = async () => {
  const response = await axios.get(ENDPOINT.CONFIG, {
    withToken: false,
  });

  return response?.data ?? {};
};

export const getApiTranslations = async ({ xLocale }) => {
  const response = await axios.get(ENDPOINT.TRANSLATIONS(), {
    withToken: false,
    xLocale,
  });

  return response?.data ?? {};
};

export default {
  getConfig,
  getApiTranslations,
};
