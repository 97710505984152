import { Trans, useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateUserData } from '@store/auth/auth.actions';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex, Modal } from '@components';
import { useMediaQuery } from '@hooks';
import { down } from '@utils/screens';
import showToast from '@utils/showToast';

import { initialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const MissingPhoneModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDown500 = useMediaQuery(down('500px'), true);

  const fields = useFields();
  const validationSchema = useValidationSchema();

  const hancleClickSubmit = (values, { setSubmitting, setStatus }) => {
    dispatch(updateUserData(values))
      .then(unwrapResult)
      .then(() => {
        onClose();
        showToast(
          t(
            '$*notification.updateUserPhone.success',
            '$$Numer został pomyślnie zaktualizowany.'
          ),
          { type: 'info' }
        );
      })
      .catch(error => {
        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styles={{ wrapper: tw`w-full max-w-lg` }}
    >
      <Modal.Header>
        {t('$*components.missingPhoneModal.title', '$$Uwaga')}
      </Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="$*components.missingPhoneModal.description"
          defaults="$$<0>Nie mamy jeszcze Twojego numeru telefonu, nie jest on niezbędny, ale na pewno bardzo ułatwi kontakt przy dostarczeniu Twojego zamówienia.</0>"
          components={[<p key="0" tw="mb-6" />]}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={hancleClickSubmit}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <div className="row">
                {fields.map(
                  ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
                    return (
                      <div className={colClass} key={id} css={colCss}>
                        <InputsIndex id={id} {...restProps} />
                      </div>
                    );
                  }
                )}
              </div>

              {status?.apiErrorMessage && (
                <Alert styles={{ css: tw`mt-4` }}>
                  {/* i18next-extract-disable-next-line */}
                  {t(`$*error.api.${status.apiErrorMessage}`)}
                </Alert>
              )}

              <div tw="pt-3 flex flex-wrap justify-between -mx-2 -my-1">
                <Button
                  type="button"
                  color="error"
                  variant="outlined"
                  styles={{
                    button: [
                      tw`mx-2 my-1`,
                      isDown500 && tw`justify-center flex-auto`,
                    ],
                  }}
                  onClick={onClose}
                >
                  {t('$*common.skip', '$$Pomiń')}
                </Button>
                <Button
                  type="submit"
                  color="success"
                  variant="outlined"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  styles={{
                    button: [
                      tw`mx-2 my-1`,
                      isDown500 && tw`justify-center flex-auto`,
                    ],
                  }}
                >
                  {t('$*common.save', '$$Zapisz')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

MissingPhoneModal.displayName = 'MissingPhoneModal';

export default MissingPhoneModal;
