import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInitialDiet } from '@store/basket/basketInitialData.slice';
import isEmpty from 'lodash/isEmpty';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import {
  useBasketMethods,
  useClientPanelConfig,
  useMenuDishes,
  useOrderForm,
} from '@hooks';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { format, formatArray } from '@services/Date.service';
import { useAppContext } from '@views/App/App.context';

import { useNewOrderCreatePageContext } from '../../NewOrderCreatePage.context';

const OrderFormDietsContext = createContext([{}, () => {}]);

const OrderFormDietsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [avaliableVariants, setAvaliableVariants] = useState([]);
  const [avaliableCalorifics, setAvaliableCalorifics] = useState([]);

  const { isDietMini } = useAppContext();
  const orderFormQuery = useOrderForm({ enabled: false });
  const {
    data: {
      days: { standardDays = {}, testDays = {} } = {},
      diets = [],
      firstPossibleOrderDay = new Date(),
      packages = [],
    } = {},
    isFetched: isOrderFormFetched,
    isError: isOrderFormError,
    isLoading: isOrderFormLoading,
    isFetching: isOrderFormFetching,
  } = orderFormQuery;

  const {
    basketQuery: { data: { id: basketId, testMode, rows = [] } = {} },
    basketStore,
    currentDiet,
    handleAddBasketDiet,
    handleUpdateBasketDiet,
    isMutatingBasket,
  } = useBasketMethods();

  const { selectDaysByRange } = useNewOrderCreatePageContext();
  const { filterShownMenuByDiet } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const menuDate = firstPossibleOrderDay;
  const [currentSelectedDate, setCurrentSelectedDate] = useState(menuDate);

  const { packageId, dietId, variantId, calorificId, paymentMode } =
    currentDiet;

  const currentMenuDishesQuery = useMenuDishes(
    {
      date: format(new Date(currentSelectedDate)),
      diet: filterShownMenuByDiet ? dietId : null,
    },
    { enabled: isOrderFormFetched && isDietMini }
  );

  const { defaultPaymentMode, transformNumberPaymentModeToBasketFormat } =
    useClientPanelConfig();

  const isEmptyBasketItems = isEmpty(rows);

  useEffect(() => {
    const onMountAction = async () => {
      if (basketId && !isMutatingBasket) {
        setCurrentSelectedDate(firstPossibleOrderDay);

        if (isEmptyBasketItems) {
          return await initializeConfigurator();
        }
      }
    };

    onMountAction();
  }, [basketId, isMutatingBasket, isEmptyBasketItems]);

  useEffect(() => {
    const initialDiet = getInitialDiet();

    dispatch(setInitialDiet(initialDiet));
  }, []);

  useEffect(() => {
    const variants = diets.find(diet => diet['@id'] === dietId)?.variants ?? [];

    setAvaliableVariants(variants);
  }, [dietId]);

  useEffect(() => {
    if (isEmpty(variantId)) {
      return setAvaliableCalorifics([]);
    }

    const variants = diets.find(diet => diet['@id'] === dietId)?.variants ?? [];

    if (variants?.length > 0) {
      const calorifics =
        variants.find(variant => variant['@id'] === variantId)?.calories ?? [];
      setAvaliableCalorifics(calorifics);
    }
  }, [variantId]);

  const getInitialDiet = () => {
    const getInitialDietByType = () => {
      if (!isEmpty(packages)) {
        const initialPackage = packages[0];
        const { activeDietsIds } = initialPackage;
        const packageDiets = diets.filter(diet =>
          activeDietsIds.includes(diet.id)
        );
        const initialDiet = packageDiets[0];

        return initialDiet;
      }

      if (!isEmpty(diets)) {
        const initialDiet = diets[0];

        return initialDiet;
      }
    };

    const initialDietByType = getInitialDietByType();
    const paymentMode =
      transformNumberPaymentModeToBasketFormat(defaultPaymentMode);
    const isSubscription =
      paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;
    const dietLength = isSubscription
      ? 1
      : (testMode ? testDays.default : standardDays?.default) ?? 5;
    const newDeliveryDates = formatArray(
      selectDaysByRange(new Date(firstPossibleOrderDay), dietLength)
    );

    const initialDiet = {
      package: !isEmpty(packages) ? packages?.[0]?.['@id'] ?? null : null,
      diet: initialDietByType?.['@id'] ?? null,
      variant: initialDietByType?.variants?.[0]?.['@id'] ?? null,
      calorific:
        initialDietByType?.variants?.[0]?.['calories']?.[0]?.['@id'] ?? null,
      paymentMode,
      dietLength,
      deliveryDates: isSubscription
        ? [format(firstPossibleOrderDay)]
        : newDeliveryDates,
      firstDeliveryDay: firstPossibleOrderDay,
      useEcoContainers: false,
      addons: [],
      optionChangeMenu: false,
      sundayInclude: true,
      saturdayInclude: true,
    };

    return initialDiet;
  };

  const initializeConfigurator = async () => {
    const initialDiet = getInitialDiet();

    return await handleAddBasketDiet(initialDiet);
  };

  const selectPackage = pack => {
    const { activeDietsIds = [] } = pack;
    const firstDietId = activeDietsIds[0] ?? 0;
    const firstDiet = diets.find(diet => diet.id === firstDietId);

    const hasDietVariants = !isEmpty(firstDiet?.variants);
    const hasDietCalorifics =
      hasDietVariants && !isEmpty(firstDiet?.variants[0]?.calories);
    const isEmptyItems = isEmpty(basketStore?.items?.dietElements);

    handleUpdateBasketDiet({
      package: pack['@id'],
      diet: firstDiet['@id'],
      variant: hasDietVariants ? firstDiet.variants[0]['@id'] : null,
      calorific: hasDietCalorifics
        ? firstDiet.variants[0]['calories'][0]['@id']
        : null,

      // add after clear basket
      ...(isEmptyItems
        ? {
            deliveryDates: [format(firstPossibleOrderDay)],
            firstDeliveryDay: firstPossibleOrderDay,
            dietLength: standardDays?.default ?? 5,
            paymentMode:
              transformNumberPaymentModeToBasketFormat(defaultPaymentMode),
          }
        : {}),
    });
  };

  const selectDiet = diet => {
    const hasDietVariants = !isEmpty(diet?.variants);
    const hasDietCalorifics =
      hasDietVariants && !isEmpty(diet?.variants[0]?.calories);
    const isEmptyItems = isEmpty(basketStore?.items?.dietElements);

    handleUpdateBasketDiet({
      diet: diet['@id'],
      variant: hasDietVariants ? diet.variants[0]['@id'] : null,
      calorific: hasDietCalorifics
        ? diet.variants[0]['calories'][0]['@id']
        : null,

      // add after clear basket
      ...(isEmptyItems
        ? {
            deliveryDates: [format(firstPossibleOrderDay)],
            firstDeliveryDay: firstPossibleOrderDay,
            dietLength: standardDays?.default ?? 5,
            paymentMode:
              transformNumberPaymentModeToBasketFormat(defaultPaymentMode),
          }
        : {}),
    }).then(() => {
      currentMenuDishesQuery.refetch();
    });
  };

  const selectVariant = variant => {
    const hasVariantCalorifics = !isEmpty(variant?.calories);

    handleUpdateBasketDiet({
      variant: variant['@id'],
      calorific: hasVariantCalorifics ? variant['calories'][0]['@id'] : null,
    });
  };

  const selectCalorific = calorific => {
    handleUpdateBasketDiet({
      calorific: calorific['@id'],
    });
  };

  const value = {
    diets,
    packages,
    menuDate,
    currentMenuDishesQuery,
    orderFormQuery,
    currentSelectedDate,
    setCurrentSelectedDate,

    selectedPackageId: packageId,
    selectedDietId: dietId,
    selectedVariantId: variantId,
    selectedCalorificId: calorificId,
    selectedPaymentMode: paymentMode,

    avaliableVariants: avaliableVariants,
    avaliableCalorifics: avaliableCalorifics,

    isDietConfigError: isOrderFormError,
    isDietConfigLoading: isOrderFormLoading,
    isDietConfigFetching: isOrderFormFetching,

    selectPackage,
    selectDiet,
    selectVariant,
    selectCalorific,
  };

  return (
    <OrderFormDietsContext.Provider value={value}>
      {children}
    </OrderFormDietsContext.Provider>
  );
};

const useOrderFormDietsContext = () => useContext(OrderFormDietsContext);

export { OrderFormDietsProvider, useOrderFormDietsContext };
