import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faChevronDown,
  faChevronUp,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  logout,
  selectIsAuthenticated,
  selectUser,
} from '@store/auth/auth.slice';
import tw from 'twin.macro';

import { Button, Link } from '@components';
import ROUTE_URLS from '@constants/routeUrls';
import { useOnClickOutside, useSwitch } from '@hooks';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

const AuthButtons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const ref = useRef();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { id: userId } = useSelector(selectUser);
  const { allowClientSeeMoneyBox, allowClientSeeOwnId } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const [isOpenMenu, setOpenMenu, setClosedMenu] = useSwitch(false);
  useOnClickOutside(ref, setClosedMenu);

  if (isAuthenticated) {
    return (
      <div tw="relative mx-2" ref={ref}>
        <div
          tw="cursor-pointer flex items-center"
          onClick={isOpenMenu ? setClosedMenu : setOpenMenu}
          data-cy="my-account-dropdown"
        >
          <FontAwesomeIcon icon={faUserCircle} />
          <span tw="ml-3 mr-2">
            {t('$*components.header.myAccount', '$$Moje konto')}
          </span>
          <FontAwesomeIcon
            icon={isOpenMenu ? faChevronUp : faChevronDown}
            tw="text-xs"
          />
        </div>
        {isOpenMenu && (
          <div tw="absolute px-3 py-4 bg-white shadow whitespace-nowrap right-0 top-14">
            {allowClientSeeOwnId && (
              <div tw="border-b border-gray-200 mb-3 pb-2">
                <span tw="font-bold">
                  {t('$*components.header.myAccount.id', '$$ID')}:
                </span>{' '}
                {userId}
              </div>
            )}
            {allowClientSeeMoneyBox && (
              <div onClick={setClosedMenu}>
                <Link
                  href={ROUTE_URLS.MONEY_BOX}
                  styles={
                    router.asPath === ROUTE_URLS.MONEY_BOX
                      ? tw`font-semibold`
                      : ''
                  }
                >
                  {t(
                    '$*components.header.myAccount.conditionMoneyBox',
                    '$$Stan skarbonki'
                  )}
                </Link>
              </div>
            )}
            <div onClick={setClosedMenu}>
              <Link
                href={ROUTE_URLS.ACCOUNT_SETTINGS}
                styles={
                  router.asPath === ROUTE_URLS.ACCOUNT_SETTINGS
                    ? tw`font-semibold`
                    : ''
                }
              >
                {t(
                  '$*components.header.myAccount.settings',
                  '$$Ustawienia konta'
                )}
              </Link>
            </div>
            <div tw="border-t border-gray-200 mt-3 pt-2">
              <Button
                variant="link"
                onClick={() => {
                  dispatch(logout());
                  setClosedMenu();
                }}
              >
                {t('$*components.header.button.logout', '$$Wyloguj się')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div tw="flex items-center">
        <Link href={ROUTE_URLS.AUTH_LOGIN} styles={tw`mx-3`}>
          {t('$*components.header.button.login', '$$Zaloguj się')}
        </Link>
        <Link
          href={ROUTE_URLS.AUTH_REGISTER}
          styles={tw`mx-3`}
          type="button"
          variant="contained"
        >
          {t('$*components.header.button.register', '$$Zarejestruj się')}
        </Link>
      </div>
    );
  }
};

export default AuthButtons;
