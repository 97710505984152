import tw from 'twin.macro';

import { Button, Checkbox, InputText } from '@components';

const CheckboxCounter = ({
  count = 0,
  onChange = () => {},
  min = null,
  max = null,
}) => {
  const handleChangeValue = event => {
    const {
      target: { value },
    } = event;

    if (
      (min === null || (min !== null && value >= min)) &&
      (max === null || (max !== null && value <= max)) &&
      value !== ''
    ) {
      onChange(event, parseInt(value));
    }
  };

  const handleClickIncrement = event => {
    const nextQuantity = count + 1;

    if (max === null || (max !== null && nextQuantity <= max)) {
      onChange(event, nextQuantity);
    }
  };

  const handleClickDecrement = event => {
    const nextQuantity = count - 1;

    if (min === null || (min !== null && nextQuantity >= min)) {
      onChange(event, nextQuantity);
    }
  };

  const handleClickCheckbox = event => onChange(event, 1);

  if (count === 0) {
    return (
      <Checkbox
        onClick={handleClickCheckbox}
        styles={{
          input: {
            css: {
              ...tw`w-6 h-6 hover:(bg-primary cursor-pointer ring-primary)`,
              // eslint-disable-next-line quotes
              backgroundImage: `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")`,
            },
          },
        }}
        readOnly={true}
        data-cy="counter__checkbox"
      />
    );
  }

  return (
    <div tw="flex items-center">
      <Button
        type="button"
        variant="outlined"
        onClick={handleClickDecrement}
        disabled={!(min === null || (min !== null && count - 1 >= min))}
        styles={{
          button: tw`justify-center w-6 h-6 p-0`,
        }}
        data-cy="counter__button--minus"
      >
        -
      </Button>
      <InputText
        type="text"
        value={count}
        onChange={handleChangeValue}
        onClick={e => {
          e.stopPropagation();
        }}
        step="1"
        styles={{
          css: tw`text-center mx-1 w-6 p-0 text-sm border-none outline-none bg-transparent focus-visible:(shadow-none border-transparent ring-transparent)`,
          mode: 'replace',
        }}
        data-cy="counter__input"
      />
      <Button
        type="button"
        variant="outlined"
        onClick={handleClickIncrement}
        disabled={!(min === null || (min !== null && count + 1 <= max))}
        styles={{ button: tw`justify-center w-6 h-6 p-0` }}
        data-cy="counter__button--plus"
      >
        +
      </Button>
    </div>
  );
};

CheckboxCounter.displayName = 'CheckboxCounter';

export default CheckboxCounter;
