const InvoiceIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 -18 456.212 456"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M415.008 1.59a9.95 9.95 0 00-5.223-1.485H111.996c-25.601 0-46.43 20.625-46.43 45.903l-1.75 256.441H10c-5.523 0-10 4.477-10 10v46.2c.035 33.925 27.527 61.417 61.453 61.456.688 0 1.379-.07 2.055-.21h248.457c1.48.105 2.973.175 4.476.175 34.454-.062 62.352-28 62.364-62.453V207.711h67.406c5.523 0 10-4.477 10-10V49.266c-.121-23.871-17.602-44.102-41.203-47.676zM20 358.652v-36.199h234.078v35.168a62.242 62.242 0 0016.512 42.274H60.086c-.473.003-.941.039-1.406.109-21.754-1.48-38.653-19.547-38.68-41.352zM358.805 49.266v308.351a42.284 42.284 0 01-12.356 29.801 41.871 41.871 0 01-28.648 12.625 9.958 9.958 0 00-1.637-.145h-3.516c-21.78-2.144-38.425-20.386-38.57-42.273v-45.176c0-5.52-4.476-10-10-10H83.816l1.75-256.37c.145-14.462 11.965-26.083 26.43-25.974h256.742a47.735 47.735 0 00-9.933 29.16zM436.21 187.71h-57.406V49.266c.261-15.668 13.035-28.227 28.703-28.227s28.441 12.559 28.703 28.227zm0 0" />
    <path d="M128.387 108.441h94.71c5.524 0 10-4.476 10-10 0-5.523-4.476-10-10-10h-94.71c-5.524 0-10 4.477-10 10 0 5.524 4.476 10 10 10zm0 0M305.059 159.941H128.387c-5.524 0-10 4.477-10 10 0 5.52 4.476 10 10 10h176.672c5.523 0 10-4.48 10-10 0-5.523-4.477-10-10-10zm0 0M305.059 231.438H128.387c-5.524 0-10 4.48-10 10 0 5.523 4.476 10 10 10h176.672c5.523 0 10-4.477 10-10 0-5.52-4.477-10-10-10zm0 0" />
  </svg>
);

export default InvoiceIcon;
