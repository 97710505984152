import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getOrderForm = async () => {
  const response = await axios.get(ENDPOINT.ORDER_FORM);

  return { ...response?.data, banner: { ...response?.data?.banner } } ?? {};
};

const useOrderForm = (options = {}) => {
  return useQuery('orderForm', getOrderForm, options);
};

export default useOrderForm;
