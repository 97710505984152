const PencilIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61 66"
  >
    {title && <title>{title}</title>}
    <path d="M2.91 65.83h55.16c1.62 0 2.92-1.3 2.92-2.91S59.69 60 58.07 60H2.91C1.3 60 0 61.31 0 62.92c0 1.61 1.3 2.91 2.91 2.91zm4.22-31.51l-.03.03c-.31.32-.53.69-.66 1.08L.17 52.85c-.38 1.05-.12 2.24.68 3.04.79.8 1.97 1.07 3.04.69l17.48-6.2c.4-.13.77-.35 1.09-.66l.03-.03 30.16-29.94.04-.04c5.8-5.99 4.74-12.7 1.07-16.48-3.68-3.8-10.35-5.02-16.4.65-.03.02-.05.05-.08.07L7.13 34.32zm.61 14.71l7.37-2.62-4.73-4.72-2.64 7.34zm38.83-31.47l-2.68 2.67c-3.4 3.37-6.81 6.75-10.21 10.13L20.44 43.5l-7.13-7.13 25.94-26.12 7.32 7.31zm3.74-4.51l-6.56-6.56c2.27-1.04 4.45-.62 5.82.8 1.35 1.39 1.74 3.53.74 5.76z" />
  </svg>
);

export default PencilIcon;
