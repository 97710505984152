export const resetTagsReducer = state => {
  state.selectedTags = [];
};

export const toggleTagReducer = (state, { payload: tagIri }) => {
  const tagIndex = state.selectedTags.findIndex(tag => tag === tagIri);

  if (tagIndex !== -1) {
    state.selectedTags.splice(tagIndex, 1);
  } else {
    state.selectedTags.push(tagIri);
  }
};

export const setDiet = (state, { payload: dietId }) => {
  state.diet = dietId;
};

export const setVariant = (state, { payload: variantId }) => {
  state.dietVaraint = variantId;
};
