import { PurchasedProductTileProvider } from './PurchasedProductTile.context';
import PurchasedProductTileView from './PurchasedProductTile.view';

const PurchasedProductTile = ({
  optionsPopoverScope,
  selectedDietIri,
  selectedDate,
  dishSizesQuantity,
  isSelected,
  onChangeQuantity,
  ...props
}) => {
  const providerProps = {
    optionsPopoverScope,
    selectedDietIri,
    selectedDate,
    dishSizesQuantity,
    isSelected,
    onChangeQuantity,
    product: props,
  };

  return (
    <PurchasedProductTileProvider {...providerProps}>
      <PurchasedProductTileView />
    </PurchasedProductTileProvider>
  );
};

export default PurchasedProductTile;
