import { useTranslation } from 'next-i18next';

import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/invoiceInputs';

export const initialValues = {
  [INPUT_IDS.COMPANY_NAME]: '',
  [INPUT_IDS.NIP]: '',
  [INPUT_IDS.ADDRESS_POST_CODE]: '',
  [INPUT_IDS.ADDRESS_CITY]: '',
  [INPUT_IDS.ADDRESS_STREET]: '',
  [INPUT_IDS.ADDRESS_BUILD_NUMBER]: '',
  [INPUT_IDS.ADDRESS_PLACE_NUMBER]: '',
  [INPUT_IDS.ADDITIONAL_NOTES]: '',
  [INPUT_IDS.TYPE]: false, // true => DefaultCompany || false => Other
};

export const useValidationSchema = integrationType => {
  const { t } = useTranslation();

  switch (integrationType) {
    case 'RECEIPT':
      return Yup.object({
        [INPUT_IDS.NIP]: Yup.string().required(t('$*error.field.required')),
      });
    case 'INVOICE':
      return Yup.object({
        [INPUT_IDS.COMPANY_NAME]: Yup.string().required(
          t('$*error.field.required')
        ),
        [INPUT_IDS.NIP]: Yup.string().required(t('$*error.field.required')),
      });
    default:
      return null;
  }
};
