import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { css } from '@emotion/react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button } from '@components';
import ROUTE_URLS from '@constants/routeUrls';
import { useBasketMethods, useClientPanelConfig, useOrderForm } from '@hooks';
import { format } from '@services/Date.service';

const DietConfigurator = ({ avaliableDate, dietTypes = [] }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { handleUpdateBasketDiet } = useBasketMethods();
  const { defaultPaymentMode, transformNumberPaymentModeToBasketFormat } =
    useClientPanelConfig();
  const { data: { days: { standardDays = {} } = {} } = {} } = useOrderForm();

  const handleDietSelect = async dietType => {
    try {
      const variant = !isEmpty(dietType?.variants)
        ? dietType?.variants[0]
        : null;
      const calorific = !isEmpty(variant?.calories)
        ? variant?.calories[0]
        : null;

      await handleUpdateBasketDiet({
        diet: dietType?.id,
        variant: variant?.['@id'],
        calorific: calorific?.['@id'],
        dietLength: standardDays?.default ?? 5,
        paymentMode:
          transformNumberPaymentModeToBasketFormat(defaultPaymentMode),
        deliveryDates: [format(avaliableDate)],
        firstDeliveryDay: format(avaliableDate),
      });
      router.push(ROUTE_URLS.NEW_ORDER_FORM);
    } catch (error) {
      //
    }
  };

  return (
    <div>
      {!isEmpty(dietTypes) &&
        dietTypes.map(dietType => {
          return (
            <Button
              key={dietType?.id}
              type="button"
              color="primary"
              fullWidth={true}
              tooltip={
                !isEmpty(dietType?.variants)
                  ? t('$*dishPage.avaliableInVariants', {
                      defaultValue: '$$Występuje w wariantach: {{variants}}',
                      replace: {
                        variants: dietType?.variants
                          .map(({ name }) => name)
                          .join(', '),
                      },
                    })
                  : null
              }
              variant="outlined"
              styles={{
                button: css`
                  ${tw`font-normal hover:(bg-primary-hover border-primary-hover) my-4`}
                `,
              }}
              onClick={() => handleDietSelect(dietType)}
            >
              {dietType?.name}
            </Button>
          );
        })}
    </div>
  );
};

export default DietConfigurator;
