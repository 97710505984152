const CartIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M5.1,12.6a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,5.1,12.6ZM1.5,3V4.2H2.7L4.86,8.754l-.81,1.47a1.16,1.16,0,0,0-.15.576A1.2,1.2,0,0,0,5.1,12h7.2V10.8H5.352a.149.149,0,0,1-.15-.15l.018-.072L5.76,9.6h4.47a1.194,1.194,0,0,0,1.05-.618l2.148-3.894A.586.586,0,0,0,13.5,4.8a.6.6,0,0,0-.6-.6H4.026L3.462,3Zm9.6,9.6a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,11.1,12.6Z"
      transform="translate(-1.5 -3)"
    />
  </svg>
);

export default CartIcon;
