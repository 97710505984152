import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getAppConfig = async () => {
  const response = await axios.get(ENDPOINT.CONFIG, {
    withToken: false,
  });

  return response?.data ?? {};
};

const useAppConfig = (options = {}) =>
  useQuery('appConfig', getAppConfig, options);

export const useAppConfigSelector = selector => {
  const { data } = useAppConfig({ enabled: false });

  return selector(data);
};

const initialState = {
  additionalPrices: {
    CHANGE_MENU: {},
    ECO_CONTAINER: {},
  },
  authMethods: {},
  branding: {
    name: 'Panel',
    favicon: null,
    backgroundColor: '#F7F7F7',
    backgroundImage: null,
    primaryColor: '#03388C',
    secondaryColor: '#B58A78',
  },
  dictionaries: {},
  modules: {
    Addons: {},
    AppMode: {},
    BlueMedia: {},
    CodeReferences: {},
    ConfigClientPanel: { referralProgramDescription: '' },
    FacebookPixel: {},
    Fakturownia: { allowInvoicesForClient: false },
    Fonts: {
      clientTitle: {
        libraryParams: [],
        font: 'Nunito Sans',
        library: 'google',
      },
      clientText: { libraryParams: [], font: 'Nunito Sans', library: 'google' },
    },
    GoogleAnalytics: {},
    GoogleTagManager: {},
    HotJar: {},
    Hover: {},
    Links: {},
    MenuPage: {},
    MoneyBox: {},
    PayU: {},
    Restaurant: {},
    SalesManago: {},
    Seo: {},
    Shop: {},
    Stripe: {},
    SeoDish: {},
    ClickOnMetrics: {},
    PollsterTrack: {},
  },
  multinational: {
    defaultLanguage: 'PL',
    clientAllowedToChangeLanguage: true,
    currencySymbol: '',
  },
  pages: [],
  panelOptions: {
    seo: { metaTitle: 'Panel' },
    showReferrerCode: false,
  },
};

export const selectConfig = data => Object.assign(initialState, data);

export const selectAdditionalPrices = data =>
  Object.assign(initialState.additionalPrices, data?.additionalPrices);

export const selectAuthMethods = data =>
  Object.assign(initialState.authMethods, data?.authMethods);

export const selectBranding = data =>
  Object.assign(initialState.branding, data?.branding);

export const selectDictionaries = data =>
  Object.assign(initialState.dictionaries, data?.dictionaries);

export const selectModules = data =>
  Object.assign(initialState.modules, data?.modules);

export const selectModuleAddons = data =>
  Object.assign(initialState.modules.Addons, data?.modules?.Addons);

export const selectModuleBlueMedia = data =>
  Object.assign(initialState.modules.BlueMedia, data?.modules?.BlueMedia);

export const selectModuleCodeReferences = data =>
  Object.assign(
    initialState.modules.CodeReferences,
    data?.modules?.CodeReferences
  );

export const selectModuleConfigClientPanel = data =>
  Object.assign(
    initialState.modules.ConfigClientPanel,
    data?.modules?.ConfigClientPanel
  );

export const selectModuleFacebookPixel = data =>
  Object.assign(
    initialState.modules.FacebookPixel,
    data?.modules?.FacebookPixel
  );

export const selectModuleFakturownia = data =>
  Object.assign(initialState.modules.Fakturownia, data?.modules?.Fakturownia);

export const selectModuleFonts = data =>
  Object.assign(initialState.modules.Fonts, data?.modules?.Fonts);

export const selectModuleGoogleAnalytics = data =>
  Object.assign(
    initialState.modules.GoogleAnalytics,
    data?.modules?.GoogleAnalytics
  );

export const selectModuleGoogleTagManager = data =>
  Object.assign(
    initialState.modules.GoogleTagManager,
    data?.modules?.GoogleTagManager
  );

export const selectModuleHotJar = data =>
  Object.assign(initialState.modules.HotJar, data?.modules?.HotJar);

export const selectModuleHover = data =>
  Object.assign(initialState.modules.Hover, data?.modules?.Hover);

export const selectModuleLinks = data =>
  Object.assign(initialState.modules.Links, data?.modules?.Links);

export const selectModuleMenuPage = data =>
  Object.assign(initialState.modules.MenuPage, data?.modules?.MenuPage);

export const selectModuleMoneyBox = data =>
  Object.assign(initialState.modules.MoneyBox, data?.modules?.MoneyBox);

export const selectModuleRestaurant = data =>
  Object.assign(initialState.modules.Restaurant, data?.modules?.Restaurant);

export const selectModuleSalesManago = data =>
  Object.assign(initialState.modules.SalesManago, data?.modules?.SalesManago);

export const selectModuleSeoDish = data =>
  Object.assign(initialState.modules.SeoDish, data?.modules?.SeoDish);

export const selectModuleSeoDiet = data =>
  Object.assign(initialState.modules.SeoDiet, data?.modules?.SeoDiet);

export const selectModuleSeoAddon = data =>
  Object.assign(initialState.modules.SeoAddon, data?.modules?.SeoAddon);

export const selectModuleSeoZone = data =>
  Object.assign(initialState.modules.SeoZone, data?.modules?.SeoZone);

export const selectModuleSeo = () => {};
// Object.assign(initialState.modules.Seo, data?.modules?.Seo);

export const selectModuleShop = data =>
  Object.assign(initialState.modules.Shop, data?.modules?.Shop);

export const selectModuleStripe = data =>
  Object.assign(initialState.modules.Stripe, data?.modules?.Stripe);

export const selectModulePayU = data =>
  Object.assign(initialState.modules.PayU, data?.modules?.PayU);

export const selectModuleAppMode = data =>
  Object.assign(initialState.modules.AppMode, data?.modules?.AppMode);

export const selectModuleMyLead = data =>
  Object.assign(initialState.modules.MyLead, data?.modules?.MyLead);

export const selectModuleClickOnMetrics = data =>
  Object.assign(
    initialState.modules.ClickOnMetrics,
    data?.modules?.ClickOnMetrics
  );

export const selectModulePollsterTrack = data =>
  Object.assign(
    initialState.modules.PollsterTrack ?? {},
    data?.modules?.PollsterTrack ?? {}
  );

export const selectMultinational = data =>
  Object.assign(initialState.multinational, data?.multinational);

export const selectPanelOptions = data =>
  Object.assign(initialState.panelOptions, data?.panelOptions);

export const selectPages = data =>
  Object.assign(initialState.pages, data?.pages);

export const selectFooterPages = data =>
  selectPages(data).filter(({ inFooter }) => inFooter) ?? [];

export const selectDisabledDays = data => Object.assign([], data?.disabledDays);

export const selectDisabledDaysOfWeek = data => {
  const days = [
    { name: 'monday', isoWeek: 1 },
    { name: 'tuesday', isoWeek: 2 },
    { name: 'wednesday', isoWeek: 3 },
    { name: 'thursday', isoWeek: 4 },
    { name: 'friday', isoWeek: 5 },
    { name: 'saturday', isoWeek: 6 },
    { name: 'sunday', isoWeek: 7 },
  ];
  const disabledDaysOfWeek = [];

  days.map(day => {
    if (data?.weekDays?.[day.name]?.shouldBeDeliveredIn === 0) {
      disabledDaysOfWeek.push(day.isoWeek);
    }
  });

  return disabledDaysOfWeek;
};

export default useAppConfig;
