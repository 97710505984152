import tw from 'twin.macro';

import { LoadingTimeout, Spinner } from '@components';
import { cssMerge } from '@utils/styleHelpers';

const Loading = ({
  isLoading = false,
  withTimeoutInfo = false,
  showLoadingText = true,
  justify = 'center',
  styles = {},
}) => {
  if (!isLoading) {
    return null;
  }

  const justifyStyle = {
    left: { timeout: tw`text-left`, spinner: tw`justify-start` },
    center: { timeout: tw`text-center`, spinner: tw`justify-center` },
    right: { timeout: tw`text-right`, spinner: tw`justify-end` },
  }[justify];

  const twStyle = {
    timeout: {
      css: cssMerge({
        defaultCss: justifyStyle.timeout,
        ...styles?.timeout,
      }),
    },
    spinner: {
      wrapper: {
        css: cssMerge({
          defaultCss: justifyStyle.spinner,
          ...styles?.spinner?.wrapper,
        }),
      },
      icon: styles?.spinner?.icon,
    },
  };

  return (
    <div css={styles?.wrapper}>
      {withTimeoutInfo && <LoadingTimeout styles={twStyle.timeout} />}
      <Spinner showLoadingText={showLoadingText} styles={twStyle.spinner} />
    </div>
  );
};

Loading.displayName = 'Loading';

export default Loading;
