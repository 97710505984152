import { css } from '@emotion/react';
import tw from 'twin.macro';

const DishWrapper = ({ children, onClick = () => {} }) => {
  return (
    <div
      css={[
        tw`rounded-lg relative border-2 transition-colors duration-300 cursor-pointer hover:(border-primary duration-300) `,
        css`
          width: 100%;
          min-height: 340px;
          min-width: 250px;
        `,
      ]}
      onClick={onClick}
      data-cy="dish-tile__wrapper"
    >
      {children}
    </div>
  );
};

export default DishWrapper;
