import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, withStripeConsumer } from '@components';
import PAYMENT_TYPES from '@constants/paymentTypes';
import ROUTE_URLS from '@constants/routeUrls';
import {
  useBasketMethods,
  useCreateBasketOrder,
  usePaymentCards,
} from '@hooks';
import {
  selectModuleMyLead,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import BasketService from '@services/Basket.service';
import { getIntegerIfIntegerOrFixed, isExternalUrl } from '@utils/helpers';
import { getMyLeadTrackingParams } from '@utils/myLead';
import showToast from '@utils/showToast';
import { cssMerge } from '@utils/styleHelpers';

const BasketPay = ({ styles = {}, stripe }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { enabled: isMyLeadEnabled } = useAppConfigSelector(selectModuleMyLead);
  const { data: cards = [] } = usePaymentCards();

  const {
    basketQuery: { data: basket = {} },
    basketStore: { invoice, useInvoice },
    basketCommonCatch,
    setPayStatus,
  } = useBasketMethods();

  const paymentType = basket?.paymentType ?? null;
  const paymentTypes = basket?.paymentTypes ?? [];

  const isSelectedProviderCardAvaliable = [
    PAYMENT_TYPES.PAYU_CARD,
    PAYMENT_TYPES.STRIPE_CARD,
  ].includes(paymentType)
    ? !isEmpty(cards?.find(({ provider }) => provider === paymentType))
    : true;

  const {
    isLoading: isLoadingCreateBasketOrder,
    mutateAsync: createBasketOrder,
  } = useCreateBasketOrder();

  const handlePay = () => {
    let payload = {};
    const convertedInvoice = BasketService.convertInvoice(invoice);
    const { protocol, hostname, port } = window.location;
    const host = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const returnUrl = `${host}/${i18n.language}/new-order/{{id}}/payment-status?type=${paymentType}`;

    const { clickId, userId } = getMyLeadTrackingParams();

    //If MyLead modules is enabled we should add tracking params to payload
    if (isMyLeadEnabled && !!clickId && !!userId) {
      payload = {
        ...payload,
        tracking: { mylead: { userId, clickId } },
      };
    }

    createBasketOrder({
      id: basket.id,
      payload: {
        ...payload,
        invoice: { include: useInvoice, ...convertedInvoice },
        returnUrl,
      },
    })
      .then(async response => {
        if (response?.declineCode === 'authentication_required') {
          try {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              response.clientSecret,
              {
                payment_method: response.lastPaymentMethodId,
              }
            );

            if (error) {
              showToast(
                /* i18next-extract-disable-next-line */
                t(`$*error.stripe.${error.code}`, `$$${error.message}`),
                {
                  type: 'error',
                }
              );
              return router.push(ROUTE_URLS.NEW_ORDER_ERROR);
            } else {
              if (paymentIntent.status === 'succeeded') {
                showToast(
                  `${t(
                    '$*success.stripe.payment_intent_authentication_success',
                    '$$Uwierzytelnianie Twojej metody płatności przebiegło pomyślnie!'
                  )}
                  ${t(
                    '$*success.stripe.withdrawFunds',
                    '$$Środki zostały pobrane z twojego konta!'
                  )}`,
                  {
                    type: 'success',
                  }
                );
                return router.push(
                  ROUTE_URLS.NEW_ORDER_PAYMENT_STATUS(
                    response?.order?.orderId ?? 0
                  )
                );
              }
            }
          } catch (error) {
            //
          }
        }

        if (
          [
            PAYMENT_TYPES.CASH,
            PAYMENT_TYPES.BANK_WIRE,
            PAYMENT_TYPES.STRIPE_CARD,
          ].includes(response?.paymentType) ||
          response?.status?.systemValue === 'PAID'
        ) {
          dispatch(
            setPayStatus({ isPayActionSuccess: true, isExternalPayment: false })
          );

          return router.push(
            ROUTE_URLS.NEW_ORDER_PAYMENT_STATUS(response?.orderId ?? 0)
          );
        }

        showToast(
          t(
            '$*notification.paymentRedirect.info',
            '$$Za chwilę zostaniesz przekierowany na stronę płatności'
          ),
          { type: 'info' }
        );

        const redirectUrl =
          response?.paymentLink ??
          ROUTE_URLS.NEW_ORDER_PAYMENT_STATUS(response?.orderId ?? 0);

        dispatch(
          setPayStatus({
            isPayActionSuccess: true,
            isExternalPayment: isExternalUrl(redirectUrl),
          })
        );

        return router.push(redirectUrl);
      })
      .catch(async error => {
        await dispatch(
          setPayStatus({
            isPayActionSuccess: false,
            isExternalPayment: false,
          })
        );

        basketCommonCatch(error?.response?.data);
      });
  };

  const twButtonStyle = cssMerge({
    defaultCss: tw`mt-12`,
    ...styles,
  });

  return (
    <Button
      onClick={handlePay}
      isLoading={isLoadingCreateBasketOrder}
      disabled={
        isEmpty(paymentType) ||
        isEmpty(paymentTypes) ||
        !isSelectedProviderCardAvaliable ||
        !Object.values(paymentTypes).includes(paymentType)
      }
      fullWidth={true}
      styles={{ button: twButtonStyle }}
      data-cy="order-pay"
    >
      {t('$*newOrderCreatePage.orderForm.summary.pay', {
        defaultValue: '$$Zapłać ({{price}} {{currencySymbol}})',
        replace: {
          price: getIntegerIfIntegerOrFixed(basket?.price?.afterDiscount ?? 0),
          currencySymbol,
        },
      })}
    </Button>
  );
};

export default withStripeConsumer(BasketPay);
