import Head from 'next/head';

import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  selectBranding,
  selectModuleSeo,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getImageUrlFromResources } from '@services/Api.service';

const Seo = ({ pageName = '', seoProps = {} }) => {
  const { name, favicon } = useAppConfigSelector(selectBranding);
  const seo = !isEmpty(seoProps)
    ? seoProps
    : useAppConfigSelector(selectModuleSeo);

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${pageName} - ${seo?.meta?.title || name}`}</title>
      {/* Google / Search Engine Tags */}
      {!seo?.indexing && (
        <meta name="robots" content="noindex, nofollow, nosnippet" />
      )}
      {seo?.meta?.title && <meta itemProp="name" content={seo.meta.title} />}
      {seo?.og?.image && (
        <Fragment>
          <meta
            itemProp="image"
            content={getImageUrlFromResources(seo.og.image, seo['@resources'])}
          />
          <link
            rel="image_src"
            href={getImageUrlFromResources(seo.og.image, seo['@resources'])}
          />
        </Fragment>
      )}
      {seo?.meta?.description && (
        <meta name="description" content={seo.meta.description} />
      )}
      {seo?.meta?.description && (
        <meta itemProp="description" content={seo.meta.description} />
      )}
      {/* {seo?.metaKeywords && (
        <meta name="keywords" content={seo.metaKeywords.toString()} />
      )} */}

      {/* Facebook Meta Tags */}
      <meta
        property="og:url"
        content={`${window.location.protocol}//${window.location.hostname}/`}
      />
      <meta property="og:type" content="website" />
      {seo?.og?.title && <meta property="og:title" content={seo.og.title} />}
      {seo?.og?.description && (
        <meta property="og:description" content={seo.og.description} />
      )}
      {seo?.og?.image && (
        <meta
          property="og:image"
          content={getImageUrlFromResources(seo.og.image, seo['@resources'])}
        />
      )}

      {/* Twitter Meta Tags */}
      <meta
        property="twitter:url"
        content={`${window.location.protocol}//${window.location.hostname}/`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      {seo?.og?.title && <meta name="twitter:title" content={seo.og.title} />}
      {seo?.og?.description && (
        <meta name="twitter:description" content={seo.og.description} />
      )}
      {seo?.og?.image && (
        <meta
          name="twitter:image"
          content={getImageUrlFromResources(seo.og.image, seo['@resources'])}
        />
      )}

      {favicon && <link rel="icon" type="image/png" href={favicon} />}
    </Head>
  );
};

Seo.displayName = 'Seo';

export default Seo;
