import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getBagItems = async ({ id, date }) => {
  const response = await axios.get(ENDPOINT.BAG_ITEMS({ id, date }));
  const responseData = response?.data;

  return {
    addonElements: responseData?.addonElements?.['hydra:member'] ?? [],
    dietElements: responseData?.dietElements?.['hydra:member'] ?? [],
    shopElements: responseData?.shopElements?.['hydra:member'] ?? [],
    subscriptionElements:
      responseData?.subscriptionElements?.['hydra:member'] ?? [],
  };
};

const useUserBagItems = ({ id, date }, options = {}) => {
  return useQuery(['bagItems', { id, date }], () => getBagItems({ id, date }), {
    ...options,
  });
};

export default useUserBagItems;
