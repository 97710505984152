import { useMemo } from 'react';
import Masonry from 'react-masonry-css';

import {
  AddonTileDesktop,
  AddonTileMobile,
  DisplayDownMd,
  DisplayUpMd,
} from '@components';
import { sortByPosition } from '@utils/helpers';
import { screensInt } from '@utils/screens';

const AddonsList = ({
  basketItemsDeliveryModes,
  basketItemsQuantity,
  checkIsAddonSelected = () => {},
  deliveryModesEnabled,
  dishDate,
  items = [],
  onChangeQuantity = () => {},
  selectedDietIri,
}) => {
  const addons = useMemo(
    () => items.reduce((acc, { addons }) => [...acc, ...addons], []),
    [items]
  );

  const sortedAddons = useMemo(() => sortByPosition(addons, 'DESC'), [addons]);

  return (
    <Masonry
      breakpointCols={{
        default: 3,
        [screensInt.xxl]: 2,
        [screensInt.xl]: 3,
        [screensInt.lg]: 2,
        [screensInt.sm]: 1,
      }}
      className="masonry-grid"
      columnClassName="masonry-grid-column"
    >
      {sortedAddons.map(addon => {
        const props = {
          ...addon,
          isSelected: checkIsAddonSelected({
            addon,
            dietIri: selectedDietIri,
            selectedDate: dishDate,
          }),
          onChangeQuantity: onChangeQuantity,
          dishDate: dishDate,
          selectedDietIri,
          deliveryModesEnabled,
          basketItemsQuantity: basketItemsQuantity,
          basketItemsDeliveryModes,
        };

        return (
          <div key={addon['@id']}>
            <DisplayUpMd>
              <AddonTileDesktop {...props} />
            </DisplayUpMd>
            <DisplayDownMd>
              <AddonTileMobile {...props} />
            </DisplayDownMd>
          </div>
        );
      })}
    </Masonry>
  );
};

export default AddonsList;
