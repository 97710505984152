import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getDietPage = async dietPageId => {
  const response = await axios.get(ENDPOINT.DIET_PAGE(dietPageId), {
    withToken: false,
  });

  return response?.data ?? {};
};

const useDietPage = (dietPageID, options = {}) =>
  useQuery(['dietPage', dietPageID], () => getDietPage(dietPageID), options);

export default useDietPage;
