import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getPickupPoints = async () => {
  const response = await axios.get(ENDPOINT.DELIVERY_PICKUP_POINTS, null, true);

  return response?.data?.['hydra:member'] ?? [];
};

const usePickupPoints = (options = {}) => {
  return useQuery('pickupPoints', getPickupPoints, {
    ...options,
  });
};

export default usePickupPoints;
