import { format as fnsFormat, isValid } from 'date-fns';
import { de, enUS, fi, pl } from 'date-fns/locale';

const locales = { pl, enUS, fi, de };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const format = (
  date = '',
  formatStr = 'yyyy-MM-dd',
  defaultDate = null
) => {
  const locale =
    typeof window !== 'undefined'
      ? locales[window.__localeId__]
      : locales[global.__localeId__]; // Check browser, server

  const dateObject = new Date(date);

  if (isValid(dateObject)) {
    return fnsFormat(dateObject, formatStr, {
      locale,
    });
  }

  return defaultDate;
};

export const formatArray = (array, formatStr = 'yyyy-MM-dd') =>
  array.map(date => format(date, formatStr));

export const isSameDate = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};
