import { createSlice } from '@reduxjs/toolkit';

import { initialState, reducers } from './basket.common';

const basketEditOrderSlice = createSlice({
  name: 'basketEditOrder',
  initialState,
  reducers,
});

export const selectors = {
  selectBasket: state => state.basketEditOrder,
  selectCurrentDiet: ({ basketEditOrder }) => {
    return (
      basketEditOrder.items.dietElements?.find(
        row => row['@id'] === basketEditOrder.activeOrderDietIri
      ) ?? {}
    );
  },
};

export const {
  addDietToBasket,
  addItemToBasket,
  removeDiet,
  resetBasket,
  setDiscountCode,
  setInvoice,
  setIsValid,
  setPaymentMethod,
  setPaymentMethodMetadata,
  setPayStatus,
  setStatusBasket,
  setUseMoneyBox,
  toggleBasketDay,
  toggleBasketGroup,
} = basketEditOrderSlice.actions;

export const actions = basketEditOrderSlice.actions;

export default basketEditOrderSlice.reducer;
