import coreStyles from './Stepper.styles';

const Stepper = ({ children }) => {
  return <ol css={coreStyles.wrapper}>{children}</ol>;
};

const Step = ({ isActive, isCompleted, title, description }) => {
  return (
    <li css={coreStyles.item({ isActive, isCompleted })}>
      <div css={coreStyles.itemContent}>
        {title && <h3 css={coreStyles.itemTitle}>{title}</h3>}
        {description && <p css={coreStyles.itemDescription}>{description}</p>}
      </div>
    </li>
  );
};

Stepper.displayName = 'Stepper';
Step.displayName = 'Step';

Stepper.Step = Step;

export default Stepper;
