import { useTranslation } from 'next-i18next';

import { ExclamationIcon } from '@assets/icons';
import { Tooltip } from '@components';

const BasketModificationTooltip = ({ renderFrom = null, renderTo = null }) => {
  const { t } = useTranslation();

  if (!renderFrom && !renderTo) return null;

  return (
    <Tooltip
      content={
        <div tw="flex -m-2">
          {renderFrom && (
            <div tw="p-2 flex-initial w-1/2">
              <div tw="font-semibold border-b pb-0.5 mb-2 border-dashed">
                {t(
                  '$*components.basketModificationTooltip.changeFrom',
                  '$$Zmiana z:'
                )}
              </div>
              {renderFrom()}
            </div>
          )}
          {renderTo && (
            <div tw="p-2 flex-initial w-1/2">
              <div tw="font-semibold border-b pb-0.5 mb-2 border-dashed">
                {t(
                  '$*components.basketModificationTooltip.changeTo',
                  '$$Zmiana na:'
                )}
              </div>
              {renderTo()}
            </div>
          )}
        </div>
      }
    >
      <div tw="ml-2">
        <ExclamationIcon tw="w-3.5 cursor-pointer" />
      </div>
    </Tooltip>
  );
};

export default BasketModificationTooltip;
