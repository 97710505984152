import tw from 'twin.macro';

const headerSizeStyle = {
  sm: tw`px-6 py-3`,
  md: tw`px-8 py-4`,
  lg: tw``,
};

const closeButtonSizeStyle = {
  sm: tw`right-6 top-3`,
  md: tw`right-8 top-4`,
  lg: tw``,
};

const contentSizeStyle = {
  sm: tw`px-6 pt-5 pb-6`,
  md: tw`px-8 pt-6 pb-7`,
  lg: tw``,
};

const footerSizeStyle = {
  sm: tw`px-6 py-4 mt-5`,
  md: tw`px-8 py-5 mt-6`,
  lg: tw``,
};

const styles = {
  header: ({ noBorder, size }) => [
    tw`sticky top-0 bg-white z-50`,
    !noBorder && tw`border-b`,
    headerSizeStyle[size],
  ],
  closeButton: ({ size }) => [
    tw`border-transparent text-gray-500 transform duration-300 flex items-center justify-center rounded-lg absolute cursor-pointer outline-none hover:text-gray-700 focus-visible:(outline-none ring-gray-300)`,
    closeButtonSizeStyle[size],
  ],
  content: ({ size }) => [contentSizeStyle[size]],
  footer: ({ size }) => [
    tw`bg-gray-50 flex justify-between border-t border-gray-200`,
    footerSizeStyle[size],
  ],
};

export default styles;
