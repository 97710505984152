import { useTranslation } from 'next-i18next';

import { ExclamationIcon } from '@assets/icons';
import { useToggle } from '@hooks';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import PurchasedProductSizeInfo from './PurchasedProductSizeInfo';

const PurchasedProductSize = ({
  allergens = [],
  components = [],
  quantity = 0,
  macrosAfterProcessing = {},
  name = null,
}) => {
  const { t } = useTranslation();
  const [isShowSizeWrapper, toggleShowSizeWrapper] = useToggle(false);

  return (
    <div tw="text-xs py-1">
      <div>
        <button
          type="button"
          tw="flex items-center"
          onClick={toggleShowSizeWrapper}
        >
          <span>
            {`${quantity} x ${name} (${getIntegerIfIntegerOrFixed(
              macrosAfterProcessing?.calories ?? 0,
              0
            )} ${t('$*common.kcal', '$$kcal')})`}
          </span>
          <ExclamationIcon
            tw="w-4 ml-3"
            title={t(
              '$*components.productTile.detailsDishSize',
              '$$Szczegoły wielkości porcji'
            )}
          />
        </button>
      </div>

      <PurchasedProductSizeInfo
        allergens={allergens}
        components={components}
        isShow={isShowSizeWrapper}
        macrosAfterProcessing={macrosAfterProcessing}
        name={name}
        toggleShow={toggleShowSizeWrapper}
      />
    </div>
  );
};

export default PurchasedProductSize;
