import { Children, cloneElement } from 'react';
import { css } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';
import tw from 'twin.macro';

import { SpinnerThirdIcon } from '@assets/icons';
import { cssMerge } from '@utils/styleHelpers';

const PaymentType = ({
  children,
  disabled = false,
  isLoading = false,
  isSelected,
  onClick,
  size = 'lg',
  styles = {},
  type,
}) => {
  const childrenWithProps = Children.map(children, child =>
    cloneElement(child, { size })
  );

  const styleBySize = {
    md: css`
      min-height: 40px;
      ${tw`my-2 text-sm`}
    `,
    lg: css`
      min-height: 56px;
      ${tw`my-4`}
    `,
  }[size];

  const twStyle = cssMerge({
    defaultCss: css`
      ${tw`border-2 rounded-lg flex py-2 px-3.5 items-center cursor-pointer `}
      ${isSelected ? tw`border-primary text-primary` : tw`border-gray-300`}
      ${isLoading && tw`opacity-60`}
      ${styleBySize}
    `,
    ...styles,
  });

  return (
    <div tw="relative">
      {isLoading && (
        <div tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <SpinnerThirdIcon tw="w-5 text-primary animate-spin" />
        </div>
      )}
      <div
        onClick={e => {
          !disabled && onClick(e);
        }}
        css={twStyle}
        data-cy={`payment-type payment-type--${kebabCase(type)}`}
      >
        {childrenWithProps}
      </div>
    </div>
  );
};

const Image = ({ children, size }) => {
  return (
    <div
      css={css`
        ${tw`mr-3`};
        ${size === 'md' && tw`w-10`}
        ${size === 'lg' && tw`w-14`}
        flex: 0 0 auto;

        img,
        svg {
          ${size === 'md' && 'max-height: 24px;'}
          ${size === 'lg' && 'max-height: 36px;'}
          ${tw`table mx-auto`}
        }
      `}
    >
      {children}
    </div>
  );
};

const Text = ({ children }) => {
  return <div tw="text-sm sm:text-base">{children}</div>;
};

const RightCol = ({ children }) => {
  return children;
};

Image.displayName = 'Image';
Text.displayName = 'Text';
RightCol.displayName = 'RightCol';

PaymentType.Image = Image;
PaymentType.Text = Text;
PaymentType.RightCol = RightCol;

PaymentType.displayName = 'PaymentType';

export default PaymentType;
