import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const updateBasketModify = async ({ id, payload = {} }) => {
  const response = await axios.put(ENDPOINT.BASKET_MODIFY(id), payload, {
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useUpdateBasketModify = ({
  basketKey = 'basketNewOrder',
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(updateBasketModify, {
    mutationKey: `${basketKey}UpdateModify`,
    onSuccess: (response, request) => {
      if (request?.payload?.abandoned !== true) {
        queryClient.setQueryData(basketKey, () => response);
      }
    },
    ...options,
  });

  return query;
};

export default useUpdateBasketModify;
