import { forwardRef } from 'react';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Box = forwardRef(
  ({ size = 'lg', children, styles = {}, ...restProps }, ref) => {
    const paddingBySize = {
      md: tw`px-5 py-4`,
      lg: tw`px-5 py-4 md:px-7 md:py-6 lg:px-8 lg:py-7`,
    }[size];

    const twStyle = cssMerge({
      defaultCss: [tw`bg-white rounded-md shadow-lg`, paddingBySize],
      ...styles,
    });

    return (
      <div css={twStyle} {...restProps} ref={ref}>
        {children}
      </div>
    );
  }
);

Box.displayName = 'Box';

export default Box;
