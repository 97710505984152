import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Button } from '@components';
import ROUTE_URLS from '@constants/routeUrls';

const AddonPageConfigurator = ({ addonName, isActive }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div tw="flex items-center">
          <div tw="w-1/4 mr-4" style={{ borderTop: '1px solid' }} />
          <div tw="w-1/2 text-center">{addonName}</div>
          <div tw="w-1/4 ml-4" style={{ borderTop: '1px solid' }} />
        </div>
        <p tw="py-2">
          {isActive
            ? t(
                '$*addonPageConfigurator.addonIsAvaliable',
                '$$Dodatek jest dostępny'
              )
            : t(
                '$*addonPageConfigurator.addonIsTemporaryUnavaliable',
                '$$Dodatek jest czasowo niedostępny'
              )}
        </p>
        <Link href={ROUTE_URLS.NEW_ORDER_FORM}>
          <Button fullWidth={true}>
            {isActive
              ? t('$*addonPageConfigurator.orderWithDiet', '$$Zamów z dietą')
              : t('$*common.checkCurrentMenu', '$$Zobacz aktualną ofertę')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AddonPageConfigurator;
