import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { Fragment, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import {
  DiscountCode,
  Invoice,
  PaymentTypes,
  PiggyBankPoints,
} from '@components';
import PAYMENT_TYPES from '@constants/paymentTypes';
import { useBasketMethods } from '@hooks';

const BasketPay = dynamic(() => import('./BasketPay'));

const BasketSummary = ({
  currentStepNumber,
  showBasketPay = true,
  styles = {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentTypesRef = useRef();

  const {
    basketQuery: { data: basket = {} },
    basketStore: { discountCode, useDiscountCode, useInvoice, useMoneyBox },
    commonSetDiscountCode,
    setDiscountCode,
    setInvoice,
    setPaymentMethod,
    setUseMoneyBox,
    updateOrderBasketModify,
  } = useBasketMethods();

  const paymentType = basket?.paymentType ?? null;
  const paymentTypes = basket?.paymentTypes ?? [];

  const handleClickPaymentType = ({ paymentType, metadata = {} }) => {
    updateOrderBasketModify({
      payload: {
        paymentType,
        ...(!isEmpty(metadata) ? { paymentMetadata: metadata } : {}),
        ...(useDiscountCode ? { discountCode: discountCode?.['@id'] } : {}),
      },
      stepNumber: currentStepNumber,
    });

    dispatch(setPaymentMethod(paymentType));
  };

  const handleClickConfirmStripeCard = () => {
    handleClickPaymentType({ paymentType: PAYMENT_TYPES.STRIPE_CARD });
  };

  const handleClickConfirmPayUCard = () => {
    handleClickPaymentType({ paymentType: PAYMENT_TYPES.PAYU_CARD });
  };

  const handleClickConfirmPayPo = metadata => {
    handleClickPaymentType({ paymentType: PAYMENT_TYPES.PAYPO, metadata });
  };

  return (
    <Fragment>
      <div>
        <div>
          {t(
            '$*newOrderCreatePage.orderForm.summary.financialOptions',
            '$$Opcje finansowe'
          )}
        </div>
        <div tw="-my-4 pt-4 pb-8">
          <DiscountCode
            useDiscountCodeStore={useDiscountCode}
            commonSetDiscountCode={commonSetDiscountCode}
            updateOrderBasketModify={updateOrderBasketModify}
            setDiscountCode={setDiscountCode}
            discountCode={discountCode}
          />
          <PiggyBankPoints
            updateOrderBasketModify={updateOrderBasketModify}
            useMoneyBox={useMoneyBox}
            setUseMoneyBox={setUseMoneyBox}
          />
          <Invoice useInvoice={useInvoice} setInvoice={setInvoice} />
        </div>
      </div>
      <div>
        <div>
          {t(
            '$*newOrderCreatePage.orderForm.summary.paymentTypes',
            '$$Sposoby płatności'
          )}
        </div>

        <PaymentTypes
          ref={paymentTypesRef}
          payments={Object.values(paymentTypes)}
          selectedPaymentType={paymentType}
          onClickConfirmPayPo={handleClickConfirmPayPo}
          onClickConfirmStripeCard={handleClickConfirmStripeCard}
          onClickConfirmPayUCard={handleClickConfirmPayUCard}
          onClickPayment={handleClickPaymentType}
          styles={{ wrapper: { css: tw`pt-4` } }}
        />
      </div>

      {showBasketPay && <BasketPay styles={styles?.basketPay} />}
    </Fragment>
  );
};

export default BasketSummary;
