import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { isEmpty } from 'lodash';

import { getIntegerIfIntegerOrFixed, isFalsify } from '@utils/helpers';

const DishDetails = ({
  allergens = [],
  showConfig = { allergens: true, components: true, nutritionalValues: true },
  macrosAfterProcessing = {},
  ingredientsSortedByUsageWithEmptyExcluded = [],
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {showConfig?.components && (
        <div tw="pb-6">
          <h3>{t('$*common.dishComposition', '$$Skład')}</h3>
          <p>
            {ingredientsSortedByUsageWithEmptyExcluded
              .map(({ name }) => name)
              .join(', ')}
          </p>
        </div>
      )}

      {showConfig?.allergens && (
        <div tw="pb-6">
          <h3>{t('$*common.allergens', '$$Alergeny')}</h3>
          {isEmpty(allergens)
            ? '-'
            : allergens.map(({ value }) => value).join(', ')}
        </div>
      )}

      {showConfig?.nutritionalValues && (
        <div tw="pb-6">
          <h3>{t('$*common.nutrionalValues', '$$Wartosci odzywcze')}</h3>
          <p tw="mb-0">
            {!isFalsify(macrosAfterProcessing?.protein) &&
              `${t(
                '$*common.proteins',
                '$$Białko'
              )}: ${getIntegerIfIntegerOrFixed(
                macrosAfterProcessing?.protein
              )} g`}
          </p>
          <p tw="mb-0">
            {!isFalsify(macrosAfterProcessing?.carbohydrates) &&
              `${t(
                '$*common.carbohydrates',
                '$$Węglowodany'
              )}: ${getIntegerIfIntegerOrFixed(
                macrosAfterProcessing?.carbohydrates
              )} g`}
          </p>
          <p tw="mb-0">
            {!isFalsify(macrosAfterProcessing?.fat) &&
              `${t(
                '$*common.fats',
                '$$Tłuszcze'
              )}: ${getIntegerIfIntegerOrFixed(macrosAfterProcessing?.fat)} g`}
          </p>
        </div>
      )}

      {showConfig?.glycemicIndex && (
        <div>
          <h3>{t('$*common.glycemicIndex', '$$Indeks glikemiczny')}</h3>
          <p>{macrosAfterProcessing?.glycemicIndex ?? '-'}</p>
        </div>
      )}
    </Fragment>
  );
};

export default DishDetails;
