import { useTable } from 'react-table';
import { css } from '@emotion/react';
import tw from 'twin.macro';

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div {...getTableProps()} tw="w-full table">
      <div tw="table-row-group sticky top-20">
        {headerGroups.map((headerGroup, index) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            key={index}
            tw="table-row"
          >
            {headerGroup.headers.map((column, index) => (
              <div
                key={index}
                {...column.getHeaderProps()}
                css={css`
                  min-width: 150px;
                `}
                tw="bg-gray-200 text-gray-600 uppercase text-sm font-black text-left px-4 py-3 table-cell"
              >
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()} tw="table-row-group">
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <div
              {...row.getRowProps()}
              key={index}
              css={css`
                ${index % 2 && tw`bg-gray-50`};
              `}
              tw="hover:(bg-gray-100) table-row"
            >
              {row.cells.map((cell, index) => {
                return (
                  <div
                    key={index}
                    {...cell.getCellProps()}
                    tw="text-left px-4 py-3 table-cell"
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Table.displayName = 'Table';

export default Table;
