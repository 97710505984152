import { useEffect, useState } from 'react';

import { isClient } from '@utils/helpers';

const getMatch = query => {
  return window.matchMedia(query);
};

const parseQueryString = query => {
  return query.replaceAll('@media only screen and', '').trim();
};

const useMediaQuery = (query, defaultState = false) => {
  const parseAndMatch = s => getMatch(parseQueryString(s));
  const [state, setState] = useState(
    isClient ? () => parseAndMatch(query).matches : defaultState
  );

  useEffect(() => {
    let mounted = true;
    const mql = parseAndMatch(query);

    const onChange = () => {
      if (!mounted) return;
      setState(!!mql.matches);
    };

    mql.addEventListener('change', onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeEventListener('change', onChange);
    };
  }, [query]);

  return state;
};

export default useMediaQuery;
