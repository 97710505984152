import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectUser } from '@store/auth/auth.slice';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { CheckIcon, PiggyBankIcon } from '@assets/icons';
import { Button, Collapse } from '@components';
import { useBasketMethods, useMediaQuery, useToggle } from '@hooks';
import {
  selectModuleMoneyBox,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';
import { down } from '@utils/screens';

const PiggyBankPoints = ({
  updateOrderBasketModify,
  useMoneyBox,
  setUseMoneyBox,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDown500 = useMediaQuery(down('500px'), true);

  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { moneyBox = 0 } = useSelector(selectUser);
  const { shortName, forPoints, cashValue } =
    useAppConfigSelector(selectModuleMoneyBox);
  const {
    basketQuery: { data: { price = {} } = {} },
  } = useBasketMethods();

  const pointsToCashRate = forPoints !== 0 ? cashValue / forPoints : 0;
  const moneyBoxPointsCash = getIntegerIfIntegerOrFixed(
    moneyBox * pointsToCashRate
  );

  const countCashDiscount = price?.discountParts?.MONEYBOX ?? 0;
  const cashToPointsRate = cashValue !== 0 ? forPoints / cashValue : 0;
  const countPointsDiscount = parseInt(
    (cashToPointsRate * countCashDiscount).toFixed()
  );

  const restPointsAfterDiscount = moneyBox - countPointsDiscount;
  const restCashAfterDiscountPrice = getIntegerIfIntegerOrFixed(
    moneyBoxPointsCash - countCashDiscount
  );
  const restCashAfterDiscount =
    restCashAfterDiscountPrice > 0 ? restCashAfterDiscountPrice : 0;

  const handleUseMoneyBox = () => {
    dispatch(setUseMoneyBox(true));
    updateOrderBasketModify({ payload: { useMoneybox: true } });
    toggleIsExpanded();
  };

  const handleCancelUseMoneyBox = () => {
    dispatch(setUseMoneyBox(false));
    updateOrderBasketModify({ payload: { useMoneybox: false } });
    toggleIsExpanded();
  };

  if (!moneyBox || moneyBox === 0) {
    return null;
  }

  return (
    <div
      css={[
        tw`p-3.5 my-4 overflow-hidden border-2 rounded-lg`,
        useMoneyBox ? tw`border-primary` : tw`border-gray-200`,
      ]}
    >
      <motion.header
        initial={false}
        onClick={toggleIsExpanded}
        data-cy="piggy-bank__collapse"
      >
        <div tw="cursor-pointer flex items-center justify-between">
          <div tw="flex items-center">
            <PiggyBankIcon
              css={css`
                flex: 0 0 auto;
                ${tw`w-5`}
              `}
            />
            <div tw="px-4">
              {t(
                '$*newOrderCreatePage.orderForm.summary.useMoneyBox',
                '$$Wykorzystaj punkty ze skarbonki'
              )}
            </div>
            {useMoneyBox && (
              <div tw="text-primary">
                <CheckIcon tw="w-4" />
              </div>
            )}
          </div>
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </div>
      </motion.header>
      <Collapse isExpanded={isExpanded}>
        <div tw="pt-4">
          <div tw="flex justify-between flex-wrap -mx-1">
            <div tw="px-1">
              {t(
                '$*newOrderCreatePage.orderForm.summary.moneyBoxValue',
                '$$Wartość skarbonki'
              )}
              :
            </div>
            <div tw="px-1 font-semibold">
              <div css={[useMoneyBox && tw`text-red-500 line-through`]}>
                {`${moneyBox} ${
                  shortName || t('$*common.pointAbbreviation', '$$pkt')
                } / ${moneyBoxPointsCash} ${currencySymbol}`}
              </div>
              {useMoneyBox && (
                <div>
                  {`${restPointsAfterDiscount} ${
                    shortName || t('$*common.pointAbbreviation', '$$pkt')
                  } / ${restCashAfterDiscount} ${currencySymbol}`}
                </div>
              )}
            </div>
          </div>
          <div tw="pt-4 -mx-2 -my-1 flex justify-between flex-wrap">
            <Button
              type="button"
              color="error"
              variant="outlined"
              onClick={handleCancelUseMoneyBox}
              disabled={!useMoneyBox}
              styles={{
                button: [
                  tw`mx-2 my-1`,
                  isDown500 && tw`justify-center flex-auto`,
                ],
              }}
              data-cy="piggy-bank-form__cancel"
            >
              {t('$*common.cancelOrder', '$$Anuluj')}
            </Button>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleUseMoneyBox}
              disabled={useMoneyBox}
              styles={{
                button: [
                  tw`mx-2 my-1`,
                  isDown500 && tw`justify-center flex-auto`,
                ],
              }}
              data-cy="piggy-bank-form__submit"
            >
              {t('$*common.use', '$$Użyj')}
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default PiggyBankPoints;
