import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { BinIcon, CircleInfoIcon } from '@assets/icons';
import { PriceWrapper, Tooltip } from '@components';
import { useBasketMethods } from '@hooks';
import { isCMSPage } from '@utils/helpers';

import BasketDiscountTooltip from './BasketDiscountTooltip';

const BasketHeader = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    basketQuery: { data: { price = {} } = {} },
    handleBasketClear,
    isEmptyBasket,
  } = useBasketMethods();
  const hasDiscountParts = !isEmpty(price?.discountParts);

  return (
    <div tw="flex mb-4 pb-1.5 border-b border-gray-200 items-center justify-between font-semibold text-primary">
      <div tw="flex items-center">
        <h2 className="h3" tw="mb-0">
          {t('$*components.basket.title', '$$Koszyk')}
        </h2>
        {!isEmptyBasket && !isCMSPage(router.pathname) && (
          <Tooltip content={t('$*common.clearBasket', '$$Wyczyść koszyk')}>
            <div tw="ml-3 text-red-500" onClick={handleBasketClear}>
              <BinIcon tw="w-4 cursor-pointer" />
            </div>
          </Tooltip>
        )}
      </div>

      <div tw="flex items-center">
        {hasDiscountParts && (
          <div tw="mr-2">
            <BasketDiscountTooltip>
              <span>
                <CircleInfoIcon tw="w-3 cursor-pointer" />
              </span>
            </BasketDiscountTooltip>
          </div>
        )}
        <PriceWrapper
          beforeDiscount={price?.beforeDiscount}
          afterDiscount={price?.afterDiscount}
          styles={{ wrapper: { css: tw`text-lg` } }}
        />
      </div>
    </div>
  );
};

export default BasketHeader;
