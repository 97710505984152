import DisplayOn from './DisplayOn';

const DisplayDownXl = ({ children }) => (
  <DisplayOn size="xl" mode="down" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayDownXl.displayName = 'DisplayDownXl';

export default DisplayDownXl;
