import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/userDataInputs';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const initialValues = {
  [INPUT_IDS.PHONE]: { number: '', countryCode: '' },
};

export const useValidationSchema = () => {
  const { phoneValid } = useDefaultSchemaYup();

  return Yup.object({
    [INPUT_IDS.PHONE]: phoneValid,
  });
};
