const LidIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 3679 1873"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M1465.6 358.07C837.9 519.1 368.4 1076.88 340.8 1747.88H62.5c-34.5 0-62.5 28-62.5 62.5s28 62.5 62.5 62.5h3553c34.5 0 62.5-28 62.5-62.5s-28-62.5-62.5-62.5h-278.7c-27.6-670.9-496.9-1228.59-1124.5-1389.74C2204.1 159.1 2040 0 1838.9 0c-201 0-365.1 159.07-373.3 358.07Zm1746 1389.81c-32.7-729.5-635.3-1311.71-1372.8-1311.71S498.7 1018.38 466 1747.88h2745.6ZM2084 331.15C2063.8 214.13 1961.7 125 1838.9 125c-122.7 0-224.8 89.11-245 206.11 79.7-13.12 161.5-19.94 244.9-19.94 83.5 0 165.4 6.84 245.2 19.98Z" />
  </svg>
);

export default LidIcon;
