import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Radiobox = ({ label, styles = {}, ...radioboxProps }) => {
  const twInputStyle = cssMerge({
    defaultCss: tw`w-5 h-5 rounded-full cursor-pointer text-primary`,
    ...styles?.input,
  });

  const twSpanStyle = cssMerge({
    defaultCss: tw`ml-3 cursor-pointer`,
    ...styles?.span,
  });

  return (
    <label tw="inline-flex items-center">
      <input type="radio" css={twInputStyle} {...radioboxProps} />
      <span css={twSpanStyle}>{label}</span>
    </label>
  );
};

Radiobox.displayName = 'Radiobox';

export default Radiobox;
