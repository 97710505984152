import { useMutation, useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const basketClean = async ({ iri = null, id, payload = {} }) => {
  const url = isEmpty(iri) ? ENDPOINT.BASKET_CLEAN(id) : iri;
  const response = await axios.put(url, payload);

  return response?.data ?? {};
};

const useBasketClean = ({
  basketKey = 'basketNewOrder',
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(basketClean, {
    mutationKey: `${basketKey}Clean`,
    onSuccess: response => {
      queryClient.setQueryData(basketKey, () => response);
    },
    ...options,
  });

  return query;
};

export default useBasketClean;
