import { combineReducers } from '@reduxjs/toolkit';

import orderTabsSlice from '@features/orderTabs/orderTabsSlice';

import authSlice from './auth/auth.slice';
import basketEditOrderSlice from './basket/basketEditOrder.slice';
import basketInitialDataSlice from './basket/basketInitialData.slice';
import basketNewOrderSlice from './basket/basketNewOrder.slice';
import myDietSlice from './myDiet/myDiet.slice';
import orderFormSlice from './orderForm/orderForm.slice';

const rootReducer = combineReducers({
  auth: authSlice,
  basketEditOrder: basketEditOrderSlice,
  basketInitialData: basketInitialDataSlice,
  basketNewOrder: basketNewOrderSlice,
  myDiet: myDietSlice,
  orderForm: orderFormSlice,
  orderTabs: orderTabsSlice,
  lastAction: (_, action) => action.type,
});

export default rootReducer;
