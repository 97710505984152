import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectUser } from '@store/auth/auth.slice';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { CheckIcon, InvoiceIcon } from '@assets/icons';
import { Alert, Button, Collapse, InputsIndex } from '@components';
import { useMediaQuery, useToggle } from '@hooks';
import {
  selectModuleFakturownia,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getInitialFormsStateByFields } from '@utils/helpers';
import { down } from '@utils/screens';

import { initialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const Invoice = ({ useInvoice, setInvoice }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDown500 = useMediaQuery(down('500px'), true);

  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const user = useSelector(selectUser);
  const { allowInvoicesForClient, integrationType } = useAppConfigSelector(
    selectModuleFakturownia
  );
  const validationSchema = useValidationSchema(integrationType);
  const fields = useFields(integrationType);

  const userInvoiceDataValues = !isEmpty(user)
    ? getInitialFormsStateByFields({
        fields: fields.map(({ id, type }) => ({ key: id, type })),
        data: user,
      })
    : initialValues;

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(setInvoice({ useInvoice: true, invoice: values }));

    setSubmitting(false);
    toggleIsExpanded();
  };

  const handleRemoveUseInvoice = () => {
    dispatch(setInvoice({ useInvoice: false, invoice: {} }));
    toggleIsExpanded();
  };

  if (!allowInvoicesForClient || integrationType === 'DISABLED') {
    return null;
  }

  return (
    <div
      css={[
        tw`p-3.5 my-4 overflow-hidden border-2 rounded-lg`,
        useInvoice ? tw`border-primary` : tw`border-gray-200`,
      ]}
    >
      <motion.header
        initial={false}
        onClick={toggleIsExpanded}
        data-cy="invoice__collapse"
      >
        <div tw="cursor-pointer flex items-center justify-between">
          <div tw="flex items-center">
            <InvoiceIcon
              css={css`
                flex: 0 0 auto;
                ${tw`w-5`}
              `}
            />
            <div tw="px-4">
              {t(
                '$*newOrderCreatePage.orderForm.summary.generateInvoice',
                '$$Generuj fakturę na firmę'
              )}
            </div>
            {useInvoice && (
              <div tw="text-primary">
                <CheckIcon tw="w-4" />
              </div>
            )}
          </div>
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </div>
      </motion.header>
      <Collapse isExpanded={isExpanded}>
        <div tw="pt-4">
          <Formik
            initialValues={userInvoiceDataValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
          >
            {({ status, isSubmitting }) => (
              <Form data-cy="invoice-form">
                <div className="row">
                  {fields.map(({ id, colClass = 'col-12', ...restProps }) => {
                    return (
                      <div className={colClass} key={id}>
                        <InputsIndex id={id} {...restProps} />
                      </div>
                    );
                  })}
                </div>

                {status?.apiErrorMessage && (
                  <Alert styles={{ css: tw`mt-4` }}>
                    {/* i18next-extract-disable-next-line */}
                    {t(`$*error.api.${status.apiErrorMessage}`)}
                  </Alert>
                )}

                <div tw="pt-4 -mx-2 -my-1 flex justify-between flex-wrap">
                  <Button
                    type="button"
                    color="error"
                    variant="outlined"
                    onClick={handleRemoveUseInvoice}
                    disabled={!useInvoice}
                    styles={{
                      button: [
                        tw`mx-2 my-1`,
                        isDown500 && tw`justify-center flex-auto`,
                      ],
                    }}
                    data-cy="invoice-form__cancel"
                  >
                    {t('$*common.cancel', '$$Anuluj')}
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || useInvoice}
                    styles={{
                      button: [
                        tw`mx-2 my-1`,
                        isDown500 && tw`justify-center flex-auto`,
                      ],
                    }}
                    data-cy="invoice-form__submit"
                  >
                    {t('$*common.save', '$$Zapisz')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Collapse>
    </div>
  );
};

export default Invoice;
