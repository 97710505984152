import { useTranslation } from 'next-i18next';

import { css } from '@emotion/react';
import tw from 'twin.macro';

import PAYMENT_TYPES from '@constants/paymentTypes';
import { usePaymentCards } from '@hooks';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getChildByDisplayName } from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

import PaymentCard from './PaymentCard/PaymentCard';
import PayUCardForm from './PayUCardForm/PayUCardForm';
import StripeCardForm from './StripeCardForm/StripeCardForm';

const PaymentCardWrapper = ({ children = null, styles = {}, paymentType }) => {
  const { t } = useTranslation();
  const { data: cards = [] } = usePaymentCards();
  const { subscriptionPaymentDefaultGateway } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const currentPaymentType = paymentType ?? subscriptionPaymentDefaultGateway;

  const getPaymentCardForm = () => {
    switch (currentPaymentType) {
      case PAYMENT_TYPES.STRIPE_CARD:
        return <StripeCardForm />;
      case PAYMENT_TYPES.PAYU_CARD:
        return <PayUCardForm />;
      default:
        return <></>;
    }
  };

  const filteredCards = cards?.filter(
    ({ provider }) => provider === currentPaymentType
  );
  const card = filteredCards?.length > 0 ? filteredCards[0] : null;
  const AfterPaymentCardChildren = getChildByDisplayName(
    children,
    'AfterPaymentCard'
  );

  const paymentCardWrapperStyle = cssMerge({
    defaultCss: [tw`py-1.5 px-2.5`, { flex: '0 1 340px' }],
    ...styles?.paymentCardWrapper,
  });

  if (!currentPaymentType)
    return (
      <div
        css={css`
          ${tw`py-1.5`};
          flex: 1 1 calc(100% - 340px);
        `}
      >
        {t(
          '$*accountSettingsPage.paymentCardSection.cardCanBeAddedDuringNewOrderCreation',
          'Kartę możesz dodać podczas składania nowego zamówienia'
        )}
      </div>
    );

  if (filteredCards?.length > 0) {
    return (
      <div>
        <div tw="flex flex-wrap -my-1.5 -mx-2.5">
          <div css={paymentCardWrapperStyle}>
            <PaymentCard
              id={card.index}
              brand={card.issuer}
              provider={card.provider}
              last4={card.mask}
              name={card.name}
              expireAt={new Date(`${card.validityYear}-${card.validityMonth}`)}
              validatedByProvider={card?.validatedByProvider}
            />
          </div>
          <div
            css={css`
              ${tw`py-1.5 px-2.5`};
              flex: 1 1 calc(100% - 340px);
            `}
          >
            {t(
              '$*accountSettingsPage.paymentCardSection.changeDataTip',
              '$$To update your card details, first remove the current card'
            )}
          </div>
        </div>
        {AfterPaymentCardChildren}
      </div>
    );
  }

  return getPaymentCardForm();
};

const AfterPaymentCard = ({ children }) => children;
AfterPaymentCard.displayName = 'AfterPaymentCard';
PaymentCardWrapper.AfterPaymentCard = AfterPaymentCard;

export default PaymentCardWrapper;
