const PlusIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 67 67"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M30.085 3.348a3.358 3.358 0 013.353-3.352 3.357 3.357 0 013.35 3.35l-.002 27.3 26.733.004A3.34 3.34 0 0166.87 34a3.336 3.336 0 01-3.346 3.346l-26.74.003.002 26.172a3.347 3.347 0 01-3.352 3.353 3.357 3.357 0 01-3.35-3.35l.002-26.219-26.736.083a3.34 3.34 0 01-3.35-3.35 3.346 3.346 0 013.352-3.352l26.82-.001-.086-27.337z" />
  </svg>
);

export default PlusIcon;
