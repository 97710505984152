import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Carousel } from '@components';
import { Button } from '@components';
import { useMediaQuery, useMobileDetect } from '@hooks';
import { down } from '@utils/screens';
import { useOrderFormDietsContext } from '@views/NewOrder/NewOrderCreatePage/components/Diets/Diets.context';

const SelectCalorificSection = ({ className }) => {
  const { t } = useTranslation();
  const [initialCalorificSlide, setInitialCalorificSlide] = useState(-1);
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const isDown500 = useMediaQuery(down('500px'), true);

  const { avaliableCalorifics, selectCalorific, selectedCalorificId } =
    useOrderFormDietsContext();

  useEffect(() => {
    if (!isEmpty(avaliableCalorifics)) {
      const initialCalorificIndex = avaliableCalorifics
        .map(calorific =>
          calorific?.['@id'] === selectedCalorificId ? true : false
        )
        .indexOf(true);

      setInitialCalorificSlide(
        initialCalorificIndex > 0 ? initialCalorificIndex : 0
      );
    }
  }, [avaliableCalorifics]);

  if (isEmpty(avaliableCalorifics) || initialCalorificSlide < 0) {
    return null;
  }

  return (
    <div tw="">
      <h2 tw="text-lg">
        {t(
          '$*newOrderCreatePage.orderForm.diets.selectCalorific.title',
          '$$Kaloryczność'
        )}
      </h2>
      {avaliableCalorifics.length > 4 ? (
        <div tw="relative w-full">
          <Carousel
            className={className}
            options={{
              initialSlide: initialCalorificSlide,
              slidesPerView: 2,
              spaceBetween: 0,
              allowTouchMove: isMobile,
              ...(!isMobile
                ? {
                    keyboard: {
                      enabled: true,
                      onlyInViewport: false,
                    },
                  }
                : {}),
            }}
          >
            {Slide =>
              avaliableCalorifics.map(calorific => {
                return (
                  <Slide
                    key={calorific['@id']}
                    onClick={e => {
                      e.preventDefault();
                    }}
                  >
                    <div tw="w-full px-1">
                      <Button
                        type="button"
                        color="primary"
                        fullWidth
                        variant="outlined"
                        styles={{
                          button: [
                            tw`flex-auto my-1 text-xs shadow hover:(bg-white text-primary)`,
                            calorific['@id'] !== selectedCalorificId &&
                              tw`border-transparent`,
                            calorific['@id'] === selectedCalorificId &&
                              tw`font-bold border-2 `,
                            { minHeight: '65px' },
                          ],
                        }}
                        onClick={() => selectCalorific(calorific)}
                      >
                        {calorific?.name}
                      </Button>
                    </div>
                  </Slide>
                );
              })
            }
          </Carousel>
        </div>
      ) : (
        <div tw="flex flex-wrap items-stretch -m-3 pt-2">
          {avaliableCalorifics.map(calorific => (
            <div
              key={calorific['@id']}
              tw="p-1"
              css={[isDown500 ? tw`w-1/2` : tw`w-1/2`]}
            >
              <Button
                type="button"
                color="primary"
                variant="outlined"
                fullWidth={true}
                styles={{
                  button: [
                    tw`flex-auto mx-2 my-1 text-xs shadow hover:(bg-white text-primary)`,
                    calorific['@id'] !== selectedCalorificId &&
                      tw`border-transparent`,
                    calorific['@id'] === selectedCalorificId &&
                      tw`font-bold border-2`,
                    { minHeight: '65px' },
                  ],
                }}
                onClick={() => selectCalorific(calorific)}
              >
                {calorific?.name}
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCalorificSection;
