import { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';

const SelectDishesSectionContext = createContext([{}, () => {}]);

const SelectDishesSectionProvider = ({
  children,
  handleChangeProductQuantity,
  checkIsProductSelected,
  selectedDate,
  selectedDietIri,
  selectedTags,
  toggleTag,
  resetTags,
}) => {
  const dispatch = useDispatch();

  const handleToggleTag = tag => dispatch(toggleTag(tag));
  const handleResetTags = () => dispatch(resetTags());

  const value = {
    selectedTags,
    handleChangeProductQuantity,
    checkIsProductSelected,
    selectedDietIri,
    selectedDate,
    handleToggleTag,
    handleResetTags,
  };

  return (
    <SelectDishesSectionContext.Provider value={value}>
      {children}
    </SelectDishesSectionContext.Provider>
  );
};

const useSelectDishesSectionContext = () =>
  useContext(SelectDishesSectionContext);

export { SelectDishesSectionProvider, useSelectDishesSectionContext };
