import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

const AddonInfoWrapperExpanded = ({ children, isOpen = false }) => {
  return (
    <motion.div
      initial="collapsed"
      animate={isOpen ? 'open' : 'collapsed'}
      exit="collapsed"
      variants={{
        open: {
          height: 'auto',
          transition: {
            duration: 0.2,
            delay: 0.2,
          },
          transitionEnd: {
            display: 'inherit',
          },
        },
        collapsed: {
          height: 0,
          transition: {
            duration: 0.2,
          },
          transitionEnd: {
            display: 'none',
          },
        },
      }}
      css={css`
        ${tw`overflow-hidden`};
      `}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </motion.div>
  );
};

export default AddonInfoWrapperExpanded;
