import Rating from './Rating';

const Review = ({ rate = 0, comment = '' }) => {
  return (
    <div tw="mt-5">
      <Rating value={rate} isSmall isReadOnly />
      <div tw="rounded-lg bg-gray-100 p-2">{comment}</div>
    </div>
  );
};

export default Review;
