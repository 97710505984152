import Cookies from 'js-cookie';

export const setMyLeadTrackingParams = ({ clickId, userId }) => {
  const expireTime = 30; //30 days

  if (clickId) {
    Cookies.set('clickId', clickId, { expires: expireTime });
  }

  if (userId) {
    Cookies.set('userId', userId, { expires: expireTime });
  }
};

export const getMyLeadTrackingParams = () => {
  const userId = Cookies.get('userId');
  const clickId = Cookies.get('clickId');

  return { clickId, userId };
};
