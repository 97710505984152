import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import ADDON_DELIVERY_MODES from '@constants/addonDeliveryModes';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { checkImageExists, getIntegerIfIntegerOrFixed } from '@utils/helpers';

import AddonDeliveryModeSwitch from './AddonDeliveryModeSwitch';
import AddonName from './AddonName';
import { Tile } from './AddonTile.styles';

const AddonTileDesktop = ({
  '@id': addonIri,
  basketItemsDeliveryModes,
  basketItemsQuantity,
  clientCost,
  deliveryModesEnabled,
  dishDate,
  image = null,
  isSelected = false,
  maxCount = null,
  minCount = null,
  name,
  onChangeQuantity = () => {},
  selectedDietIri,
  shortDescription,
}) => {
  const [isExistImage, setIsExistImage] = useState(false);

  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  useEffect(() => {
    checkImageExists(image)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [image]);

  const imageUrl = isExistImage ? image : '/images/placeholder.jpeg';
  const currentAddonQuantity = basketItemsQuantity()?.[addonIri] ?? 0;
  const currentAddonDeliveryMode = deliveryModesEnabled
    ? basketItemsDeliveryModes?.[addonIri] ?? ADDON_DELIVERY_MODES.FOR_EVERY_BAG
    : null;

  const handleDeliveryModeChange = newDeliveryMode => {
    if (newDeliveryMode === currentAddonDeliveryMode) return;

    onChangeQuantity({
      addonIri,
      quantity: currentAddonQuantity,
      deliveryMode: newDeliveryMode,
      day: dishDate,
      dietIri: selectedDietIri,
    });
  };

  return (
    <Tile isSelected={isSelected} data-cy="addon-tile">
      <div
        onClick={() => {
          onChangeQuantity({
            addonIri,
            day: dishDate,
            quantity: Number(!isSelected),
            dietIri: selectedDietIri,
            deliveryMode: currentAddonDeliveryMode,
          });
        }}
      >
        <Tile.Content>
          <Tile.ContentInnerWrapper>
            <Tile.Image src={imageUrl} />
            <div tw="px-3 pt-4">
              <AddonName name={name} styles={{ css: tw`text-sm` }} />
              <Tile.Description
                dangerouslySetInnerHTML={{ __html: shortDescription }}
              />
            </div>
          </Tile.ContentInnerWrapper>
        </Tile.Content>
        <div tw="flex items-center justify-between p-3">
          <div>{`${getIntegerIfIntegerOrFixed(
            clientCost
          )} ${currencySymbol}`}</div>

          <CheckboxCounter
            count={currentAddonQuantity}
            min={minCount}
            max={maxCount}
            onChange={(e, quantity) => {
              e.stopPropagation();

              onChangeQuantity({
                addonIri,
                quantity,
                day: dishDate,
                dietIri: selectedDietIri,
                isRemove: currentAddonQuantity > quantity,
                deliveryMode: currentAddonDeliveryMode,
              });
            }}
          />
        </div>
      </div>

      {isSelected && currentAddonDeliveryMode && (
        <AddonDeliveryModeSwitch
          selectedMode={currentAddonDeliveryMode}
          onSwitch={handleDeliveryModeChange}
        />
      )}
    </Tile>
  );
};

export default AddonTileDesktop;
