import DisplayOn from './DisplayOn';

const DisplayDownXxl = ({ children }) => (
  <DisplayOn size="xxl" mode="down" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayDownXxl.displayName = 'DisplayDownXxl';

export default DisplayDownXxl;
