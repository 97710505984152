import { useTranslation } from 'next-i18next';

import React from 'react';
import tw from 'twin.macro';

import ADDON_DELIVERY_MODES from '@constants/addonDeliveryModes';

const AddonDeliveryModeSwitch = ({
  selectedMode = ADDON_DELIVERY_MODES.FOR_EVERY_BAG,
  onSwitch = () => {},
}) => {
  const { t } = useTranslation();
  const twStyles = {
    switch: tw`flex items-center justify-center w-1/2 px-0.5 py-1 leading-5 rounded`,
    selectedSwitch: tw`font-semibold bg-white`,
  };

  return (
    <div
      css={[
        tw`flex items-stretch justify-around border-2 rounded-md bg-primary border-primary -m-0.5 text-center relative z-10`,
        { minHeight: '2.25rem', width: 'calc(100% + 4px)' },
      ]}
    >
      <div
        css={[
          twStyles.switch,
          selectedMode === ADDON_DELIVERY_MODES.FOR_FIRST_BAG &&
            twStyles.selectedSwitch,
        ]}
        onClick={e => {
          e.stopPropagation();
          onSwitch(ADDON_DELIVERY_MODES.FOR_FIRST_BAG);
        }}
      >
        {t(
          '$*components.addons.deliveryModeSwitch.firstDelivery',
          '$$pierwsza dostawa'
        )}
      </div>
      <div
        css={[
          twStyles.switch,
          selectedMode === ADDON_DELIVERY_MODES.FOR_EVERY_BAG &&
            twStyles.selectedSwitch,
        ]}
        onClick={e => {
          e.stopPropagation();
          onSwitch(ADDON_DELIVERY_MODES.FOR_EVERY_BAG);
        }}
      >
        {t(
          '$*components.addons.deliveryModeSwitch.everyDelivery',
          '$$każda dostawa'
        )}
      </div>
    </div>
  );
};

export default AddonDeliveryModeSwitch;
