import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getOrderStatus = async orderId => {
  const response = await axios.get(ENDPOINT.ORDER_STATUS(orderId));

  return response?.data ?? {};
};

const useOrderStatus = ({ payload: { orderId }, options = {} }) =>
  useQuery(['orderStatus', orderId], () => getOrderStatus(orderId), {
    ...options,
  });

export default useOrderStatus;
