import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import { Collapse } from '@components';
import { selectBranding, useAppConfigSelector } from '@hooks/app/useAppConfig';

const CollapsingSection = ({
  title = '',
  children,
  isInitialyExpanded = false,
  styles = {},
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitialyExpanded);
  const { primaryColor } = useAppConfigSelector(selectBranding);

  return (
    <div css={styles}>
      <motion.header initial={false} onClick={() => setIsExpanded(!isExpanded)}>
        <div tw="flex items-center justify-between mb-3 cursor-pointer">
          <div tw="flex">
            <p tw="text-primary text-xl">{title}</p>
          </div>
          <div>
            <FontAwesomeIcon
              icon={isExpanded ? faChevronUp : faChevronDown}
              style={{ color: primaryColor }}
            />
          </div>
        </div>
      </motion.header>
      <Collapse isExpanded={isExpanded}>{children}</Collapse>
    </div>
  );
};

export default CollapsingSection;
