import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.service';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const addPaymentCard = async payload => {
  const response = await axios.post(ENDPOINT.PAYMENT_CARDS, payload, {
    errorHandle: false,
  });

  return [response?.data ?? {}];
};

const useAddPaymentCard = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(addPaymentCard, {
    mutationKey: 'paymentCards',
    onSuccess: response => {
      queryClient.setQueryData('paymentCards', response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    ...options,
  });

  return query;
};

export default useAddPaymentCard;
