import { useMutation, useQueryClient } from 'react-query';

import showToast from '@utils/showToast';

const usePersistBasket = ({
  basketKey = 'basketNewOrder',
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(
    () => {
      return queryClient.getQueryData(basketKey);
    },
    {
      mutationKey: `${basketKey}Restore`,
      onSuccess: response => {
        queryClient.setQueryData(basketKey, response);
      },
      onError: err => {
        const error = err?.response?.data;

        showToast(error, { type: 'error' });
      },
      retry: 3,
      ...options,
    }
  );

  return query;
};

export default usePersistBasket;
