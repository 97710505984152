import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import INPUT_IDS from '@constants/inputs/paypoInputs';

const useFields = () => {
  const { t } = useTranslation();

  const formFields = useMemo(() => {
    const fields = [
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.PAYPO_ADDRESS_POST_CODE,
        type: 'text',
        label: t('$*input.postal.label', '$$Kod pocztowy'),
        required: true,
        autoComplete: uuidv4(),
      },
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.PAYPO_ADDRESS_CITY,
        type: 'text',
        label: t('$*input.city.label', '$$Miasto'),
        required: true,
        autoComplete: uuidv4(),
      },
      {
        colClass: 'col-12',
        id: INPUT_IDS.PAYPO_ADDRESS_STREET,
        type: 'text',
        label: t('$*input.street.label', '$$Ulica'),
        required: true,
        autoComplete: uuidv4(),
      },
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.PAYPO_ADDRESS_BUILD_NUMBER,
        type: 'text',
        label: t('$*input.houseNumber.label', '$$Numer'),
        required: true,
      },
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.PAYPO_ADDRESS_PLACE_NUMBER,
        type: 'text',
        label: t('$*input.houseUnitNumber.label', '$$Lokal'),
        required: false,
      },
    ];

    return fields;
  }, [t]);

  return formFields;
};

export default useFields;
