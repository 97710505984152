import { useCallback, useMemo } from 'react';
import Masonry from 'react-masonry-css';
import isEmpty from 'lodash/isEmpty';

import {
  AddonTileDesktop,
  AddonTileMobile,
  DisplayDownMd,
  DisplayUpMd,
} from '@components';
import { sortByPosition } from '@utils/helpers';
import { screensInt } from '@utils/screens';

const AddonsGrouped = ({
  basketItemsDeliveryModes,
  basketItemsQuantity,
  checkIsAddonSelected = () => {},
  deliveryModesEnabled,
  dishDate,
  items = [],
  onChangeQuantity = () => {},
  selectedDietIri,
}) => {
  if (isEmpty(items)) {
    return null;
  }

  const sortedCategories = useMemo(
    () => sortByPosition(items, 'DESC'),
    [items]
  );

  const sortedAddons = useCallback(
    addons => sortByPosition(addons, 'DESC'),
    [items]
  );

  return (
    <div tw="-my-4">
      {sortedCategories.map(({ '@id': iri, name, addons }, index) => (
        <div key={iri} tw="py-4">
          <h3>{`${index + 1}. ${name}`}</h3>
          <Masonry
            breakpointCols={{
              default: 3,
              [screensInt.xxl]: 2,
              [screensInt.xl]: 3,
              [screensInt.lg]: 2,
              [screensInt.sm]: 1,
            }}
            className="masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {sortedAddons(addons).map(addon => {
              const props = {
                ...addon,
                isSelected: checkIsAddonSelected({
                  addon,
                  dietIri: selectedDietIri,
                  selectedDate: dishDate,
                }),
                onChangeQuantity: onChangeQuantity,
                dishDate: dishDate,
                basketItemsQuantity: basketItemsQuantity,
                selectedDietIri,
                deliveryModesEnabled,
                basketItemsDeliveryModes,
              };

              return (
                <div key={addon['@id']}>
                  <DisplayUpMd>
                    <AddonTileDesktop {...props} />
                  </DisplayUpMd>
                  <DisplayDownMd>
                    <AddonTileMobile {...props} />
                  </DisplayDownMd>
                </div>
              );
            })}
          </Masonry>
        </div>
      ))}
    </div>
  );
};

export default AddonsGrouped;
