import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const deleteCreditCard = async ({ id, provider }) => {
  const response = await axios.delete(`${ENDPOINT.CARDS}/${provider}/${id}`);

  return response?.data ?? {};
};

const useDeletePaymentCard = () => {
  const queryClient = useQueryClient();

  const query = useMutation(deleteCreditCard, {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData('paymentCards', cards =>
        cards.filter(card => card.index !== id)
      );
    },
  });

  return query;
};

export default useDeletePaymentCard;
