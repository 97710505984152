import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const createStyles = ({
  isClicable = true,
  isPlannedDate = false,
  isSelected = false,
  isToday = false,
  styles,
}) => {
  const wrapperStyle = cssMerge({
    defaultCss: tw`relative flex items-center justify-center font-bold h-11 rounded-xl`,
    ...styles?.wrapper,
  });

  const coreStyles = {
    wrapper: [
      wrapperStyle,
      isClicable && tw`cursor-pointer`,
      isSelected && tw`text-white bg-primary`,
      isPlannedDate && tw`text-white bg-grayGhost`,
    ].filter(Boolean),
    text: [isToday && tw`text-xl font-semibold`].filter(Boolean),
    todayDot: [
      tw`absolute w-1 h-1 transform -translate-x-1/2 -translate-y-1/2 bg-gray-400 rounded-full bottom-1 left-1/2`,
      (isSelected || isPlannedDate) && tw`bg-white`,
    ].filter(Boolean),
  };

  const concatedStyles = Object.entries(coreStyles).reduce(
    (acc, [key, value]) => {
      const overrideStyleKey = styles[key];
      const hasOverrideStyleKey = !isEmpty(overrideStyleKey);
      const style = hasOverrideStyleKey
        ? cssMerge({
            defaultCss: value,
            ...overrideStyleKey,
          })
        : value;

      return {
        ...acc,
        [key]: style,
      };
    },
    {}
  );

  return concatedStyles;
};

const memoStyles = ({
  isClicable,
  isPlannedDate,
  isSelected,
  isToday,
  styles,
}) =>
  useMemo(
    () =>
      createStyles({ isClicable, isPlannedDate, isSelected, isToday, styles }),
    [isClicable, isPlannedDate, isSelected, isToday, styles]
  );

export default memoStyles;
