import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { DisplayDownXl, DisplayUpXl, Image, ImageWrapper } from '@components';
import { screensInt } from '@utils/screens';

import styles from './Banner.styles';

const Banner = ({ banner = {}, isDisabled = false }) => {
  if (!banner?.enable || isDisabled || isEmpty(banner?.imageDesktop)) {
    return null;
  }

  return (
    <Fragment>
      <div css={styles.wrapper}>
        <DisplayUpXl>
          <ImageWrapper>
            <Image
              src={banner?.imageDesktop}
              css={styles.image}
              alt=""
              priority={true}
              layout="fill"
              sizes="1920px"
            />
          </ImageWrapper>
        </DisplayUpXl>
        <DisplayDownXl>
          <ImageWrapper>
            <Image
              src={banner?.imageMobile}
              css={styles.image}
              alt=""
              priority={true}
              layout="fill"
              sizes={`${screensInt.xl}px`}
            />
          </ImageWrapper>
        </DisplayDownXl>
        <div css={styles.contentWrapper}>
          <div
            dangerouslySetInnerHTML={{
              __html: banner?.title,
            }}
            css={styles.titleWrapper}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: banner?.description,
            }}
            css={styles.descriptionWrapper}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
