import React from 'react';

import { EditIcon, OkIcon } from '@assets/icons';

const EditableValue = ({
  value,
  isEditable = true,
  isEditMode,
  setIsEditMode,
  acceptChanges,
  children,
}) => {
  return (
    <>
      {isEditMode ? (
        <div tw="w-full relative flex justify-end items-center">
          {children}
          {acceptChanges && (
            <OkIcon tw="cursor-pointer w-4 ml-3" onClick={acceptChanges} />
          )}
        </div>
      ) : (
        <div tw="w-full flex justify-between items-center">
          <p tw="font-semibold mb-0">{value}</p>
          {isEditable && (
            <EditIcon tw="cursor-pointer w-4 ml-3" onClick={setIsEditMode} />
          )}
        </div>
      )}
    </>
  );
};

export default EditableValue;
