import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { useMenuDishTileContext } from './MenuDishTile.context';

const MenuDishInfoWrapper = ({ children }) => {
  const { enabled, handleToggleOpenMenu, isOpenMenu, showDishImage } =
    useMenuDishTileContext();

  return (
    <motion.div
      key="content"
      animate={isOpenMenu ? 'open' : 'collapsed'}
      variants={{
        open: {
          height: showDishImage ? 336 : 'auto',
        },
        collapsed: {
          height: showDishImage ? 110 : 86,
        },
      }}
      transition={{ duration: 0.3 }}
      aria-expanded={isOpenMenu}
      css={css`
        ${showDishImage && tw`absolute bottom-0 left-0 right-0`}
        ${tw`overflow-hidden transform bg-white rounded-lg`};
        ${!enabled && tw`bg-gray-100`};
      `}
      {...(showDishImage ? { onClick: handleToggleOpenMenu } : {})}
    >
      {children}
    </motion.div>
  );
};

export default MenuDishInfoWrapper;
