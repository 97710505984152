const INPUT_IDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  GENDER: 'gender',
  EMAIL: 'email',
  PHONE: 'phone',
  PASSWORD: 'plainPassword',
  REPEAT_PASSWORD: 'repeatPlainPassword',
  REFERRER: 'knowAboutUs',
  REFERRER_TEXT: 'knowAboutUsText',
  MARKETING_AGREEMENT: 'marketingTerms',
  SERVICE_AGREEMENT: 'serviceTerms',
  KEEP_ME_LOGGED_IN: 'keepMeLoggedIn',
};

export default INPUT_IDS;
