import { Fragment } from 'react';

import { ErrorLoading, LoadedWrapper, Loading } from '@components';

const ApiLoadingBoundary = ({
  children,
  isError = false,
  isFetching = false,
  isLoading = false,
  refetch = null,
  showLoadingText,
  withTimeoutInfo = true,
  justify,
  styles = {},
}) => {
  return (
    <Fragment>
      <Loading
        isLoading={isLoading}
        justify={justify}
        styles={styles?.loading}
        showLoadingText={showLoadingText}
        withTimeoutInfo={withTimeoutInfo}
      />
      <LoadedWrapper isLoading={isLoading} isError={isError}>
        {children}
      </LoadedWrapper>
      <ErrorLoading
        isFetching={isFetching}
        isError={isError}
        onTryAgain={refetch}
        styles={styles?.error}
        justify={justify}
      />
    </Fragment>
  );
};

ApiLoadingBoundary.displayName = 'ApiLoadingBoundary';

export default ApiLoadingBoundary;
