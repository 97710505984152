import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMyDiet, setDiet } from '@store/myDiet/myDiet.slice';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { PencilIcon } from '@assets/icons';
import { Button, EditDietNameModal, Select } from '@components';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { cssMerge } from '@utils/styleHelpers';

const DietsTab = ({
  userDietsQuery = {},
  onChange = () => {},
  styles = {},
  selectProps = {},
  editDietNameIsDisabled = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedDiet, selectedDietType } = useSelector(selectMyDiet);
  const { allowClientOwnDietName } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const { isFetched, data: diets = [] } = userDietsQuery;

  const [isEditDietNameModalOpen, setIsEditDietNameModalOpen] = useState(false);
  const openEditDietNameModal = () => {
    !editDietNameIsDisabled && setIsEditDietNameModalOpen(true);
  };
  const closeEditDietNameModal = () => setIsEditDietNameModalOpen(false);

  useEffect(() => {
    if (isFetched && selectedDietType === 'INIT') {
      const firstDiet = first(diets);

      if (!isEmpty(firstDiet)) {
        dispatch(setDiet(firstDiet));
      }
    }
  }, [isFetched]);

  const handleChangeDiet = option => {
    const diet = diets.find(({ id }) => id === option?.value);
    dispatch(setDiet(diet));
    onChange(diet);
  };

  const getDietLabelName = ({ id, name, shop, isSubscription }) => {
    const dietTypeText = shop
      ? t('$*common.dietType.order', '$$zamówienie')
      : isSubscription
      ? t('$*common.dietType.subscription', '$$subskrypcja')
      : t('$*common.dietType.singlePayment', '$$jednorazowa');

    if (!isEmpty(name)) {
      return `${name} - ${dietTypeText} (#${id})`;
    }

    if (shop) {
      return `${t('$*common.order', '$$Zamówienie')} (#${id})`;
    }

    if (isSubscription) {
      return `${t('$*common.diet', '$$Dieta')} - ${dietTypeText} (#${id})`;
    }

    return `${t('$*common.diet', '$$Dieta')} - ${dietTypeText} (#${id})`;
  };

  const twStyle = cssMerge({
    defaultCss: tw`flex`,
    ...styles,
  });

  return (
    <div css={twStyle}>
      <Select
        options={diets.map(diet => {
          return {
            label: getDietLabelName(diet),
            value: diet.id,
          };
        })}
        value={{
          label: getDietLabelName(selectedDiet),
          value: selectedDiet.id,
        }}
        onChange={handleChangeDiet}
        styles={{
          container: provided => ({
            ...provided,
            flex: '1 1 auto',
            width: '240px',
          }),
        }}
        {...selectProps}
      />

      {allowClientOwnDietName && (
        <Fragment>
          <Button
            styles={{
              button: tw`px-3 py-2 my-px ml-2`,
            }}
            onClick={openEditDietNameModal}
            disabled={editDietNameIsDisabled}
          >
            <PencilIcon tw="w-4 text-white" />
          </Button>

          <EditDietNameModal
            isOpen={isEditDietNameModalOpen}
            onClose={closeEditDietNameModal}
            diet={selectedDiet}
          />
        </Fragment>
      )}
    </div>
  );
};

export default DietsTab;
