import { createContext, useContext, useEffect, useState } from 'react';

import { useToggle } from '@hooks';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { checkImageExists, getAllergensFromIngredients } from '@utils/helpers';

const MenuDishTileContext = createContext([{}, () => {}]);

const MenuDishTileProvider = ({
  children,
  dish = {},
  onSelect = () => {},
  isSelected = false,
  portionDivision = 1,
  isOpenMenuOnClick = false,
}) => {
  const {
    '@resources': resources,
    dishImagePlaceholder,
    showInformation: {
      allergens: showAllergens,
      avgDishRating: showRateAvg,
      dishCalorific: showDishCalorific,
      dishComposition: showDishComposition,
      dishImage: showDishImage,
      glycemicIndex: { show: showGlycemicIndex } = {},
      macroIngredients: showMacroIngredients,
      servingProposal: showServingSuggestion,
      tags: showTags,
    } = {},
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const [isOpenMenu, toggleOpenMenu] = useToggle(showDishImage ? false : true);
  const [isExistImage, setIsExistImage] = useState(false);

  const {
    id = null,
    iri = null,
    name = null,
    fat = 0,
    tags = [],
    image = resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg',
    protein = 0,
    rateAVG = 0,
    calories = 0,
    ingredients = [],
    carbohydrates = 0,
    glycemicIndex = 0,
    servingSuggestion = {},
    enabled = false,
  } = dish;

  const handleToggleOpenMenu = e => {
    e.stopPropagation();
    toggleOpenMenu();
  };

  const handleClickDish = () => onSelect({ iri, enabled });

  useEffect(() => {
    checkImageExists(dish.image)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [dish?.image]);

  const allergens = getAllergensFromIngredients(ingredients);

  const value = {
    allergens,
    calories,
    carbohydrates,
    dishIsSelected: isSelected,
    enabled,
    fat,
    glycemicIndex,
    handleClickDish,
    handleToggleOpenMenu,
    id,
    image,
    ingredients,
    iri,
    isExistImage,
    isOpenMenu,
    isOpenMenuOnClick,
    name,
    portionDivision,
    protein,
    rateAVG,
    servingSuggestion,
    showAllergens,
    showDishCalorific,
    showDishComposition,
    showDishImage,
    showGlycemicIndex,
    showMacroIngredients,
    showRateAvg,
    showServingSuggestion,
    showTags,
    tags,
  };

  return (
    <MenuDishTileContext.Provider value={value}>
      {children}
    </MenuDishTileContext.Provider>
  );
};

const useMenuDishTileContext = () => useContext(MenuDishTileContext);

export { MenuDishTileProvider, useMenuDishTileContext };
