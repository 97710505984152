import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { useProductTileContext } from './ProductTile.context';

const ProductInfoWrapperCollapsed = ({ children }) => {
  const { isOpenMenu } = useProductTileContext();

  return (
    <motion.div
      initial="collapsed"
      animate={isOpenMenu ? 'open' : 'collapsed'}
      exit="collapsed"
      variants={{
        open: {
          width: 0,
          height: 0,
          opacity: 0,
          display: 'none',
          transition: {
            display: { delay: 0.2 },
            width: { duration: 0.15 },
            height: { duration: 0.15 },
          },
        },
        collapsed: {
          width: 'calc(100% - 96px)',
          height: '96px',
          opacity: 1,
          transition: {
            opacity: { delay: 0.4 },
            width: { delay: 0.2, duration: 0.2 },
            height: { delay: 0.2, duration: 0.2 },
          },
        },
      }}
      css={css`
        ${tw`overflow-hidden`};
      `}
    >
      {children}
    </motion.div>
  );
};

export default ProductInfoWrapperCollapsed;
