const OkIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1772 974"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M0 486.861c0-74.165 10.94-141.347 32.827-201.554 21.888-60.207 52.418-111.463 91.58-153.777 39.164-42.323 85.623-74.818 139.386-97.504C317.548 11.34 376.293-.008 440.035-.008c63.734 0 122.487 11.348 176.242 34.034 53.755 22.686 100.214 55.181 139.385 97.504 39.163 42.314 69.685 93.57 91.572 153.777 21.888 60.207 32.828 127.389 32.828 201.554 0 74.157-10.94 141.348-32.828 201.546-21.887 60.207-52.409 111.462-91.572 153.776-39.171 42.323-85.63 74.819-139.385 97.505-53.755 22.686-112.508 34.033-176.242 34.033-63.743 0-122.487-11.347-176.242-34.033-53.763-22.686-100.222-55.182-139.385-97.505-39.163-42.314-69.693-93.57-91.58-153.776C10.94 628.209 0 561.017 0 486.86zM1009.081 23.555h179.698v384.777l316.784-384.777h234.987l-362.858 430.578 393.962 496.026h-251.122L1188.78 510.415v439.744H1009.08V23.555zM186.607 486.86c0 43.62 5.959 83.537 17.859 119.753 11.9 36.206 28.986 67.61 51.26 94.222 22.273 26.611 48.953 47.34 80.057 62.174 31.104 14.826 65.852 22.248 104.252 22.248 38.392 0 73.148-7.422 104.243-22.248 31.104-14.835 57.793-35.563 80.058-62.174 22.274-26.612 39.36-58.016 51.268-94.222 11.9-36.216 17.85-76.133 17.85-119.753 0-42.76-5.95-82.455-17.85-119.1-11.908-36.644-28.994-68.272-51.268-94.883-22.265-26.612-48.954-47.34-80.058-62.175-31.095-14.826-65.851-22.248-104.243-22.248-38.4 0-73.148 7.422-104.252 22.248-31.104 14.835-57.784 35.563-80.058 62.175-22.273 26.611-39.36 58.24-51.26 94.884-11.9 36.644-17.858 76.338-17.858 119.1z"
      fillRule="nonzero"
    />
  </svg>
);

export default OkIcon;
