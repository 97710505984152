import tw from 'twin.macro';

import { Button, InputText } from '@components';

const Counter = ({
  min = 0,
  max = 0,
  count = 0,
  handleChangeValue = () => {},
  handleClickDecrement = () => {},
  handleClickIncrement = () => {},
}) => {
  return (
    <div tw="flex items-center justify-center">
      <Button
        type="button"
        variant="outlined"
        onClick={handleClickDecrement}
        disabled={!(min === null || (min !== null && count - 1 >= min))}
        styles={{
          button: tw`justify-center w-6 h-6 p-0`,
        }}
        data-cy="counter__button--minus"
      >
        -
      </Button>
      <InputText
        type="text"
        value={count}
        onChange={handleChangeValue}
        onClick={e => {
          e.stopPropagation();
        }}
        step="1"
        styles={{
          css: tw`text-center mx-1 w-6 p-0 text-sm border-none outline-none bg-transparent focus-visible:(shadow-none border-transparent ring-transparent)`,
          mode: 'replace',
        }}
        data-cy="counter__input"
      />
      <Button
        type="button"
        variant="outlined"
        onClick={handleClickIncrement}
        disabled={!(min === null || (min !== null && count + 1 <= max))}
        styles={{ button: tw`justify-center w-6 h-6 p-0` }}
        data-cy="counter__button--plus"
      >
        +
      </Button>
    </div>
  );
};

export default Counter;
