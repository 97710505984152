import tw from 'twin.macro';

import { Icon, Translation } from '@components';
import { cssMerge } from '@utils/styleHelpers';

const Spinner = ({
  children,
  showLoadingText = true,
  text = '$*common.isDataLoading',
  styles = {},
}) => {
  const twWrapperStyle = cssMerge({
    defaultCss: tw`flex items-center justify-center`,
    ...styles?.wrapper,
  });

  const twIconStyle = cssMerge({
    defaultCss: tw`w-8 h-8 mr-3 text-primary animate-spin`,
    ...styles?.icon,
  });

  return (
    <div css={twWrapperStyle}>
      <Icon name="SpinnerThird" css={twIconStyle} />
      {children ||
        (showLoadingText && (
          <Translation
            text={text}
            defaultValue="$$Trwa wczytywanie danych..."
          />
        ))}
    </div>
  );
};

Spinner.displayName = 'Spinner';

export default Spinner;
