const PrinterIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2599 2395"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2425.945 559.05c95.095 0 172.48 77.344 172.469 172.459v1017.787c0 95.115-77.384 172.459-172.479 172.459h-306.507l.01 406.105c0 36.854-29.867 66.742-66.702 66.742H545.674c-36.845 0-66.712-29.888-66.712-66.742v-406.105H172.47c-95.097 0-172.473-77.344-172.473-172.459V731.51c0-95.115 77.376-172.459 172.473-172.459h306.492V66.615c0-36.855 29.867-66.742 66.712-66.742h1507.073c36.834 0 66.701 29.887 66.701 66.742V559.05h306.497zm-452.531 1702.068c6.967 0 12.621-5.553 12.621-12.621V1461.63c0-6.967-5.654-12.622-12.621-12.622H624.996c-6.967 0-12.621 5.655-12.621 12.622v786.867c0 7.068 5.654 12.621 12.621 12.621h1348.418zm491.597-511.822h-.01V731.51c0-21.204-17.892-39.076-39.066-39.076H172.47c-21.173 0-39.064 17.872-39.064 39.076v1017.787c0 21.204 17.891 39.076 39.064 39.076h306.492v-406.105c0-36.855 29.867-66.742 66.712-66.742h1507.073c36.834 0 66.701 29.887 66.701 66.742l-.01 406.105h306.507c21.174 0 39.066-17.872 39.066-39.076zm-359.113-845.53c55.312 0 100.213 44.931 100.213 100.263 0 55.332-44.901 100.164-100.213 100.164-55.312 0-100.214-44.832-100.214-100.164s44.902-100.264 100.214-100.264zM624.996 133.355c-6.967 0-12.621 5.655-12.621 12.622V559.05h1373.66V145.978c0-6.967-5.654-12.622-12.621-12.622H624.996z" />
  </svg>
);

export default PrinterIcon;
