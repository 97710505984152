import { ProductTileProvider } from './ProductTile.context';
import ProductTileView from './ProductTile.view';

const ProductTile = ({
  optionsPopoverScope,
  selectedDietIri,
  selectedDate,
  dishSizesQuantity,
  isSelected,
  onChangeQuantity,
  ...props
}) => {
  const providerProps = {
    optionsPopoverScope,
    selectedDietIri,
    selectedDate,
    dishSizesQuantity,
    isSelected,
    onChangeQuantity,
    product: props,
  };

  return (
    <ProductTileProvider {...providerProps}>
      <ProductTileView />
    </ProductTileProvider>
  );
};

export default ProductTile;
