import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useFormikContext } from 'formik';
import tw from 'twin.macro';

import { Alert, Button, InputsIndex } from '@components';
import REGISTER_INPUT_IDS from '@constants/inputs/registerInputs';

import useRegisterFields from './useRegisterFields';

const RegisterFormFields = () => {
  const { t } = useTranslation();
  const fields = useRegisterFields();
  const { isSubmitting, values, status, dirty } = useFormikContext();

  return (
    <Fragment>
      <div className="row">
        {fields.map(
          ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
            if (
              id === REGISTER_INPUT_IDS.REFERRER_TEXT &&
              values[REGISTER_INPUT_IDS.REFERRER].value !==
                t('$*input.knowAboutUs.option.other.label')
            ) {
              return null;
            }

            return (
              <div className={colClass} key={id} css={colCss}>
                <InputsIndex id={id} {...restProps} />
              </div>
            );
          }
        )}
      </div>

      {status?.apiErrorMessage && (
        <Alert styles={{ css: tw`mb-4` }}>
          {/* i18next-extract-disable-next-line */}
          {t(`$*error.api.${status.apiErrorMessage}`)}
        </Alert>
      )}

      <div tw="mt-6">
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !dirty}
          fullWidth={true}
          data-cy="register-form__submit"
        >
          {t('$*registerPage.createAccount', '$$Załóż konto')}
        </Button>
      </div>
    </Fragment>
  );
};

export default RegisterFormFields;
