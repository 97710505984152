import { Fragment } from 'react';

import { DisplayDownMd, DisplayUpMd } from '@components';

import PurchasedProductTileDesktop from './PurchasedProductTileDesktop';
import PurchasedProductTileMobile from './PurchasedProductTileMobile';

const PurchasedProductTile = () => {
  return (
    <Fragment>
      <DisplayUpMd>
        <PurchasedProductTileDesktop />
      </DisplayUpMd>
      <DisplayDownMd>
        <PurchasedProductTileMobile />
      </DisplayDownMd>
    </Fragment>
  );
};

export default PurchasedProductTile;
