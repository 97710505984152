import tw from 'twin.macro';

import { Button, Translation } from '@components';
import { cssMerge } from '@utils/styleHelpers';

const ErrorLoading = ({
  isError = false,
  isFetching = false,
  onTryAgain = null,
  justify = 'center',
  styles = {},
}) => {
  if (!isError) {
    return null;
  }

  const justifyStyle = {
    left: { wrapper: '', btnWrapper: '' },
    center: { wrapper: tw`text-center`, btnWrapper: tw`mx-auto` },
    right: { wrapper: tw`text-right`, btnWrapper: tw`ml-auto` },
  }[justify];

  const twStyle = cssMerge({
    defaultCss: justifyStyle.wrapper,
    ...styles?.wrapper,
  });

  return (
    <div css={twStyle}>
      <div className="h2" tw="mb-0">
        <Translation
          text="$*components.errorLoading.title"
          defaultValue="$$Ups! Coś poszło nie tak..."
        />
      </div>
      <p>
        <Translation
          text="$*components.errorLoading.paragraph"
          defaultValue="$$Nie udało się pobrać danych. Spróbuj ponownie później"
        />
      </p>

      {onTryAgain && (
        <div css={[tw`table mt-4`, justifyStyle.btnWrapper]}>
          <Button
            onClick={onTryAgain}
            isLoading={isFetching}
            disabled={isFetching}
          >
            <Translation
              text="$*components.errorLoading.tryAgain"
              defaultValue="$$Spróbuj ponownie"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

ErrorLoading.displayName = 'ErrorLoading';

export default ErrorLoading;
