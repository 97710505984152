import { createSlice } from '@reduxjs/toolkit';
import { updateDiet } from '@store/myDiet/myDiet.actions';
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  isAfter,
  startOfMonth,
  subDays,
} from 'date-fns';
import first from 'lodash/first';
import last from 'lodash/last';

import { format, formatArray } from '@services/Date.service';

import {
  resetTagsReducer,
  toggleTagReducer,
} from '../orderForm/orderForm.actions';

const today = new Date();

const initialState = {
  selectedDietType: 'INIT',
  selectedDate: format(today),
  selectedDietId: 0,
  selectedDiet: {},
  selectedTags: [],
  paymentMethod: null,
  paymentMethodMetadata: {},

  calendarDays: formatArray(
    eachDayOfInterval({
      start: startOfMonth(today),
      end: endOfMonth(today),
    })
  ),
  menuPlanningCalendarDays: [],
};

const myDietSlice = createSlice({
  name: 'myDiet',
  initialState,
  reducers: {
    setDiet: (state, { payload: diet }) => {
      const isTodayAfterFirstDeliveryDate = isAfter(
        today,
        new Date(diet?.firstDeliveryDate)
      );

      const date = isTodayAfterFirstDeliveryDate
        ? today
        : new Date(diet?.firstDeliveryDate);
      const startDate = startOfMonth(date);
      const endDate = endOfMonth(date);
      const rangeDays = formatArray(
        eachDayOfInterval({ start: startDate, end: endDate })
      );

      state.selectedDietId = diet?.id;
      state.selectedDiet = diet;
      state.selectedDietType = 'ORDER_CLICK';
      state.selectedDate = isTodayAfterFirstDeliveryDate
        ? format(today)
        : format(new Date(diet?.firstDeliveryDate));
      state.calendarDays = rangeDays;
    },
    setMyDiet: (state, { payload }) => {
      Object.entries(payload).map(([key, value]) => {
        state[key] = value;
      });
    },
    setSelectedDate: (state, { payload: selectedDate }) => {
      state.selectedDate = selectedDate;
    },
    setCalendarDays: (state, { payload: rangeDays }) => {
      state.calendarDays = rangeDays;
    },
    setMenuPlanningCalendarDays: (state, { payload: rangeDays }) => {
      state.menuPlanningCalendarDays = rangeDays;
    },
    addMenuPlanningCalendarDays: (state, { payload }) => {
      const { direction, visibleDays } = payload;
      const isNext = direction === 'next';
      const day = isNext
        ? last(state.menuPlanningCalendarDays)
        : first(state.menuPlanningCalendarDays);

      const fullDate = new Date(day);
      const startDay = isNext
        ? subDays(fullDate, visibleDays * 2 - 1)
        : subDays(fullDate, visibleDays);
      const endDay = isNext
        ? addDays(fullDate, visibleDays)
        : addDays(fullDate, visibleDays * 2 - 1);

      const rangeDays = formatArray(
        eachDayOfInterval({
          start: startDay,
          end: endDay,
        })
      );

      state.menuPlanningCalendarDays = rangeDays;
    },

    resetTags: resetTagsReducer,
    toggleTag: toggleTagReducer,
    resetMyDiet: () => initialState,
  },
  extraReducers: {
    [updateDiet.fulfilled]: (state, { payload }) => {
      state.selectedDiet = { ...state.selectedDiet, ...payload };
    },
  },
});

export const selectMyDiet = state => state.myDiet;

export const {
  addMenuPlanningCalendarDays,
  resetTags,
  setSelectedDate,
  setCalendarDays,
  setDiet,
  setMenuPlanningCalendarDays,
  setMyDiet,
  toggleTag,
  resetMyDiet,
} = myDietSlice.actions;

export default myDietSlice.reducer;
