import React, { useState } from 'react';
import tw from 'twin.macro';

import { Collapse } from '@components';
import { useMobileDetect } from '@hooks';

const RecipeStepCard = ({
  step = '',
  title = '',
  pdfImage,
  description = '',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const toggleStep = () => {
    if (isMobile) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      tw="w-full rounded-2xl shadow-lg"
      css={[isExpanded || !isMobile ? tw`bg-primary` : tw`bg-white`]}
      onClick={toggleStep}
    >
      <div tw="flex">
        <p
          tw="mb-0 py-2 text-xl text-white px-7 font-bold bg-primary rounded-l-xl"
          style={{ minWidth: '65px' }}
        >
          {step}
        </p>
        <p
          css={[
            isExpanded || !isMobile
              ? tw`text-white bg-primary`
              : tw`bg-white text-primary`,
          ]}
          tw="mb-0 py-2 w-full text-lg pl-5 font-bold rounded-r-xl"
        >
          {title}
        </p>
      </div>
      <Collapse isExpanded={isExpanded || !isMobile}>
        <div
          tw="w-full bg-white rounded-xl shadow-lg"
          style={{ minHeight: '150px' }}
        >
          {pdfImage && (
            <div tw="bg-primary">
              <img tw="rounded-xl" src={pdfImage} alt="img" loading="lazy" />
            </div>
          )}
          <p tw="p-3">{description}</p>
        </div>
      </Collapse>
    </div>
  );
};

export default RecipeStepCard;
