import INVOICE_INPUT_IDS from './invoiceInputs';

const INPUT_IDS = {
  // basic information
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',

  // invoice data
  ...INVOICE_INPUT_IDS,

  // agreements
  MARKETING_AGREEMENT: 'marketingTerms',
  SERVICE_AGREEMENT: 'serviceTerms',
};

export default INPUT_IDS;
