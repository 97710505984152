import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const getCreateBasket = async () => {
  const response = await axios.post(ENDPOINT.BASKET_CREATE, {});

  return response?.data ?? {};
};

const useCreateBasket = ({
  basketKey = 'basketNewOrder',
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(getCreateBasket, {
    mutationKey: `${basketKey}Create`,
    onSuccess: response => {
      queryClient.setQueryData(basketKey, response);
    },
    onError: err => {
      const error = err?.response?.data;

      showToast(error, { type: 'error' });
    },
    retry: 3,
    ...options,
  });

  return query;
};

export default useCreateBasket;
