import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';
// import { useDispatch } from 'react-redux';
// import { actions as basketNewOrderActions } from '@store/basket/basketNewOrder.slice';
import isEmpty from 'lodash/isEmpty';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const basketClear = async ({ iri = null, id, payload = { onlyDays: [] } }) => {
  const url = isEmpty(iri) ? ENDPOINT.BASKET_CLEAR(id) : iri;
  const response = await axios.put(url, payload);

  return response?.data ?? {};
};

const useBasketClear = ({
  basketKey = 'basketNewOrder',
  options = {},
} = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(basketClear, {
    mutationKey: `${basketKey}Clear`,
    onSuccess: response => {
      queryClient.setQueryData(basketKey, () => response);

      showToast(
        t(
          '$*notification.basketClear.clear.success',
          '$$Koszyk został poprawnie wyczyszczony.'
        ),
        { type: 'success' }
      );

      options?.onSuccess();
    },
  });

  return query;
};

export default useBasketClear;
