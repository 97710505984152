import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getLanguages = async () => {
  const response = await axios.get(ENDPOINT.LANGUAGES, {
    withToken: false,
  });

  return response?.data?.['hydra:member'] ?? [];
};

const useLanguages = (options = {}) =>
  useQuery('languages', getLanguages, options);

export default useLanguages;
