import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import {
  ArrowToBottomIcon,
  EllipsisVIcon,
  EyeIcon,
  MonitorIcon,
  PrinterIcon,
} from '@assets/icons';
import { Button, Link, Popover } from '@components';
import ROUTE_URLS from '@constants/routeUrls';
import { useDishPdf } from '@hooks';
import {
  selectModuleConfigClientPanel,
  selectModuleShop,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import { useProductTileContext } from './ProductTile.context';

const ProductOptionsPopover = () => {
  const { t } = useTranslation();

  const { product, optionsPopoverScope } = useProductTileContext();
  const productId = product?.id || 0;
  const moduleSelector = {
    shop: selectModuleShop,
    configClientPanel: selectModuleConfigClientPanel,
  }[optionsPopoverScope];
  const {
    allowDownloadRecipe,
    allowPageRecipe,
    allowPrintRecipe,
    allowShowRecipe,
  } = useAppConfigSelector(moduleSelector);
  const [pdfMode, setPdfMode] = useState('download');

  const { refetch } = useDishPdf({
    dishId: productId,
    options: {
      onSuccess: data => {
        const blobPdf = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blobPdf);

        if (pdfMode === 'download') {
          const link = document.createElement('a');
          link.id = `dish_${productId}_pdf`;
          link.href = url;
          link.setAttribute('download', `${product.name}.pdf`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          const oWindow = window.open(url, 'print');
          oWindow.print();
          oWindow.close();
        }
      },
    },
  });

  const items = [
    {
      conditionalKey: allowPrintRecipe,
      Icon: PrinterIcon,
      label: t('$*components.productTile.printRecipe', '$$Wydrukuj przepis'),
      onClick: () => {
        setPdfMode('print');
        refetch();
      },
    },
    {
      conditionalKey: allowDownloadRecipe,
      Icon: ArrowToBottomIcon,
      label: t(
        '$*components.productTile.downloadRecipePDF',
        '$$Pobierz przepis w PDF'
      ),
      onClick: () => {
        setPdfMode('download');
        refetch();
      },
    },
    {
      conditionalKey: allowPageRecipe,
      Icon: EyeIcon,
      href: ROUTE_URLS.RECIPE_PAGE(productId),
      label: t('$*components.productTile.showRecipe', '$$Pokaż przepis'),
    },
    {
      conditionalKey: allowShowRecipe,
      Icon: MonitorIcon,
      href: ROUTE_URLS.DISH_PAGE(productId),
      label: t(
        '$*components.productTile.showRecipePage',
        '$$Pokaż stronę przepisu'
      ),
    },
  ];

  const filteredItems = items.filter(item => item.conditionalKey);
  const hasItems = !isEmpty(filteredItems);

  if (!hasItems) {
    return null;
  }

  return (
    <Popover
      placement="left"
      zIndex={2}
      content={
        <div
          tw="bg-white p-4 shadow-lg rounded-md text-sm "
          onClick={e => e.stopPropagation()}
        >
          <div tw="-my-2">
            {filteredItems.map(({ href, onClick = () => {}, Icon, label }) => {
              if (!isEmpty(href)) {
                return (
                  <div tw="py-2" key={uuidv4()}>
                    <Link href={href} tw="flex items-center">
                      <Icon tw="w-5 mr-4" />
                      {label}
                    </Link>
                  </div>
                );
              }

              return (
                <div tw="py-2" key={uuidv4()}>
                  <Button variant="link" onClick={onClick}>
                    <Icon tw="w-5 mr-4" />
                    {label}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      }
    >
      {({ visible, hide, show }) => (
        <button
          tw="focus-visible:(text-primary) hover:(text-primary) mt-3 w-6 flex justify-center"
          onClick={e => {
            e.stopPropagation();
            return visible ? hide() : show();
          }}
        >
          <EllipsisVIcon
            title={t(
              '$*components.productTile.showMoreOptions',
              '$$Pokaż więcej opcji'
            )}
            tw="w-1"
          />
        </button>
      )}
    </Popover>
  );
};

export default ProductOptionsPopover;
