import { useTranslation } from 'next-i18next';

import { Modal } from '@components';

import AddressFormWrapper from '../AddressForm/AddressFormWrapper';

const AddressModal = ({
  address,
  isAddAddress,
  isEditAddress,
  isFirstAddress,
  isNewAddress,
  isOpen,
  onClose,
  onSuccessAddAddress,
  onSuccessUpdateAddress,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        {isEditAddress &&
          t('$*components.addressesList.modal.title.edit', '$$Edycja adresu')}
        {isAddAddress &&
          t('$*components.addressesList.modal.title.add', '$$Dodaj nowy adres')}
      </Modal.Header>
      <Modal.Content>
        <AddressFormWrapper
          address={address}
          isFirstAddress={isFirstAddress}
          isNewAddress={isNewAddress}
          onSuccessAddAddress={onSuccessAddAddress}
          onSuccessUpdateAddress={onSuccessUpdateAddress}
        />
      </Modal.Content>
    </Modal>
  );
};

export default AddressModal;
