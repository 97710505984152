import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.service';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const createBasketAddon = async addon => {
  const response = await axios.post(ENDPOINT.BASKET_ADD_ADDON, addon, {
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useCreateBasketAddon = ({
  basketKey = 'basketNewOrder',
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(createBasketAddon, {
    mutationKey: `${basketKey}AddAddon`,
    onSuccess: response => {
      queryClient.setQueryData(basketKey, response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    ...options,
  });

  return query;
};

export default useCreateBasketAddon;
