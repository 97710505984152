import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Box, Seo } from '@components';
import { getChildByDisplayName } from '@utils/helpers';

const PageLayout = ({ children, pageName, title }) => {
  let ContentChildren = getChildByDisplayName(children, 'Content');
  let ContainerContentChildren = getChildByDisplayName(
    children,
    'ContainerContent'
  );

  return (
    <Fragment>
      <Seo pageName={pageName} />
      <h1 tw="mb-8 text-center">{title}</h1>
      <div className="container">
        {!isEmpty(ContentChildren) && <Box>{ContentChildren}</Box>}

        {ContainerContentChildren}
      </div>
    </Fragment>
  );
};

const Content = ({ children }) => <Fragment>{children}</Fragment>;
const ContainerContent = ({ children }) => <Fragment>{children}</Fragment>;

Content.displayName = 'Content';
ContainerContent.displayName = 'ContainerContent';

PageLayout.displayName = 'PageLayout';
PageLayout.Content = Content;
PageLayout.ContainerContent = ContainerContent;

export default PageLayout;
