import { Fragment } from 'react';

// NOTE: Sannan doesn't want "kcal" string after calorfic
const DietLineInfo = ({ dietType = '', variant = '', calorific = '' }) => {
  return (
    <Fragment>
      <div>{dietType}</div>
      <div>
        {`${variant}${variant && calorific ? ', ' : ''}${
          calorific ? `${calorific}` : ''
        }`}
      </div>
    </Fragment>
  );
};

export default DietLineInfo;
