import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';
import { v4 as uuidv4 } from 'uuid';

import { Button, PriceWrapper } from '@components';
import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import ROUTE_URLS from '@constants/routeUrls';
import { useBasketMethods } from '@hooks';
import { format } from '@services/Date.service';

const ShopConfigurator = ({ avaliableDate, dishIri, dishSizes = [] }) => {
  const { t } = useTranslation();

  const { basketDietsDays, handleChangeProductQuantity } = useBasketMethods();

  const currentBasketDayDish =
    basketDietsDays?.noDiet?.[format(avaliableDate)]?.dishes?.[dishIri];

  return (
    <div tw="mt-4">
      {dishSizes.map(({ name, kcal, dishSize }) => {
        let quantity = 0;
        let dishSizeInBasket = null;

        if (!isEmpty(currentBasketDayDish?.sizes)) {
          dishSizeInBasket = currentBasketDayDish?.sizes.find(
            ({ dishSize: basketDishSize }) => basketDishSize['@id'] === dishSize
          );

          quantity = dishSizeInBasket?.quantity ?? 0;
        }

        return (
          <div tw="flex justify-between -my-1.5 items-center" key={uuidv4()}>
            <div>{`${name}${
              kcal ? ` (${kcal} ${t('$*common.kcal', '$$kcal')})` : ''
            }`}</div>
            <div tw="flex -m-2 py-1.5 items-center">
              {!isEmpty(dishSizeInBasket) && (
                <PriceWrapper
                  afterDiscount={dishSizeInBasket?.price?.afterDiscount}
                  beforeDiscount={dishSizeInBasket?.price?.beforeDiscount}
                  styles={tw`p-2`}
                />
              )}
              <div tw="p-2">
                <CheckboxCounter
                  count={quantity}
                  min={0}
                  max={99}
                  onChange={(_, quantity) =>
                    handleChangeProductQuantity({
                      dishIri: dishSize,
                      quantity,
                      day: format(avaliableDate),
                      dietIri: 'noDiet',
                    })
                  }
                />
              </div>
            </div>
          </div>
        );
      })}
      <Link href={ROUTE_URLS.NEW_ORDER_FORM}>
        <Button
          fullWidth={true}
          styles={{
            button: [tw`mt-4`],
          }}
        >
          {t('$*dishPage.orderDish', '$$Zamów danie')}
        </Button>
      </Link>
    </div>
  );
};

export default ShopConfigurator;
