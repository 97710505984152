const SplashScreen = () => {
  return (
    <div tw="flex flex-col min-h-screen">
      <header id="header" tw="py-3 bg-white border-b border-grayPorcelain">
        <div className="container">
          <div className="row" tw="items-center justify-between animate-pulse">
            <div className="col">
              <div tw="flex items-center">
                <div tw="h-12 w-20 bg-gray-300 rounded mr-6"></div>
                <div tw="h-4 w-14 bg-gray-300 rounded"></div>
              </div>
            </div>
            <div className="col">
              <div tw="flex items-center">
                <div tw="flex -mx-3 items-center">
                  <div tw="h-4 w-20 bg-gray-300 rounded mx-3"></div>
                  <div tw="h-11 w-40 bg-gray-300 rounded mx-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <footer
        id="footer"
        tw="px-5 py-6 mt-auto bg-white border-t border-grayPorcelain"
      >
        <div className="container">
          <div
            className="row"
            tw="flex-col items-center md:justify-between md:flex-row animate-pulse"
          >
            <div className="col">
              <div tw="md:flex md:items-center">
                <div tw="h-8 w-10 bg-gray-300 rounded md:mr-6"></div>
                <div tw="mt-2 mb-3 flex md:mt-0 md:mb-0">
                  <div tw="h-4 w-4 bg-gray-300 rounded"></div>
                  <div tw="h-4 w-10 bg-gray-300 rounded ml-2"></div>
                </div>
              </div>
            </div>
            <div className="col">
              <div tw="md:-mx-5 -my-2">
                <ul tw="text-center md:flex">
                  <li tw="my-2 md:mx-5">
                    <div tw="h-4 w-10 bg-gray-300 rounded"></div>
                  </li>
                  <li tw="my-2 md:mx-5">
                    <div tw="h-4 w-16 bg-gray-300 rounded"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

SplashScreen.displayName = 'SplashScreen';

export default SplashScreen;
