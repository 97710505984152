// import Swiper core and required modules
import { Fragment, useEffect, useRef } from 'react';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ChevronUpIcon } from '@assets/icons';

import 'swiper/css';

import styles from './Carousel.styles';

// install Swiper modules
SwiperCore.use([A11y, Keyboard, Navigation]);

const Carousel = ({
  children,
  options = {},
  navigation = {},
  swiperRef = useRef(null),
  isDisabledCustomNavigation = false,
  navigationPrevRef = useRef(null),
  navigationNextRef = useRef(null),
  ...restProps
}) => {
  const swiperOptions = {
    spaceBetween: 50,
    slidesPerView: 3,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    ...options,
  };

  useEffect(() => {
    if (
      swiperRef?.current?.swiper?.params?.navigation?.prevEl === null ||
      swiperRef?.current?.swiper?.params?.navigation?.nextEl === null
    ) {
      // Override prevEl & nextEl now that refs are defined
      swiperRef.current.swiper.params.navigation.prevEl =
        navigationPrevRef.current;
      swiperRef.current.swiper.params.navigation.nextEl =
        navigationNextRef.current;

      // Re-init navigation
      swiperRef.current.swiper.navigation.destroy();
      swiperRef.current.swiper.navigation.init();
      swiperRef.current.swiper.navigation.update();
    }
  }, [navigationNextRef]);

  return (
    <div tw="relative">
      <Swiper
        {...(swiperRef ? { ref: swiperRef } : {})}
        {...restProps}
        {...swiperOptions}
      >
        {children(SwiperSlide)}
      </Swiper>
      {!isDisabledCustomNavigation && (
        <Fragment>
          <div
            ref={navigationPrevRef}
            role="button"
            css={styles.navigation({ prev: true })}
            {...navigation?.prev}
          >
            <ChevronUpIcon css={styles.navigationIcon} />
          </div>
          <div
            ref={navigationNextRef}
            role="button"
            css={styles.navigation({ next: true })}
            {...navigation?.next}
          >
            <ChevronUpIcon css={styles.navigationIcon} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

Carousel.displayName = 'Carousel';

export default Carousel;
