const INPUT_IDS = {
  COMPANY_NAME: 'invoiceCompany',
  NIP: 'invoiceVatNumber',
  ADDRESS_CITY: 'invoiceAddressCity',
  ADDRESS_STREET: 'invoiceAddressStreet',
  ADDRESS_POST_CODE: 'invoiceAddressPostCode',
  ADDRESS_BUILD_NUMBER: 'invoiceAddressBuildNumber',
  ADDRESS_PLACE_NUMBER: 'invoiceAddressPlaceNumber',
  ADDITIONAL_NOTES: 'invoiceComments',
  TYPE: 'invoiceType',
};

export default INPUT_IDS;
