import { useTranslation } from 'next-i18next';

import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, InputsIndex, Modal } from '@components';
import { useBasketMethods, useMediaQuery, usePaypoLastAddress } from '@hooks';
import { down } from '@utils/screens';

import { getInitialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const PayPoModal = ({ isOpen, closeModal, onClickConfirm }) => {
  const { t } = useTranslation();
  const isDown500 = useMediaQuery(down('500px'), true);
  const {
    basketQuery: { data: basket = {} },
  } = useBasketMethods();
  const paymentMetadata = basket?.paymentMetadata ?? {};
  const fields = useFields();
  const validationSchema = useValidationSchema();
  const { data: paypoLastAddress = {} } = usePaypoLastAddress();

  const paypoAddress = !isEmpty(paymentMetadata)
    ? paymentMetadata
    : paypoLastAddress;
  const paypoInitialValues = getInitialValues(paypoAddress);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      styles={{ wrapper: tw`w-full max-w-xl` }}
      size="sm"
    >
      <Modal.Header>
        {t(
          '$*components.paymentTypes.paypo.modal.title',
          '$$Dane rozliczeniowe PayPo'
        )}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={paypoInitialValues}
          validationSchema={validationSchema}
          onSubmit={onClickConfirm}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                {fields.map(
                  ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
                    return (
                      <div className={colClass} key={id} css={colCss}>
                        <InputsIndex id={id} {...restProps} />
                      </div>
                    );
                  }
                )}
              </div>

              <div tw="pt-6 flex flex-wrap justify-between -mx-2 -my-1">
                <Button
                  type="button"
                  color="error"
                  variant="outlined"
                  styles={{
                    button: [
                      tw`mx-2 my-1`,
                      isDown500 && tw`justify-center flex-auto`,
                    ],
                  }}
                  onClick={closeModal}
                >
                  {t('$*common.cancel', '$$Anuluj')}
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  styles={{
                    button: [
                      tw`mx-2 my-1`,
                      isDown500 && tw`justify-center flex-auto`,
                    ],
                  }}
                >
                  {t('$*common.confirm', '$$Potwierdź')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

PayPoModal.displayName = 'PayPoModal';

export default PayPoModal;
