import { createSlice } from '@reduxjs/toolkit';
import findLastIndex from 'lodash/findLastIndex';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isSetInititalState: false,
  isAnimating: false,
  currentStepIndex: 0,
  currentStepSlug: null,
  isNextDisabled: true,
  isPrevDisabled: false,
  stepsCount: 0,
  steps: [], // { label: '', slug: '', isValid: false }
};

const changeTab = (state, stepIndex) => {
  state.currentStepIndex = stepIndex;
  state.currentStepSlug = state.steps[stepIndex]?.slug;
  state.isNextDisabled = !state.steps[stepIndex]?.isValid ?? true;
  state.isAnimating = true;
};

const orderTabsSlice = createSlice({
  name: 'orderTabs',
  initialState,
  reducers: {
    setIsCurrentValid: (state, { payload = true }) => {
      state.steps[state.currentStepIndex].isValid = payload;
      state.isNextDisabled = !payload;
    },
    setTabsInitialState: (state, { payload: steps }) => {
      state.isSetInititalState = true;
      state.currentStepIndex = 0;
      state.currentStepSlug = steps[0].slug;
      state.stepsCount = steps.length;
      state.steps = steps;
    },
    updateSteps: (state, { payload: tabs }) => {
      const newSteps = tabs.map(tab => {
        const stateStep = state.steps.find(step => step.id === tab.id);
        if (!isEmpty(stateStep)) {
          return {
            isValid: false,
            ...stateStep,
            label: tab.label,
            slug: tab.slug,
          };
        } else {
          return { isValid: false, ...tab };
        }
      });

      state.currentStepSlug = newSteps[state.currentStepIndex].slug;
      state.stepsCount = newSteps.length;
      state.steps = newSteps;
    },
    changeStepAndResetValid: (state, { payload: stepIndex }) => {
      const steps = state.steps;
      const stepsBeforeStepIndex = steps.slice(0, stepIndex);
      const lastValidStepIndex = findLastIndex(stepsBeforeStepIndex, [
        'isValid',
        true,
      ]);
      const correctLastValidStepIndex =
        lastValidStepIndex >= 0 ? lastValidStepIndex : 0;

      const changeTabToIndex =
        lastValidStepIndex === stepIndex - 1
          ? stepIndex
          : correctLastValidStepIndex;

      changeTab(state, changeTabToIndex);

      const validSteps = steps.slice(0, correctLastValidStepIndex + 1);
      const invalidSteps = steps
        .slice(correctLastValidStepIndex + 1, steps.length)
        .map(step => ({ ...step, isValid: false }));

      state.steps = [...validSteps, ...invalidSteps];
    },
    changeStep: (state, { payload }) => changeTab(state, payload),
    prevStep: state => changeTab(state, state.currentStepIndex - 1),
    nextStep: state => changeTab(state, state.currentStepIndex + 1),
    setIsAnimating: (state, { payload }) => {
      state.isAnimating = payload;
    },
    resetOrderTabs: () => initialState,
  },
});

// selectors
export const selectTabs = state => state.orderTabs;
export const selectIsSetInititalState = state =>
  state.orderTabs.isSetInititalState;
export const selectIsNextDisabled = state => state.orderTabs.isNextDisabled;

export const {
  setIsCurrentValid,
  setTabsInitialState,
  changeStepAndResetValid,
  changeStep,
  prevStep,
  nextStep,
  updateSteps,
  setIsAnimating,
  resetOrderTabs,
} = orderTabsSlice.actions;

export default orderTabsSlice.reducer;
