import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { CancelToken } from 'axios';

import axios from '@utils/http';

const ITEMS_PER_PAGE = 5;

const useInfiniteQueryMiddleware = (name, endpoint, params = {}) => {
  const [isInit, setIsInit] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = async ({ pageParam = 1 }) => {
    const source = CancelToken.source();
    const promise = await axios.get(endpoint, {
      cancelToken: source.token,
      params: {
        'order[id]': 'DESC',
        partial: false,
        itemsPerPage: ITEMS_PER_PAGE,
        page: pageParam ? pageParam : 1,
        ...params,
      },
    });

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    const totalItems = promise.data['hydra:totalItems'];
    const pageCount =
      totalItems < ITEMS_PER_PAGE ? 1 : Math.ceil(totalItems / ITEMS_PER_PAGE);

    setIsInit(false);
    setTotalItems(totalItems);

    return {
      data: promise.data['hydra:member'],
      prevId: pageParam - 1 >= 0 ? pageParam - 1 : null,
      nextId: pageParam ? (pageParam < pageCount ? pageParam + 1 : null) : 2,
    };
  };

  const { data, ...restQuery } = useInfiniteQuery(name, fetchData, {
    getPreviousPageParam: firstPage => firstPage.prevId ?? false,
    getNextPageParam: lastPage => lastPage.nextId ?? false,
  });

  const items =
    data?.pages?.reduce((acc, { data }) => {
      return [...acc, ...data];
    }, []) ?? [];

  return {
    isInit,
    totalItems,
    items,
    ...restQuery,
  };
};

export default useInfiniteQueryMiddleware;
