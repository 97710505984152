import DisplayOn from './DisplayOn';

const DisplayUpMd = ({ children }) => (
  <DisplayOn size="md" mode="up" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayUpMd.displayName = 'DisplayUpMd';

export default DisplayUpMd;
