import { useTranslation } from 'next-i18next';

import { useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, Collapse } from '@components';

const ShortDietConfigurator = ({
  diets = [],
  currentConfig = {},
  setCurrentConfig = () => {},
}) => {
  const { t } = useTranslation();
  const variantsRef = useRef();
  const calorificsRef = useRef();

  const [areDietsExpanded, setAreDietsExpanded] = useState(true);
  const [areVariantsExpanded, setAreVariantsExpanded] = useState(false);
  const [areCalorificsExpanded, setAreCalorificsExpanded] = useState(false);

  const variants =
    diets.find(diet => diet['@id'] === currentConfig?.diet)?.variants ?? [];

  const calorifics = !isEmpty(variants)
    ? variants.find(variant => variant['@id'] === currentConfig?.variant)
        ?.calories ?? []
    : [];

  const isCurrentVariantAvaliable = variants => {
    if (isEmpty(variants)) return false;

    return !!variants.find(
      variant => variant?.['@id'] === currentConfig?.variant
    );
  };

  const isCurrentCalorificAvaliable = calorifics => {
    if (isEmpty(calorifics)) return false;

    return !!calorifics.find(
      calorific => calorific?.['@id'] === currentConfig?.calorific
    );
  };

  const scrollToRef = ref => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const currentDiet = diets.find(
    ({ '@id': iri }) => iri === currentConfig?.diet
  );
  const currentVariant = variants.find(
    ({ '@id': iri }) => iri === currentConfig?.variant
  );
  const currentCalorific = calorifics.find(
    ({ '@id': iri }) => iri === currentConfig?.calorific
  );

  return (
    <div tw="-mx-2">
      {/* DIETS */}
      <div tw="w-full mb-5">
        <motion.header
          initial={false}
          onClick={() => setAreDietsExpanded(!areDietsExpanded)}
          tw="px-2 py-2 text-sm bg-gray-100 rounded-md cursor-pointer"
        >
          <div tw="flex justify-between">
            <div>
              <p tw="flex text-lg font-bold">
                {t(
                  '$*newOrderCreatePage.orderForm.diets.selectDiet.title',
                  '$$Jaką dietę preferujesz?'
                )}
              </p>
              <p tw="text-base">{`${t(
                '$common.selectedOption',
                'Wybrana opcja'
              )}: ${currentDiet?.name ?? ''}`}</p>
            </div>
            <div>
              <p tw="flex text-lg font-bold"></p>
            </div>

            <div>
              <FontAwesomeIcon
                icon={areDietsExpanded ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
        </motion.header>
        <Collapse isExpanded={areDietsExpanded} styles={tw`overflow-hidden`}>
          <div tw="flex flex-wrap w-full mt-3">
            {diets.map(diet => (
              <div key={diet['@id']} tw="w-full sm:w-1/2 py-2 sm:p-2">
                <Button
                  type="button"
                  color="primary"
                  fullWidth={true}
                  variant="outlined"
                  styles={{
                    button: [
                      tw`flex-auto border-2 text-xs shadow hover:(bg-white text-primary)`,
                      currentConfig?.diet !== diet['@id'] &&
                        tw`border-transparent`,
                      currentConfig?.diet === diet['@id'] && tw`font-bold`,
                    ],
                  }}
                  onClick={() => {
                    setCurrentConfig({
                      ...currentConfig,
                      diet: diet['@id'],
                      variant: isCurrentVariantAvaliable(diet?.variants)
                        ? currentConfig.variant
                        : null,
                    });
                    setAreDietsExpanded(false);
                    setAreVariantsExpanded(true);
                    setAreCalorificsExpanded(false);
                    setTimeout(() => scrollToRef(variantsRef), 500);
                  }}
                >
                  {diet?.name}
                </Button>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
      {/* VARIANTS */}
      <div tw="w-full mb-5">
        <motion.header
          initial={false}
          onClick={() => setAreVariantsExpanded(!areVariantsExpanded)}
          tw="px-2 py-2 text-sm bg-gray-100 rounded-md cursor-pointer"
        >
          <div tw="flex justify-between" ref={variantsRef}>
            <div>
              <p tw="flex text-lg font-bold">
                {t(
                  '$*newOrderCreatePage.orderForm.diets.selectVariant.title',
                  '$$Wariant'
                )}
              </p>
              <p tw="text-base">{`${t(
                '$common.selectedOption',
                'Wybrana opcja'
              )}: ${currentVariant?.name ?? ''}`}</p>
            </div>

            <div>
              <FontAwesomeIcon
                icon={areDietsExpanded ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
        </motion.header>
        <Collapse isExpanded={areVariantsExpanded} styles={tw`overflow-hidden`}>
          <div tw="flex flex-wrap w-full mt-3">
            {variants.map(variant => (
              <div key={variant['@id']} tw="w-full sm:w-1/2 py-2 sm:p-2">
                <Button
                  type="button"
                  color="primary"
                  fullWidth={true}
                  variant="outlined"
                  styles={{
                    button: [
                      tw`flex-auto border-2 text-xs shadow hover:(bg-white text-primary)`,
                      currentConfig?.variant !== variant['@id'] &&
                        tw`border-transparent`,
                      currentConfig?.variant === variant['@id'] &&
                        tw`font-bold`,
                    ],
                  }}
                  onClick={() => {
                    setCurrentConfig({
                      ...currentConfig,
                      variant: variant['@id'],
                      calorific: isCurrentCalorificAvaliable(variant?.calories)
                        ? currentConfig.calorific
                        : null,
                    });
                    setAreDietsExpanded(false);
                    setAreVariantsExpanded(false);
                    setAreCalorificsExpanded(true);
                    setTimeout(() => scrollToRef(calorificsRef), 500);
                  }}
                >
                  {variant?.name}
                </Button>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
      {/* CALORIFICS */}
      <div tw="w-full mb-5" ref={calorificsRef}>
        <motion.header
          initial={false}
          onClick={() => setAreCalorificsExpanded(!areCalorificsExpanded)}
          tw="px-2 py-2 text-sm bg-gray-100 rounded-md cursor-pointer"
        >
          <div tw="flex justify-between">
            <div>
              <p tw="flex text-lg font-bold">
                {t(
                  '$*newOrderCreatePage.orderForm.diets.selectCalorific.title',
                  '$$Kaloryczność'
                )}
              </p>
              <p tw="text-base">{`${t(
                '$common.selectedOption',
                'Wybrana opcja'
              )}: ${currentCalorific?.name ?? ''}`}</p>
            </div>

            <div>
              <FontAwesomeIcon
                icon={areCalorificsExpanded ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
        </motion.header>
        <Collapse
          isExpanded={areCalorificsExpanded}
          styles={tw`overflow-hidden `}
        >
          <div tw="flex flex-wrap w-full mt-3">
            {calorifics.map(calorific => (
              <div key={calorific['@id']} tw="w-full sm:w-1/2 py-2 sm:p-2">
                <Button
                  type="button"
                  color="primary"
                  fullWidth={true}
                  variant="outlined"
                  styles={{
                    button: [
                      tw`flex-auto border-2 text-xs shadow hover:(bg-white text-primary) `,
                      currentConfig?.calorific !== calorific['@id'] &&
                        tw`border-transparent`,
                      currentConfig?.calorific === calorific['@id'] &&
                        tw`font-bold`,
                    ],
                  }}
                  onClick={() => {
                    setCurrentConfig({
                      ...currentConfig,
                      calorific: calorific['@id'],
                    });
                    setAreDietsExpanded(false);
                    setAreVariantsExpanded(false);
                    setAreCalorificsExpanded(false);
                  }}
                >
                  {calorific?.name}
                </Button>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ShortDietConfigurator;
