import { forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

const Tab = forwardRef(
  (
    {
      disabled = false,
      activeTabIndex,
      index,
      label,
      onClick,
      'data-cy': dataCy = '',
    },
    ref
  ) => {
    const twStyle = [
      tw`flex items-center flex-auto px-2 py-1 text-lg font-semibold cursor-pointer`,
      disabled && tw`text-gray-300 cursor-default`,
      activeTabIndex === index && tw`text-primary`,
    ];

    const liProps = {
      css: twStyle,
      ref: ref,
      onClick: () => !disabled && onClick(index),
      role: 'presentation',
      ...(!isEmpty(dataCy) ? { 'data-cy': dataCy } : {}),
    };

    return <li {...liProps}>{label}</li>;
  }
);

Tab.displayName = 'Tab';

export default Tab;
