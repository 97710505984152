const INPUT_IDS = {
  ADDRESS_POST_CODE: 'addressPostCode',
  ADDRESS_CITY: 'addressCity',
  ADDRESS_STREET: 'addressStreet',
  ADDRESS_BUILD_NUMBER: 'addressBuildNumber',
  ADDRESS_PLACE_NUMBER: 'addressPlaceNumber',

  PAYPO_ADDRESS_POST_CODE: 'paypoAddressPostCode',
  PAYPO_ADDRESS_CITY: 'paypoAddressCity',
  PAYPO_ADDRESS_STREET: 'paypoAddressStreet',
  PAYPO_ADDRESS_BUILD_NUMBER: 'paypoAddressBuildNumber',
  PAYPO_ADDRESS_PLACE_NUMBER: 'paypoAddressPlaceNumber',
};

export default INPUT_IDS;
