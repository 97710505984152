import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';

const Translation = ({ text = '', defaultValue = '' }) => {
  const { t } = useTranslation();

  // i18next-extract-disable-next-line
  return <Fragment>{t(text, defaultValue)}</Fragment>;
};

export default Translation;
