import { Trans, useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Link } from '@components';
import {
  selectModuleStripe,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import { INPUT_IDS } from './consts';

const useFields = isSubscriptionInCart => {
  const { t } = useTranslation();
  const { rulesLink } = useAppConfigSelector(selectModuleStripe);

  const formFields = useMemo(() => {
    const fields = [
      {
        id: INPUT_IDS.PAYU_CARD,
        type: 'custom',
        label: t('$*input.stripeCard.label', '$$Dane karty'),
        Component: (
          <section
            tw="text-center block box-border"
            style={{ borderRadius: '5px' }}
          >
            <div
              tw="w-full block box-border bg-black text-left"
              style={{ padding: '2px', borderRadius: '6px', margin: '0 auto' }}
            >
              <div
                tw="bg-white"
                style={{ borderRadius: '4px', padding: '15px 10px' }}
                className="payu-card-form"
                id="payu-card"
              ></div>
            </div>
            <div id="responseTokenize"></div>
          </section>
        ),
        required: true,
      },
      ...(isSubscriptionInCart
        ? [
            {
              id: INPUT_IDS.TERMS,
              type: 'checkbox',
              label: (
                <Trans
                  i18nKey="$*input.subscriptionTerms.label"
                  defaults="$$Akceptuję <0>regulamin płatności cyklicznych</0>"
                  components={[
                    ...(!isEmpty(rulesLink)
                      ? [
                          <Link
                            key="0"
                            href={rulesLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            tw="font-semibold"
                          />,
                        ]
                      : [<span key="0" />]),
                  ]}
                />
              ),
              required: true,
            },
          ]
        : []),
    ];

    return fields;
  }, [t, isSubscriptionInCart]);

  return formFields;
};

export default useFields;
