import { Fragment } from 'react';

import { DisplayDownMd, DisplayUpMd } from '@components';

import PurchasedAddonTileDesktop from './PurchasedAddonTileDesktop';
import PurchasedAddonTileMobile from './PurchasedAddonTileMobile';

const PurchasedAddonTile = props => {
  return (
    <Fragment>
      <DisplayUpMd>
        <PurchasedAddonTileDesktop {...props} />
      </DisplayUpMd>
      <DisplayDownMd>
        <PurchasedAddonTileMobile {...props} />
      </DisplayDownMd>
    </Fragment>
  );
};

export default PurchasedAddonTile;
