import { css } from '@emotion/react';
import tw from 'twin.macro';

import { useMenuDishTileContext } from './MenuDishTile.context';

const MenuDishWrapper = ({ children, onClick = () => {} }) => {
  const { dishIsSelected, showDishImage } = useMenuDishTileContext();

  return (
    <div
      css={[
        tw`rounded-lg relative border-2 transition-colors duration-300 cursor-pointer hover:(border-primary duration-300)`,
        dishIsSelected && tw`border-2 border-current`,
        showDishImage &&
          css`
            min-height: 340px;
          `,
      ]}
      onClick={onClick}
      data-cy="dish-tile__wrapper"
    >
      {children}
    </div>
  );
};

export default MenuDishWrapper;
