import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';

import { ExclamationIcon } from '@assets/icons';
import { Tooltip } from '@components';
import INPUT_IDS from '@constants/inputs/invoiceInputs';

const useFields = integrationType => {
  const { t } = useTranslation();

  const formFields = useMemo(() => {
    const fields = [
      {
        id: INPUT_IDS.COMPANY_NAME,
        type: 'text',
        label: t('$*input.invoiceCompany.label', '$$Nazwa firmy'),
        required: true,
      },
      {
        id: INPUT_IDS.NIP,
        type: 'text',
        label: t('$*input.invoiceVatNumber.label', '$$NIP'),
        required: true,
      },
      {
        colClass: 'sm:col-5',
        id: INPUT_IDS.ADDRESS_POST_CODE,
        type: 'text',
        label: t('$*input.invoiceAddressPostCode.label', '$$Kod pocztowy'),
        required: false,
      },
      {
        colClass: 'sm:col-7',
        id: INPUT_IDS.ADDRESS_CITY,
        type: 'text',
        label: t('$*input.invoiceAddressCity.label', '$$Miasto'),
        required: false,
      },
      {
        id: INPUT_IDS.ADDRESS_STREET,
        type: 'text',
        label: t('$*input.invoiceAddressStreet.label', '$$Ulica'),
        required: false,
      },
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.ADDRESS_BUILD_NUMBER,
        type: 'text',
        label: t('$*input.invoiceAddressBuildNumber.label', '$$Nr budynku'),
        required: false,
      },
      {
        colClass: 'sm:col-6',
        id: INPUT_IDS.ADDRESS_PLACE_NUMBER,
        type: 'text',
        label: t('$*input.invoiceAddressPlaceNumber.label', '$$Nr lokalu'),
        required: false,
      },
      {
        id: INPUT_IDS.TYPE,
        type: 'toggle',
        label: (
          <div tw="flex items-center">
            {t(
              '$*input.invoiceType.label',
              '$$Użyj danych z formularza jako domyślnych'
            )}

            <Tooltip
              content={
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      '$*input.invoiceType.tooltip.content',
                      '$$Dane z formularza zostaną zapisane jako <b>"Dane do faktury"</b> w <b>"Ustawieniach konta"</b>.<br/> Zaznacz to pole jeśli chcesz zapisać bieżące dane.'
                    ),
                  }}
                />
              }
            >
              <div tw="ml-3">
                <ExclamationIcon tw="w-4 cursor-pointer" />
              </div>
            </Tooltip>
          </div>
        ),

        required: false,
      },
    ];

    return fields;
  }, [t]);

  switch (integrationType) {
    case 'RECEIPT':
      return formFields.filter(({ id }) => id === INPUT_IDS.NIP);
    case 'INVOICE':
      return formFields;
    default:
      return [];
  }
};

export default useFields;
