import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const createUserAddress = async address => {
  const response = await axios.post(ENDPOINT.CREATE_ADDRESS, address);

  return response?.data ?? {};
};

const useCreateUserAddress = (options = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(createUserAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries('userAddresses');

      showToast(
        t(
          '$*notification.createUserAddress.success',
          '$$Adres został pomyślnie dodany.'
        ),
        { type: 'success' }
      );
    },
    ...options,
  });

  return query;
};

export default useCreateUserAddress;
