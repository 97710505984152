// synchronous store + initial store
const initQueryStore = queryClient => {
  queryClient.setQueryData('stripeKeys', {
    secret: null,
    key: null,
  });

  queryClient.setQueryData('paymentCards', []);
  queryClient.setQueryData('order', {});
};

export default initQueryStore;
