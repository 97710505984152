import { css } from '@emotion/react';
import tw from 'twin.macro';

import { useMediaQuery } from '@hooks';
import { up } from '@utils/screens';

const DietTileWrapper = ({
  children,
  isSelected,
  showImage,
  customStyles = [],
}) => {
  const isUpMd = useMediaQuery(up('md'), true);

  return (
    <div
      css={[
        tw`rounded-xl shadow-md border-2 border-transparent transition-colors duration-300 cursor-pointer hover:(border-primary duration-300)`,
        isSelected && tw`border-primary`,
        showImage && isUpMd
          ? css`
              min-height: 370px;
            `
          : '',
        ...customStyles,
      ]}
    >
      {children}
    </div>
  );
};

export default DietTileWrapper;
