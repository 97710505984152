const ArrowRightIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1772 1520"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M1729.89 801.586a59.058 59.058 0 00.003-83.517c-117.925-117.928-455.699-455.71-622.504-622.514a134.87 134.87 0 00-95.5-39.502 134.847 134.847 0 00-95.415 39.695c-52.791 53.01-52.742 138.734.105 191.68 127.226 127.458 336.314 336.936 336.314 336.936H135.42A135.418 135.418 0 000 759.785a135.418 135.418 0 00135.42 135.42h1121.612l-340.882 339.88a133.8 133.8 0 00-39.322 94.863 133.78 133.78 0 0039.494 94.785c0 .004.004.004.004.004 52.791 52.443 138.058 52.305 190.676-.31 166.678-166.664 504.871-504.836 622.887-622.841z"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowRightIcon;
