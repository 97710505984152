import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';

import INPUT_IDS from '@constants/inputs/userDataInputs';

const useFields = () => {
  const { t } = useTranslation();

  const formFields = useMemo(() => {
    const fields = [
      {
        id: INPUT_IDS.FIRST_NAME,
        type: 'text',
        label: t('$*input.firstName.label', '$$Imię'),
        required: true,
      },
      {
        id: INPUT_IDS.LAST_NAME,
        type: 'text',
        label: t('$*input.lastName.label', '$$Nazwisko'),
        required: true,
      },
    ];

    return fields;
  }, [t]);

  return formFields;
};

export default useFields;
