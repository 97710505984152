import dynamic from 'next/dynamic';

import {
  selectAuthMethods,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import { ButtonWrapper } from './ExternalAuth.styles';

const AppleAuth = dynamic(() => import('./AppleAuth'));
const FacebookAuth = dynamic(() => import('./FacebookAuth'));
const GoogleAuth = dynamic(() => import('./GoogleAuth'));

const ExternalAuth = ({ onAfterLogin = () => {} }) => {
  const { apple, facebook, google } = useAppConfigSelector(selectAuthMethods);
  const enabledAuthMethodsCount = [
    apple?.enabled,
    facebook?.enabled,
    google?.enabled,
  ].filter(Boolean).length;

  if (!enabledAuthMethodsCount) {
    return null;
  }

  return (
    <div tw="-mx-1 -my-2 flex flex-wrap flex-col items-center justify-center sm:flex-row">
      {apple?.enabled && (
        <ButtonWrapper enabledCount={enabledAuthMethodsCount}>
          <AppleAuth
            clientId={apple?.parameters?.clientId}
            enabled={apple?.enabled}
            onAfterLogin={onAfterLogin}
          />
        </ButtonWrapper>
      )}
      {facebook?.enabled && (
        <ButtonWrapper enabledCount={enabledAuthMethodsCount}>
          <FacebookAuth
            appId={facebook?.parameters?.appId}
            enabled={facebook?.enabled}
            onAfterLogin={onAfterLogin}
          />
        </ButtonWrapper>
      )}
      {google?.enabled && (
        <ButtonWrapper enabledCount={enabledAuthMethodsCount}>
          <GoogleAuth
            clientId={google?.parameters?.clientId}
            enabled={google?.enabled}
            onAfterLogin={onAfterLogin}
          />
        </ButtonWrapper>
      )}
    </div>
  );
};

ExternalAuth.displayName = 'ExternalAuth';

export default ExternalAuth;
