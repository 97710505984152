import { MenuDishTileProvider } from './MenuDishTile.context';
import MenuDishTileView from './MenuDishTile.view';

const MenuDishTile = props => {
  return (
    <MenuDishTileProvider {...props}>
      <MenuDishTileView />
    </MenuDishTileProvider>
  );
};

export default MenuDishTile;
