import React from 'react';
import { AnimateSharedLayout, motion } from 'framer-motion';
import tw, { css } from 'twin.macro';

const labelStyles = css`
  margin: 0 2px;
  transition: all 0.15s ease-in-out;
  user-select: none;
`;

const inputStyles = css`
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  &:checked + label {
    color: black;
  }
`;

const backgroundStyles = css`
  position: absolute;
  top: 3px;
  left: 1px;
  right: 1px;
  bottom: 3px;
  border-radius: 6px;
  z-index: -1;
`;

const Switch = props => {
  const { options, onChange, label } = props;
  const [isSelected, setSelected] = React.useState(
    options.find(o => o.default)?.value || options[0].value
  );

  function onChangeRadio(e) {
    setSelected(e.target.value);
    onChange(e);
  }

  return (
    <motion.div
      tw="relative overflow-y-hidden flex w-full border border-gray-300 h-12 items-center rounded-md justify-start"
      id={props.labelId}
      onChange={onChangeRadio}
    >
      <AnimateSharedLayout>
        {options.map(option => (
          <React.Fragment key={option.label}>
            <motion.input
              type="radio"
              tw="absolute border-none overflow-y-hidden"
              css={inputStyles}
              id={option.value}
              value={option.value}
              disabled={option.disabled}
              name={label}
              defaultChecked={option.default}
              checked={isSelected === option.value}
            />
            <motion.label
              tw="relative w-full h-full inline-flex items-center justify-center border-gray-300 px-4 text-gray-400 text-center rounded cursor-pointer"
              css={[
                labelStyles,
                option.disabled && tw`text-gray-200 cursor-not-allowed`,
              ]}
              htmlFor={option.value}
              initial={false}
              animate={{
                visibility: 'visible',
              }}
            >
              {isSelected === option.value && (
                <motion.div
                  layoutId="radioBackground"
                  css={backgroundStyles}
                  animate={{
                    backgroundColor: '#eee',
                  }}
                />
              )}
              {option.label}
            </motion.label>
          </React.Fragment>
        ))}
      </AnimateSharedLayout>
    </motion.div>
  );
};

export default Switch;
