import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { Button, Modal } from '@components';

const DecisionModal = ({
  onClose = () => {},
  isOpened = false,
  children,
  modalStyles = {},
  modalHeaderText = '',
  button1Text = '',
  button2Text = '',
  button1OnClick = () => {},
  button2OnClick = () => {},
}) => {
  return (
    <Modal
      isOpen={isOpened}
      onClose={onClose}
      styles={
        isEmpty(modalStyles) ? { wrapper: tw`w-full max-w-xs` } : modalStyles
      }
      size="sm"
    >
      <Modal.Header>{modalHeaderText}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Button color="default" size="sm" onClick={button1OnClick}>
          {button1Text}
        </Button>
        <Button color="success" size="sm" onClick={button2OnClick}>
          {button2Text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DecisionModal;
