import { createContext, useContext, useEffect, useState } from 'react';

import { useToggle } from '@hooks';
import {
  selectModuleShop,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { checkImageExists } from '@utils/helpers';

const PurchasedProductTileContext = createContext([{}, () => {}]);

const PurchasedProductTileProvider = ({
  children,
  optionsPopoverScope,
  selectedDietIri,
  selectedDate,
  dishSizesQuantity,
  isSelected,
  onChangeQuantity = () => {},
  product = {},
}) => {
  const { showImage } = useAppConfigSelector(selectModuleShop);
  const [isOpenMenu, toggleOpenMenu] = useToggle(false);
  const [hasChoosedDishSize, setHasChoosedDishSize] = useState(false);

  const [isExistImage, setIsExistImage] = useState(false);

  useEffect(() => {
    checkImageExists(product.dish.image)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [product.image]);

  const value = {
    dishDate: selectedDate,
    dishSizesQuantity,
    handleChangeQuantity: onChangeQuantity,
    hasChoosedDishSize,
    isExistImage,
    isOpenMenu,
    product,
    productIsSelected: isSelected,
    setHasChoosedDishSize,
    showImage,
    toggleOpenMenu,
    optionsPopoverScope,
    selectedDietIri,
  };

  return (
    <PurchasedProductTileContext.Provider value={value}>
      {children}
    </PurchasedProductTileContext.Provider>
  );
};

const usePurchasedProductTileContext = () =>
  useContext(PurchasedProductTileContext);

export { PurchasedProductTileProvider, usePurchasedProductTileContext };
