import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { useMediaQuery } from '@hooks';
import { up } from '@utils/screens';

import { useProductTileContext } from './ProductTile.context';

const ProductImage = ({ showImage = false, imageUrl = null }) => {
  const isUpMd = useMediaQuery(up('md'), true);
  const { isOpenMenu } = useProductTileContext();

  if (!showImage) {
    return null;
  }

  return (
    <motion.div
      initial="collapsed"
      animate={isOpenMenu ? 'open' : 'collapsed'}
      exit="collapsed"
      variants={{
        open: {
          width: '100%',
          height: '220px',
          borderRadius: '6px 6px 0 0',
          transition: {
            duration: 0.2,
          },
        },
        collapsed: {
          width: '96px',
          height: '96px',
          transition: { delay: 0.2, duration: 0.2 },
        },
      }}
      css={[
        css`
          background: url(${imageUrl}) no-repeat center center / cover;
        `,
        isUpMd
          ? css`
              height: 270px;
            `
          : '',
        tw`md:(w-full rounded-lg)`,
        tw`order-2 rounded-tr-md rounded-br-md`,
      ]}
    />
  );
};

export default ProductImage;
