import { forwardRef } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import { SpinnerThirdIcon } from '@assets/icons';
import { CircleInfoIcon } from '@assets/icons';
import { Tooltip } from '@components';

const Button = forwardRef((props, ref) => {
  const {
    children,
    Component = 'button',
    disabled = false,
    endIcon: endIconProp,
    fullWidth = false,
    isLoading = false,
    startIcon: startIconProp,
    tooltip: tooltipProp,
    type = 'button',
    variant = 'contained',
    color = 'primary',
    size = 'base',
    styles = {},
    ...restProps
  } = props;

  const startIcon = startIconProp && (
    <span tw="flex items-center mr-4">{startIconProp}</span>
  );

  const endIcon = endIconProp && (
    <span tw="flex items-center ml-4">{endIconProp}</span>
  );

  const tooltip = tooltipProp && (
    <div tw="absolute top-1 right-1">
      <Tooltip content={tooltipProp}>
        <span>
          <CircleInfoIcon tw="w-3 cursor-pointer" />
        </span>
      </Tooltip>
    </div>
  );

  const primaryText = css`
    ${tw`transition-colors duration-300 text-primary`};
    ${!disabled
      ? tw`focus-visible:(ring-primary-hover text-primary-hover) hover:(text-primary-hover transition-colors duration-300)`
      : null};
    ${variant === 'link' && tw`focus-visible:(rounded-sm)`}
  `;

  const variantStyle = {
    contained: {
      primary: {
        button: css`
          ${tw`text-white bg-primary border-primary`};
          ${!disabled
            ? tw`hover:(bg-primary-hover border-primary-hover)`
            : null};
        `,
      },
      default: {
        button: css`
          ${tw`text-white bg-gray-400 border-gray-400`};
          ${!disabled && tw`hover:(bg-gray-500 border-gray-500)`};
        `,
      },
      success: {
        button: css`
          ${tw`text-white bg-green-600 border-green-600`};
          ${!disabled && tw`hover:(bg-green-700 border-green-700)`};
        `,
      },
      warning: {
        button: css`
          ${tw`text-white bg-yellow-500 border-yellow-500`};
          ${!disabled && tw`hover:(bg-yellow-600 border-yellow-600)`};
        `,
      },
      error: {
        button: css`
          ${tw`text-white bg-red-600 border-red-600`};
          ${!disabled && tw`hover:(bg-red-700 border-red-700)`};
        `,
      },
    },
    outlined: {
      primary: {
        button: css`
          ${tw`border-primary text-primary`};
          ${!disabled && tw`hover:(bg-primary text-white)`}
        `,
        spinner: disabled && tw`text-primary`,
      },
      default: {
        button: css`
          ${tw`text-gray-500 border-gray-500`};
          ${!disabled && tw` hover:(bg-gray-500 text-white)`};
        `,
      },
      success: {
        button: css`
          ${tw`text-green-600 border-green-600`};
          ${!disabled && tw`hover:(bg-green-600 text-white)`}
        `,
      },
      warning: {
        button: css`
          ${tw`text-yellow-500 border-yellow-500`};
          ${!disabled && tw`hover:(bg-yellow-500 text-white)`}
        `,
      },
      error: {
        button: css`
          ${tw`text-red-600 border-red-600`};
          ${!disabled && tw`hover:(bg-red-600 text-white)`}
        `,
      },
    },
    text: {
      primary: {
        button: primaryText,
        spinner: null,
      },
    },
    link: {
      primary: {
        button: primaryText,
        spinner: null,
      },
      error: {
        button: css`
          ${tw`text-red-600 hover:(text-red-700 underline)`};
          ${isLoading && tw`flex items-center`}
        `,
        spinner: tw`text-red-500`,
      },
    },
  };

  const paddingStyle = {
    sm: tw`px-4 py-2`,
    base: tw`px-6 py-3`,
    lg: tw`px-8 py-4`,
  };

  const twButtonStyle = [
    variant !== 'link' && [
      paddingStyle[size],
      tw`text-sm font-bold uppercase text-center rounded-md align-middle duration-300 border border-transparent cursor-pointer select-none focus-visible:(ring-2 outline-none ring-offset-2) hover:(transition-colors duration-300)`,
    ],
    tw`flex items-center tracking-wide`,
    tooltip && tw`relative justify-start px-2`,
    fullWidth && tw`justify-center w-full`,
    variantStyle[variant][color].button,
    disabled && [tw`cursor-not-allowed opacity-70`],
    styles?.button,
  ];

  const twSpinnerStyle = [
    tw`w-5 mr-3 -ml-1 text-white animate-spin`,
    variantStyle[variant][color]?.spinner,
    styles?.spinner,
  ];

  return (
    <Component
      css={twButtonStyle}
      disabled={disabled}
      ref={ref}
      type={type}
      {...restProps}
    >
      {isLoading && <SpinnerThirdIcon css={twSpinnerStyle} />}

      {tooltip}
      {startIcon}
      {children}
      {endIcon}
    </Component>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.node,
  type: PropTypes.oneOfType([
    PropTypes.oneOf(['button', 'reset', 'submit']),
    PropTypes.string,
  ]),
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['contained', 'outlined', 'text', 'link']),
    PropTypes.string,
  ]),
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'default', 'success', 'warning', 'error']),
    PropTypes.string,
  ]),
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['sm', 'base', 'lg']),
    PropTypes.string,
  ]),
};

export default Button;
