import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/dietInputs';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const initialValues = {
  [INPUT_IDS.NAME]: '',
};

export const getInitialValues = diet => {
  return {
    [INPUT_IDS.NAME]: diet[INPUT_IDS.NAME] ?? '',
  };
};

export const useValidationSchema = () => {
  const { stringRequired } = useDefaultSchemaYup();

  return Yup.object({
    [INPUT_IDS.NAME]: stringRequired,
  });
};
