import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import chunk from 'lodash/chunk';
import tw from 'twin.macro';

import { useMediaQuery, useMobileDetect } from '@hooks';
import { getProperty } from '@utils/helpers';
import { down } from '@utils/screens';

const Carousel = dynamic(() =>
  import('@components/elements/Carousel/Carousel')
);
const Button = dynamic(() => import('@components/elements/Button'));
const NoImageDietsBatch = dynamic(() => import('./NoImageDietsBatch'));
const DietCard = dynamic(() => import('./DietCard'));

const MAX_DIETS_PER_VIEW = 6;

const SelectDietSection = ({
  className = '',
  diets = [],
  selectDiet = () => {},
  selectedDietId = null,
  displayAllAsButtons = false,
}) => {
  const [dietsBatches, setDietsBatches] = useState([]);
  const [initialDietSlide, setInitialDietSlide] = useState(-1);
  const [initialBatchSlide, setInitialBatchSlide] = useState(-1);
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const isDown1140 = useMediaQuery(down('1140px'), true);
  const isDown1450 = useMediaQuery(down('1450px'), true);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(diets)) {
      const batches = chunk(diets, MAX_DIETS_PER_VIEW);
      const initialBatchIndex = batches
        .map(batch => {
          const batchIncludingSelectedDiet = batch.find(diet =>
            diet?.['@id'] === selectedDietId ? true : false
          );

          return !isEmpty(batchIncludingSelectedDiet);
        })
        .indexOf(true);
      const initialDietIndex = diets
        .map(diet => (diet?.['@id'] === selectedDietId ? true : false))
        .indexOf(true);

      setDietsBatches(batches);
      setInitialDietSlide(initialDietIndex > 0 ? initialDietIndex : 0);
      setInitialBatchSlide(initialBatchIndex > 0 ? initialBatchIndex : 0);
    }
  }, [diets]);

  if (isEmpty(diets) || initialDietSlide < 0 || initialBatchSlide < 0) {
    return null;
  }

  return (
    <>
      <h2 tw="text-lg">
        {t(
          '$*newOrderCreatePage.orderForm.diets.selectDiet.title',
          '$$Jaką dietę preferujesz?'
        )}
      </h2>
      {displayAllAsButtons && (
        <div tw="flex flex-wrap -mx-2">
          {diets.map(diet => (
            <div key={diet['@id']} tw="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-1">
              <Button
                type="button"
                color="primary"
                fullWidth={true}
                variant="outlined"
                styles={{
                  button: [
                    tw`flex-auto my-1 border-2 text-xs shadow hover:(bg-white text-primary) justify-start py-2 px-2 sm:px-4 text-left`,
                    selectedDietId === diet['@id']
                      ? tw`font-bold`
                      : tw`border-transparent`,
                    { minHeight: '70px' },
                  ],
                }}
                onClick={() => selectDiet(diet)}
              >
                <img
                  src={diet?.mainImage}
                  alt=""
                  css={[tw`w-full mr-2.5`, { maxWidth: '50px' }]}
                />
                {diet?.name}
              </Button>
            </div>
          ))}
        </div>
      )}
      {!displayAllAsButtons && (
        <div tw="xl:pr-5">
          {!diets[0]?.icon ? (
            diets?.length > 6 ? (
              <div tw="relative w-full">
                <Carousel
                  className={className}
                  options={{
                    initialSlide: initialBatchSlide,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    allowTouchMove: isMobile,
                    ...(!isMobile
                      ? {
                          keyboard: {
                            enabled: true,
                            onlyInViewport: false,
                          },
                        }
                      : {}),
                  }}
                >
                  {Slide =>
                    dietsBatches.map((dietsBatch, index) => {
                      return (
                        <Slide
                          key={index}
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <NoImageDietsBatch batchOfDiets={dietsBatch} />
                        </Slide>
                      );
                    })
                  }
                </Carousel>
              </div>
            ) : (
              <NoImageDietsBatch batchOfDiets={diets} />
            )
          ) : isDown1450 || diets.length > 2 ? (
            <div tw="xl:-mx-2 relative w-full">
              <Carousel
                className={className}
                options={{
                  initialSlid: initialDietSlide,
                  slidesPerView: 1,
                  spaceBetween: 0,
                  allowTouchMove: isMobile,
                  ...(!isMobile
                    ? {
                        keyboard: {
                          enabled: true,
                          onlyInViewport: false,
                        },
                      }
                    : {}),
                  breakpoints: {
                    500: { slidesPerView: 'auto' },
                  },
                }}
              >
                {Slide =>
                  diets.map(diet => (
                    <Slide
                      key={diet.id}
                      onClick={e => {
                        e.preventDefault();
                        selectDiet(diet);
                      }}
                    >
                      <DietCard
                        isSelected={selectedDietId === diet['@id']}
                        diet={diet}
                      />
                    </Slide>
                  ))
                }
              </Carousel>
            </div>
          ) : (
            <div tw="flex flex-wrap -mx-2">
              {diets.map(diet => (
                <div key={diet.id} onClick={() => selectDiet(diet)}>
                  <DietCard
                    isSelected={selectedDietId === diet['@id']}
                    diet={diet}
                  />
                </div>
              ))}
            </div>
          )}

          <div
            tw="mt-5"
            css={[tw`mt-3 xl:mt-5`, !isDown1140 && { minHeight: '150px' }]}
            dangerouslySetInnerHTML={{
              __html: getProperty(
                diets.find(diet => diet['@id'] === selectedDietId),
                'description',
                ''
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default SelectDietSection;
