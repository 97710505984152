import { css } from '@emotion/react';
import tw, { styled } from 'twin.macro';

const CommonTile = styled.div(
  ({ isSelected }) => css`
    ${tw`w-full h-auto transition-colors duration-300 transform bg-white border-2 rounded-lg cursor-pointer`}
    ${isSelected ? tw`border-primary` : tw`border-gray-200`}

    @media (hover: hover) and (pointer: fine) {
      ${tw`hover:border-primary`}
    }
  `
);

export const Tile = styled(CommonTile)`
  min-height: 330px;
`;

export const MobileTile = CommonTile;

Tile.ContentInnerWrapper = styled.div`
  ${tw`duration-300 transform translate-y-0`}
`;

Tile.Content = styled.div`
  ${tw`mb-4 overflow-hidden rounded-md`}
  max-height: 278px;

  &:hover {
    ${Tile.ContentInnerWrapper} {
      ${tw`duration-300 transform -translate-y-60`}
    }
  }
`;

const Description = styled.div`
  ${tw`overflow-auto`}
  max-height: 215px;
`;

Tile.Description = styled(Description)`
  ${tw`pt-4 text-sm`}
`;

MobileTile.Description = styled(Description)`
  ${tw`text-xs`}
`;

Tile.Image = styled.div(
  ({ src }) => css`
    background: url('${src}') no-repeat center center;
    height: 239px;
    background-size: auto 239px;
    ${tw`flex items-center justify-center`}
  `
);
