import { useQuery, useQueryClient } from 'react-query';

const useBasket = ({ basketKey = 'basketNewOrder', options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    basketKey,
    () => {
      return queryClient.getQueryData(basketKey);
    },
    {
      ...options,
    }
  );

  return query;
};

export default useBasket;
