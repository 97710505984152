import { useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import { InputText } from '@components';

import 'react-phone-number-input/style.css';

const PhoneInputT = ({
  value,
  onChange,
  onBlur = () => {},
  defaultCountry,
}) => {
  const [number, setNumber] = useState(value.number);
  const [countryCode, setCountryCode] = useState(
    value.countryCode || defaultCountry
  );

  const handleChange = number => {
    setNumber(number);
    onChange({ number, countryCode });
  };

  const handleCountryChange = countryCode => {
    setCountryCode(countryCode);
    onChange({ number, countryCode });
  };

  return (
    <PhoneInput
      countryCallingCodeEditable={false}
      international={true}
      withCountryCallingCode={true}
      initialValueFormat="national"
      defaultCountry={defaultCountry}
      value={number}
      country={countryCode}
      onChange={handleChange}
      onCountryChange={handleCountryChange}
      onBlur={onBlur}
      inputComponent={InputText}
    />
  );
};

PhoneInputT.displayName = 'PhoneInput';

export default PhoneInputT;
