import { useTranslation } from 'next-i18next';

import { useSelector } from 'react-redux';
import { selectAuth } from '@store/auth/auth.slice';
import isEmpty from 'lodash/isEmpty';

const LoggedAsUserBar = () => {
  const { t } = useTranslation();
  const { impersonateEmail } = useSelector(selectAuth);

  if (isEmpty(impersonateEmail)) {
    return null;
  }

  return (
    <div tw="bg-red-600 text-white text-center font-semibold p-1 text-xs md:text-base">
      {t(
        '$*components.loggedAsUserBar.text',
        '$$UWAGA! ZALOGOWANO JAKO ADMINISTRATOR, NA KONCIE UŻYTKOWNIKA.'
      )}
    </div>
  );
};

LoggedAsUserBar.displayName = 'LoggedAsUserBar';

export default LoggedAsUserBar;
