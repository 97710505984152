import isNull from 'lodash/isNull';

import { ENDPOINT } from '@constants/endpoint';
import useInfiniteQueryMiddleware from '@hooks/useInfiniteQueryMiddleware';

const useDishProducts = ({ payload }) => {
  const { tags = [], date = null, disableMenuRestriction = false } = payload;

  const query = useInfiniteQueryMiddleware(
    isNull(date) ? 'dishProducts' : ['dishProducts', { date }],
    ENDPOINT.DISH_PRODUCTS,
    {
      disableMenuRestriction: disableMenuRestriction,
      ...(!isNull(date) && { date }),
      'dish.clientTags': tags ?? [],
      itemsPerPage: disableMenuRestriction ? 200 : 9,
    }
  );

  return query;
};

export default useDishProducts;
