import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getZonePage = async zonePageId => {
  const response = await axios.get(ENDPOINT.ZONE_PAGE(zonePageId));

  return response?.data ?? {};
};

const useZonePage = (zonePageID, options = {}) =>
  useQuery(['zonePage', zonePageID], () => getZonePage(zonePageID), options);

export default useZonePage;
