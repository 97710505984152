import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import UserService from '@services/User.service';

export const impersonateLoginWithUserData = createAsyncThunk(
  'auth/impersonateLoginWithUserData',
  async (requestPayload, { rejectWithValue }) => {
    try {
      Cookies.set('refreshToken', requestPayload.refreshToken);
      Cookies.set('token', requestPayload.token);
      Cookies.set('impersonateEmail', requestPayload.email);
      Cookies.set('keepMeLoggedIn', false);

      const userData = await UserService.getData({
        headers: { Authorization: `Bearer ${requestPayload.token}` },
      });

      return { userData, email: requestPayload.email };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const loginWithUserData = createAsyncThunk(
  'auth/loginWithUserData',
  async (requestPayload, { rejectWithValue }) => {
    try {
      const user = await UserService.login(requestPayload);
      const userData = await UserService.getData({
        headers: { Authorization: `Bearer ${user.token}` },
      });

      return { user, userData };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await UserService.register(requestPayload);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      return await UserService.refreshToken();
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUserData = createAsyncThunk(
  'auth/getUserData',
  async (_, { rejectWithValue }) => {
    try {
      return await UserService.getData();
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateUserData = createAsyncThunk(
  'auth/updateUserData',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await UserService.updateData(requestPayload);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await UserService.changePassword(requestPayload);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
