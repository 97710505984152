import DisplayOn from './DisplayOn';

const DisplayUpCustom = ({ children, size }) => (
  <DisplayOn size={size} mode="up" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayUpCustom.displayName = 'DisplayUpCustom';

export default DisplayUpCustom;
