import Tippy from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

const Tooltip = ({ children = null, ...tooltipProps }) => {
  if (isEmpty(children)) {
    return <Tippy {...tooltipProps} />;
  }

  return <Tippy {...tooltipProps}>{children}</Tippy>;
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
