import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import { useInterval } from 'react-use';
import { css } from '@emotion/react';
import { intervalToDuration } from 'date-fns';
import tw from 'twin.macro';

import { isEmptyObject } from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

const Countdown = ({
  startDate = new Date(),
  endDate,
  interval = 1000,
  disableYears = true,
  disableMonths = true,
  disableDays = false,
  disableHours = false,
  disableMinutes = false,
  disableSeconds = false,
  showEmptyPartialTime = false,
  styles = {},
}) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState({});

  const startDateInstance = new Date(startDate);
  const endDateInstance = new Date(endDate);

  useInterval(
    () => {
      const intevalDuration = intervalToDuration({
        start: startDateInstance,
        end: endDateInstance,
      });

      setDuration(intevalDuration);
    },
    isEmptyObject(duration) ? 0 : interval
  );

  const twStyle = {
    wrapper: cssMerge({
      defaultCss: tw`flex flex-wrap -m-1 text-sm text-center`,
      ...styles?.wrapper,
    }),
    item: cssMerge({
      defaultCss: css`
        ${tw`px-1 py-1 m-1 border border-gray-200 rounded-md`};
        min-width: 48px;
      `,
      ...styles?.item,
    }),
  };

  return (
    <div css={twStyle.wrapper}>
      {!disableYears && (duration.years > 0 || showEmptyPartialTime) && (
        <div css={twStyle.item}>
          {duration.years} {t('$*common.years.short', '$$l.')}
        </div>
      )}
      {!disableMonths && (duration.months > 0 || showEmptyPartialTime) && (
        <div css={twStyle.item}>
          {duration.months} {t('$*common.months.short', '$$mies.')}
        </div>
      )}
      {!disableDays &&
        (duration.days > 0 || duration.months > 0 || showEmptyPartialTime) && (
          <div css={twStyle.item}>
            {duration.days} {t('$*common.days.short', '$$d')}
          </div>
        )}
      {!disableHours &&
        (duration.hours > 0 || duration.days > 0 || showEmptyPartialTime) && (
          <div css={twStyle.item}>
            {duration.hours} {t('$*common.hours.short', '$$g.')}
          </div>
        )}
      {!disableMinutes &&
        (duration.minutes > 0 ||
          duration.hours > 0 ||
          showEmptyPartialTime) && (
          <div css={twStyle.item}>
            {duration.minutes} {t('$*common.minutes.short', '$$min')}
          </div>
        )}
      {!disableSeconds &&
        (duration.seconds > 0 ||
          duration.minutes > 0 ||
          showEmptyPartialTime) && (
          <div css={twStyle.item}>
            {duration.seconds} {t('$*common.seconds.short', '$$s')}
          </div>
        )}
    </div>
  );
};

export default Countdown;
