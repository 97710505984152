import DisplayOn from './DisplayOn';

const DisplayBetweenCustom = ({ children, size = [] }) => (
  <DisplayOn size={size} mode="between" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayBetweenCustom.displayName = 'DisplayBetweenCustom';

export default DisplayBetweenCustom;
