import {
  addDietReducer,
  addItemToBasketReducer,
  removeDietReducer,
  setRevalidateBasketReducer,
  toggleBasketDayReducer,
  toggleBasketGroupReducer,
  updateDietReducer,
} from './basket.actions';

export const initialState = {
  orderDetails: {
    summary: {
      diets: 0,
      days: 0,
      dishes: 0,
      addons: 0,
      firstDeliveryDay: null,
      lastDeliveryDay: null,
    },
  },
  activeOrderDietIri: null,
  items: {
    dietElements: [],
  },
  collapsed: {},
  isOpenMobile: false,
  isPayActionSuccess: false,
  isExternalPayment: false,
  isEmpty: true,
  isValid: false,

  useDiscountCode: false,
  discountCode: { '@id': null, value: '' },
  useMoneyBox: false,
  useInvoice: false,
  invoice: {},
  paymentMethod: null,
  paymentMethodMetadata: {},
};

export const reducers = {
  resetBasket: () => initialState,
  setStatusBasket: (state, { payload: isOpen }) => {
    state.isOpenMobile = isOpen;
  },
  setActiveOrderDietIri: (state, { payload: dietIri }) => {
    state.activeOrderDietIri = dietIri;
  },
  toggleBasketDay: toggleBasketDayReducer,
  toggleBasketGroup: toggleBasketGroupReducer,
  setRevalidateBasket: setRevalidateBasketReducer,
  addItemToBasket: addItemToBasketReducer,
  addDiet: addDietReducer,
  updateDiet: updateDietReducer,
  removeDiet: removeDietReducer,
  setPayStatus: (state, { payload }) => {
    state.isPayActionSuccess = payload.isPayActionSuccess;
    state.isExternalPayment = payload.isExternalPayment;
  },
  setIsValid: (state, { payload: isValid }) => {
    state.isValid = isValid;
  },
  setDiscountCode: (state, { payload }) => {
    state.useDiscountCode = payload.useDiscountCode;
    state.discountCode = payload.discountCode;
  },
  setInvoice: (state, { payload }) => {
    state.useInvoice = payload.useInvoice;
    state.invoice = payload.invoice;
  },
  setPaymentMethod: (state, { payload }) => {
    state.paymentMethod = payload;
  },
  setPaymentMethodMetadata: (state, { payload }) => {
    state.paymentMethodMetadata = payload;
  },
  setUseMoneyBox: (state, { payload }) => {
    state.useMoneyBox = payload;
  },
  setDietName: (state, { payload }) => {
    const dietElementIndex = state.items.dietElements.findIndex(
      dietElement => dietElement?.['@id'] === payload.existingItem
    );

    state.items.dietElements[dietElementIndex].name = payload.name;
  },
};
