import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const deleteUserAddress = async id => {
  const response = await axios.delete(ENDPOINT.ADDRESS(id));

  return response?.data ?? {};
};

const useDeleteUserAddress = (options = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(deleteUserAddress, {
    onSuccess: (_, id) => {
      queryClient.setQueryData('userAddresses', addresses =>
        addresses.filter(address => address.id !== id)
      );

      showToast(
        t(
          '$*notification.deleteUserAddress.success',
          '$$Adres został pomyślnie usunięty.'
        ),
        { type: 'info' }
      );
    },
    ...options,
  });

  return query;
};

export default useDeleteUserAddress;
