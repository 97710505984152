import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Tag = ({
  color = 'default',
  label = '',
  onClick,
  isRemovable = false,
  variant = 'default',
  styles = {},
  isSelected = false,
  ...restProps
}) => {
  const variantStyle = {
    default: {
      main: tw`border`,
      color: {
        default: tw`text-gray-500 bg-gray-100 border-gray-100`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
      colorHover: {
        default: tw`hover:(bg-gray-200 border-gray-200)`,
        primary: tw`hover:(bg-primary-hover border-primary-hover)`,
        secondary: tw`hover:(bg-primary border-primary)`,
      },
      colorSelected: {
        default: tw`bg-gray-200 border-gray-200`,
        primary: tw`bg-primary-hover border-primary-hover`,
        secondary: tw`bg-primary border-primary`,
      },
    },
    outline: {
      main: tw`border`,
      color: {
        default: tw`text-gray-500 border-gray-200`,
        primary: tw`text-primary border-primary`,
        secondary: tw`text-primary-hover border-primary-hover`,
      },
      colorHover: {
        default: tw`hover:(bg-gray-100 text-gray-500 border-gray-100)`,
        primary: tw`hover:(bg-primary text-white border-primary)`,
        secondary: tw`hover:(bg-primary-hover text-white border-primary-hover)`,
      },
      colorSelected: {
        default: tw`text-gray-500 bg-gray-100 border-gray-100`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
    },
    noHover: {
      color: {
        default: tw`text-gray-500 bg-gray-100 border-gray-100`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
      colorHover: {
        default: tw`text-gray-500 bg-gray-100 border-gray-100`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
      colorSelected: {
        default: tw`text-gray-500 bg-gray-100 border-gray-100`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
    },
  };

  const currentVariant = variantStyle[variant];
  const twStyle = [
    {
      ...tw`inline-block px-2 py-1 text-sm transition-colors duration-300 rounded-md`,
      ...currentVariant.main,
      ...currentVariant.color[color],
    },
    onClick !== null && [
      tw`transition-colors duration-300`,
      variant !== 'noHover' && tw`cursor-pointer`,
      currentVariant.colorHover[color],
    ],
    isSelected && [currentVariant.colorSelected[color]],
  ];

  const divProps = {
    css: cssMerge({ defaultCss: twStyle, ...styles }),
    'data-cy': 'tag',
    ...restProps,
  };

  const iconProps = {
    icon: faTimesCircle,
    css: tw`ml-1`,
  };

  return (
    <div {...divProps} onClick={onClick}>
      {label} {isRemovable && <FontAwesomeIcon {...iconProps} />}
    </div>
  );
};

Tag.displayName = 'Tag';

Tag.propTypes = {
  clickable: PropTypes.bool,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'primary-light',
    'primary-dark',
  ]),
  deleteIcon: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['default', 'outline']),
};
export default Tag;
