import isEmpty from 'lodash/isEmpty';

import { useAppContext } from '@views/App/App.context';

import BasketDay from './BasketDay';

const BasketDietDay = ({ basketDaysError, days = [], dietIri }) => {
  const { isShop } = useAppContext();

  return (
    !isEmpty(days) &&
    Object.entries(days).map(([date, { price, dishes, delivery, addons }]) => {
      return (
        <BasketDay
          key={date}
          day={new Date(date)}
          dietIri={dietIri}
          dayPrice={price}
          addons={addons}
          dishes={Object.values(dishes)}
          delivery={delivery}
          isValidDayRequirements={
            isShop ? basketDaysError[dietIri][date].isValid : true
          }
          errorMessageDayRequirements={
            isShop ? basketDaysError[dietIri][date].errorMessage : null
          }
        />
      );
    })
  );
};

export default BasketDietDay;
