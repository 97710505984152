import { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';

const Underline = ({ refs, finishAnimating, activeTab }) => {
  const [{ x, width }, setAttributes] = useState({
    x: 0,
    width: 0,
  });

  const updateAttributes = useCallback(() => {
    if (refs && refs[activeTab]) {
      setAttributes({
        x: refs[activeTab].current?.offsetLeft,
        width: refs[activeTab].current?.getBoundingClientRect().width,
      });
    }
  }, [activeTab, refs]);

  // Update attributes if active route changes (or refs change)
  useEffect(() => {
    updateAttributes();
  }, [activeTab, refs, updateAttributes]);

  // After window resize, recalculate
  useEffect(() => {
    const recalculateAttrs = debounce(() => {
      updateAttributes();
    }, 500);

    window.addEventListener('resize', recalculateAttrs);
    return () => {
      window.removeEventListener('resize', recalculateAttrs);
    };
  });

  return (
    <div>
      <motion.div
        animate={{
          x,
          width,
        }}
        onAnimationComplete={finishAnimating}
        tw="absolute left-0 bottom-0 h-1 bg-primary rounded-full z-10"
      />
      <div tw="absolute left-0 bottom-px w-full h-0.5 bg-gray-200 z-0" />
    </div>
  );
};

Underline.displayName = 'TabsUnderline';

export default Underline;
