const ArrowDownIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1702 2334"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M763.308 2035.092l-.082-1947.556c.017-48.35 39.2-87.534 87.54-87.534 48.335 0 87.523 39.183 87.534 87.534l-.01 1947.156 613.488-613.493c34.183-34.178 89.596-34.178 123.774 0 34.183 34.183 34.183 89.596 0 123.796l-762.89 762.896c-17.155 17.05-39.567 25.677-61.956 25.677-14.42 0-28.845-3.529-41.83-10.618a86.97 86.97 0 01-20.438-15.284L25.63 1544.995c-34.178-34.2-34.178-89.613 0-123.796 34.178-34.178 89.596-34.178 123.796 0l613.882 613.893z" />
  </svg>
);

export default ArrowDownIcon;
