import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getUserSubscription = async ({ subscriptionId }) => {
  const response = await axios.get(
    ENDPOINT.USER_SUBSCRIPTION({ id: subscriptionId })
  );

  return response?.data ?? {};
};

const useUserSubscription = ({ subscriptionId }, options = {}) => {
  return useQuery(
    ['userSubscription', { subscriptionId }],
    () => getUserSubscription({ subscriptionId }),
    {
      enabled: !!subscriptionId,
      ...options,
    }
  );
};

export default useUserSubscription;
