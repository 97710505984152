const CircleInfoIcon = ({ title = '', ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <path
      d="M5.99471 0C2.68614 0 0 2.68614 0 5.99471C0 9.30328 2.68614 11.9894 5.99471 11.9894C9.30328 11.9894 11.9895 9.30328 11.9895 5.99471C11.9895 2.68614 9.30328 0 5.99471 0ZM5.99471 0.936804C8.78626 0.936804 11.0526 3.20316 11.0526 5.99471C11.0526 8.78626 8.78626 11.0526 5.99471 11.0526C3.20316 11.0526 0.936804 8.78626 0.936804 5.99471C0.936804 3.20316 3.20316 0.936804 5.99471 0.936804ZM5.99471 9.81869C6.33634 9.81869 6.61327 9.54176 6.61327 9.20013V5.21685C6.61327 4.87522 6.33634 4.59829 5.99471 4.59829C5.65308 4.59829 5.37615 4.87522 5.37615 5.21685V9.20013C5.37615 9.54176 5.65308 9.81869 5.99471 9.81869ZM5.99471 3.84087C5.53382 3.84087 5.15966 3.46667 5.15966 3.00581C5.15966 2.54493 5.53382 2.17076 5.99471 2.17076C6.4556 2.17076 6.82976 2.54493 6.82976 3.00581C6.82976 3.46667 6.4556 3.84087 5.99471 3.84087Z"
      fill={props?.fill ?? 'black'}
    />
  </svg>
);

export default CircleInfoIcon;
