import { Fragment } from 'react';

import { DisplayDownMd, DisplayUpMd } from '@components';

import BigTileDay from './BigDayTile';
import SmallDayTile from './SmallDayTile';

const DayTile = ({
  isSelected = false,
  date = new Date(),
  onClick = () => {},
}) => {
  const props = {
    isSelected,
    date,
    onClick,
  };

  return (
    <Fragment>
      <DisplayUpMd>
        <BigTileDay {...props} />
      </DisplayUpMd>
      <DisplayDownMd>
        <SmallDayTile {...props} />
      </DisplayDownMd>
    </Fragment>
  );
};

DayTile.displayName = 'DayTile';

export default DayTile;
