const InfoIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 510 2334"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M254.794 509.634C114.167 509.634 0 395.467 0 254.84 0 114.212 114.167.046 254.794.046c140.628 0 254.794 114.166 254.794 254.794 0 140.627-114.166 254.794-254.794 254.794zm0 1823.977c104.24 0 188.736-84.497 188.736-188.736V929.478c0-104.239-84.497-188.736-188.736-188.736-104.239 0-188.736 84.497-188.736 188.736v1215.397c0 104.239 84.497 188.736 188.736 188.736z" />
  </svg>
);

export default InfoIcon;
