import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { Fragment, useMemo } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';

const FormGroup = dynamic(() => import('./FormGroup'));
const HelperText = dynamic(() => import('./HelperText'));
const Label = dynamic(() => import('./Label'));
const CheckboxInput = dynamic(() => import('./Checkbox'));
const EmailInput = dynamic(() => import('./InputText'));
const HiddenInput = dynamic(() => import('./InputText'));
const NumberInput = dynamic(() => import('./InputText'));
const PasswordInput = dynamic(() => import('./InputText'));
const PhoneInput = dynamic(() => import('./PhoneInput'));
const RatingStarInput = dynamic(() => import('./RatingStar'));
const RatingThumbInput = dynamic(() => import('./RatingThumb'));
const SelectInput = dynamic(() => import('./Select'));
const SwitchInput = dynamic(() => import('./Switch'));
const TextInput = dynamic(() => import('./InputText'));
const TextareaInput = dynamic(() => import('./Textarea'));
const TextEmojiInput = dynamic(() => import('./InputEmoji/InputEmoji'));
const ToggleInput = dynamic(() => import('./Toggle'));

const INPUTS = {
  // bool: Toggle,
  // date: DatePicker,
  // datetime: DateTime,
  // enum: Enumeration,
  // number: InputNumber,
  // time: TimePicker,
  checkbox: CheckboxInput,
  email: EmailInput,
  hidden: HiddenInput,
  number: NumberInput,
  password: PasswordInput,
  phone: PhoneInput,
  ratingStar: RatingStarInput,
  ratingThumb: RatingThumbInput,
  select: SelectInput,
  switch: SwitchInput,
  text: TextInput,
  textarea: TextareaInput,
  textEmoji: TextEmojiInput,
  toggle: ToggleInput,
};

const InputsIndex = ({
  id,
  name,
  type = 'text',
  label = '',
  required = false,
  description = '',
  formGroupProps = {},
  labelProps = {},
  Component = null,
  ...restInputProps
}) => {
  name ??= id;
  const router = useRouter();
  const { locale } = router;

  let showLabel = true;
  if (type === 'checkbox' || type === 'toggle') {
    showLabel = false;
  }

  const { errors, touched, status } = useFormikContext();
  const hasError = (errors?.[id] && touched?.[id]) ?? false;
  const apiFieldError = status?.apiFieldErrors?.[id] || '';
  const hasApiFieldError = !isEmpty(apiFieldError);

  const inputProps = useMemo(() => {
    let inputProps = {};
    const { disabled, options = [], placeholder = '' } = restInputProps;

    switch (type) {
      case 'toggle':
      case 'checkbox': {
        inputProps = { label, required, disabled };
        break;
      }
      case 'switch': {
        inputProps = {
          label,
          disabled,
          options,
          default: restInputProps.default,
        };
        break;
      }
      case 'hidden':
      case 'password':
      case 'number': {
        inputProps = { type, ...restInputProps };
        break;
      }
      case 'select': {
        inputProps = { options, placeholder, ...restInputProps };
        break;
      }
      case 'enum': {
        inputProps = {
          options,
        };
        break;
      }
      case 'phone': {
        inputProps = {
          defaultCountry: (locale === 'en' ? 'US' : locale).toUpperCase(),
        };
        break;
      }

      default: {
        inputProps = { ...restInputProps };
      }
    }

    return inputProps;
  }, [label, type, restInputProps]);

  const InputComponent =
    INPUTS[type] || dynamic(() => import('./UnknownInput'));

  return (
    <Fragment>
      <FormGroup isInvalid={hasApiFieldError || hasError} {...formGroupProps}>
        {label && showLabel && (
          <Label htmlFor={id} {...labelProps}>
            {label} {required && '*'}
          </Label>
        )}
        {type === 'custom' ? (
          Component
        ) : (
          <Field name={name}>
            {({ field, form }) => {
              let additionalInputProps = {};

              if (type === 'select') {
                additionalInputProps = {
                  value: field.value,
                  onChange: option => {
                    form.setFieldValue(field.name, option);
                  },
                  onBlur: () => {
                    form.setFieldTouched(field.name, true);
                  },
                };
              }

              if (type === 'ratingThumb') {
                additionalInputProps = {
                  value: field.value,
                  onChange: option => {
                    form.setFieldValue(field.name, option);
                  },
                };
              }

              if (['phone', 'textEmoji', 'ratingStar'].includes(type)) {
                additionalInputProps = {
                  onChange: item => {
                    form.setFieldValue(field.name, item);
                  },
                  onBlur: () => {
                    form.setFieldTouched(field.name, true);
                  },
                };
              }

              return (
                <InputComponent
                  {...{
                    ...field,
                    ...inputProps,
                    ...additionalInputProps,
                    'data-cy': `input-${type}--${kebabCase(id)}`,
                  }}
                />
              );
            }}
          </Field>
        )}
        {description && <HelperText>{description}</HelperText>}
        {hasApiFieldError && <HelperText>{apiFieldError}</HelperText>}
        <ErrorMessage name={name} component={HelperText} />
      </FormGroup>
    </Fragment>
  );
};

InputsIndex.displayName = 'InputsIndex';

export default InputsIndex;
