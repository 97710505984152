const BellIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1480 1772"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M739.918 1771.654c156.559 0 283.892-127.343 283.892-283.902 0-35.888-29.128-65.015-65.016-65.015-35.888 0-65.015 29.127-65.015 65.015 0 84.872-69.009 153.87-153.861 153.87-84.872 0-153.87-68.998-153.87-153.87 0-35.888-29.128-65.015-65.016-65.015-35.888 0-65.015 29.127-65.015 65.015 0 156.56 127.332 283.902 283.901 283.902z"
      fillRule="nonzero"
    />
    <path
      d="M674.903 222.917C418.172 255.003 218.885 474.607 218.885 739.9v203.428c0 141.355-61.941 274.776-170.574 366.567-.01.01-.03.02-.05.04C17.583 1336.183 0 1374.414 0 1414.8c0 76.076 61.892 137.968 137.988 137.968H1341.84c76.085 0 137.977-61.892 137.977-137.968 0-40.365-17.563-78.595-49.004-105.39-.01-.01-.02-.02-.03-.02-107.92-91.297-169.852-224.708-169.852-366.063V739.899c0-265.291-199.286-484.896-455.998-516.98V65.014C804.933 29.126 775.806 0 739.918 0c-35.888 0-65.015 29.127-65.015 65.016v157.902zm65.015 125.997c215.605 0 390.982 175.375 390.982 390.985v203.428c0 179.655 78.734 349.32 216.099 465.464 1.048.88 2.787 2.846 2.787 6.01 0 4.309-3.627 7.936-7.946 7.936H137.987c-4.32 0-7.957-3.618-7.957-7.937 0-3.202 1.799-5.169 2.936-6.167 137.226-116.016 215.95-285.66 215.95-465.306V739.899c0-215.61 175.378-390.985 391.002-390.985z"
      fillRule="nonzero"
    />
  </svg>
);

export default BellIcon;
