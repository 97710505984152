import { theme } from 'twin.macro';

/**
 * NOTE: Keep this in sync with the (custom) Tailwind theme `screens` config.
 * @see https://tailwindcss.com/docs/breakpoints
 */
export const screens = theme`screens`;
export const screensInt = Object.entries(screens).reduce(
  (acc, [key, value]) => {
    const beakpoint = parseInt(value.replace('px', ''));

    return { ...acc, [key]: beakpoint };
  },
  {}
);

// The maximum value is calculated as the minimum of the next one less 0.02px.
// @see https://www.w3.org/TR/mediaqueries-4/#mq-min-max
const getNextBpValue = bp => {
  return `${parseInt(bp) - 0.02}px`;
};

export const up = bp => {
  const screen = screens[bp] ?? bp;

  return `@media only screen and (min-width: ${screen})`;
};

export const down = bp => {
  const bpScreen = screens[bp];
  const screen = bpScreen ? getNextBpValue(bpScreen) : bp;

  return `@media only screen and (max-width: ${screen})`;
};

export const between = (bpMin, bpMax) => {
  const screenMin = screens[bpMin] ?? bpMin;
  const bpScreenMax = screens[bpMax];
  const screenMax = bpScreenMax ? getNextBpValue(bpScreenMax) : bpMax;

  return `@media only screen and (min-width: ${screenMin}) and (max-width: ${screenMax})`;
};

export const only = bp => {
  const screenKeys = Object.keys(screens);
  const currentKeyIndex = screenKeys.indexOf(bp);
  const nextBp = screenKeys[currentKeyIndex + 1];

  return nextBp ? between(bp, nextBp) : up(bp);
};
