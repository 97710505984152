import { createContext, useContext, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

const SelectAddonsSectionContext = createContext([{}, () => {}]);

const SelectAddonsSectionProvider = ({
  children,
  onSwitchMode,
  selectedDate,
  selectedDietIri,
  activeOrderDietIri,
  deliveryModesEnabled,
  checkIsAddonSelected,
  initialSelectedAddons,
  isSaveChangesModeActive,
  handleSelectedAddonsAdd,
  handleChangeAddonQuantity,
}) => {
  // NOTE: This state selectedAddons are to prevent triggering request per quantity change and handle adding all selected addons by one button click, in use when isSaveChangesModeActive value is true
  const [selectedAddons, setSelectedAddons] = useState(
    initialSelectedAddons ?? []
  );
  const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false);

  const isSaveChangesEnabled =
    selectedAddons?.length !== initialSelectedAddons?.length ||
    (selectedAddons?.length === initialSelectedAddons?.length &&
      !isEmpty(
        selectedAddons.filter(addon => {
          const sameInitialAddon = initialSelectedAddons.find(
            initialAddon => initialAddon?.addonIri === addon?.addonIri
          );
          if (
            !sameInitialAddon ||
            sameInitialAddon?.quantity !== addon?.quantity
          ) {
            return addon;
          }
        })
      ));

  const checkIsStateAddonSelected = ({ addon }) => {
    const selectedIris = selectedAddons.map(({ addonIri }) => addonIri);

    return selectedIris.includes(addon?.['@id']);
  };

  const handleChangeStateAddonQuantity = addon => {
    if (addon?.quantity === 0) {
      return setSelectedAddons(
        selectedAddons.filter(({ addonIri }) => addonIri !== addon?.addonIri)
      );
    }
    let wasItemUpdated = false;

    const updatedAddons = selectedAddons.map(selectedAddon => {
      if (selectedAddon?.addonIri === addon?.addonIri) {
        wasItemUpdated = true;
        return { addonIri: addon?.addonIri, quantity: addon?.quantity };
      }

      return selectedAddon;
    });

    if (wasItemUpdated) {
      return setSelectedAddons(updatedAddons);
    }

    return setSelectedAddons([...updatedAddons, addon]);
  };

  const handleSaveChanges = async () => {
    setIsSaveChangesLoading(true);
    const payload = selectedAddons.map(({ addonIri, quantity }) => ({
      addon: addonIri,
      quantity,
    }));

    try {
      await handleSelectedAddonsAdd(payload);
    } catch (error) {
      //
    }

    setIsSaveChangesLoading(false);
  };

  const value = {
    onSwitchMode,
    selectedDate,
    selectedAddons,
    selectedDietIri,
    handleSaveChanges,
    activeOrderDietIri,
    deliveryModesEnabled,
    checkIsAddonSelected: !isSaveChangesModeActive
      ? checkIsAddonSelected
      : checkIsStateAddonSelected,
    isSaveChangesLoading,
    isSaveChangesEnabled,
    handleSelectedAddonsAdd: handleSelectedAddonsAdd,
    handleChangeAddonQuantity: !isSaveChangesModeActive
      ? handleChangeAddonQuantity
      : handleChangeStateAddonQuantity,
  };

  return (
    <SelectAddonsSectionContext.Provider value={value}>
      {children}
    </SelectAddonsSectionContext.Provider>
  );
};

const useSelectAddonsSectionContext = () =>
  useContext(SelectAddonsSectionContext);

export { SelectAddonsSectionProvider, useSelectAddonsSectionContext };
