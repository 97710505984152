const EmojiFaceIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" />
    <path d="M8 7a2 2 0 10-.001 3.999A2 2 0 008 7m8 0a2 2 0 10-.001 3.999A2 2 0 0016 7m-.768 8c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1012 0" />
  </svg>
);

export default EmojiFaceIcon;
