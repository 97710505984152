import DisplayOn from './DisplayOn';

const DisplayUpLg = ({ children }) => (
  <DisplayOn size="lg" mode="up" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayUpLg.displayName = 'DisplayUpLg';

export default DisplayUpLg;
