import produce from 'immer';
import isEmpty from 'lodash/isEmpty';

import { sortObjectByKeys } from '@utils/helpers';

import { format } from './Date.service';

const convertDateItemsToGroupedByDishSizeDish = dateItems => {
  const newStructure = Object.entries(sortObjectByKeys(dateItems)).reduce(
    (acc, [date, items]) => {
      const groupedByDishSizeDish = items.reduce(
        (acc, item) => {
          const nextState = produce(acc, draft => {
            if (item['@type'] === 'BasketItemDayDelivery') {
              draft.delivery = {
                address: item.address,
                pickUpPoint: item.pickUpPoint,
                price: item.price,
              };
            }

            if (item['@type'] === 'BasketItemAddon') {
              const { addon, quantity, price } = item;

              draft.addons.push({ ...addon, quantity, price });
            }

            if (item['@type'] === 'BasketItemDish') {
              const { dish, size, ...restDishSize } = item.dishSize;

              const singleSize = {
                '@id': size?.['@id'] || null,
                name: size?.name || null,
                price: item.price,
                quantity: item.quantity,
                dishSize: restDishSize,
              };

              // group by dish iri and get data only once
              if (isEmpty(draft.dishes[dish['@id']])) {
                draft.dishes[dish['@id']] = {
                  dish: dish,
                };
              }

              // push to dish sizes[] single size with price & quantity
              draft.dishes[dish['@id']].sizes = (
                draft.dishes[dish['@id']].sizes || []
              ).concat(singleSize);
            }

            // summ day price from items
            draft.price.afterDiscount += item.price.afterDiscount;
            draft.price.beforeDiscount += item.price.beforeDiscount;
            draft.price.discount += item.price.discount;
          });

          return nextState;
        },
        {
          dishes: {},
          addons: [],
          delivery: {
            address: null,
            pickUpPoint: null,
            price: {},
          },
          price: {
            afterDiscount: 0,
            beforeDiscount: 0,
            discount: 0,
          },
        }
      );

      const hasProducts =
        !isEmpty(groupedByDishSizeDish.addons) ||
        !isEmpty(groupedByDishSizeDish.dishes);

      return {
        ...acc,
        ...(hasProducts ? { [date]: groupedByDishSizeDish } : {}),
      };
    },
    {}
  );

  return newStructure;
};

const convertBasketRowsToBasketDayObject = rows => {
  const groupedByDateAndDiet =
    rows?.reduce((acc, item) => {
      const nextState = produce(acc, draft => {
        const formattedDate = format(new Date(item.day));
        const dietIri = item?.clientDiet ?? null;

        if (!isEmpty(dietIri)) {
          draft[dietIri] = draft[dietIri] || {};

          draft[dietIri][formattedDate] = (
            draft[dietIri][formattedDate] || []
          ).concat(item);
        } else {
          draft.noDiet = draft.noDiet || {};

          draft.noDiet[formattedDate] = (
            draft.noDiet[formattedDate] || []
          ).concat(item);
        }
      });

      return nextState;
    }, {}) ?? {};

  const newStructure = Object.entries(
    sortObjectByKeys(groupedByDateAndDiet)
  ).reduce((acc, [dietIri, dietDays]) => {
    const groupedByDishSizeDish =
      convertDateItemsToGroupedByDishSizeDish(dietDays);

    return {
      ...acc,
      [dietIri]: groupedByDishSizeDish,
    };
  }, {});

  return newStructure;
};

const convertInvoice = invoice => {
  return {
    companyName: invoice?.invoiceCompany ?? null,
    vatNumber: invoice?.invoiceVatNumber ?? null,
    type: invoice?.invoiceType ? 'DefaultCompany' : 'Other',
    address: {
      postCode: invoice?.invoiceAddressPostCode ?? null,
      city: invoice?.invoiceAddressCity ?? null,
      street: invoice?.invoiceAddressStreet ?? null,
      buildNumber: invoice?.invoiceAddressBuildNumber ?? null,
      placeNumber: invoice?.invoiceAddressPlaceNumber ?? null,
    },
  };
};

export default {
  convertBasketRowsToBasketDayObject,
  convertInvoice,
};
