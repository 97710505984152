import { useTranslation } from 'next-i18next';

import { ExclamationIcon } from '@assets/icons';
import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import { useToggle } from '@hooks';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import ProductSizeInfo from './ProductSizeInfo';
import { useProductTileContext } from './ProductTile.context';

const ProductSize = ({
  dishIri,
  allergens = [],
  components = [],
  cost = 0,
  macrosAfterProcessing = {},
  name = null,
}) => {
  const { t } = useTranslation();
  const [isShowSizeWrapper, toggleShowSizeWrapper] = useToggle(false);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { dishDate, dishSizesQuantity, handleChangeQuantity, selectedDietIri } =
    useProductTileContext();

  const currentSizeQuantity = dishSizesQuantity?.[dishIri] ?? 0;

  return (
    <div tw="text-xs py-1" data-cy="product__size">
      <div className="row" tw="items-center -mx-1 justify-between">
        <div className="col-auto" tw="px-1">
          <button
            type="button"
            tw="flex items-center"
            onClick={toggleShowSizeWrapper}
          >
            <span>{name}</span>
            <ExclamationIcon
              tw="w-4 ml-3"
              title={t(
                '$*components.productTile.detailsDishSize',
                '$$Szczegoły wielkości porcji'
              )}
            />
          </button>
        </div>
        <div className="col-auto" tw="px-1">
          <div tw="flex items-center">
            <span tw="mr-2">
              {`${getIntegerIfIntegerOrFixed(cost)} ${currencySymbol}${
                currentSizeQuantity > 0 ? ' x' : ''
              }`}
            </span>
            <CheckboxCounter
              count={currentSizeQuantity}
              min={0}
              max={99}
              onChange={(_, quantity) =>
                handleChangeQuantity({
                  dishIri,
                  quantity,
                  day: dishDate,
                  dietIri: selectedDietIri,
                  isRemove: quantity < currentSizeQuantity,
                })
              }
            />
          </div>
        </div>
      </div>

      <ProductSizeInfo
        allergens={allergens}
        components={components}
        isShow={isShowSizeWrapper}
        macrosAfterProcessing={macrosAfterProcessing}
        name={name}
        toggleShow={toggleShowSizeWrapper}
      />
    </div>
  );
};

export default ProductSize;
