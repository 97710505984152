import { css } from '@emotion/react';
import tw from 'twin.macro';

import { useDishTileContext } from './DishTile.context';

const DishInfoWrapper = ({ children }) => {
  const { isOpenMenu } = useDishTileContext();

  return (
    <div
      css={css`
        ${tw`absolute bottom-0 left-0 right-0`}
        ${tw`overflow-hidden duration-300 transform bg-white`};
        ${isOpenMenu
          ? 'height: 336px; border-radius: 5px;'
          : 'height: 110px; border-radius: 8px;'}
      `}
    >
      {children}
    </div>
  );
};

export default DishInfoWrapper;
