import { useEffect, useState } from 'react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

import { Translation } from '@components';
import { cssMerge } from '@utils/styleHelpers';

const LoadingTimeout = ({ styles = {} }) => {
  const [clockIsVisible, setClockIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClockIsVisible(true);
    }, 2000);

    return () => {
      setClockIsVisible(false);
      clearTimeout(timer);
    };
  }, []);

  if (!clockIsVisible) {
    return null;
  }

  const twStyle = cssMerge({
    defaultCss: tw`mb-8 text-center`,
    ...styles,
  });

  return (
    <div css={twStyle}>
      <FontAwesomeIcon icon={faClock} tw="text-6xl mb-4" />
      <div className="h2" tw="mb-0">
        <Translation
          text="$*components.loadingTimeout.title"
          defaultValue="$$Pobieranie danych trwa dłuzej niz zwykle"
        />
      </div>
      <p>
        <Translation
          text="$*components.loadingTimeout.paragraph"
          defaultValue="$$Sprawdź jakość swojego połączenia z internetem"
        />
      </p>
    </div>
  );
};

LoadingTimeout.displayName = 'LoadingTimeout';

export default LoadingTimeout;
