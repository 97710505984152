const EditIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 18 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}

    <path
      d="M1.39442 15.0085H1.49584L6.38881 13.9437C6.48411 13.9279 6.57243 13.8837 6.64231 13.817L14.9324 5.52684C15.538 4.92255 15.8759 4.10062 15.8704 3.24517C15.8716 2.38306 15.5348 1.55488 14.9324 0.93812C14.3282 0.332522 13.5062 -0.0053611 12.6507 0.000122286C11.7898 -0.00749622 10.964 0.34116 10.3691 0.963499L2.10425 9.2536C2.03137 9.31226 1.97823 9.39189 1.95212 9.48176L0.887377 14.3747C0.85831 14.5504 0.914794 14.7294 1.0395 14.8564C1.13311 14.9519 1.26068 15.0066 1.39442 15.0085ZM12.6507 1.01421C13.8689 1.01411 14.8564 2.00156 14.8565 3.21969C14.8565 3.22818 14.8565 3.23668 14.8564 3.24517C14.8647 3.82548 14.6357 4.38406 14.2226 4.79163L11.1043 1.64795C11.5151 1.23971 12.0715 1.01164 12.6507 1.01421ZM10.3944 2.38321L13.5127 5.50151L6.28734 12.7015L3.16905 9.60851L10.3944 2.38321ZM2.76342 10.6226L5.27326 13.1325L2.05354 13.8424L2.76342 10.6226Z"
      fill="#031324"
    />
    <path
      d="M17.493 17.7461H0.507043C0.227002 17.7461 0 17.9731 0 18.2531C0 18.5332 0.227002 18.7602 0.507043 18.7602H17.493C17.773 18.7602 18 18.5332 18 18.2531C18 17.9731 17.773 17.7461 17.493 17.7461Z"
      fill="#031324"
    />
  </svg>
);

export default EditIcon;
