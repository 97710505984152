import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { AddressLineInfo, DietLineInfo, PriceWrapper } from '@components';
import { isEmptyObject, isFalsify } from '@utils/helpers';
import { useOrderDetailsPageContext } from '@views/MyAccount/OrderDetailsPage/OrderDetailsPage.context';

import BasketModificationTooltip from './BasketModificationTooltip';

const BasketModification = ({ modification = {} }) => {
  const { t } = useTranslation();

  const { userBagDetailsQuery } = useOrderDetailsPageContext();
  const bagDetails = userBagDetailsQuery?.data ?? {};

  const delivery = bagDetails?.delivery;
  const address = delivery?.address || {};
  const pickUpPoint = delivery?.pickUpPoint || null;
  const dietConfiguration = bagDetails?.dietConfiguration;

  const isSaturdayIncludeModified = modification?.options?.saturdayInclude;
  const isSundayIncludeModified = modification?.options?.sundayInclude;
  const isUseEcoContainersModified = modification?.options?.useEcoContainers;
  const isOptionChangeMenuModified = modification?.options?.optionChangeMenu;
  const isDateModified = modification?.date;
  const isAddressModified = modification?.address;
  const isPickUpPointModified = modification?.pickUpPoint;
  const isDietModified = modification?.diet;
  const isCalorificModified = modification?.calorific;
  const isVariantModified = modification?.variant;
  const isAddonsModified = !isEmpty(modification?.addons);
  const isOverrideAddonsModified = modification?.overrideAddons;
  const isRequestedMenuModified = !isEmpty(modification?.requestedMenu);

  if (isFalsify(modification)) {
    return null;
  }

  return (
    <Fragment>
      <div tw="flex justify-between font-semibold text-primary text-sm pb-2">
        <div>
          {t(
            '$*components.basketModification.modification',
            '$$Modifykacje zamówienia'
          )}
        </div>
        <div>
          <PriceWrapper
            beforeDiscount={modification?.price?.beforeDiscount ?? 0}
            afterDiscount={modification?.price?.afterDiscount ?? 0}
          />
        </div>
      </div>

      {isSaturdayIncludeModified && (
        <div tw="flex items-center text-primary text-sm">
          {t(
            '$*components.basketModification.saturdayInclude',
            '$$Dostawa w sobotę'
          )}
          <BasketModificationTooltip />
        </div>
      )}

      {isSundayIncludeModified && (
        <div tw="flex items-center text-primary text-sm">
          {t(
            '$*components.basketModification.sundayInclude',
            '$$Dostawa w niedzielę'
          )}
          <BasketModificationTooltip />
        </div>
      )}

      {isUseEcoContainersModified && (
        <div tw="flex items-center text-primary text-sm">
          {t(
            '$*components.basketModification.useEcoContainers',
            '$$Użyj eko opakowań'
          )}
          <BasketModificationTooltip />
        </div>
      )}

      {isOptionChangeMenuModified && (
        <div tw="flex items-center text-primary text-sm">
          {t(
            '$*components.basketModification.optionChangeMenu',
            '$$Możliwość zmiany menu'
          )}
          <BasketModificationTooltip />
        </div>
      )}

      {isDateModified && (
        <div tw="flex items-center text-primary text-sm">
          {t('$*components.basketModification.date', '$$Zmiana daty dostawy')}
          <BasketModificationTooltip />
        </div>
      )}

      {(isAddressModified || isPickUpPointModified) && (
        <div tw="flex items-center text-primary text-sm">
          {t('$*components.basketModification.address', '$$Zmiana adresu')}
          <BasketModificationTooltip
            renderFrom={() =>
              !isEmptyObject(address) ? (
                <AddressLineInfo {...address} />
              ) : (
                pickUpPoint?.value
              )
            }
            renderTo={() => <AddressLineInfo {...modification.address} />}
          />
        </div>
      )}

      {(isDietModified || isCalorificModified || isVariantModified) && (
        <div tw="flex items-center text-primary text-sm">
          {t('$*components.basketModification.diet', '$$Zmiana diety')}
          <BasketModificationTooltip
            renderFrom={() => (
              <DietLineInfo
                dietType={dietConfiguration?.diet?.name}
                variant={dietConfiguration?.variant?.name}
                calorific={dietConfiguration?.calorific?.name}
              />
            )}
            renderTo={() => (
              <DietLineInfo
                dietType={modification?.diet?.name}
                variant={modification?.variant?.name}
                calorific={modification?.calorific?.name}
              />
            )}
          />
        </div>
      )}

      {isAddonsModified && !isOverrideAddonsModified && (
        <div tw="flex items-center text-primary text-sm">
          {t('$*components.basketModification.addons', '$$Zakupione dodatki')}
          <BasketModificationTooltip />
        </div>
      )}

      {isOverrideAddonsModified && (
        <div tw="flex items-center text-primary text-sm">
          {t(
            '$*components.basketModification.overrideAddons',
            '$$Dokupione dodatki'
          )}
          <BasketModificationTooltip />
        </div>
      )}

      {isRequestedMenuModified && (
        <div tw="flex items-center text-primary text-sm">
          {t('$*components.basketModification.requestedMenu', '$$Wybór dań')}
          <BasketModificationTooltip
            renderFrom={() => null}
            renderTo={() => (
              <div>
                {modification?.requestedMenu?.map(
                  ({ dish, mealType }, index) => (
                    <p
                      key={index}
                    >{`${mealType.name} - ${dish.nameForClient}`}</p>
                  )
                )}
              </div>
            )}
          />
        </div>
      )}
    </Fragment>
  );
};

export default BasketModification;
