import { useRouter } from 'next/router';

import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { LanguageSelectorCollapse, Link } from '@components';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import useNavItems from '../Nav/useNavItems';

const variants = {
  show: {
    transform: 'translateX(0em)',
    opacity: 1,
  },
  hide: {
    transform: 'translateX(5em)',
    opacity: 0,
  },
};

const NavMenu = ({ isOpen, toggle }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { clientAllowedToChangeLanguage } =
    useAppConfigSelector(selectMultinational);

  const navItems = useNavItems(true);
  const router = useRouter();
  let renderedIndex = 0;

  return (
    <nav>
      {clientAllowedToChangeLanguage && (
        <div tw="pb-4">
          <LanguageSelectorCollapse />
        </div>
      )}

      <ul tw="-my-2 xxl:-mx-3">
        {navItems.map(
          (
            {
              listItem,
              link: { label, isProtected, onClick = () => {}, ...linkProps },
            },
            index
          ) => {
            if (!isAuthenticated && isProtected) {
              return null;
            }

            renderedIndex += 1;

            return (
              <motion.li
                {...listItem}
                key={index}
                tw="py-2"
                initial={false}
                animate={isOpen ? 'show' : 'hide'}
                variants={{
                  show: {
                    ...variants.show,
                    transition: {
                      delay: (0.2 + (renderedIndex + 1) * 0.1).toFixed(1),
                      duration: 0.15,
                    },
                  },
                  hide: {
                    ...variants.hide,
                    transition: {
                      delay: 0.05 + (renderedIndex + 1) * 0.05,
                      duration: 0.05,
                    },
                  },
                }}
              >
                <Link
                  {...linkProps}
                  styles={
                    router.asPath === linkProps.href
                      ? tw`text-lg font-semibold text-primary`
                      : tw`text-lg text-gray-700`
                  }
                  onClick={() => {
                    onClick();
                    toggle();
                  }}
                >
                  {label}
                </Link>
              </motion.li>
            );
          }
        )}
      </ul>
    </nav>
  );
};

export default NavMenu;
