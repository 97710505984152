import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';
import { v4 as uuidv4 } from 'uuid';

import { ExclamationIcon } from '@assets/icons';
import WarningIcon from '@assets/icons/WarningIcon';
import { AddressLineInfo, Collapse, PriceWrapper, Tooltip } from '@components';
import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import { useBasketMethods } from '@hooks';
import { transformDeliveryHourToString } from '@services/Api.service';
import { format } from '@services/Date.service';
import { isEmptyObject } from '@utils/helpers';

import 'tippy.js/themes/light-border.css';

const BasketDay = ({
  day,
  dayPrice = {},
  addons = [],
  dishes = [],
  delivery = {},
  dietIri,
  isValidDayRequirements = false,
  errorMessageDayRequirements = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    basketStore,
    handleChangeAddonQuantity,
    handleChangeProductQuantity,
    isBasketEditOrder,
    toggleBasketDay,
  } = useBasketMethods();

  const formattedDate = format(day);
  const isExpanded =
    basketStore.collapsed?.[dietIri]?.days?.[formattedDate]?.isOpen ?? false;

  const address = delivery?.address ?? {};
  const pickUpPoint = delivery?.pickUpPoint || null;

  const deliveryHourString = transformDeliveryHourToString({
    hour: {
      hourFrom: address?.deliveryHourFrom ?? null,
      hourTo: address?.deliveryHourTo ?? null,
    },
    translations: {
      bothHours: {
        from: t('$*common.deliveryHours.between', '$$Pomiędzy'),
        connector: '',
        to: t('$*common.deliveryHours.betweenA', '$$a'),
      },
      singularHour: { from: t('$*common.from'), to: t('$*common.to') },
    },
    t,
  });

  const handleToggleBasketDay = () => {
    dispatch(toggleBasketDay({ date: formattedDate, dietIri }));
  };

  return (
    <div tw="py-2 overflow-hidden -mx-2">
      <motion.header initial={false} onClick={handleToggleBasketDay}>
        <div tw="px-2 py-1 text-sm font-semibold bg-gray-100 rounded-md cursor-pointer">
          <div tw="flex justify-between">
            <div tw="capitalize flex items-center">
              {format(day, 'eeee')}

              {!isValidDayRequirements && (
                <Tooltip
                  content={
                    <div tw="text-xs text-red-500">
                      {errorMessageDayRequirements}
                    </div>
                  }
                  maxWidth={300}
                  theme="light-border"
                >
                  <div tw="ml-2">
                    <WarningIcon tw="text-red-500 w-4" />
                  </div>
                </Tooltip>
              )}
            </div>
            <div>
              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
          <div tw="flex justify-between">
            <div>{format(day, 'dd.MM.Y')}</div>
            <motion.div
              initial="initial"
              animate={isExpanded ? 'initial' : 'animated'}
              variants={{
                initial: { opacity: 0 },
                animated: { opacity: 1 },
              }}
            >
              <PriceWrapper
                beforeDiscount={dayPrice.beforeDiscount}
                afterDiscount={dayPrice.afterDiscount}
              />
            </motion.div>
          </div>
        </div>
      </motion.header>

      <Collapse isExpanded={isExpanded} styles={tw`px-2`}>
        <div tw="flex justify-between border-b border-dashed pt-3 pb-2 mb-3 border-primary font-semibold text-primary text-sm">
          <div>
            {t('$*components.basketDay.pricePerDay', '$$Cena za dzień')}
          </div>
          <div>
            <PriceWrapper
              beforeDiscount={dayPrice.beforeDiscount}
              afterDiscount={dayPrice.afterDiscount}
            />
          </div>
        </div>

        <div tw="-my-2">
          {dishes.map(({ dish, sizes }) => (
            <div tw="text-sm py-2" key={dish['@id']}>
              <div tw="text-primary font-medium pb-1">{dish.nameForClient}</div>
              {sizes.map(({ name, kcal, quantity, price, dishSize }) => (
                <div
                  tw="flex justify-between -my-1.5 items-center"
                  key={uuidv4()}
                >
                  <div>{`${name}${
                    kcal ? ` (${kcal} ${t('$*common.kcal', '$$kcal')})` : ''
                  }`}</div>
                  <div tw="flex -m-2 py-1.5 items-center">
                    <PriceWrapper
                      beforeDiscount={price.beforeDiscount}
                      afterDiscount={price.afterDiscount}
                      styles={tw`p-2`}
                    />
                    <div tw="p-2">
                      <CheckboxCounter
                        count={quantity}
                        min={0}
                        max={99}
                        onChange={(_, newQuantity) =>
                          handleChangeProductQuantity({
                            dishIri: dishSize['@id'],
                            quantity: newQuantity,
                            day: format(day),
                            dietIri,
                            isRemove: newQuantity < quantity,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}

          <div tw="text-sm py-2">
            {addons.map(({ '@id': addonIri, price, name, quantity }) => (
              <div
                tw="flex justify-between -my-1.5 items-center flex-auto"
                key={addonIri}
              >
                <div tw="pr-2 py-1">{name}</div>
                <div tw="flex -mx-2 py-1.5 items-center flex-shrink-0">
                  <PriceWrapper
                    beforeDiscount={price.beforeDiscount}
                    afterDiscount={price.afterDiscount}
                    styles={{ wrapper: { css: tw`pl-2` } }}
                  />
                  <div tw="px-2">
                    <CheckboxCounter
                      count={quantity}
                      min={0}
                      max={99}
                      onChange={(_, quantity) =>
                        handleChangeAddonQuantity({
                          addonIri,
                          quantity,
                          day: format(day),
                          dietIri,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {!isBasketEditOrder && (
            <div tw="text-sm py-2">
              <div tw="flex justify-between">
                <div tw="text-primary font-medium flex items-center">
                  {t('$*components.basketDay.delivery', '$$Dostawa')}

                  <Tooltip
                    content={
                      <Fragment>
                        {!isEmptyObject(address) || !isEmpty(pickUpPoint) ? (
                          <Fragment>
                            {!isEmptyObject(address) && (
                              <Fragment>
                                <p tw="mb-1">
                                  {t(
                                    '$*components.basketDay.delivery.address',
                                    '$$Dostawa na adres'
                                  )}
                                  :
                                </p>
                                <div tw="mb-1">
                                  <AddressLineInfo {...address} />
                                </div>
                              </Fragment>
                            )}
                            {!isEmpty(pickUpPoint) && (
                              <Fragment>
                                <p tw="mb-1">
                                  {t(
                                    '$*components.basketDay.delivery.pickUpPoint',
                                    '$$Odbiór w adresie'
                                  )}
                                  :
                                </p>
                                <p tw="mb-1">{pickUpPoint.value}</p>
                              </Fragment>
                            )}

                            {deliveryHourString}
                          </Fragment>
                        ) : (
                          <div>
                            {t(
                              '$*components.basketDay.delivery.noAddress',
                              '$$Brak adresu dostawy'
                            )}
                          </div>
                        )}
                      </Fragment>
                    }
                  >
                    <div tw="ml-3">
                      <ExclamationIcon tw="w-4 cursor-pointer" />
                    </div>
                  </Tooltip>
                </div>
                <PriceWrapper
                  beforeDiscount={delivery?.price?.beforeDiscount}
                  afterDiscount={delivery?.price?.afterDiscount}
                  styles={tw`p-2`}
                />
              </div>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default BasketDay;
