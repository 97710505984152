import { SelectAddonsSectionProvider } from './SelectAddonsSection.context';
import SelectAddonsSectionView from './SelectAddonsSection.view';

const SelectAddonsSection = ({
  onSwitchMode,
  selectedDate,
  selectedDietIri,
  activeOrderDietIri,
  deliveryModesEnabled,
  checkIsAddonSelected,
  initialSelectedAddons,
  isSaveChangesModeActive,
  handleSelectedAddonsAdd,
  handleChangeAddonQuantity,
  ...viewProps
}) => {
  const providerProps = {
    onSwitchMode,
    selectedDate,
    selectedDietIri,
    activeOrderDietIri,
    deliveryModesEnabled,
    checkIsAddonSelected,
    initialSelectedAddons,
    isSaveChangesModeActive,
    handleSelectedAddonsAdd,
    handleChangeAddonQuantity,
  };

  return (
    <SelectAddonsSectionProvider {...providerProps}>
      <SelectAddonsSectionView
        {...viewProps}
        isSaveChangesModeActive={isSaveChangesModeActive}
      />
    </SelectAddonsSectionProvider>
  );
};

export default SelectAddonsSection;
