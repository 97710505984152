import { useTranslation } from 'next-i18next';

import { createContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectMyDiet } from '@store/myDiet/myDiet.slice';
import first from 'lodash/first';
import last from 'lodash/last';

import ADDITIONAL_ACTIONS_TYPES from '@constants/additionalActionsTypes';
import {
  useBasketMethods,
  useUserDietDeliveryDays,
  useUserDietUpdate,
  useUserSubscription,
} from '@hooks';
import showToast from '@utils/showToast';

export const SubscriptionManagementContext = createContext([{}, () => {}]);

const SubscriptionManagementProvider = ({
  children,
  id: dietId,
  subscription = {},
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { calendarDays, selectedDietId } = useSelector(selectMyDiet);

  const { basketCommonCatch } = useBasketMethods();

  const firstMonthDay = first(calendarDays);
  const lastMonthDay = last(calendarDays);

  const [
    isSubscriptionManagementModalOpened,
    setIsSubscriptionManagementModalOpened,
  ] = useState(false);
  const [
    isSubscriptionAddressChangeModalOpened,
    setIsSubscriptionAddressChangeModalOpened,
  ] = useState(false);
  const [
    isSubscriptionDietChangeModalOpened,
    setIsSubscriptionDietChangeModalOpened,
  ] = useState(false);
  const [
    isSubscriptionAddonsChangeModalOpened,
    setIsSubscriptionAddonsChangeModalOpened,
  ] = useState(false);
  const [additionalActions, setAdditionalActions] = useState({});
  const [isSubscriptionCancelModalOpened, setIsSubscriptionCancelModalOpened] =
    useState(false);

  const deliveryDays = useUserDietDeliveryDays({
    dietId,
  });

  const currentSubscription = useUserSubscription({
    subscriptionId: subscription?.id,
  });

  const updateUserDietQuery = useUserDietUpdate({
    id: subscription?.id,
  });

  const { mutateAsync: updateUserDiet } = updateUserDietQuery;

  const handleUpdateUserDiet = ({
    payload,
    options: { orderEditingActionType } = {},
  }) => {
    updateUserDiet({ id: selectedDietId, payload })
      .then(() => {
        showToast(
          t(
            '$*notification.update.success',
            '$$The data has been successfully updated'
          ),
          { type: 'success' }
        );

        queryClient.invalidateQueries('userDietCalendar', {
          dateFrom: firstMonthDay,
          dateTo: lastMonthDay,
          dietId: selectedDietId,
        });
      })
      .catch(({ response: { status, data } }) => {
        switch (status) {
          case 402:
            setAdditionalActions({
              actionType: ADDITIONAL_ACTIONS_TYPES.SURCHARGE,
              orderEditingActionType,
              surcharge: data?.price,
              payload: { ...payload },
            });
            break;
          case 409:
            setAdditionalActions({
              actionType:
                data?.['@type'] === 'PriceChangeKeyRequired'
                  ? ADDITIONAL_ACTIONS_TYPES.PRICE_CHANGE
                  : ADDITIONAL_ACTIONS_TYPES.CASHBACK,
              orderEditingActionType: orderEditingActionType,
              surcharge: data?.price,
              returnCost: data?.returnCost,
              priceChange: data,
              payload: { ...payload },
            });
            break;
          default: {
            basketCommonCatch(data);

            break;
          }
        }
      });
  };

  const value = {
    deliveryDays,
    subscription: currentSubscription,
    additionalActions,
    setAdditionalActions,
    handleUpdateUserDiet,
    isSubscriptionCancelModalOpened,
    setIsSubscriptionCancelModalOpened,
    isSubscriptionManagementModalOpened,
    isSubscriptionDietChangeModalOpened,
    isSubscriptionAddonsChangeModalOpened,
    setIsSubscriptionManagementModalOpened,
    isSubscriptionAddressChangeModalOpened,
    setIsSubscriptionDietChangeModalOpened,
    setIsSubscriptionAddonsChangeModalOpened,
    setIsSubscriptionAddressChangeModalOpened,
  };

  return (
    <SubscriptionManagementContext.Provider value={value}>
      {children}
    </SubscriptionManagementContext.Provider>
  );
};

export default SubscriptionManagementProvider;
