import DisplayOn from './DisplayOn';

const DisplayDownLg = ({ children }) => (
  <DisplayOn size="lg" mode="down" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayDownLg.displayName = 'DisplayDownLg';

export default DisplayDownLg;
