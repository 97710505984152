import { useMediaQuery } from '@hooks';
import { between, down, only, up } from '@utils/screens';

const DisplayOn = ({
  children,
  size = 'lg',
  mode = 'up',
  defaultState = false,
}) => {
  let isScreenQuery = false;
  const modeFn =
    {
      down,
      only,
      up,
    }[mode] || up;

  if (mode === 'between' && Array.isArray(size)) {
    const [min, max] = size;
    isScreenQuery = useMediaQuery(between(min, max), defaultState);
  } else {
    isScreenQuery = useMediaQuery(modeFn(size), defaultState);
  }

  if (isScreenQuery) {
    return children;
  }

  return null;
};

DisplayOn.displayName = 'DisplayOn';

export default DisplayOn;
