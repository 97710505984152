import React from 'react';

import {
  selectModuleAddons,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import AddonsGrouped from './AddonsGrouped';
import AddonsList from './AddonsList';

const Addons = ({
  items = [],
  checkIsAddonSelected = () => {},
  onChangeQuantity = () => {},
  dishDate,
  basketItemsQuantity,
  deliveryModesEnabled,
  basketItemsDeliveryModes,
  selectedDietIri,
}) => {
  const { groupByCategory } = useAppConfigSelector(selectModuleAddons);

  const addonsProps = {
    basketItemsQuantity,
    deliveryModesEnabled,
    basketItemsDeliveryModes,
    checkIsAddonSelected,
    dishDate,
    items,
    onChangeQuantity,
    selectedDietIri,
  };

  if (groupByCategory) {
    return <AddonsGrouped {...addonsProps} />;
  }

  return <AddonsList {...addonsProps} />;
};

export default Addons;
