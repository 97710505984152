const DeliveryCarIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fillRule="evenodd"
    viewBox="0 0 75 55"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M11.68 47.61c1.28 4.16 5.24 7.21 9.94 7.21 4.71 0 8.66-3.05 9.95-7.21h13.28c1.29 4.16 5.24 7.21 9.95 7.21 4.7 0 8.66-3.05 9.94-7.21h6.47c1.61 0 2.92-1.31 2.92-2.92V28.43c0-.51-.14-1.01-.39-1.45l-3.45-6.04c-.02-.03-.04-.07-.06-.1L61.85 6.19a2.92 2.92 0 00-2.53-1.47H48.9V2.91C48.9 1.3 47.6 0 45.99 0H2.92C1.3 0 0 1.3 0 2.91v41.78c0 1.61 1.3 2.92 2.92 2.92h8.76zm5.39-2.9v-.03c.01-2.39 2.08-4.28 4.55-4.28 2.48 0 4.55 1.9 4.55 4.3 0 2.39-2.07 4.29-4.55 4.29-2.47 0-4.54-1.89-4.55-4.28zm42.28-.05c-.02-2.38-2.08-4.26-4.55-4.26-2.46 0-4.5 1.85-4.55 4.21V44.78c.05 2.36 2.09 4.21 4.55 4.21 2.47 0 4.53-1.88 4.55-4.26v-.04-.03zm6.68-19.41H45.99c-1.62 0-2.92-1.3-2.92-2.91V5.83H5.83v35.95h5.85c1.29-4.16 5.24-7.21 9.94-7.21 4.71 0 8.66 3.05 9.94 7.21h13.29c1.29-4.16 5.24-7.21 9.95-7.21 4.7 0 8.66 3.05 9.94 7.21h3.55V29.2l-2.26-3.95zM48.9 19.42h13.8l-5.08-8.86H48.9v8.86z" />
  </svg>
);

export default DeliveryCarIcon;
