import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const updateUserAddress = async ({ id, address }) => {
  const response = await axios.put(ENDPOINT.ADDRESS(id), address);

  return response?.data ?? {};
};

const useUpdateUserAddress = (options = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(updateUserAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries('userAddresses');

      showToast(
        t(
          '$*notification.updateUserAddress.success',
          '$$Adress został pomyślnie zaktualizowany.'
        ),
        { type: 'success' }
      );
    },
    ...options,
  });

  return query;
};

export default useUpdateUserAddress;
