import { useTranslation } from 'next-i18next';

import React, { useState } from 'react';

import { ChevronUpIcon } from '@assets/icons';

import coreStyles from './Pagination.styles';

const Pagination = ({
  elements = [],
  itemsPerPage = 1,
  setCurrentItems = () => {},
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const numberOfPages = Math.ceil(elements.length / itemsPerPage);

  const setNextPage = () => {
    if (currentPage !== numberOfPages) {
      setCurrentPage(currentPage + 1);
      const newIndex = currentIndex + itemsPerPage;
      setCurrentItems(elements.slice(newIndex, newIndex + itemsPerPage));
      setCurrentIndex(newIndex);
    }
  };

  const setPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      const newIndex = currentIndex - itemsPerPage;
      setCurrentItems(elements.slice(newIndex, newIndex + itemsPerPage));
      setCurrentIndex(newIndex);
    }
  };

  return (
    <div css={coreStyles.wrapper}>
      <div css={coreStyles.arrowLeft} onClick={setPreviousPage}>
        <ChevronUpIcon />
      </div>
      <p tw="mb-0">
        {t('$*common.pagination.page', {
          replace: {
            currentPage,
            numberOfPages,
          },
        })}
      </p>
      <div css={coreStyles.arrowRight} onClick={setNextPage}>
        <ChevronUpIcon tw="w-4" />
      </div>
    </div>
  );
};

export default Pagination;
