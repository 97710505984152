import React from 'react';

import { CalendarIcon } from '@assets/icons';

const DurationTile = ({ durationTime }) => {
  return (
    <div tw="flex">
      <CalendarIcon tw="w-5" />
      <p tw="pl-3">{`${durationTime} min`}</p>
    </div>
  );
};

export default DurationTile;
