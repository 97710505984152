import { useRouter } from 'next/router';

import { forwardRef } from 'react';
import ReactDayPicker, { LocaleUtils } from 'react-day-picker';

import {
  FIRST_DAY_OF_WEEK,
  LABELS,
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from '@services/Calendar.service';

const DayPicker = forwardRef((props, ref) => {
  const router = useRouter();
  const { locale } = router;

  const reactDayPickerProps = {
    ref,
    locale,
    localeUtils: { ...LocaleUtils },
    firstDayOfWeek: FIRST_DAY_OF_WEEK[locale],
    labels: LABELS[locale],
    months: MONTHS[locale],
    weekdaysLong: WEEKDAYS_LONG[locale],
    weekdaysShort: WEEKDAYS_SHORT[locale],
    ...props,
  };

  return <ReactDayPicker {...reactDayPickerProps} />;
});

DayPicker.displayName = 'DayPicker';

export default DayPicker;
