import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getPaymentCards = async () => {
  const response = await axios.get(ENDPOINT.PAYMENT_CARDS);

  return response?.data?.['hydra:member'];
};

const usePaymentCards = (options = {}) => {
  const query = useQuery('paymentCards', getPaymentCards, options);

  return query;
};

export default usePaymentCards;
