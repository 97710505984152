import { useTranslation } from 'next-i18next';

import PropTypes from 'prop-types';

const UnknownInput = ({ type }) => {
  const { t } = useTranslation();

  return (
    <div>
      {t('$*components.unknownInput.label', {
        defaultValue: '$$Pole typu {{ type }} nie jest obsługiwane.',
        type,
      })}
    </div>
  );
};

UnknownInput.defaultProps = {
  type: 'noType',
};

UnknownInput.propTypes = {
  type: PropTypes.string,
};

UnknownInput.displayName = 'UnknownInput';

export default UnknownInput;
