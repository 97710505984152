import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { createContext, useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { getUserData } from '@store/auth/auth.actions';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import last from 'lodash/last';
import uniq from 'lodash/uniq';

import ADDITIONAL_ACTIONS_TYPES from '@constants/additionalActionsTypes';
import DAY_STATUSES from '@constants/dayStatuses';
import ROUTE_URLS from '@constants/routeUrls';
import {
  useBasketMethods,
  useCreateUserBagRate,
  useMediaQuery,
  useUserBagDetails,
  useUserBagDetailsUpdate,
  useUserBagItems,
  useUserDietCalendar,
  useUserDietDeliveryDays,
  useUserDietUpdate,
} from '@hooks';
import {
  selectConfig,
  selectDisabledDaysOfWeek,
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import PlanMenuService from '@services/ChangeMenu.service';
import { format, formatArray } from '@services/Date.service';
import OrderDetailsService from '@services/OrderDetails.service';
import OrdersCalendarService from '@services/OrdersCalendar.service';
import { between, up } from '@utils/screens';
import showToast from '@utils/showToast';

const OrderDetailsPageContext = createContext([{}, () => {}]);

const OrderDetailsPageProvider = ({ children }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { day, dietId, bagId, type } = router.query;
  const queryClient = useQueryClient();
  const shopElementsAddonsSectionRef = useRef();
  const dispatch = useDispatch();

  const { basketCommonCatch } = useBasketMethods();
  const { disabledDays } = useAppConfigSelector(selectConfig);
  const { hideDisabledDaysOnClientCalendar, changeMenuMode = {} } =
    useAppConfigSelector(selectModuleConfigClientPanel);
  const disabledDaysOfWeek = useAppConfigSelector(selectDisabledDaysOfWeek);

  const updateUserDietQuery = useUserDietUpdate({
    id: dietId,
  });

  const updateUserBagDetailsQuery = useUserBagDetailsUpdate({
    id: dietId,
    date: day,
  });

  const { mutateAsync: updateUserDiet } = updateUserDietQuery;
  const { mutateAsync: updateUserBagDetails } = updateUserBagDetailsQuery;

  const isUpXxl = useMediaQuery(up('xxl'), true);
  const isXl = useMediaQuery(between('xl', 'xxl'), true);
  const isLg = useMediaQuery(between('lg', 'xl'), true);

  const getDiffDaysCount = () => {
    if (isUpXxl) {
      return {
        current: { start: 3, end: 3 },
        prev: { start: 7, end: 1 },
        next: { start: 1, end: 7 },
      };
    }

    if (isXl) {
      return {
        current: { start: 2, end: 2 },
        prev: { start: 5, end: 1 },
        next: { start: 1, end: 5 },
      };
    }

    if (isLg) {
      return {
        current: { start: 2, end: 3 },
        prev: { start: 6, end: 1 },
        next: { start: 1, end: 6 },
      };
    }

    return {
      current: { start: 3, end: 3 },
      prev: { start: 7, end: 1 },
      next: { start: 1, end: 7 },
    };
  };

  const {
    current: currentIntervalOptions,
    prev: prevIntervalOptions,
    next: nextIntervalOptions,
  } = getDiffDaysCount();

  const formattedDisabledDays = hideDisabledDaysOnClientCalendar
    ? disabledDays.map(day => format(new Date(day), 'yyyy-MM-dd'))
    : [];

  const visibleDays = OrdersCalendarService.getVisibleDays({
    selectedDay: new Date(day),
    numberOfDays: currentIntervalOptions,
    disabledDays: formattedDisabledDays,
    permanentlyDisabledDays: disabledDaysOfWeek,
  });

  const rangeDays = formatArray(visibleDays);

  const getUniqMonths = days =>
    uniq(days.map(date => format(date, 'LLLL yyyy')));

  const [calendarDays, setCalendarDays] = useState(rangeDays);
  const [activeMonths, setActiveMonths] = useState(getUniqMonths(rangeDays));

  //Order editing
  const [additionalActions, setAdditionalActions] = useState({});
  const [selectedPaidMenuOptions, selectPaidMenuOptions] = useState({});
  const [openedOrderEditingModal, setOpenedOrderEditingModal] = useState(null);

  const [menuClickedDish, setMenuClickedDish] = useState({});

  useEffect(() => {
    setCalendarDays(rangeDays);
  }, [day]);

  useEffect(() => {
    setActiveMonths(getUniqMonths(calendarDays));
  }, [calendarDays]);

  useEffect(() => {
    setCalendarDays(rangeDays);
  }, [JSON.stringify(currentIntervalOptions)]);

  const activeDayDietCalendarQuery = useUserDietCalendar({
    dateFrom: first(rangeDays),
    dateTo: last(rangeDays),
    dietId,
  });

  const userDietCalendarQuery = useUserDietCalendar({
    dateFrom: first(calendarDays),
    dateTo: last(calendarDays),
    dietId,
  });

  const [selectedDietId, setSelectedDietId] = useState(null);
  const nextDietCalendarQuery = useUserDietCalendar({
    dateFrom: day,
    dateTo: day,
    dietId: selectedDietId,
  });

  const handleChangeDiet = diet => setSelectedDietId(diet.id);

  useEffect(() => {
    if (nextDietCalendarQuery.isFetched) {
      const { id, '@type': type } =
        nextDietCalendarQuery?.data?.days?.[day] || {};

      router.push(
        ROUTE_URLS.MY_ORDER_DETAILS({
          day: day,
          dietId: selectedDietId,
          bagId: isNull(id) ? 0 : id,
          type,
        })
      );
    }
  }, [nextDietCalendarQuery.isFetched, selectedDietId]);

  const currentDateState =
    activeDayDietCalendarQuery.data?.days?.[day]?.newState ?? //NOT_DELIVERED_WITH_CONFIGURABLE_ALL
    DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER;

  const currentBagType =
    activeDayDietCalendarQuery.data?.days?.[day]?.['@type'];

  const isEabledUserBagItems = OrderDetailsService.isDayStatusNeedUserBagItems({
    bagState: currentDateState,
  });

  const userBagDetailsQuery = useUserBagDetails({
    id: dietId,
    date: day,
  });

  const userBagItemsQuery = useUserBagItems(
    { id: dietId, date: day },
    { enabled: isEabledUserBagItems }
  );

  const deliveryDays = useUserDietDeliveryDays({
    dietId,
  });

  const { mutateAsync: updateBagRate } = useCreateUserBagRate();

  const scrollToRef = ref => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleNavigationCalendarClick = ({ direction, offset }) => {
    const currentCenteredDay =
      calendarDays.length >= currentIntervalOptions.start
        ? calendarDays[currentIntervalOptions.start]
        : last(calendarDays);

    const directionCenteredDay = OrdersCalendarService.getDirectionCenteredDay({
      direction,
      currentCenteredDay,
      offset,
      disabledDays: formattedDisabledDays,
      permanentlyDisabledDays: disabledDaysOfWeek,
    });

    const visibleDays = OrdersCalendarService.getVisibleDays({
      selectedDay: new Date(directionCenteredDay),
      numberOfDays: currentIntervalOptions,
      disabledDays: formattedDisabledDays,
      permanentlyDisabledDays: disabledDaysOfWeek,
    });

    const rangeDays = formatArray(visibleDays);

    setCalendarDays(rangeDays);
  };

  const handlePrevClickCalendar = () =>
    handleNavigationCalendarClick({
      direction: 'prev',
      offset: prevIntervalOptions.start,
    });

  const handleNextClickCalendar = () =>
    handleNavigationCalendarClick({
      direction: 'next',
      offset: nextIntervalOptions.end,
    });

  const handleUpdateBagRate = payload => {
    updateBagRate(payload);
  };

  const handleFreeMenuChange = (items = []) => {
    return PlanMenuService.changeMenu({
      date: day,
      dietId,
      payload: {
        items,
      },
    });
  };

  const handleUserBagDetailsUpdate = async ({
    payload = {},
    options: { orderEditingActionType } = {},
  }) => {
    if (isEmpty(payload)) return;

    return updateUserBagDetails({ id: dietId, date: day, payload })
      .then(() => {
        setOpenedOrderEditingModal(null);
        showToast(
          t(
            '$*notification.update.success',
            '$$Dane zostały pomyślnie zaktualizowane'
          ),
          { type: 'success' }
        );
      })
      .catch(({ response: { data, status } }) => {
        switch (status) {
          case 402:
            setAdditionalActions({
              actionType: ADDITIONAL_ACTIONS_TYPES.SURCHARGE,
              orderEditingActionType: orderEditingActionType,
              surcharge: data?.price,
              payload,
            });
            break;
          case 409:
            setAdditionalActions({
              actionType:
                data?.['@type'] === 'PriceChangeKeyRequired'
                  ? ADDITIONAL_ACTIONS_TYPES.PRICE_CHANGE
                  : ADDITIONAL_ACTIONS_TYPES.CASHBACK,
              orderEditingActionType: orderEditingActionType,
              surcharge: data?.price,
              returnCost: data?.returnCost,
              priceChange: data,
              payload,
            });
            break;
          default: {
            basketCommonCatch(data);

            break;
          }
        }
      });
  };

  const handleDeliveryDateChange = async ({ oldDates = [], newDates = [] }) => {
    if (isEmpty(oldDates) || isEmpty(newDates)) return;

    return updateUserBagDetails({
      id: dietId,
      date: day,
      payload: {
        date: new Date(newDates[0]),
      },
    })
      .then(() => {
        showToast(
          t(
            '$*notification.update.success',
            '$$Dane zostały pomyślnie zaktualizowane'
          ),
          { type: 'success' }
        );
        setOpenedOrderEditingModal(null);
        queryClient.invalidateQueries('userDietDeliveryDays');

        router.push(
          ROUTE_URLS.MY_ORDER_DETAILS({
            day: format(new Date(newDates[0])),
            dietId: dietId,
            bagId: isNull(bagId) ? 0 : bagId,
            type: 'Bag',
          })
        );
      })
      .catch(error => {
        const violations = error?.response?.data?.violations ?? [];
        violations.map(violation => {
          showToast(t(violation.message), { type: 'error' });
        });
      });
  };

  const handle409ResponseAccept = async payload => {
    return updateUserBagDetails({
      id: dietId,
      date: day,
      payload: {
        ...payload,
      },
    })
      .then(() => {
        dispatch(getUserData());
        showToast(
          t(
            '$*notification.update.success',
            '$$Dane zostały pomyślnie zaktualizowane'
          ),
          { type: 'success' }
        );
        setOpenedOrderEditingModal(null);
      })
      .catch(error => {
        const violations = error?.response?.data?.violations ?? [];
        violations.map(violation => {
          showToast(t(violation.message), { type: 'error' });
        });
      });
  };

  const handleIntentDeliverySuspencion = async payload => {
    return updateUserDiet({ id: dietId, payload })
      .then(() => {
        showToast(
          t(
            '$*notification.update.success',
            '$$The data has been successfully updated'
          ),
          { type: 'success' }
        );
      })
      .catch(error => {
        const violations = error?.response?.data?.violations ?? [];
        violations.map(violation => {
          showToast(t(violation.message), { type: 'error' });
        });
      });
  };

  const value = {
    activeDayDietCalendarQuery,
    activeMonths,
    additionalActions,
    calendarDays,
    changeMenuMode,
    currentBagType,
    currentDateState,
    day,
    dayType: type,
    deliveryDays,
    handle409ResponseAccept,
    handleChangeDiet,
    handleDeliveryDateChange,
    handleFreeMenuChange,
    handleIntentDeliverySuspencion,
    handleNextClickCalendar,
    handlePrevClickCalendar,
    handleUpdateBagRate,
    handleUserBagDetailsUpdate,
    menuClickedDish,
    nextDietCalendarQuery,
    openedOrderEditingModal,
    scrollToRef,
    selectedPaidMenuOptions,
    selectPaidMenuOptions,
    setActiveMonths,
    setAdditionalActions,
    setCalendarDays,
    setMenuClickedDish,
    setOpenedOrderEditingModal,
    shopElementsAddonsSectionRef,
    updateUserBagDetailsQuery,
    userBagDetailsQuery,
    userBagItemsQuery,
    userDietCalendarQuery,
  };

  return (
    <OrderDetailsPageContext.Provider value={value}>
      {children}
    </OrderDetailsPageContext.Provider>
  );
};

const useOrderDetailsPageContext = () => useContext(OrderDetailsPageContext);

export { OrderDetailsPageProvider, useOrderDetailsPageContext };
