import tw, { styled } from 'twin.macro';

import { Image, ImageWrapper } from '@components';
import { useMediaQuery } from '@hooks';
import {
  selectModuleHover,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import ApiService from '@services/Api.service';
import { down } from '@utils/screens';

const HoverImageWrapper = styled(ImageWrapper)`
  ${tw`mb-3`}
`;

const HoverBasket = () => {
  const {
    active,
    title,
    description,
    image,
    '@resources': resources,
  } = useAppConfigSelector(selectModuleHover);
  const isDownMd = useMediaQuery(down('md'), true);

  const imageUrl = ApiService.getImageUrlFromResources(image, resources);

  if (!active || isDownMd) {
    return null;
  }

  return (
    <div tw="bg-white py-4 px-5 rounded-md shadow-lg mt-8">
      <div tw="flex mb-4 pb-1.5 border-b border-gray-200 items-center justify-between font-semibold text-primary">
        <h2 className="h3" tw="mb-0">
          {title}
        </h2>
      </div>

      {image && (
        <HoverImageWrapper>
          <Image src={imageUrl} alt="" loading="lazy" layout="fill" />
        </HoverImageWrapper>
      )}
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default HoverBasket;
