import { css } from '@emotion/react';

import { useMinScreen } from '@hooks';

const DebugBreakPoint = () => {
  const { min } = useMinScreen();

  return (
    <div
      tw="fixed top-0.5 left-0.5 text-white bg-black opacity-50 px-1"
      css={css`
        z-index: 9999;
      `}
    >
      {!min`sm` && <div>&lt; xs(576px)</div>}
      {min`sm` && !min`md` && <div>sm(576px) - md(768px)</div>}
      {min`md` && !min`lg` && <div>md(768px) - lg(992px)</div>}
      {min`lg` && !min`xl` && <div>lg(992px) - xl(1200px)</div>}
      {min`xl` && !min`xxl` && <div>xl(1200px) - 2xl(1400px)</div>}
      {min`xxl` && <div>&gt; xxl(1400px)</div>}
    </div>
  );
};

DebugBreakPoint.displayName = 'DebugBreakPoint';

export default DebugBreakPoint;
