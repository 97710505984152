import NextLink from 'next/link';

import { css } from '@emotion/react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';
import { styled } from 'twin.macro';

import { Image, ImageWrapper } from '@components';
import ROUTE_URLS from '@constants/routeUrls';
import { useMediaQuery } from '@hooks';
import {
  selectBranding,
  selectModuleLinks,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { up } from '@utils/screens';

export const LogoImageWrapper = styled(ImageWrapper)(
  ({ isSmall }) => css`
    img {
      ${tw`!w-auto`};
      ${isSmall ? tw`!max-h-8` : tw`!max-h-14`}
    }
  `
);

const Logo = ({ isSmall = false, styles = {} }) => {
  const { logos = {} } = useAppConfigSelector(selectBranding);
  const links = useAppConfigSelector(selectModuleLinks);
  const logoLink = links?.logo?.link ?? ROUTE_URLS.NEW_ORDER_FORM;
  const isUpLg = useMediaQuery(up('lg'), true);

  if (isEmpty(logos)) {
    return null;
  }

  return (
    <NextLink href={logoLink}>
      <a css={styles?.a} href={logoLink} tw="md:mr-6 inline-block">
        <LogoImageWrapper isSmall={isSmall}>
          <Image
            src={isUpLg ? logos.desktop : logos.mobile}
            alt="Logo"
            layout="fill"
          />
        </LogoImageWrapper>
      </a>
    </NextLink>
  );
};

Logo.displayName = 'Logo';

export default Logo;
