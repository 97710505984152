const SHoppingBagIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2142 2748"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M16455.9 19656.3v1.5l.2 1.5c1.8 17.7-3.9 35.2-15.7 48.4h-.1c-11.8 13.2-28.7 20.7-46.5 20.7H14378c-17.7 0-34.6-7.5-46.5-20.6-11.9-13.2-17.6-30.8-15.8-48.5l.2-1.5v-1954.3c4-31.2 30.5-54.7 62.1-54.7h469.5v-129.5c0-296.9 241.5-538.5 538.4-538.5s538.5 241.6 538.5 538.5v129.5h469.5c31.5 0 58.1 23.5 62 54.7v1952.8Zm-2024.5-82.3-.1 29.3h1900.2V17774h-407.1v231.3c0 34.6-28.1 62.6-62.6 62.6-34.6 0-62.6-28-62.6-62.6V17774h-826.5v231.3c0 34.6-28.1 62.6-62.6 62.6-34.6 0-62.6-28-62.6-62.6V17774h-407l-9.1 1800Zm541.3-2054.7v129.5h826.5v-129.5c0-227.9-185.4-413.3-413.3-413.3-227.8 0-413.2 185.4-413.2 413.3Z"
      style={{
        fillRule: 'nonzero',
      }}
      transform="translate(-14315.4 -16980.8)"
    />
  </svg>
);

export default SHoppingBagIcon;
