import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getOrderDetails = async orderId => {
  const response = await axios.get(ENDPOINT.ORDER_DETAILS(orderId));

  return response?.data ?? {};
};

const useOrderDetails = ({ payload: { orderId }, options = {} }) =>
  useQuery(['orderDetails', orderId], () => getOrderDetails(orderId), {
    ...options,
  });

export default useOrderDetails;
