const DAY_STATUSES = {
  DISABLED: 'DISABLED',
  DISABLED_WITH_BAG: 'DISABLED_WITH_BAG',
  DELIVERED_NOT_RATED_BLOCKED: 'DELIVERED_NOT_RATED_BLOCKED',
  DELIVERED_RATED_BLOCKED: 'DELIVERED_RATED_BLOCKED',
  DELIVERED_RATED_PARTIALLY_BLOCKED: 'DELIVERED_RATED_PARTIALLY_BLOCKED',
  DELIVERED_NOT_RATED_CAN_RATE: 'DELIVERED_NOT_RATED_CAN_RATE',
  DELIVERED_RATED_CAN_RATE: 'DELIVERED_RATED_CAN_RATE',
  DELIVERED_RATED_PARTIALLY_CAN_RATE: 'DELIVERED_RATED_PARTIALLY_CAN_RATE',
  NOT_DELIVERED_BLOCKED: 'NOT_DELIVERED_BLOCKED',
  NOT_DELIVERED_WITH_CONFIGURABLE_ALL: 'NOT_DELIVERED_WITH_CONFIGURABLE_ALL',
  NOT_DIET_CAN_PLACE_ORDER: 'NOT_DIET_CAN_PLACE_ORDER',
  NOT_DIET_CANT_PLACE_ORDER: 'NOT_DIET_CANT_PLACE_ORDER',
  NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
    'NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU',
  SUBSCRIPTION_SUSPENDED: 'SUBSCRIPTION_SUSPENDED',
};

export default DAY_STATUSES;
