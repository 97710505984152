import { Trans, useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { loginWithUserData, register } from '@store/auth/auth.actions';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { ExternalAuth } from '@components';
import LOGIN_INPUT_IDS from '@constants/inputs/loginInputs';
import REGISTER_INPUT_IDS from '@constants/inputs/registerInputs';
import { transformApiErrors } from '@services/Api.service';

import { initialValues, useValidationSchema } from './formikData';
import RegisterFormFields from './RegisterFormFields';

const RegisterForm = ({ onAfterLogin = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const handleFormikSubmit = (
    values,
    { resetForm, setStatus, setSubmitting }
  ) => {
    dispatch(register(values))
      .then(unwrapResult)
      .then(() =>
        dispatch(
          loginWithUserData({
            [LOGIN_INPUT_IDS.USERNAME]: values[REGISTER_INPUT_IDS.EMAIL],
            [LOGIN_INPUT_IDS.PASSWORD]: values[REGISTER_INPUT_IDS.PASSWORD],
            [LOGIN_INPUT_IDS.KEEP_ME_LOGGED_IN]:
              values[REGISTER_INPUT_IDS.KEEP_ME_LOGGED_IN],
          })
        )
      )
      .then(unwrapResult)
      .then(() => {
        setStatus();
        resetForm();
        onAfterLogin();
      })
      .catch(error => {
        const apiFieldErrors = transformApiErrors(error);

        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
          apiFieldErrors,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const hasParagraph = !isEmpty(t('$*registerPage.paragraph'));
  const hasListText = !isEmpty(t('$*registerPage.advantagesList'));

  return (
    <Fragment>
      {hasParagraph && (
        <p tw="text-gray-700">
          {t(
            '$*registerPage.paragraph',
            '$$Utwórz konto, które pozwoli Ci samodzielnie i o dogodnej dla Ciebie porze:'
          )}
        </p>
      )}
      {hasListText && (
        <ul tw="mb-4 md:mb-5 lg:mb-8 list-disc ml-5">
          <Trans
            i18nKey="$*registerPage.advantagesList"
            components={[<li key="0" />]}
          />
        </ul>
      )}
      <ExternalAuth onAfterLogin={onAfterLogin} />
      <div tw="py-6 text-center">
        {t('$*components.registerForm.normalRegister', '$$lub kontynuuj przez')}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormikSubmit}
      >
        <Form data-cy="register-form">
          <RegisterFormFields />
        </Form>
      </Formik>
    </Fragment>
  );
};

export default RegisterForm;
