import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import { Collapse } from '@components';
import { useToggle } from '@hooks';

import LanguageOptionLabel from './LanguageOptionLabel';
import withLanguageSelector from './withLanguageSelector';

const LanguageSelectorCollapse = ({ languages, language, changeLanguage }) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  return (
    <div tw="border-2 border-primary rounded-md overflow-hidden w-36 bg-gray-100 px-2 py-1">
      <motion.header initial={false} onClick={toggleIsExpanded}>
        <div tw="flex justify-between">
          <LanguageOptionLabel
            flagCode={language.flagCode}
            label={language.label}
            image={language.image}
          />
          <div>
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
          </div>
        </div>
      </motion.header>
      <Collapse isExpanded={isExpanded}>
        {languages.map(language => {
          const { id, flagCode, image, label } = language;

          return (
            <div key={id} tw="py-1 border-t border-gray-200">
              <LanguageOptionLabel
                flagCode={flagCode}
                label={label}
                image={image}
                onClick={() => changeLanguage(language)}
              />
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

LanguageSelectorCollapse.displayName = 'LanguageSelectorCollapse';

export default withLanguageSelector(LanguageSelectorCollapse);
