import { Carousel, DishTile } from '@components';
import { useMobileDetect } from '@hooks';

const MenuForTheDaySection = ({ dishes = [], showSlider = true }) => {
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  if (dishes.length < 5 && showSlider) {
    return (
      <div tw="flex -mx-2">
        {dishes.map(
          ({
            id,
            clientImage,
            clientTags,
            highestDishSize,
            lowestDishSize,
            nameForClient,
          }) => {
            const dishSize =
              highestDishSize.size.portionDivision > 1
                ? highestDishSize
                : lowestDishSize;

            return (
              <div key={id} tw="px-1" style={{ width: '250px' }}>
                <DishTile
                  allergens={dishSize?.allergens ?? []}
                  image={clientImage}
                  ingredients={dishSize?.ingredientsSortedByUsage}
                  macrosAfterProcessing={
                    dishSize?.macrosAfterProcessing ?? dishSize.macroIngredients
                  }
                  name={nameForClient}
                  portionDivision={dishSize?.size?.portionDivision ?? 1}
                  tags={clientTags ?? []}
                />
              </div>
            );
          }
        )}
      </div>
    );
  }

  return (
    <div tw="relative w-full mt-7">
      <Carousel
        className="swiper--menu-day"
        options={{
          slidesPerView: 'auto',
          spaceBetween: 8,
          allowTouchMove: isMobile,
          ...(!isMobile
            ? {
                keyboard: {
                  enabled: true,
                  onlyInViewport: false,
                },
              }
            : {}),
        }}
      >
        {Slide =>
          dishes.map(
            ({
              id,
              clientImage,
              clientTags,
              highestDishSize,
              lowestDishSize,
              nameForClient,
            }) => {
              const dishSize =
                highestDishSize.size.portionDivision > 1
                  ? highestDishSize
                  : lowestDishSize;

              return (
                <Slide
                  key={id}
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  <div style={{ width: '250px' }}>
                    <DishTile
                      allergens={dishSize?.allergens ?? []}
                      image={clientImage}
                      ingredients={dishSize?.ingredientsSortedByUsage}
                      macrosAfterProcessing={
                        dishSize?.macrosAfterProcessing ??
                        dishSize.macroIngredients
                      }
                      name={nameForClient}
                      portionDivision={dishSize?.size?.portionDivision ?? 1}
                      tags={clientTags ?? []}
                    />
                  </div>
                </Slide>
              );
            }
          )
        }
      </Carousel>
    </div>
  );
};

export default MenuForTheDaySection;
