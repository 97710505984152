import { useRouter } from 'next/router';

import tw from 'twin.macro';

import { Link, Logo } from '@components';
import {
  selectBranding,
  selectFooterPages,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { isExternalUrl } from '@utils/helpers';

const Footer = () => {
  const router = useRouter();
  const { name } = useAppConfigSelector(selectBranding);
  const footerPages = useAppConfigSelector(selectFooterPages);

  return (
    <footer
      id="footer"
      tw="px-5 py-6 mt-auto bg-white border-t border-grayPorcelain"
    >
      <div className="container">
        <div
          className="row"
          tw="flex-col items-center md:justify-between md:flex-row -my-1.5 text-sm"
        >
          <div className="col-auto">
            <div tw="md:flex md:items-center py-1.5">
              <Logo
                isSmall={true}
                styles={{
                  a: tw`table mx-auto`,
                }}
              />
              <p tw="mt-2 mb-3 text-center md:mt-0 md:mb-0">&copy; {name}</p>
            </div>
          </div>
          <div className="col-auto">
            <div tw="py-1.5">
              <div tw="md:-mx-3 -my-0.5 md:pt-0">
                <ul tw="text-center md:flex flex-wrap">
                  {footerPages.map(({ id, label, slug }) => {
                    const isExternal = isExternalUrl(slug);
                    const url = isExternal
                      ? slug
                      : `/pages/${slug}`.replace(/[/]+/g, '/'); // replace multiple "/" on singular

                    return (
                      <li key={id} tw="my-0.5 md:mx-3">
                        <Link
                          href={url}
                          styles={
                            router.asPath === url ? tw`font-semibold` : ''
                          }
                        >
                          {label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
