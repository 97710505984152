const KeycapStarIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1660 1874"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M1641.701 1405.503l-.004.004c-36.841 63.783-118.4 85.633-182.196 48.82L963.276 1167.97v572.46c0 35.4-14.063 69.35-39.092 94.383a133.49 133.49 0 01-94.383 39.091 133.49 133.49 0 01-94.384-39.091 133.49 133.49 0 01-39.091-94.384v-572.413L200.063 1454.35c-63.788 36.804-145.334 14.954-182.167-48.817l-.008-.008a133.423 133.423 0 0148.85-182.296l496.158-286.275L66.734 650.665A133.393 133.393 0 014.55 569.644a133.411 133.411 0 0113.346-101.259l.004-.004c36.834-63.75 118.359-85.596 182.13-48.8l496.296 286.346v-572.45c0-35.4 14.062-69.35 39.091-94.384A133.49 133.49 0 01829.801.002c35.4 0 69.35 14.062 94.383 39.091a133.49 133.49 0 0139.092 94.384V705.89l496.263-286.334c63.787-36.804 145.333-14.954 182.167 48.817l.008.008a133.423 133.423 0 01-48.85 182.296l-496.159 286.276s324.884 187.437 496.138 286.237a133.435 133.435 0 0162.204 81.034 133.428 133.428 0 01-13.346 101.279z"
      fillRule="nonzero"
    />
  </svg>
);

export default KeycapStarIcon;
