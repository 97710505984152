import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import {
  selectModuleConfigClientPanel,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import ProductImage from './ProductImage';
import ProductInfoWrapper from './ProductInfoWrapper';
import ProductName from './ProductName';
import ProductOptionsPopover from './ProductOptionsPopover';
import ProductSizes from './ProductSizes';
import ProductTags from './ProductTags';
import { useProductTileContext } from './ProductTile.context';
import ProductWrapper from './ProductWrapper';

const ProductTileDesktop = () => {
  const { t } = useTranslation();
  const {
    isExistImage,
    isOpenMenu,
    showImage,
    toggleOpenMenu,
    product,
    productIsSelected,
  } = useProductTileContext();

  const { name, image = '', tags = [], sizes = [], minCost = 0 } = product;
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { '@resources': resources, dishImagePlaceholder } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const imageUrl =
    showImage && isExistImage
      ? image
      : resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg';

  return (
    <ProductWrapper isSelected={productIsSelected} onClick={toggleOpenMenu}>
      <ProductImage showImage={showImage} imageUrl={imageUrl} />
      <ProductInfoWrapper>
        <div tw="flex flex-col h-full">
          <div css={[tw`p-3`, !isOpenMenu && tw`h-full`]}>
            <div className="row" css={[!isOpenMenu && tw`h-full`]}>
              <div className="col">
                {!isOpenMenu && (
                  <div tw="flex flex-col h-full">
                    <ProductName name={name} />
                    <div tw="text-sm pt-2 mt-auto">
                      {t('$*components.productTile.priceFrom', {
                        defaultValue: '$$od {{price}} {{currencySymbol}}',
                        replace: {
                          price: getIntegerIfIntegerOrFixed(minCost),
                          currencySymbol,
                        },
                      })}
                    </div>
                  </div>
                )}

                {isOpenMenu && (
                  <Fragment>
                    <ProductName name={name} />
                    <div tw="pt-4 flex -my-0.5 -mx-1 flex-wrap">
                      <ProductTags tags={tags} />
                    </div>
                  </Fragment>
                )}
              </div>
              {isOpenMenu && (
                <div className="col-auto">
                  <div tw="flex flex-col items-center">
                    <div>
                      <button
                        className="group"
                        tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                      >
                        <ArrowDownIcon
                          className="group-focus:(fill-current)"
                          tw="w-4 transform transition-colors  duration-300 hover:(duration-300)"
                          title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                        />
                      </button>
                    </div>
                    <div>
                      <ProductOptionsPopover />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isOpenMenu && (
            <div
              tw="relative px-3 pb-3 flex-auto overflow-auto"
              onClick={e => e.stopPropagation()}
              data-cy=""
            >
              <ProductSizes sizes={sizes} />
            </div>
          )}
        </div>
      </ProductInfoWrapper>
    </ProductWrapper>
  );
};

export default ProductTileDesktop;
