import tw from 'twin.macro';

import { OverflowContent } from '@components';
import { cssMerge } from '@utils/styleHelpers';

const AddonName = ({ name, isSelected = false, styles = {} }) => {
  const twStyle = cssMerge({
    defaultCss: [
      tw`text-xs font-semibold max-h-14`,
      isSelected && tw`text-primary`,
    ],
    ...styles,
  });

  return (
    <OverflowContent lineClamp={3} styles={{ css: twStyle }}>
      {name}
    </OverflowContent>
  );
};

export default AddonName;
