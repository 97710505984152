import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import { PriceWrapper } from '@components';

import { PRICE_PARTS } from './PriceWithPriceParts.const';

const PriceWithPriceParts = ({ title = '', price }) => {
  const { t } = useTranslation();

  const getPricePartTitle = key => {
    switch (key) {
      case PRICE_PARTS.DIET:
        return t(
          '$*historyOrdersPage.orderDetailsModal.bagModification.diet',
          '$$Dieta'
        );
      case PRICE_PARTS.ADDON:
        return t(
          '$*historyOrdersPage.orderDetailsModal.dietAddons.addons',
          '$$Dodatki'
        );
      case PRICE_PARTS.ZONE:
        return t(
          '$*historyOrdersPage.orderDetailsModal.delivery.zoneChange',
          '$$Dostawa'
        );
      case PRICE_PARTS.ECO_CONTAINER:
        return t(
          '$*historyOrdersPage.orderDetailsModal.additionalOptions.ecoContainer',
          '$$Eko opakowanie'
        );
      case PRICE_PARTS.CHANGE_MENU:
        return t(
          '$*historyOrdersPage.orderDetailsModal.additionalOptions.changeMenu',
          '$$Wybór menu'
        );
      case PRICE_PARTS.DELIVERY:
        return t('$*components.diet.delivery.address', '$$Dostawa');
      case PRICE_PARTS.PICKUP_POINT:
        return t('$*components.diet.delivery.pickUpPoint', '$$Punkt odbioru');
      default:
        return '';
    }
  };

  return (
    <div>
      <div tw="flex items-center justify-between">
        <div tw="font-semibold mr-2">{title}</div>
        <PriceWrapper
          beforeDiscount={price?.beforeDiscount}
          afterDiscount={price?.afterDiscount}
        />
      </div>
      {!isEmpty(price?.priceParts) &&
        Object.entries(price.priceParts).map(entry => (
          <div key={entry[0]} tw="flex items-center justify-between">
            <div>{`${getPricePartTitle(entry[0])}: `}</div>
            <PriceWrapper beforeDiscount={entry[1]} afterDiscount={entry[1]} />
          </div>
        ))}
      {!!price?.discount && (
        <div tw="flex items-center justify-between">
          <div>
            {`${t(
              '$*newOrderCreatePage.orderForm.summary.grantedDiscount',
              '$$Udzielony rabat'
            )}: `}
          </div>
          <PriceWrapper
            beforeDiscount={price?.discount * -1}
            afterDiscount={price?.discount * -1}
          />
        </div>
      )}
    </div>
  );
};

export default PriceWithPriceParts;
