import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

import DishImage from './DishImage';
import DishInfoWrapper from './DishInfoWrapper';
import DishName from './DishName';
import DishSizeInfo from './DishSizeInfo';
import DishTags from './DishTags';
import { useDishTileContext } from './DishTile.context';
import DishWrapper from './DishWrapper';

const DishTile = () => {
  const { t } = useTranslation();
  const { image, isOpenMenu, name, tags, toggleOpenMenu } =
    useDishTileContext();
  const { '@resources': resources, dishImagePlaceholder } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const imageUrl =
    image ?? resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg';

  return (
    <DishWrapper onClick={toggleOpenMenu}>
      <DishImage showImage={true} imageUrl={imageUrl} />
      <DishInfoWrapper>
        <div tw="flex flex-col h-full">
          <div css={[tw`p-3`, !isOpenMenu && tw`h-full`]}>
            <div className="row" css={[!isOpenMenu && tw`h-full`]}>
              <div className="col">
                {!isOpenMenu && (
                  <div tw="flex flex-col h-full">
                    <DishName name={name} />
                  </div>
                )}

                {isOpenMenu && (
                  <Fragment>
                    <DishName name={name} />
                    <div tw="pt-4 flex -my-0.5 -mx-1 flex-wrap">
                      <DishTags tags={tags} />
                    </div>
                  </Fragment>
                )}
              </div>

              <div className="col-auto">
                <div tw="flex flex-col items-center">
                  <button
                    className="group"
                    tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                  >
                    {isOpenMenu ? (
                      <ArrowDownIcon
                        className="group-focus:(fill-current)"
                        tw="w-4 transform transition-colors duration-300 hover:(duration-300)"
                        title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                      />
                    ) : (
                      <ArrowDownIcon
                        className="group-focus:(fill-current)"
                        tw="w-4 transform transition-colors rotate-180 duration-300 hover:(duration-300)"
                        title={t('$*common.showDetails', '$$Pokaż szczegóły')}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isOpenMenu && (
            <div
              tw="relative px-3 pb-3 flex-auto overflow-auto"
              onClick={e => e.stopPropagation()}
              data-cy=""
            >
              <DishSizeInfo />
            </div>
          )}
        </div>
      </DishInfoWrapper>
    </DishWrapper>
  );
};

export default DishTile;
