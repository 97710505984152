import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import { OverflowContent } from '@components';

import DishMacros from './MenuDishMacros';
import { useMenuDishTileContext } from './MenuDishTile.context';

const MenuDishInfo = () => {
  const { t } = useTranslation();
  const {
    allergens,
    calories,
    carbohydrates,
    fat,
    glycemicIndex,
    ingredients,
    portionDivision,
    protein,
    rateAVG,
    servingSuggestion,
    showAllergens,
    showDishCalorific,
    showDishComposition,
    showGlycemicIndex,
    showMacroIngredients,
    showRateAvg,
    showServingSuggestion,
  } = useMenuDishTileContext();

  const allergensNames = !isEmpty(allergens)
    ? allergens.map(({ value }) => value).join(', ') ?? '-'
    : '-';

  const componentsNames = !isEmpty(ingredients)
    ? ingredients.map(({ name }) => name).join(', ') ?? '-'
    : '-';

  return (
    <div tw="text-xs">
      {showDishCalorific && (
        <div>
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.kcal', '$$kcal')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              {portionDivision
                ? parseFloat((calories ?? 0) / portionDivision).toFixed(0)
                : parseFloat(calories ?? 0).toFixed(0)}
            </div>
          </div>
        </div>
      )}

      {showDishComposition && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.dishComposition', '$$Skład')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              <OverflowContent lineClamp={5}>{componentsNames}</OverflowContent>
            </div>
          </div>
        </div>
      )}

      {showAllergens && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.allergens', '$$Alergeny')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              <OverflowContent lineClamp={2}>{allergensNames}</OverflowContent>
            </div>
          </div>
        </div>
      )}

      {showMacroIngredients && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.macros', '$$Makroskładniki')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              <DishMacros
                protein={protein}
                carbohydrates={carbohydrates}
                fat={fat}
              />
            </div>
          </div>
        </div>
      )}

      {showGlycemicIndex && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.glycemicIndexShort', '$$IG')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              {glycemicIndex}
            </div>
          </div>
        </div>
      )}

      {showServingSuggestion && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.servingSuggestion', '$$Propozycja podania')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              {servingSuggestion?.value ?? ''}
            </div>
          </div>
        </div>
      )}

      {showRateAvg && (
        <div tw="pt-2">
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <OverflowContent>
                {`${t('$*common.rateAvg', '$$Propozycja podania')}:`}
              </OverflowContent>
            </div>
            <div className="col-8" tw="px-1">
              {rateAVG ?? '-'}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDishInfo;
