import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/registerInputs';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const initialValues = {
  [INPUT_IDS.FIRST_NAME]: '',
  [INPUT_IDS.LAST_NAME]: '',
  [INPUT_IDS.GENDER]: { label: '', value: '' },
  [INPUT_IDS.EMAIL]: '',
  [INPUT_IDS.PHONE]: { number: '', countryCode: '' },
  [INPUT_IDS.PASSWORD]: '',
  [INPUT_IDS.REFERRER]: { label: '', value: '' },
  [INPUT_IDS.REFERRER_TEXT]: '',
  [INPUT_IDS.MARKETING_AGREEMENT]: false,
  [INPUT_IDS.SERVICE_AGREEMENT]: false,
  [INPUT_IDS.KEEP_ME_LOGGED_IN]: false,
};

export const useValidationSchema = () => {
  const {
    stringRequired,
    emailRequired,
    passwordRequired,
    phoneValid,
    booleanRequired,
  } = useDefaultSchemaYup();

  return Yup.object({
    [INPUT_IDS.FIRST_NAME]: stringRequired,
    [INPUT_IDS.LAST_NAME]: stringRequired,
    [INPUT_IDS.EMAIL]: emailRequired,
    [INPUT_IDS.PASSWORD]: passwordRequired,

    [INPUT_IDS.PHONE]: phoneValid,
    // [INPUT_IDS.REFERRER]: Yup.mixed().test({
    //   name: 'requiredField',
    //   message: t('$*error.field.required'),
    //   test: ({ value }) => value?.length > 0,
    // }),
    [INPUT_IDS.SERVICE_AGREEMENT]: booleanRequired,
    [INPUT_IDS.MARKETING_AGREEMENT]: Yup.boolean(),
  });
};
