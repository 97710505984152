import { i18n } from 'next-i18next';

import { getApiTranslations } from '@services/App.service';

const withApiTranslations = gssp => {
  return async context => {
    const languege = context.locale;

    if (!i18n?.hasResourceBundle(languege, 'default')) {
      const translations = await getApiTranslations({
        xLocale: context.locale,
      });

      i18n?.addResourceBundle(languege, 'common', translations, true, true);
    }

    return await gssp(context); // Continue on to call `getServerSideProps` logic
  };
};

withApiTranslations.displayName = 'withApiTranslations';

export default withApiTranslations;
