import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import { css } from '@emotion/react';
import tw from 'twin.macro';

import {
  AddressesList,
  ApiLoadingBoundary,
  Button,
  Tab,
  TabPanel,
  Tabs,
} from '@components';
import { useMediaQuery, usePickupPoints, useUserAddresses } from '@hooks';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { up } from '@utils/screens';

const SelectAddressSectionView = ({
  selectedAddress,
  initialTabIndex = 0,
  handleSetAddress = () => {},
  selectedPickupPoint,
  handleSetPickupPoint = () => {},
}) => {
  const { t } = useTranslation();
  const { data: pickupPoints = [] } = usePickupPoints();
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);

  const {
    deliveryOptions: {
      pickUpPoint: isPickUpPointEnabled,
      address: isAddressEnabled,
    } = {},
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const {
    data: addresses = [],
    isLoading,
    isFetched,
    isError,
    refetch,
  } = useUserAddresses();

  const isUp620 = useMediaQuery(up('620px'), true);

  const handleSuccessAddAddress = address => {
    handleSetAddress(address);
  };

  const handleChangeTab = index => {
    setActiveTabIndex(index);
  };

  return (
    <div>
      <Tabs activeTabIndex={activeTabIndex} onChange={handleChangeTab}>
        {isAddressEnabled && (
          <Tab
            label={t(
              '$*newOrderCreatePage.orderForm.delivery.addressDelivery',
              '$$Dostawa na adres'
            )}
            index={0}
            data-cy="tab-delivery--address"
          />
        )}
        {isPickUpPointEnabled && (
          <Tab
            label={t(
              '$*newOrderCreatePage.orderForm.delivery.pickupAtPoint',
              '$$Odbiór w punkcie'
            )}
            index={1}
            data-cy="tab-delivery--pickup-point"
          />
        )}
      </Tabs>
      {isAddressEnabled && (
        <TabPanel activeTabIndex={activeTabIndex} index={0}>
          <div tw="pt-3">
            <ApiLoadingBoundary
              isError={isError}
              isLoading={isLoading}
              refetch={refetch}
            >
              <AddressesList
                isFetched={isFetched}
                addresses={addresses}
                canAddNewAddress={true}
                onClickAddress={handleSetAddress}
                selectedAddresses={
                  selectedAddress?.id ? [selectedAddress?.id] : []
                }
                onSuccessAddAddress={handleSuccessAddAddress}
                styles={{
                  address: {
                    wrapper: css`
                      ${isUp620 && 'max-width: 50%;'}
                    `,
                  },
                }}
              />
            </ApiLoadingBoundary>
          </div>
        </TabPanel>
      )}
      {isPickUpPointEnabled && (
        <TabPanel activeTabIndex={activeTabIndex} index={1}>
          <div tw="pt-3">
            <p>
              {t(
                '$*newOrderCreatePage.orderForm.delivery.pickupDiscount',
                '$$Skorzystaj z rabatu za odbiór w punkcie'
              )}
            </p>
            <div tw="-m-2">
              {pickupPoints.map(item => {
                const isSelected = selectedPickupPoint?.['@id'] === item['@id'];

                return (
                  <div key={item.id} tw="p-2">
                    <Button
                      variant="outlined"
                      fullWidth={true}
                      onClick={() => {
                        handleSetPickupPoint(item);
                      }}
                      css={[isSelected && tw`text-white bg-primary`]}
                      data-cy="pickup-point"
                    >
                      {item.value}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        </TabPanel>
      )}
    </div>
  );
};

export default SelectAddressSectionView;
