import DisplayOn from './DisplayOn';

const DisplayUpXxl = ({ children }) => {
  return (
    <DisplayOn size="xxl" mode="up" defaultState={true}>
      {children}
    </DisplayOn>
  );
};

DisplayUpXxl.displayName = 'DisplayUpXxl';

export default DisplayUpXxl;
