import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getUserDietDays = async dietId => {
  const response = await axios.get(ENDPOINT.DIET_DELIVERY_DAYS({ id: dietId }));

  return response?.data?.dates ?? [];
};

const useUserDietDeliveryDays = ({ dietId }, options = {}) => {
  return useQuery(
    'userDietDeliveryDays',
    () => getUserDietDays(dietId),
    options
  );
};

export default useUserDietDeliveryDays;
