import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { SadShoppingBasketIcon } from '@assets/icons';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';
import { useBasketMethods } from '@hooks';

import BasketModification from './BasketModification/BasketModification';
import BasketDiet from './BasketDiet';
import BasketDietDay from './BasketDietDay';

const BasketContent = () => {
  const { t } = useTranslation();

  const {
    basketQuery: { data: { rows = [] } = {} },
    basketDietsDays,
    basketDietsDaysError,
  } = useBasketMethods();

  const filterByType = type => rows.filter(item => item['@type'] === type);
  const basketDiets = filterByType(BASKET_ROWS_TYPES.DIET_ITEM);
  const basketModification = filterByType(
    BASKET_ROWS_TYPES.DAY_MODIFICATION
  )[0];

  const isEmptyBasket = isEmpty(rows);

  return (
    <>
      {isEmptyBasket ? (
        <div tw="text-center pt-2">
          <SadShoppingBasketIcon tw="w-12 mx-auto" />
          <div tw="pt-3">
            {t(
              '$*components.basket.emptyBasket',
              '$$Koszyk aktualnie jest pusty'
            )}
          </div>
        </div>
      ) : (
        <Fragment>
          {basketDiets.map(diet => (
            <BasketDiet key={diet['@id']} diet={diet} />
          ))}
          <BasketModification modification={basketModification} />
          {!isEmpty(basketDietsDays) &&
            Object.entries(basketDietsDays).map(([dietIri, days]) => (
              <BasketDietDay
                key={dietIri}
                basketDaysError={basketDietsDaysError}
                dietErrors={basketDietsDaysError[dietIri]}
                days={days}
                dietIri={dietIri}
                hasOnlyNewOrder={
                  Object.keys(basketDietsDays).includes('noDiet') &&
                  Object.keys(basketDietsDays).length === 1
                }
              />
            ))}
        </Fragment>
      )}
    </>
  );
};

export default BasketContent;
