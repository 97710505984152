import { AnimatePresence, motion } from 'framer-motion';

const Collapse = ({ children, isExpanded = false, styles = {} }) => {
  return (
    <AnimatePresence initial={false}>
      <motion.section
        key="content"
        initial="collapsed"
        animate={isExpanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            opacity: 1,
            height: 'auto',
            transitionEnd: {
              display: 'block',
            },
          },
          collapsed: {
            opacity: 0,
            height: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        transition={{ duration: 0.3 }}
        aria-expanded={isExpanded}
        css={styles}
      >
        {children}
      </motion.section>
    </AnimatePresence>
  );
};

Collapse.displayName = 'Collapse';

export default Collapse;
