import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const updateUserBagDetails = async ({ id, date, payload }) => {
  const response = await axios.put(ENDPOINT.BAG_DETAILS({ id, date }), payload);

  return response?.data ?? {};
};

const useUserBagDetailsUpdate = ({ id, date, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(updateUserBagDetails, {
    mutationKey: 'updateUserBagDetails',
    onSuccess: (response, request) => {
      if (request?.payload?.abandoned !== true) {
        queryClient.setQueryData(['bagDetails', { id, date }], () => response);
        queryClient.invalidateQueries('bagItems');
      }
    },
    ...options,
  });

  return query;
};

export default useUserBagDetailsUpdate;
