import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from 'react-query';
import produce from 'immer';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const cancelOrder = async orderId => {
  const response = await axios.put(ENDPOINT.ORDER_CANCEL(orderId), {});

  return response?.data ?? {};
};

const useCancelOrder = (options = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation(cancelOrder, {
    onSuccess: (_, orderId) => {
      queryClient.setQueryData(
        'myOrders',
        produce(draft => {
          draft.pages.map((page, index) => {
            const orderIndex = page.data.findIndex(
              order => order.id === orderId
            );

            if (orderIndex !== -1) {
              draft.pages[index].data[orderIndex].status = {
                systemValue: 'CANCELED',
              };
            }
          });
        })
      );

      showToast(
        t(
          '$*notification.cancelOrder.success',
          '$$Zamówienie zostało pomyślnie anulowane.'
        ),
        { type: 'success' }
      );
    },
    ...options,
  });

  return query;
};

export default useCancelOrder;
