import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getBagDetails = async ({ id, date }) => {
  const response = await axios.get(ENDPOINT.BAG_DETAILS({ id, date }));

  return response?.data ?? {};
};

const useUserBagDetails = ({ id, date }, options = {}) => {
  return useQuery(
    ['bagDetails', { id, date }],
    () => getBagDetails({ id, date }),
    {
      ...options,
    }
  );
};

export default useUserBagDetails;
