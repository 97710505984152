import { useTranslation } from 'next-i18next';

import { Fragment, useState } from 'react';
import produce from 'immer';

import { ExclamationIcon } from '@assets/icons';
import { Button, Countdown, Tooltip } from '@components';
import DIET_EDITING_ACTIONS_TYPES from '@constants/dietEditingActionsTypes';
import { useAddons } from '@hooks';
import { useOrderDetailsPageContext } from '@views/MyAccount/OrderDetailsPage/OrderDetailsPage.context';

import AddonsList from '../Addons/AddonsList';

const GiveUpAddonsSection = ({
  headerTitle = null,
  addonElements,
  deadline,
  isCurrentDateBeforeDeadline,
}) => {
  const { t } = useTranslation();
  const { data: items = [] } = useAddons();
  const {
    handleUserBagDetailsUpdate,
    updateUserBagDetailsQuery: { isLoading: isLoadingBagDetails },
  } = useOrderDetailsPageContext();

  const userAddonIds = addonElements.map(({ addon }) => addon.id);
  const { addonsQuantity, userAddons } = items.reduce(
    (acc, item) => {
      const filteredAddons =
        item.addons.filter(addon => userAddonIds.includes(addon.id)) ?? [];

      const filteredAddonsWithMaxMin = filteredAddons.map(item => {
        const maxCount = addonsQuantity?.[item?.['@id']];

        return { ...item, minCount: 0, maxCount };
      });

      const addonsQuantity = filteredAddonsWithMaxMin.reduce((acc, addon) => {
        const quantity =
          addonElements.find(item => item.addon.id === addon.id)?.quantity ?? 0;

        return { ...acc, [addon['@id']]: quantity };
      }, {});

      return {
        ...acc,
        addonsQuantity: { ...acc.addonsQuantity, ...addonsQuantity },
        userAddons: [
          ...acc.userAddons,
          { ...item, addons: filteredAddonsWithMaxMin },
        ],
      };
    },
    { addonsQuantity: {}, userAddons: [] }
  );

  const [quantity, setQuantity] = useState(addonsQuantity);
  const showHeader = (deadline && isCurrentDateBeforeDeadline) || !deadline;

  const handleClickGiveUp = () => {
    const addons = Object.entries(quantity).map(([key, value]) => {
      return { addon: key, quantity: value };
    });

    handleUserBagDetailsUpdate({
      payload: { overrideAddons: true, addons },
      options: {
        orderEditingActionType: DIET_EDITING_ACTIONS_TYPES.ADDONS_CHANGE,
      },
    });
  };

  return (
    <Fragment>
      {showHeader && (
        <div tw="flex flex-wrap items-baseline justify-center pb-1 mb-3 -mx-1 -mt-1 border-b border-gray-200 md:justify-between">
          {headerTitle && (
            <h2 tw="mb-0 font-semibold py-1 px-2">{headerTitle}</h2>
          )}

          {deadline && isCurrentDateBeforeDeadline && (
            <div tw="flex w-full md:w-auto flex-col md:flex-row flex-wrap items-center px-2 py-1">
              <div tw="pr-3 flex items-center">
                {t('$*common.leftTo', '$$Pozostało')}{' '}
                <Tooltip
                  content={
                    <div>
                      {t(
                        '$*common.leftTo.giveUpAddons',
                        '$$Pozostały czas na rezygnację z dodatków'
                      )}
                    </div>
                  }
                >
                  <div tw="ml-2">
                    <ExclamationIcon tw="w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </div>
              <Countdown
                endDate={deadline}
                isCurrentDateBeforeEnd={isCurrentDateBeforeDeadline}
              />
              <Button
                tw="mt-5 md:ml-2 md:mt-0"
                size="sm"
                onClick={handleClickGiveUp}
                isLoading={isLoadingBagDetails}
                disabled={isLoadingBagDetails}
              >
                {t('$*common.saveChanges', '$$Zapisz zmiany')}
              </Button>
            </div>
          )}
        </div>
      )}

      <AddonsList
        items={userAddons}
        checkIsAddonSelected={({ addon }) =>
          quantity?.[addon?.['@id']] > 0 ?? false
        }
        onChangeQuantity={({ addonIri, quantity: addonQuantity }) => {
          const maxCount = addonsQuantity?.[addonIri];

          if (addonQuantity <= maxCount) {
            setQuantity(
              produce(draftState => {
                draftState[addonIri] = addonQuantity;
              })
            );
          }
        }}
        basketItemsQuantity={() => quantity}
      />
    </Fragment>
  );
};

export default GiveUpAddonsSection;
