import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Checkbox = ({
  label,
  styles = {},
  value = false,
  required = false,
  disabled = false,
  readOnly = false,
  onChange = () => {},
  ...checkboxProps
}) => {
  const { t } = useTranslation();

  const twStyle = {
    label: cssMerge({
      defaultCss: [
        tw`inline-flex items-center`,
        disabled ? tw`cursor-not-allowed opacity-70` : tw`cursor-pointer`,
      ],
      ...styles?.label,
    }),
    input: cssMerge({
      defaultCss: [tw`hidden`],
      ...styles?.input,
    }),
    span: cssMerge({
      defaultCss: tw`ml-3 text-base cursor-pointer`,
      ...styles?.span,
    }),
    switchOption: cssMerge({
      defaultCss: tw`px-2`,
      ...styles?.switchOption,
    }),
    switchOptionSelected: cssMerge({
      defaultCss: tw`px-2 py-1.5 text-white rounded-md bg-primary -m-px`,
      ...styles?.switchOptionSelected,
    }),
    switchOptionsContainer: cssMerge({
      defaultCss: tw`py-0.5 border border-current rounded-md`,
      ...styles?.switchOptionsContainer,
    }),
  };

  const DEFAULT_VALUES = [
    { label: t('$*common.no', '$$Nie'), value: false },
    { label: t('$*common.yes', '$$Tak'), value: true },
  ];

  return (
    <label css={twStyle.label}>
      {!readOnly && (
        <input
          type="checkbox"
          css={twStyle.input}
          checked={Array.isArray(value) ? value?.includes('on') : value}
          disabled={disabled}
          onChange={onChange}
          {...checkboxProps}
        />
      )}
      <div css={twStyle.switchOptionsContainer}>
        <span
          css={
            value == DEFAULT_VALUES[0]?.value
              ? twStyle.switchOptionSelected
              : twStyle.switchOption
          }
        >
          {DEFAULT_VALUES[0]?.label}
        </span>
        <span
          css={
            value == DEFAULT_VALUES[1]?.value
              ? twStyle.switchOptionSelected
              : twStyle.switchOption
          }
        >
          {DEFAULT_VALUES[1]?.label}
        </span>
      </div>
      {!isEmpty(label) && (
        <span css={twStyle.span}>
          {label} {required && '*'}
        </span>
      )}
    </label>
  );
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
