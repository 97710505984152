import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Label = ({ styles = {}, htmlFor, ...labelProps }) => {
  const twStyle = cssMerge({
    defaultCss: tw`inline-block mb-2`,
    ...styles,
  });

  return <label css={twStyle} htmlFor={htmlFor} {...labelProps} />;
};

Label.displayName = 'Label';

export default Label;
