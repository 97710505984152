import { useMutation, useQueryClient } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';
import showToast from '@utils/showToast';

const addBasketModifyDay = async ({ payload }) => {
  const response = await axios.post(ENDPOINT.BASKET_ADD_MODIFY_DAY, {
    ...payload,
  });

  return response?.data ?? {};
};

const useBasketModifyDay = ({
  basketKey = 'basketEditOrder',
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation(addBasketModifyDay, {
    mutationKey: `${basketKey}AddModifyDay`,
    onSuccess: response => {
      queryClient.setQueryData(basketKey, response);
    },
    onError: err => {
      const error = err?.response?.data;

      showToast(error, { type: 'error' });
    },
    retry: 3,
    ...options,
  });

  return query;
};

export default useBasketModifyDay;
