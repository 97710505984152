import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import tw from 'twin.macro';

import { BinIcon, EditIcon } from '@assets/icons';
import { Collapse, PriceWrapper, Tooltip } from '@components';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import DIET_SETTINGS from '@constants/dietSettings';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import { useBasketMethods } from '@hooks';
import { isCMSPage } from '@utils/helpers';
import { useAppContext } from '@views/App/App.context';

import BasketDietName from './BasketDietName/BasketDietName';

import 'tippy.js/themes/light-border.css';

const BasketDietConfig = dynamic(() => import('./BasketDiet/BasketDietConfig'));
const BasketDietOneTimePayment = dynamic(() =>
  import('./BasketDiet/BasketDietOneTimePayment')
);
const BasketDietSubscriptionPayment = dynamic(() =>
  import('./BasketDiet/BasketDietSubscriptionPayment')
);

const BasketDiet = ({ diet }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const { isDietMini } = useAppContext();
  const { currentStepIndex, stepsCount } = useSelector(selectTabs);
  const {
    basketStore: { activeOrderDietIri, items: basketItems },
    handleRemoveBasketDiet,
    setActiveOrderDietIri,
  } = useBasketMethods();
  const canDeleteDiet =
    !(isCMSPage(router.pathname) && activeOrderDietIri === diet['@id']) &&
    (basketItems?.dietElements ?? []).length > 1;

  const isLastStep = currentStepIndex === stepsCount - 1;

  const [isExpanded, toggleDiet] = useState(true);

  const [activeDietSettingEditing, setActiveDietSettingEditing] =
    useState(null);

  const isSubscriptionPayment =
    diet?.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

  return (
    <div tw="py-2 -mx-2">
      <motion.header initial={false} onClick={() => toggleDiet(!isExpanded)}>
        <div
          css={[
            tw`px-2 py-1 text-sm font-semibold bg-gray-100 border-2 border-transparent rounded-md cursor-pointer`,
            activeOrderDietIri === diet['@id'] && tw`border-primary`,
          ]}
        >
          <div tw="flex justify-between">
            <div tw="capitalize flex items-center">
              {diet?.diet?.name ?? ''}
            </div>
            <div tw="flex -mx-1">
              {!isCMSPage(router.pathname) &&
                activeOrderDietIri !== null &&
                activeOrderDietIri !== diet['@id'] && (
                  <Tooltip
                    content={
                      <div>
                        {t(
                          '$*components.basketDiet.editDiet',
                          '$$Edytuj dietę'
                        )}
                      </div>
                    }
                  >
                    <span>
                      <EditIcon
                        tw="cursor-pointer w-4 mx-1"
                        onClick={e => {
                          e.stopPropagation();
                          dispatch(setActiveOrderDietIri(diet['@id']));
                        }}
                      />
                    </span>
                  </Tooltip>
                )}

              {canDeleteDiet && (
                <Tooltip
                  content={
                    <div>
                      {t('$*components.basketDiet.removeDiet', '$$Usuń dietę')}
                    </div>
                  }
                >
                  <span>
                    <BinIcon
                      tw="cursor-pointer w-4 mx-1"
                      onClick={e => {
                        e.stopPropagation();
                        handleRemoveBasketDiet(diet?.['@id']);
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
                tw="mx-1"
              />
            </div>
          </div>
          <div tw="flex justify-between">
            <div>{`${diet?.variant?.name ?? ''}, ${
              diet?.calorific?.name ?? ''
            }`}</div>
            <motion.div
              initial="initial"
              css={[{ minWidth: '70px' }]}
              animate={isExpanded ? 'initial' : 'animated'}
              variants={{
                initial: { opacity: 0 },
                animated: { opacity: 1 },
              }}
            >
              <PriceWrapper
                afterDiscount={diet?.price?.afterDiscount ?? 0}
                beforeDiscount={diet?.price?.beforeDiscount ?? 0}
              />
            </motion.div>
          </div>
        </div>
      </motion.header>

      <Collapse
        isExpanded={isExpanded}
        styles={[
          tw`px-2`,
          activeDietSettingEditing !== DIET_SETTINGS.ORDER_TYPE &&
            tw`overflow-hidden`,
        ]}
      >
        {isLastStep && (
          <div tw="pt-3">
            <BasketDietName existingItem={diet?.['@id']} name={diet?.name} />
          </div>
        )}

        {isSubscriptionPayment ? (
          <BasketDietSubscriptionPayment diet={diet} />
        ) : (
          <BasketDietOneTimePayment diet={diet} />
        )}

        {isDietMini && (
          <BasketDietConfig
            diet={diet}
            activeDietSettingEditing={activeDietSettingEditing}
            setActiveDietSettingEditing={setActiveDietSettingEditing}
          />
        )}
      </Collapse>
    </div>
  );
};

export default BasketDiet;
