import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import INPUT_IDS from '@constants/inputs/dietInputs';

const useFields = () => {
  const { t } = useTranslation();

  const formFields = useMemo(() => {
    const fields = [
      {
        id: INPUT_IDS.NAME,
        type: 'text',
        label: t('$*input.dietName.label', '$$Nazwa diety'),
        required: true,
        autoComplete: uuidv4(),
      },
    ];

    return fields;
  }, [t]);

  return formFields;
};

export default useFields;
