import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';

import { ArrowDownIcon } from '@assets/icons';
import { OverflowContent } from '@components';
import {
  selectModuleShop,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import ProductMacros from './ProductMacros';

const ProductSizeInfo = ({
  allergens,
  components,
  isShow,
  macrosAfterProcessing,
  name,
  toggleShow,
}) => {
  const { t } = useTranslation();
  const {
    showCalorific,
    showIngredients,
    showAllergens,
    showMacroIngredients,
  } = useAppConfigSelector(selectModuleShop);

  const componentsNames = !isEmpty(components) ? components.join(', ') : '-';
  const allergensNames = !isEmpty(allergens) ? allergens.join(', ') : '-';

  return (
    <AnimatePresence>
      {isShow && (
        <motion.div
          initial={{ transform: 'translateX(100%)' }}
          animate={{ transform: 'translateX(0%)' }}
          exit={{ transform: 'translateX(100%)' }}
          tw="absolute inset-0 z-10 px-3 pb-3 text-xs bg-white"
        >
          <div className="row" tw="-mx-1">
            <div className="col-4" tw="px-1">
              <button
                className="group"
                tw="hocus:(text-primary) w-6 flex justify-center"
                onClick={toggleShow}
                tabIndex={isShow ? 0 : -1}
              >
                <ArrowDownIcon
                  className="group-focus:(fill-current)"
                  tw="w-4 transform -rotate-90 transition-colors duration-300 hover:(duration-300)"
                  title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                />
              </button>
            </div>
            <div className="col-8" tw="px-1">
              <div className="row" tw="-mx-1 items-baseline justify-between">
                <div className="col-auto" tw="px-1 text-sm">
                  {name}
                </div>
                {showCalorific && (
                  <div className="col-auto" tw="px-1">
                    {getIntegerIfIntegerOrFixed(
                      macrosAfterProcessing?.calories ?? 0,
                      0
                    )}{' '}
                    {t('$*common.kcal', '$$kcal')}
                  </div>
                )}
              </div>
            </div>
          </div>
          {showIngredients && (
            <div tw="pt-2">
              <div className="row" tw="-mx-1">
                <div className="col-4" tw="px-1">
                  <OverflowContent>
                    {`${t('$*common.dishComposition', '$$Skład')}:`}
                  </OverflowContent>
                </div>
                <div className="col-8" tw="px-1">
                  <OverflowContent lineClamp={5}>
                    {componentsNames}
                  </OverflowContent>
                </div>
              </div>
            </div>
          )}

          {showAllergens && (
            <div tw="pt-2">
              <div className="row" tw="-mx-1">
                <div className="col-4" tw="px-1">
                  <OverflowContent>
                    {`${t('$*common.allergens', '$$Alergeny')}:`}
                  </OverflowContent>
                </div>
                <div className="col-8" tw="px-1">
                  <OverflowContent lineClamp={2}>
                    {allergensNames}
                  </OverflowContent>
                </div>
              </div>
            </div>
          )}

          {showMacroIngredients && (
            <Fragment>
              <div tw="pt-2">
                <div className="row" tw="-mx-1">
                  <div className="col-4" tw="px-1">
                    <OverflowContent>
                      {`${t('$*common.macros', '$$Makroskładniki')}:`}
                    </OverflowContent>
                  </div>
                  <div className="col-8" tw="px-1">
                    <ProductMacros {...macrosAfterProcessing} />
                  </div>
                </div>
              </div>

              <div tw="pt-2">
                <div className="row" tw="-mx-1">
                  <div className="col-4" tw="px-1">
                    <OverflowContent>
                      {`${t('$*common.glycemicIndexShort', '$$IG')}:`}
                    </OverflowContent>
                  </div>
                  <div className="col-8" tw="px-1">
                    {macrosAfterProcessing?.glycemicIndex}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProductSizeInfo;
