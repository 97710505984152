import NextLink from 'next/link';

import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';

import { isExternalUrl } from '@utils/helpers';

const Link = ({
  children,
  color = 'primary',
  fullWidth = false,
  href = '#',
  styles = null,
  type = 'text',
  variant = 'text',
  ...restProps
}) => {
  const isExternal = isExternalUrl(href);

  const variantButtonStyle = {
    contained: {
      primary: css`
        ${tw`text-white bg-primary border-primary hover:(bg-primary-hover text-white border-primary-hover)`};
      `,
    },
    outlined: {
      primary: css`
        ${tw`border-primary text-primary hover:(bg-primary text-white)`};
      `,
    },
    text: {
      primary: css`
        ${tw`text-primary hover:(text-primary-hover)`};
      `,
    },
  };

  const twLinkStyle = [
    type === 'button' && [
      tw`inline-block text-sm font-bold tracking-wide text-center rounded-md align-middle duration-300 border border-transparent cursor-pointer select-none focus-visible:(ring-2 outline-none ring-offset-2) hover:(no-underline transition-colors duration-300)`,
      fullWidth && tw`w-full`,
      variantButtonStyle[variant][color],
      variant !== 'link' && tw`px-6 py-3`,
    ],
    type === 'link' && [tw`no-underline`],
    styles,
  ].filter(Boolean);

  if (isExternal) {
    return (
      <a
        href={href}
        css={twLinkStyle}
        target="_blank"
        rel="noopener noreferrer"
        {...restProps}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href}>
      <a href={href} css={twLinkStyle} {...restProps}>
        {children}
      </a>
    </NextLink>
  );
};

Link.displayName = 'Link';

Link.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOfType([
    PropTypes.oneOf(['button', 'text']),
    PropTypes.string,
  ]),
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['contained', 'outline', 'text']),
    PropTypes.string,
  ]),
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary']), PropTypes.string]),
};

export default Link;
