const KeyIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1711 1779"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M25.1 1696.137L.399 1473.839a65.304 65.304 0 0118.726-53.387L632.686 806.89c-102.055-212.32-58.621-469.57 113.46-641.651 220.317-220.318 578.813-220.318 799.136.005 220.322 220.322 220.322 578.818.005 799.135-146.3 146.3-350.621 202.094-541.941 150.538l-108.484 108.484 3.315 188.883c.333 18.7-7.47 36.621-21.163 49.278a65.309 65.309 0 01-50.936 16.807l-128.01-13.405-1.282 150.414c-.298 35.41-28.787 64.136-64.188 64.731l-197.8 3.356-76.33 76.33a65.304 65.304 0 01-53.388 18.727l-222.292-24.698c-30.347-3.37-54.311-27.335-57.687-57.687zm263.214-50.884l72.806-72.806a65.304 65.304 0 0145.066-19.116l160.548-2.725 1.354-158.644c.17-18.413 8.075-35.841 21.8-48.15a65.298 65.298 0 0150.298-16.232l126.112 13.211-2.503-142.822a65.322 65.322 0 0119.116-47.323l155.93-155.93a65.295 65.295 0 0167.891-15.407c154.555 54.501 325.528 13.39 446.201-107.283 169.408-169.397 169.403-445.031 0-614.434-169.402-169.403-445.036-169.408-614.439-.005C696.406 399.675 667.901 616.59 769.161 785.1c15.443 25.693 11.4 58.61-9.794 79.804l-625.696 625.696 15.464 139.189 139.179 15.463zM1129.237 359.93c61.027-61.027 160.327-61.021 221.354.005 61.027 61.027 61.027 160.332.005 221.354-61.022 61.022-160.327 61.022-221.354-.005-61.026-61.027-61.026-160.332-.005-221.354zm144.402 144.402c18.593-18.593 18.588-48.847-.005-67.44-18.593-18.593-48.847-18.598-67.44-.005-18.593 18.593-18.588 48.847.005 67.44 18.593 18.593 48.847 18.598 67.44.005z"
      fillRule="nonzero"
    />
  </svg>
);

export default KeyIcon;
