const HashIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 1676 1748"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M1557.127 636.927c65.42 0 118.453-53.034 118.453-118.453 0-65.419-53.034-118.452-118.453-118.452h-211.523s24.142-135.202 42.647-238.815c11.534-64.605-31.005-126.485-95.454-138.849h-.004a117.02 117.02 0 00-88.343 18.49 117.022 117.022 0 00-48.903 75.861c-21.084 118.072-50.594 283.313-50.594 283.313H739.842l42.64-238.79c11.54-64.619-31.006-126.514-95.474-138.892h-.003a116.982 116.982 0 00-137.213 94.321c-21.084 118.079-50.601 283.36-50.601 283.36H245.362c-65.419 0-118.452 53.034-118.452 118.453 0 65.42 53.033 118.453 118.452 118.453h211.523l-84.608 473.81h-253.83c-65.418 0-118.452 53.033-118.452 118.452 0 65.42 53.034 118.453 118.453 118.453H329.97l-42.639 238.778c-11.538 64.627 31.013 126.526 95.488 138.896a116.991 116.991 0 0088.314-18.486 116.99 116.99 0 0048.89-75.835c21.085-118.075 50.602-283.353 50.602-283.353h365.108s-24.153 135.269-42.658 238.893c-11.53 64.564 30.983 126.4 95.391 138.76a117.099 117.099 0 00137.338-94.414l50.58-283.24h253.829c65.419 0 118.452-53.033 118.452-118.452s-53.033-118.452-118.452-118.452H1218.69l84.608-473.81h253.83zm-579.088 473.81H612.93l84.609-473.81h365.107l-84.608 473.81z"
      fillRule="nonzero"
    />
  </svg>
);

export default HashIcon;
