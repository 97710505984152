const CheckIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 68 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M23.604 47.207a3.303 3.303 0 01-2.342-.97L.972 25.945a3.308 3.308 0 010-4.684 3.31 3.31 0 014.684 0L23.604 39.21 61.844.97a3.31 3.31 0 014.685 0 3.31 3.31 0 010 4.685L25.947 46.237a3.304 3.304 0 01-2.343.97z" />
  </svg>
);

export default CheckIcon;
