import DisplayOn from './DisplayOn';

const DisplayDownCustom = ({ children, size }) => (
  <DisplayOn size={size} mode="down" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayDownCustom.displayName = 'DisplayDownCustom';

export default DisplayDownCustom;
