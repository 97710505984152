import { css } from '@emotion/react';
import tw, { styled, theme } from 'twin.macro';

const CommonButton = styled.button`
  ${tw`relative flex items-center justify-center w-full h-12 font-medium rounded-lg`}
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
    box-shadow 0.218s ease 0s;
  cursor: pointer;
  user-select: none;

  .icon-wrapper {
    ${tw`flex justify-center w-12`}
  }

  span {
    ${tw`pl-1`}
  }
`;

export const ButtonWrapper = styled.div(
  ({ enabledCount }) => css`
    ${tw`w-full px-1 py-2`};

    @media (min-width: ${theme`screens.sm`}) {
      width: calc(100% / ${enabledCount});
    }
  `
);

export const AppleButton = styled(CommonButton)`
  ${tw`text-white bg-black`};
  padding-right: 15px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;

  &:hover {
    box-shadow: rgb(59 89 152 / 30%) 0px 0px 3px 3px;
  }
`;

export const FacebookButton = styled(CommonButton)`
  ${tw`text-white bg-blueChambray`};
  padding-right: 13px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;

  &:hover {
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 3px 3px;
  }

  .icon-wrapper svg {
    font-size: 22px;
  }
`;

export const GoogleButton = styled(CommonButton)`
  ${tw`bg-white`};
  padding-right: 14px;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
  font-family: Roboto, arial, sans-serif;

  &:hover {
    box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px;
  }
`;
