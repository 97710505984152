import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import rootReducer from './rootReducer';

const isLoggerEnabled = false;

const createNoopStorage = () => {
  return {
    getItem: () => Promise.resolve(null),
    setItem: (_, value) => Promise.resolve(value),
    removeItem: () => Promise.resolve(),
  };
};

const { persistStore, persistReducer } = require('redux-persist');
const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  key: 'nextjs',
  version: 1,
  whitelist: [
    'auth',
    'basketNewOrder',
    'basketEditOrder',
    'orderForm',
    'orderTabs',
  ], // only counter will be persisted, add other reducers if needed
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer); // Create a new reducer with our existing reducer

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    ...(isLoggerEnabled ? [logger] : []),
  ],
  devTools: { trace: true, traceLimit: 25 },
}); // Creating the store again

store.__persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature

export default store;
