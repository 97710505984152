import { useTranslation } from 'next-i18next';

import { addDays, getISODay, isDate, isSameDay } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { theme } from 'twin.macro';

import { DayPicker } from '@components';
import { useOrderForm } from '@hooks';
import { getFormattedDisabledDaysOfWeek } from '@services/Calendar.service';

const modifiersStyles = {
  selectedDate: {
    color: theme`colors.white`,
    borderRadius: '0.75rem',
    fontWeight: 'bold',
    backgroundColor: theme`colors.primary`,
  },
  plannedDates: {
    color: theme`colors.white`,
    borderRadius: '0.75rem',
    fontWeight: 'bold',
    backgroundColor: theme`colors.grayIron`,
  },
  outside: {
    backgroundColor: 'transparent',
  },
};

const SelectDatesToChangeSection = ({
  plannedDays = [],
  selectNewDays = () => {},
  selectedNewDays = [],
  // selectDaysToChange = () => {},
  selectedDaysToChange = [],
}) => {
  const { t } = useTranslation();

  const {
    data: {
      firstPossibleOrderDay,
      disabledDays = [],
      disabledDaysOfWeek = [],
    } = {},
  } = useOrderForm({ enabled: false });

  const fromBagDisabledDays = [
    ...disabledDays.map(day => new Date(day)),
    { before: new Date(firstPossibleOrderDay) },
    {
      daysOfWeek: getFormattedDisabledDaysOfWeek(disabledDaysOfWeek) ?? [],
    },
  ];
  const toBagDisabledDays = [
    ...[...disabledDays, ...plannedDays].map(day => new Date(day)),
    { before: new Date(firstPossibleOrderDay) },
    {
      daysOfWeek: getFormattedDisabledDaysOfWeek(disabledDaysOfWeek) ?? [],
    },
  ];

  const getFirstEnabledDaysFromDisabledDays = days => {
    const onlyDays = days.filter(isDate);
    const beforeDay = days.find(day => day.before)?.before ?? null;
    const daysOfWeek = days.find(day => day.daysOfWeek)?.daysOfWeek ?? [];

    let startDay = beforeDay ?? new Date();
    let firstEnabledDay = null;

    if (daysOfWeek.length < 7) {
      while (!isDate(firstEnabledDay)) {
        const currentDayIso = getISODay(startDay);
        if (
          daysOfWeek.includes(currentDayIso) ||
          onlyDays.some(day => isSameDay(day, startDay))
        ) {
          startDay = addDays(startDay, 1);
        } else {
          firstEnabledDay = startDay;
        }
      }
    }

    return firstEnabledDay;
  };

  const toBagFirstEnableDay = getFirstEnabledDaysFromDisabledDays([
    ...toBagDisabledDays,
    ...plannedDays,
  ]);

  return (
    <div>
      <div tw="flex flex-col md:flex-row justify-around items-center md:items-start">
        <div css={[{ maxWidth: '320px' }]}>
          <div tw="text-center py-2">
            {t(
              '$*historyOrdersPage.deliveryDateChangeModal.selectDaysToChange',
              'Wybierz dni, które chcesz przenieść'
            )}
          </div>
          <DayPicker
            onDayClick={(_, modifiers) => {
              if (modifiers.disabled) {
                return;
              }
            }}
            disabledDays={fromBagDisabledDays}
            selectedDays={selectedDaysToChange}
            modifiers={{
              plannedDates: plannedDays,
              selectedDate: selectedDaysToChange,
            }}
            modifiersStyles={modifiersStyles}
            fromMonth={
              !isEmpty(selectedDaysToChange)
                ? new Date(selectedDaysToChange[0])
                : new Date()
            }
            initialMonth={
              !isEmpty(selectedDaysToChange)
                ? new Date(selectedDaysToChange[0])
                : new Date()
            }
          />
        </div>
        <div css={[{ maxWidth: '320px' }]}>
          <div tw="text-center py-2">
            {t(
              '$*historyOrdersPage.deliveryDateChangeModal.selectDaysToChange',
              'Wybierz dni, które chcesz przenieść'
            )}
          </div>
          <DayPicker
            onDayClick={(day, modifiers) => {
              if (
                modifiers.disabled ||
                (!modifiers.selected &&
                  selectedNewDays.length === selectedDaysToChange.length)
              ) {
                return;
              }

              if (modifiers.selected) {
                const filteredNewDays = selectedNewDays.filter(
                  selectedDay =>
                    !isSameDay(new Date(selectedDay), new Date(day))
                );
                return selectNewDays([...filteredNewDays]);
              }

              selectNewDays([...selectedNewDays, new Date(day)]);
            }}
            disabledDays={toBagDisabledDays}
            selectedDays={selectedNewDays}
            modifiers={{
              plannedDates: plannedDays,
              selectedDate: selectedNewDays,
            }}
            modifiersStyles={modifiersStyles}
            fromMonth={toBagFirstEnableDay}
            initialMonth={toBagFirstEnableDay}
          />
          <div tw="mt-5 text-center">
            {t(
              '$*historyOrdersPage.deliveryDateChangeModal.selectNewDeliveryDates.leftToMove',
              {
                value: selectedDaysToChange.length - selectedNewDays.length,
                defaultValue: 'Pozostało do wyboru: {{value}}',
              }
            )}
          </div>
        </div>
      </div>
      <div tw="my-5 text-center">
        {t(
          '$*historyOrdersPage.deliveryDateChangeModal.selectNewDeliveryDates.additionalInfoLabel',
          'Wszystkie dodatki zaplanowane na dany dzień zostaną przeniesione.'
        )}
      </div>
    </div>
  );
};

export default SelectDatesToChangeSection;
