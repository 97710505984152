import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

export const getRecipePage = async recipePageId => {
  const response = await axios.get(ENDPOINT.PRODUCT_DISH_PAGE(recipePageId));

  return response?.data ?? {};
};

const useRecipePage = (recipePageId, options = {}) =>
  useQuery(
    ['recipePage', recipePageId],
    () => getRecipePage(recipePageId),
    options
  );

export default useRecipePage;
