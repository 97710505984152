import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '@store/auth/auth.slice';
import tw from 'twin.macro';

import { DisplayUpMd, Icon, Link } from '@components';
import ROUTE_URLS from '@constants/routeUrls';
import {
  selectModuleConfigClientPanel,
  selectModuleMoneyBox,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { checkImageExists } from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

import Styled from './MoneyBoxBalance.styles';

const MoneyBoxBalance = ({ styles = {} }) => {
  const { t } = useTranslation();
  const { icon, shortName } = useAppConfigSelector(selectModuleMoneyBox);
  const { moneyBox = 0 } = useSelector(selectUser);
  const { allowClientSeeMoneyBox } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const [isExistImage, setIsExistImage] = useState(false);

  if (!allowClientSeeMoneyBox) {
    return null;
  }

  useEffect(() => {
    checkImageExists(icon)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [icon]);

  const twLinkStyle = cssMerge({
    defaultCss: tw`px-0 py-2`,
    ...styles?.link,
  });

  return (
    <Link
      href={ROUTE_URLS.MONEY_BOX}
      type="button"
      variant="outlined"
      styles={twLinkStyle}
    >
      <Styled.MoneyBox>
        <Styled.Label>
          <Styled.IconWrapper>
            {isExistImage ? (
              <Styled.CustomIcon iconUrl={icon} />
            ) : (
              <Icon name="piggyBank" tw="fill-current" />
            )}
          </Styled.IconWrapper>
          <DisplayUpMd>
            <span>
              {t(
                '$*components.header.myAccount.conditionMoneyBox',
                '$$Stan skarbonki'
              )}
            </span>
          </DisplayUpMd>
        </Styled.Label>
        <Styled.Points>{`${moneyBox} ${
          shortName || t('$*common.pointAbbreviation', '$$pkt')
        }`}</Styled.Points>
      </Styled.MoneyBox>
    </Link>
  );
};

export default MoneyBoxBalance;
