import { useTranslation } from 'next-i18next';

import { createContext, useEffect, useState } from 'react';
import { useIsFetching, useIsMutating, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { refreshToken } from '@store/auth/auth.actions';
import {
  actions as basketEditOrderActions,
  selectors as basketEditOrderSelectors,
} from '@store/basket/basketEditOrder.slice';
import {
  actions as basketNewOrderActions,
  selectors as basketNewOrderSelectors,
} from '@store/basket/basketNewOrder.slice';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import {
  changeStepAndResetValid,
  selectTabs,
} from '@features/orderTabs/orderTabsSlice';
import {
  useBasket,
  useBasketClean,
  useBasketClear,
  useBasketModifyDay,
  useCreateBasket,
  useCreateBasketAddon,
  useCreateBasketDiet,
  useCreateBasketDish,
  useOrderForm,
  usePersistBasket,
  useUpdateBasketModify,
  useValidateDiscountCode,
} from '@hooks';
import {
  selectBranding,
  selectModuleShop,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import BasketService from '@services/Basket.service';
import {
  pushAddToCartGTMEvent,
  pushCheckoutOptionGTMEvent,
  pushRemoveFromCartGTMEvent,
} from '@utils/gtm';
import {
  getIntegerIfIntegerOrFixed,
  isEmptyObject,
  isFalsify,
  sortDates,
} from '@utils/helpers';
import showToast from '@utils/showToast';
import { useAppContext } from '@views/App/App.context';

export const BasketMethodsContext = createContext([{}, () => {}]);

const BasketMethodsProvider = ({ children, basketKey = 'basketNewOrder' }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { isShop } = useAppContext();

  const [showAbandonedModal, setShowAbandonedModal] = useState(true);
  const [
    isBasketNewOrderPaymentSummaryModalOpen,
    setIsBasketNewOrderPaymentSummaryModalOpen,
  ] = useState(false);
  const [
    isBasketEditOrderPaymentSummaryModalOpen,
    setIsBasketEditOrderPaymentSummaryModalOpen,
  ] = useState(false);

  const stateBasket = {
    basketNewOrder: {
      isPaymentSummaryModalOpen: isBasketNewOrderPaymentSummaryModalOpen,
      openPaymentSummaryModal: () =>
        setIsBasketNewOrderPaymentSummaryModalOpen(true),
      closePaymentSummaryModal: () =>
        setIsBasketNewOrderPaymentSummaryModalOpen(false),
    },
    basketEditOrder: {
      isPaymentSummaryModalOpen: isBasketEditOrderPaymentSummaryModalOpen,
      openPaymentSummaryModal: () =>
        setIsBasketEditOrderPaymentSummaryModalOpen(true),
      closePaymentSummaryModal: () =>
        setIsBasketEditOrderPaymentSummaryModalOpen(false),
    },
  }[basketKey];

  const { name: brandName } = useAppConfigSelector(selectBranding);
  const { currencySymbol, currencyCode } =
    useAppConfigSelector(selectMultinational);
  const {
    minimumDailyOrderCost,
    maximumDailyOrderCost,
    minimumDailyDishes,
    maximumDailyDishes,
  } = useAppConfigSelector(selectModuleShop);

  const isBasketNewOrder = basketKey === 'basketNewOrder';
  const isBasketEditOrder = basketKey === 'basketEditOrder';

  const basketQuery = useBasket({ basketKey });

  const basketMutationKeys = [
    `${basketKey}Clean`,
    `${basketKey}Clear`,
    `${basketKey}AddModifyDay`,
    `${basketKey}Create`,
    `${basketKey}AddAddon`,
    `${basketKey}AddDiet`,
    `${basketKey}AddDish`,
    `${basketKey}Restore`,
    `${basketKey}UpdateModify`,
  ];

  const isMutatingBasket = basketMutationKeys
    .map(mutationKey => useIsMutating(mutationKey))
    .some(Boolean);

  const isFetchingBasket = basketMutationKeys
    .map(mutationKey => useIsFetching(mutationKey))
    .some(Boolean);

  const { mutate: createBasket } = useCreateBasket({ basketKey });
  const { mutateAsync: persistBasket } = usePersistBasket({ basketKey });
  const { mutateAsync: basketCleanUp } = useBasketClean({ basketKey });

  const basketActions = isBasketNewOrder
    ? basketNewOrderActions
    : basketEditOrderActions;

  const basketSelectors = isBasketNewOrder
    ? basketNewOrderSelectors
    : basketEditOrderSelectors;

  const basketStore = useSelector(basketSelectors.selectBasket);
  const currentDiet = useSelector(basketSelectors.selectCurrentDiet);

  const {
    addDiet: addDietBasket,
    addItemToBasket,
    removeDiet: removeDietBasket,
    setDiscountCode,
    updateDiet: updateDietBasket,
    resetBasket,
  } = basketActions;

  const { isNextDisabled, currentStepIndex, steps } = useSelector(selectTabs);

  const updateBasketModifyQuery = useUpdateBasketModify({ basketKey });
  const validateDiscountCodeQuery = useValidateDiscountCode();
  const { mutateAsync: basketClear } = useBasketClear({
    basketKey,
    options: {
      onSuccess: () => dispatch(resetBasket()),
    },
  });

  const {
    discountCode,
    isPayActionSuccess,
    items: basketItems,
    useDiscountCode,
  } = basketStore;

  const { mutateAsync: updateBasketModify } = updateBasketModifyQuery;
  const { mutateAsync: updateDiscountCode } = validateDiscountCodeQuery;

  const basketCommonCatch = (data = {}) => {
    const violations = data?.violations ?? [];
    if (isEmpty(violations)) return;

    const hasErrorTestMode = some(violations, { propertyPath: 'testMode' });
    const hasErrorDeliveryDay = violations.some(({ propertyPath }) =>
      propertyPath.includes('deliveryDay')
    );

    violations.map(({ propertyPath, message }, index) => {
      showToast(message, { toastId: `${propertyPath}_${index}` });
    });

    if (hasErrorDeliveryDay) {
      queryClient.invalidateQueries('orderForm');

      showToast(
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              '$*info.basket.deliveryDay',
              '$$Automatycznie wybrano dzień rozpoczęcia dostawy.'
            ),
          }}
        />,
        { type: 'info', toastId: 'deliveryDayInfo' }
      );
    }

    if (hasErrorTestMode) {
      dispatch(changeStepAndResetValid(0));
    }
  };

  const isAnyOfDietsInBasket = (dietIds = []) => {
    if (!Array.isArray(dietIds) || !Array.isArray(basketQuery?.data?.rows)) {
      return false;
    }

    return basketQuery?.data?.rows?.some(item =>
      dietIds.includes(item?.diet?.['@id'])
    );
  };

  const updateOrderBasketModify = async ({
    basketId = null,
    payload,
    stepNumber,
  }) => {
    const isResetPayload = isEmptyObject(payload);
    const mergePayload = isResetPayload
      ? {}
      : {
          ...(useDiscountCode ? { discountCode: discountCode?.['@id'] } : {}),
          ...payload,
        };

    return updateBasketModify({
      id: basketId || basketQuery?.data?.id,
      payload: mergePayload,
    })
      .then(() => {
        if ('abandoned' in payload) {
          dispatch(refreshToken());
        }

        if ('delivery' in payload && stepNumber) {
          pushCheckoutOptionGTMEvent({
            step: stepNumber,
            checkoutOption: {
              deliveryType: Object.keys(payload?.delivery ?? {})[0],
            },
          });
        }

        if ('paymentType' in payload && stepNumber) {
          pushCheckoutOptionGTMEvent({
            step: stepNumber,
            checkoutOption: {
              paymentType: payload?.paymentType,
            },
          });
        }
      })
      .catch(error => {
        basketCommonCatch(error?.response?.data);
      });
  };

  const commonSetDiscountCode = async (code, errorHandle = true) => {
    const newCode = code.replace(/  +/g, ' ')?.trim();

    return updateDiscountCode({ code: newCode })
      .then(response => {
        if (
          !isEmpty(response?.diets) &&
          !isAnyOfDietsInBasket(response?.diets)
        ) {
          return showToast(
            t(
              '$*notification.validateDiscountCode.noDietsErrorMessage',
              '$$Code does not work for ordered diet types'
            )
          );
        }

        dispatch(
          setDiscountCode({
            useDiscountCode: true,
            discountCode: { '@id': response['@id'], value: newCode },
          })
        );

        updateOrderBasketModify({
          payload: {
            discountCode: response['@id'],
          },
        }).then(() => {
          showToast(
            t(
              '$*notification.validateDiscountCode.success',
              '$$Rabat został pomyślnie wczytany'
            ),
            { type: 'success' }
          );
        });
      })
      .catch(error => {
        if (errorHandle) {
          const violations = error?.response?.data?.violations;
          violations.map(({ propertyPath, message }, index) => {
            showToast(message, { toastId: `${propertyPath}_${index}` });
          });
        } else {
          throw error;
        }
      });
  };

  const basketDietsDays = BasketService.convertBasketRowsToBasketDayObject(
    basketQuery?.data?.rows
  );
  const basketDetails = basketStore.orderDetails;

  const basketDeliveryType = basketQuery?.data?.defaultPickUpPoint
    ? 'pickUpPoint'
    : 'address';

  const validateBasketDayRequirements = (day, dayPrice) => {
    if (
      minimumDailyOrderCost &&
      dayPrice.beforeDiscount < minimumDailyOrderCost
    ) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.minimumDailyOrderCost', {
          defaultValue:
            '$$Minimalna dzienna wartość zamówienia wynosi {{value}} {{currencySymbol}}. Dodaj do koszyka coś jeszcze lub zwiększ rozmiar porcji.',
          replace: {
            value: getIntegerIfIntegerOrFixed(minimumDailyOrderCost),
            currencySymbol,
          },
        }),
      };
    }

    if (
      maximumDailyOrderCost &&
      dayPrice.beforeDiscount > maximumDailyOrderCost
    ) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.maximumDailyOrderCost', {
          defaultValue:
            '$$Maksymalna dzienna wartość zamówienia wynosi {{value}} {{currencySymbol}}. Usuń coś z koszyka lub zmniejsz rozmiar porcji.',
          replace: {
            value: getIntegerIfIntegerOrFixed(maximumDailyOrderCost),
            currencySymbol,
          },
        }),
      };
    }

    if (
      minimumDailyDishes &&
      basketDetails?.[day]?.dishes < minimumDailyDishes
    ) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.minimumDailyDishes', {
          defaultValue:
            '$$Minimalna dzienna ilość dań wynosi {{count}}. Dodaj do koszyka któreś z dań.',
          replace: { count: minimumDailyDishes },
        }),
      };
    }

    if (
      maximumDailyDishes &&
      basketDetails?.[day]?.dishes > maximumDailyDishes
    ) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.maximumDailyDishes', {
          defaultValue:
            '$$Maksymalnalna dzienna ilość dań wynosi {{count}}. Usuń z koszyka któreś z dań.',
          replace: { count: maximumDailyDishes },
        }),
      };
    }

    return {
      isValid: true,
      errorMessage: null,
    };
  };

  const basketDietsDaysError = isShop
    ? Object.entries(basketDietsDays).reduce((acc, [dietIri, days]) => {
        const dietErrors = Object.entries(days).reduce(
          (acc, [date, { price }]) => {
            return {
              ...acc,
              [date]: validateBasketDayRequirements(date, price),
            };
          },
          {}
        );

        return {
          ...acc,
          [dietIri]: dietErrors,
        };
      }, {})
    : {};

  const isEmptyBasket = isEmpty(basketDietsDays);
  const prevIsEmptyBasket = usePrevious(isEmptyBasket);
  const someBasketDietDaysHasError = isShop
    ? Object.values(basketDietsDaysError).some(basketDay =>
        Object.values(basketDay).some(({ isValid }) => !isValid)
      )
    : false;

  const checkBasketIsValidToPayment = () => {
    if (
      someBasketDietDaysHasError ||
      isMutatingBasket ||
      isEmptyBasket ||
      (isBasketNewOrder && isNextDisabled)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (isBasketNewOrder) {
      const dishesCurrentStepIndex = steps?.findIndex(
        step => step.slug === 'wybierz-dania'
      );

      if (
        (someBasketDietDaysHasError ||
          (prevIsEmptyBasket === false && isEmptyBasket)) &&
        currentStepIndex !== dishesCurrentStepIndex &&
        currentStepIndex !== 0
      ) {
        dispatch(changeStepAndResetValid(0));

        // to remove - check later #sannan
        showToast(
          isEmptyBasket
            ? t(
                '$*notification.basketEmptyInvalid.error',
                '$$Koszyk został wyczyszczony, dodaj coś do niego'
              )
            : t(
                '$*notification.basketInvalid.error',
                '$$W koszyku wystąpiły błędy, zajrzyj do niego'
              ),
          { type: 'warning' }
        );
      }
    }
  }, [someBasketDietDaysHasError, isEmptyBasket, prevIsEmptyBasket]);

  const { mutateAsync: mutateBasketDiet } = useCreateBasketDiet({ basketKey });
  const { mutateAsync: updateBasketDish } = useCreateBasketDish({ basketKey });
  const { mutateAsync: updateBasketAddon } = useCreateBasketAddon({
    basketKey,
  });
  const { mutateAsync: updateBasketModifyDay } = useBasketModifyDay({
    basketKey,
  });

  const handleChangeProductQuantity = ({
    dishIri,
    day,
    quantity,
    isRemove = false,
    dietIri = 'noDiet',
  }) => {
    const basket = queryClient.getQueryData(basketKey);

    updateBasketDish({
      basket: basket['@id'],
      dishSize: dishIri,
      day,
      quantity,
      ...(dietIri !== 'noDiet' ? { clientDiet: dietIri } : {}),
    })
      .then(response => {
        isRemove
          ? pushRemoveFromCartGTMEvent({
              cartItems: response?.rows,
              brandName,
              currencyCode,
            })
          : pushAddToCartGTMEvent({
              cartItems: response?.rows,
              brandName,
              currencyCode,
            });
        dispatch(
          addItemToBasket({
            day,
            dietIri,
            iri: dishIri,
            quantity,
          })
        );
      })
      .catch(() => {
        //
      });
  };

  const checkIsProductSelected = ({
    product,
    dietIri = 'noDiet',
    selectedDate,
  }) => {
    const dayItems = basketItems?.[dietIri]?.[selectedDate];

    if (!isEmpty(dayItems)) {
      const items = Object.keys(dayItems);

      const productHasDishSize = product.sizes.some(size =>
        items.includes(size['@id'])
      );

      return productHasDishSize;
    }

    return false;
  };

  const handleChangeAddonQuantity = ({
    addonIri,
    day,
    quantity,
    isRemove = false,
    dietIri = 'noDiet',
  }) => {
    const basket = queryClient.getQueryData(basketKey);

    updateBasketAddon({
      basket: basket['@id'],
      addon: addonIri,
      day,
      quantity,
      ...(dietIri !== 'noDiet' ? { clientDiet: dietIri } : {}),
    })
      .then(response => {
        isRemove
          ? pushRemoveFromCartGTMEvent({
              cartItems: response?.rows,
              brandName,
              currencyCode,
            })
          : pushAddToCartGTMEvent({
              cartItems: response?.rows,
              brandName,
              currencyCode,
            });
        dispatch(
          addItemToBasket({
            day,
            dietIri,
            iri: addonIri,
            quantity,
          })
        );
      })
      .catch(() => {
        //
      });
  };

  const checkIsAddonSelected = ({
    addon,
    dietIri = 'noDiet',
    selectedDate,
  }) => {
    const dayItems = basketItems?.[dietIri]?.[selectedDate];

    if (!isEmpty(dayItems)) {
      const items = Object.keys(dayItems);
      return items.includes(addon['@id']);
    }

    return false;
  };

  const handleUpdateBasketModifyDay = async payload => {
    const basket = queryClient.getQueryData(basketKey);

    if (!basket || isEmpty(basket)) return;

    return updateBasketModifyDay({
      payload: {
        basket: basket['@id'],
        ...payload,
      },
    }).catch(error => {
      basketCommonCatch(error?.response?.data);
    });
  };

  const handleRemoveBasketDiet = dietIri => {
    const basket = queryClient.getQueryData(basketKey);

    if (!basket || isEmpty(basket)) {
      return;
    }

    const currentDiet = basketItems?.dietElements?.find(
      row => row['@id'] === dietIri
    );
    const basketDiet = {
      addons: currentDiet?.addons,
      calorific: currentDiet?.calorificId,
      deliveryDates: sortDates(currentDiet?.deliveryDates ?? []),
      diet: currentDiet?.dietId,
      dietLength: currentDiet?.dietLength,
      firstDeliveryDay: currentDiet?.firstDeliveryDay,
      optionChangeMenu: currentDiet?.optionChangeMenu,
      package: currentDiet?.packageId,
      paymentMode: currentDiet?.paymentMode,
      quantity: 0,
      saturdayInclude: currentDiet?.saturdayInclude,
      sundayInclude: currentDiet?.sundayInclude,
      useEcoContainers: currentDiet?.useEcoContainers,
      variant: currentDiet?.variantId,
    };

    mutateBasketDiet({
      basket: basket['@id'],
      existingItem: dietIri ?? null,
      ...basketDiet,
    }).then(response => {
      pushRemoveFromCartGTMEvent({ cartItems: response?.rows, brandName });
      dispatch(removeDietBasket(dietIri));
    });
  };

  const getNewDietFromBasketResponse = response => {
    const basketStoreDiets = basketItems?.dietElements?.filter(
      row => row['@type'] === 'BasketItemDiet'
    );

    const basketStoreDietIds = basketStoreDiets.map(row => row['@id']);

    const responseDiets = response?.rows?.filter(
      row => row['@type'] === 'BasketItemDiet'
    );

    const diffResponseDiets = responseDiets.filter(
      row => !basketStoreDietIds.includes(row['@id'])
    );

    const firstResponseDiet = diffResponseDiets?.[0] ?? {
      '@id': null,
      '@type': null,
    };

    return firstResponseDiet;
  };

  const handleAddBasketDiet = basketItem => {
    const basket = queryClient.getQueryData(basketKey);

    if (!basket || isEmpty(basket)) {
      return;
    }

    mutateBasketDiet({
      basket: basket?.['@id'],
      ...basketItem,
    })
      .then(response => {
        pushAddToCartGTMEvent({
          cartItems: response?.rows,
          brandName,
          currencyCode,
        });
        const firstResponseDiet = getNewDietFromBasketResponse(response);

        dispatch(
          addDietBasket({
            '@id': firstResponseDiet?.['@id'],
            '@type': firstResponseDiet?.['@type'],
            addons: basketItem?.addons,
            calorificId: basketItem?.calorific,
            deliveryDates: sortDates(basketItem?.deliveryDates ?? []),
            dietId: basketItem?.diet,
            dietLength: basketItem?.dietLength,
            firstDeliveryDay: basketItem?.firstDeliveryDay,
            optionChangeMenu: basketItem?.optionChangeMenu,
            packageId: basketItem?.package,
            paymentMode: basketItem?.paymentMode,
            useEcoContainers: basketItem?.useEcoContainers,
            variantId: basketItem?.variant,
            sundayInclude: basketItem?.sundayInclude ?? true,
            saturdayInclude: basketItem?.saturdayInclude ?? true,
          })
        );
      })
      .catch(error => {
        const isCancelledError =
          error && Object.prototype.hasOwnProperty.call(error, 'silent');

        if (!isCancelledError) {
          basketCommonCatch(error?.response?.data);
        }
      });
  };

  const handleUpdateBasketDiet = async (
    { existingItem = null, ...updatedProps } = {},
    isRemove = false
  ) => {
    const basket = queryClient.getQueryData(basketKey);

    if (!basket || isEmpty(basket)) {
      return;
    }

    const basketCurrentDiet = existingItem
      ? basketItems?.dietElements?.find(row => row['@id'] === existingItem)
      : currentDiet;

    const previousDiet = {
      addons: basketCurrentDiet?.addons,
      calorific: basketCurrentDiet?.calorificId,
      deliveryDates: sortDates(basketCurrentDiet?.deliveryDates ?? []),
      diet: basketCurrentDiet?.dietId,
      dietLength: basketCurrentDiet?.dietLength,
      firstDeliveryDay: basketCurrentDiet?.firstDeliveryDay,
      name: basketCurrentDiet?.name,
      optionChangeMenu: basketCurrentDiet?.optionChangeMenu,
      package: basketCurrentDiet?.packageId,
      paymentMode: basketCurrentDiet?.paymentMode,
      useEcoContainers: basketCurrentDiet?.useEcoContainers,
      variant: basketCurrentDiet?.variantId,
      sundayInclude: basketCurrentDiet?.sundayInclude ?? true,
      saturdayInclude: basketCurrentDiet?.saturdayInclude ?? true,
    };

    // clean object from undefined values
    const updatedDiet = pickBy(
      {
        ...previousDiet,
        ...updatedProps,
      },
      v => v !== undefined
    );

    dispatch(
      updateDietBasket({
        addons: updatedDiet?.addons,
        calorificId: updatedDiet?.calorific,
        deliveryDates: sortDates(updatedDiet?.deliveryDates ?? []),
        dietId: updatedDiet?.diet,
        dietLength: updatedDiet?.dietLength,
        existingItem,
        firstDeliveryDay: updatedDiet?.firstDeliveryDay,
        name: updatedDiet?.name,
        optionChangeMenu: updatedDiet?.optionChangeMenu,
        packageId: updatedDiet?.package,
        paymentMode: updatedDiet?.paymentMode,
        useEcoContainers: updatedDiet?.useEcoContainers,
        variantId: updatedDiet?.variant,
        sundayInclude: updatedDiet?.sundayInclude ?? true,
        saturdayInclude: updatedDiet?.saturdayInclude ?? true,
      })
    );

    return mutateBasketDiet({
      basket: basket['@id'],
      existingItem: existingItem ?? currentDiet?.['@id'] ?? null,
      ...updatedDiet,
    })
      .then(response => {
        isRemove
          ? pushRemoveFromCartGTMEvent({
              cartItems: response?.rows,
              brandName,
              currencyCode,
            })
          : pushAddToCartGTMEvent({
              cartItems: response?.rows,
              brandName,
              currencyCode,
            });
      })
      .catch(error => {
        dispatch(
          updateDietBasket({
            addons: previousDiet?.addons,
            calorificId: previousDiet?.calorific,
            deliveryDates: sortDates(previousDiet?.deliveryDates ?? []),
            dietId: previousDiet?.diet,
            dietLength: previousDiet?.dietLength,
            existingItem,
            firstDeliveryDay: previousDiet?.firstDeliveryDay,
            name: previousDiet?.name,
            optionChangeMenu: previousDiet?.optionChangeMenu,
            packageId: previousDiet?.package,
            paymentMode: previousDiet?.paymentMode,
            useEcoContainers: previousDiet?.useEcoContainers,
            variantId: previousDiet?.variant,
            sundayInclude: previousDiet?.sundayInclude ?? true,
            saturdayInclude: previousDiet?.saturdayInclude ?? true,
          })
        );

        basketCommonCatch(error?.response?.data);
      });
  };

  /***************************************
   * START: CHANGE TEST MODE [DIET FULL] *
   ***************************************/

  const { data: { days: { standardDays = {}, testDays = {} } = {} } = {} } =
    useOrderForm({ enabled: false });

  const handleChangeTestMode = (isEnabled, isEnabledUpdateBasket = true) => {
    updateOrderBasketModify({
      payload: { testMode: isEnabled },
    }).then(() => {
      if (isEnabledUpdateBasket) {
        const dietLength = isEnabled
          ? testDays?.default ?? first(testDays?.options)
          : standardDays?.default;

        handleUpdateBasketDiet({
          dietLength,
          deliveryDates: [],
        });
      }
    });
  };

  /**************************************
   * END: CHANGE TEST MODE [DIET FULL] *
   *************************************/

  const handleBasketClear = async () => {
    pushRemoveFromCartGTMEvent({ brandName });
    return basketClear({ id: basketQuery?.data?.id });
  };

  const initBasket = ({ forceCreate = false } = {}) => {
    const persistedBasket = queryClient.getQueryData(basketKey);
    // const isBasketValid = validateBasket(persistedBasket);

    if (forceCreate) {
      return createBasket();
    }

    if (!isFalsify(persistedBasket) && !isPayActionSuccess) {
      return persistBasket().then(basket => {
        basketCleanUp({ id: basket?.id }).catch(() => {
          createBasket();
        });
      });
    }

    return createBasket();
  };

  const isSubscriptionInCart =
    basketQuery?.data?.rows?.some(
      ({ paymentMode }) =>
        paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT
    ) ?? false;

  const value = {
    ...basketActions,
    ...stateBasket,
    basketCommonCatch,
    basketDeliveryType,
    basketDietsDays,
    basketDietsDaysError,
    basketQuery,
    basketStore,
    checkBasketIsValidToPayment,
    checkIsAddonSelected,
    checkIsProductSelected,
    commonSetDiscountCode,
    currentDiet,
    handleAddBasketDiet,
    handleBasketClear,
    handleChangeAddonQuantity,
    handleChangeProductQuantity,
    handleChangeTestMode,
    handleRemoveBasketDiet,
    handleUpdateBasketDiet,
    handleUpdateBasketModifyDay,
    initBasket,
    isBasketEditOrder,
    isBasketNewOrder,
    isEmptyBasket,
    isFetchingBasket,
    isMutatingBasket,
    isSubscriptionInCart,
    setShowAbandonedModal,
    showAbandonedModal,
    someBasketDietDaysHasError,
    updateBasketModifyQuery,
    updateOrderBasketModify,
    validateDiscountCodeQuery,
  };

  return (
    <BasketMethodsContext.Provider value={value}>
      {children}
    </BasketMethodsContext.Provider>
  );
};

export default BasketMethodsProvider;
