import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInitialDiet } from '@store/basket/basketInitialData.slice';
import tw from 'twin.macro';

import { Button } from '@components';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import ROUTE_URLS from '@constants/routeUrls';
import {
  changeStep,
  changeStepAndResetValid,
  nextStep,
  selectTabs,
} from '@features/orderTabs/orderTabsSlice';
import { useBasketMethods } from '@hooks';
import { isCMSPage } from '@utils/helpers';
import { useAppContext } from '@views/App/App.context';

import PaymentSummaryModal from './PaymentSummaryModal';

const BasketAction = ({
  isMobile = false,
  buttonNextText = null,
  styles = {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const { isShop, isDietFull } = useAppContext();
  const { currentStepIndex, stepsCount } = useSelector(selectTabs);
  const initialDiet = useSelector(selectInitialDiet);

  const {
    basketQuery: { data: { testMode } = {} } = {},
    checkBasketIsValidToPayment,
    closePaymentSummaryModal,
    currentDiet,
    handleAddBasketDiet,
    isBasketEditOrder,
    isMutatingBasket,
    isPaymentSummaryModalOpen,
    openPaymentSummaryModal,
    setStatusBasket,
  } = useBasketMethods();

  const { dietLength, paymentMode, deliveryDates = [] } = currentDiet;

  const isFullCalendarValid = () => {
    if (!isDietFull) return true;

    if (paymentMode === BASKET_PAYMENT_MODES.SINGLE_PAYMENT) {
      return deliveryDates?.length === dietLength;
    }

    if (paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT) {
      return deliveryDates?.length === 1;
    }

    return true;
  };

  const basketIsValidToPayment =
    checkBasketIsValidToPayment() && isFullCalendarValid();

  const isLastStep = currentStepIndex === stepsCount - 1;

  const handleCloseBasket = () => dispatch(setStatusBasket(false));
  const handleMobileAction = () => {
    window.scrollTo({ top: 0 });

    if (isMobile) {
      handleCloseBasket();
    }
  };

  const handleNextStep = () => {
    dispatch(nextStep());

    handleMobileAction();
  };

  const handleAddMore = async () => {
    if (isDietFull) {
      await handleAddBasketDiet(initialDiet);

      dispatch(changeStepAndResetValid(0));
      handleMobileAction();
    } else {
      dispatch(changeStep(0));
      handleMobileAction();
    }
  };

  if (isBasketEditOrder) {
    return (
      <Fragment>
        <Button
          fullWidth={true}
          onClick={openPaymentSummaryModal}
          isLoading={isMutatingBasket}
          disabled={!basketIsValidToPayment}
          data-cy="basket-action__button--pay"
        >
          {t('$*components.basket.pay', '$$Zapłać')}
        </Button>
        <PaymentSummaryModal
          isOpen={isPaymentSummaryModalOpen}
          onClose={closePaymentSummaryModal}
        />
      </Fragment>
    );
  }

  if (isCMSPage(router.pathname)) {
    return (
      <Button
        onClick={() => router.push(ROUTE_URLS.NEW_ORDER_FORM)}
        fullWidth={true}
        isLoading={isMutatingBasket}
        data-cy="basket-action__button--next"
        styles={{
          button: styles?.nextButton,
        }}
      >
        {buttonNextText ?? t('$*components.basket.next', '$$Dalej')}
      </Button>
    );
  }

  if (isLastStep) {
    return (isShop || isDietFull) && !testMode ? (
      <Button
        fullWidth={true}
        onClick={handleAddMore}
        variant="outlined"
        data-cy="basket-action__button--add-more"
        styles={{ button: tw`bg-white` }}
      >
        {t('$*components.basket.addMore', '$$Dodaj więcej')}
      </Button>
    ) : null;
  }

  return (
    <Button
      fullWidth={true}
      onClick={handleNextStep}
      isLoading={isMutatingBasket}
      disabled={!basketIsValidToPayment}
      data-cy="basket-action__button--next"
      styles={{
        button: styles?.nextButton,
      }}
    >
      {buttonNextText ?? t('$*components.basket.next', '$$Dalej')}
    </Button>
  );
};

export default BasketAction;
