import { createContext, useContext } from 'react';

import { useToggle } from '@hooks';

const DishTileContext = createContext([{}, () => {}]);

const DishTileProvider = ({
  allergens,
  children,
  image,
  ingredients,
  macrosAfterProcessing,
  name,
  portionDivision,
  tags,
}) => {
  const [isOpenMenu, toggleOpenMenu] = useToggle(false);

  const value = {
    allergens,
    image,
    ingredients,
    isOpenMenu,
    macrosAfterProcessing,
    name,
    portionDivision,
    tags,
    toggleOpenMenu,
  };

  return (
    <DishTileContext.Provider value={value}>
      {children}
    </DishTileContext.Provider>
  );
};

const useDishTileContext = () => useContext(DishTileContext);

export { DishTileProvider, useDishTileContext };
