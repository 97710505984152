import { css } from '@emotion/react';
import tw from 'twin.macro';

import { format } from '@services/Date.service';

const BigDayTile = ({
  isSelected = false,
  date = new Date(),
  onClick = () => {},
}) => {
  return (
    <div
      css={css`
        ${tw`border border-gray-400 mx-auto rounded-md p-2 flex-auto text-center cursor-pointer hover:(bg-primary text-white border-primary)`}
        ${isSelected && tw`text-white bg-primary border-primary`}
            ${isSelected ? 'width: 126px' : 'width: 112px'}
      `}
      onClick={onClick}
      data-cy="day-tile day-tile--big"
    >
      <div css={[tw`text-xs uppercase`, isSelected && tw`text-sm font-medium`]}>
        {format(date, 'LLLL')}
      </div>
      <div
        css={[
          tw`text-2xl font-medium`,
          isSelected && tw`py-1 text-3xl font-semibold`,
        ]}
      >
        {format(date, 'dd')}
      </div>
      <div
        css={[tw`text-xs capitalize`, isSelected && tw`text-sm font-medium`]}
      >
        {format(date, 'EEEE')}
      </div>
    </div>
  );
};

BigDayTile.displayName = 'BigDayTile';

export default BigDayTile;
