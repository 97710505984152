import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';

import { getIntegerIfIntegerOrFixed, isFalsify } from '@utils/helpers';

const MenuDishMacros = ({
  protein = null,
  carbohydrates = null,
  fat = null,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {!isFalsify(protein) && (
        <Fragment>
          <span tw="font-semibold" title={t('$*common.proteins', '$$Białko')}>
            {`${t('$*common.proteins', '$$Białko').charAt(0)}:`}
          </span>
          {` ${getIntegerIfIntegerOrFixed(protein)}g | `}
        </Fragment>
      )}
      {!isFalsify(carbohydrates) && (
        <Fragment>
          <span
            tw="font-semibold"
            title={t('$*common.carbohydrates', '$$Węglowodany')}
          >
            {`${t('$*common.carbohydrates', '$$Węglowodany').charAt(0)}:`}
          </span>
          {` ${getIntegerIfIntegerOrFixed(carbohydrates)}g | `}
        </Fragment>
      )}
      {!isFalsify(fat) && (
        <Fragment>
          <span tw="font-semibold" title={t('$*common.fats', '$$Tłuszcze')}>
            {`${t('$*common.fats', '$$Tłuszcze').charAt(0)}:`}
          </span>
          {` ${getIntegerIfIntegerOrFixed(fat)}g`}
        </Fragment>
      )}
    </Fragment>
  );
};

export default MenuDishMacros;
