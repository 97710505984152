import tw from 'twin.macro';

const DietTileImage = ({ imageUrl }) => {
  if (!imageUrl) {
    return null;
  }

  return <img src={imageUrl} css={[tw`w-full`, { borderRadius: '9px' }]} />;
};

export default DietTileImage;
