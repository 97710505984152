import { createContext, useContext, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDays, getDay, isBefore } from 'date-fns';
import produce from 'immer';

import { useBasketMethods, useOrderForm } from '@hooks';
import { getFormattedDisabledDaysOfWeek } from '@services/Calendar.service';
import { format, formatArray } from '@services/Date.service';

const NewOrderCreatePageContext = createContext([{}, () => {}]);

const NewOrderCreatePageProvider = ({ children }) => {
  const dispatch = useDispatch();

  const orderFormQuery = useOrderForm({ enabled: false });
  const {
    data: {
      disabledDays = [],
      disabledDaysOfWeek = [],
      firstPossibleOrderDay = new Date(),
      saturdayInclude = true,
      sundayInclude = true,
    } = {},
  } = orderFormQuery;

  const { basketStore, handleUpdateBasketDiet, setRevalidateBasket } =
    useBasketMethods();

  const turnedOffDaysOfWeekArrayDefault =
    [sundayInclude ? null : 0, saturdayInclude ? null : 6].filter(
      Number.isInteger
    ) ?? [];

  const [turnedOffDaysOfWeek, setTurnedOffDaysOfWeek] = useState(
    turnedOffDaysOfWeekArrayDefault
  );

  const isDateValid = date => {
    const formattedDisabledDaysOfWeek =
      getFormattedDisabledDaysOfWeek(disabledDaysOfWeek);

    return (
      !disabledDays.includes(format(date)) &&
      !turnedOffDaysOfWeek.includes(getDay(date)) &&
      !formattedDisabledDaysOfWeek.includes(getDay(date))
    );
  };

  const selectDaysByRange = (startDate, range) => {
    let selectedDays = [];
    let currentDate = startDate;

    while (selectedDays.length < range) {
      if (isDateValid(currentDate)) {
        selectedDays.push(currentDate);
      }
      currentDate = addDays(new Date(currentDate), 1);
    }

    return selectedDays;
  };

  const hasBasketInvalidDates = basketStore.items.dietElements.some(
    dietElement => {
      const currentFirstDayIsIncorrect = isBefore(
        new Date(dietElement.firstDeliveryDay),
        new Date(firstPossibleOrderDay)
      );
      const hasInvalidDeliveryDates = dietElement.deliveryDates.some(
        date =>
          isBefore(new Date(date), new Date(firstPossibleOrderDay)) ||
          !isDateValid(new Date(date))
      );

      return currentFirstDayIsIncorrect || hasInvalidDeliveryDates;
    }
  );

  useEffect(() => {
    if (hasBasketInvalidDates) {
      const revalidatedBasketStore = produce(basketStore, draftState => {
        draftState.items.dietElements.map(dietElement => {
          const currentFirstDayIsIncorrect = isBefore(
            new Date(dietElement.firstDeliveryDay),
            new Date(firstPossibleOrderDay)
          );
          const hasInvalidDeliveryDates = dietElement.deliveryDates.some(
            date =>
              isBefore(new Date(date), new Date(firstPossibleOrderDay)) ||
              !isDateValid(new Date(date))
          );

          if (currentFirstDayIsIncorrect || hasInvalidDeliveryDates) {
            const newDeliveryDates = formatArray(
              selectDaysByRange(
                new Date(firstPossibleOrderDay),
                dietElement.dietLength
              )
            );
            const deliveryDates =
              dietElement.paymentMode === 'SINGLE_PAYMENT'
                ? newDeliveryDates
                : [firstPossibleOrderDay];

            dietElement.firstDeliveryDay = firstPossibleOrderDay;
            dietElement.deliveryDates = deliveryDates;

            handleUpdateBasketDiet({
              deliveryDates,
            });
          }
        });
      });

      dispatch(setRevalidateBasket(revalidatedBasketStore));
    }
  }, [firstPossibleOrderDay, hasBasketInvalidDates]);

  const value = {
    orderFormQuery,
    selectDaysByRange,
    setTurnedOffDaysOfWeek,
    turnedOffDaysOfWeek,
  };

  return (
    <NewOrderCreatePageContext.Provider value={value}>
      {children}
    </NewOrderCreatePageContext.Provider>
  );
};

const useNewOrderCreatePageContext = () =>
  useContext(NewOrderCreatePageContext);

export { NewOrderCreatePageProvider, useNewOrderCreatePageContext };
