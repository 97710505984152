import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useStripeKeys } from '@hooks';
import {
  selectModuleStripe,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';

const withStripeConsumer = Component => {
  const StripeConsumer = props => {
    const [stripe, setStripe] = useState(null);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const { cardPayments: isStripeCardPayments } =
      useAppConfigSelector(selectModuleStripe);

    const { data: { secret = null, key = null } = {} } = useStripeKeys({
      enabled: isStripeCardPayments && isAuthenticated,
    });

    useEffect(() => {
      if (key !== null) {
        setStripe(loadStripe(key));
      }
    }, [key]);

    if (!isStripeCardPayments) {
      return <Component {...props} />;
    }

    return (
      <Elements stripe={stripe}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <Component
              {...props}
              stripe={stripe}
              elements={elements}
              stripeSecret={secret}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  };

  StripeConsumer.displayName = 'StripeConsumer';
  return StripeConsumer;
};

withStripeConsumer.displayName = 'withStripeConsumer';

export default withStripeConsumer;
