const SkipIcon = ({ title = '', ...props }) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    {title && <title>{title}</title>}
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m5 4 10 8-10 8V4zM19 5v14"
    />
  </svg>
);

export default SkipIcon;
