const PuzzleIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2527 2604"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}

    <path d="M1192.956 2603.894c34.517 0 62.504-27.987 62.504-62.504v-160.107c0-128.258 95.954-234.461 218.169-242.938a71.275 71.275 0 0 0 4.065-.408c3.846-.533 7.738-.769 11.71-.769 3.971 0 7.864.236 11.71.77 1.35.172 2.715.313 4.065.407 122.214 8.477 218.168 114.68 218.168 242.938v160.107c0 34.517 27.988 62.504 62.505 62.504h607.901c72.833 0 132.873-59.977 132.873-135.133v-627.476c0-34.517-27.987-62.504-62.504-62.504H2309.18c-123.392 0-224.024-99.627-233.253-224.604a71.163 71.163 0 0 0-.44-4.144 103.76 103.76 0 0 1 0-29.478c.188-1.366.33-2.747.44-4.144 9.23-124.962 109.86-224.604 233.253-224.604h154.942c34.517 0 62.504-27.988 62.504-62.505V601.827c0-75.156-60.04-135.117-132.873-135.117h-545.397v-98.246c0-203.727-161.582-368.496-358.952-368.496s-358.952 164.769-358.952 368.496v98.246H585.054c-72.833 0-132.872 59.961-132.872 135.117v564.971h-93.223c-197.355 0-358.952 164.784-358.952 368.496 0 203.727 161.597 368.496 358.952 368.496h93.223v564.97c0 75.157 60.04 135.134 132.872 135.134h607.902Zm-62.504-125.009H585.054c-4.929 0-7.864-5.038-7.864-10.124v-627.476c0-34.517-27.987-62.504-62.504-62.504H358.959c-129.545 0-233.944-109.767-233.944-243.487s104.399-243.487 233.944-243.487h155.727c34.517 0 62.504-27.988 62.504-62.505V601.827c0-5.086 2.935-10.109 7.864-10.109h607.902c34.517 0 62.504-27.987 62.504-62.504v-160.75c0-133.72 104.383-243.487 233.944-243.487 129.56 0 233.943 109.767 233.943 243.487v160.75c0 34.517 27.988 62.504 62.505 62.504h607.901c4.93 0 7.864 5.023 7.864 10.109v564.971h-92.437c-187.56 0-342.754 148.758-357.76 338.405a229.041 229.041 0 0 0-1.993 30.09c0 10.22.69 20.265 1.993 30.091 15.006 189.68 170.2 338.406 357.76 338.406h92.437v564.97c0 5.087-2.935 10.125-7.864 10.125h-545.397v-97.602c0-194.56-147.455-353.615-332.566-367.49a212.283 212.283 0 0 0-26.386-1.633c-8.932 0-17.738.549-26.402 1.632-185.096 13.876-332.55 172.93-332.55 367.491v97.602Z" />
  </svg>
);

export default PuzzleIcon;
