import { useTranslation } from 'next-i18next';

import { AnimatePresence } from 'framer-motion';
import tw from 'twin.macro';

import { ArrowDownIcon } from '@assets/icons';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import ProductTags from './ProductTags';
import ProductWrapper from './ProductWrapper';
import ProductImageMobile from './PurchasedProductImageMobile';
import PurchasedProductInfoWrapperCollapsed from './PurchasedProductInfoWrapperCollapsed';
import PurchasedProductInfoWrapperExpanded from './PurchasedProductInfoWrapperExpanded';
import PurchasedProductName from './PurchasedProductName';
import PurchasedProductOptionsPopover from './PurchasedProductOptionsPopover';
import PurchasedProductSizes from './PurchasedProductSizes';
import { usePurchasedProductTileContext } from './PurchasedProductTile.context';

const PurchasedProductTileMobile = () => {
  const { t } = useTranslation();
  const {
    isExistImage,
    showImage,
    toggleOpenMenu,
    product,
    productIsSelected,
  } = usePurchasedProductTileContext();
  const { '@resources': resources, dishImagePlaceholder } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const { dish, sizes = [] } = product;
  const imageUrl =
    showImage && isExistImage
      ? dish?.image || ''
      : resources?.[dishImagePlaceholder] ?? '/images/placeholder.jpeg';

  return (
    <AnimatePresence initial={false}>
      <ProductWrapper isSelected={productIsSelected} onClick={toggleOpenMenu}>
        <div tw="flex w-full justify-between flex-wrap">
          <ProductImageMobile showImage={showImage} imageUrl={imageUrl} />
          <PurchasedProductInfoWrapperCollapsed>
            <div css={[tw`h-full p-3`]}>
              <div tw="h-full overflow-auto">
                <PurchasedProductName
                  name={dish?.name}
                  styles={{ css: tw`text-xs` }}
                />

                <div tw="pt-1">
                  <div tw="text-xs">
                    {sizes.map(({ quantity, dishSize }) => (
                      <div key={dishSize.id}>
                        {`${quantity} x ${
                          dishSize.size.name
                        } (${getIntegerIfIntegerOrFixed(
                          dishSize?.macrosAfterProcessing?.calories ?? 0,
                          0
                        )} ${t('$*common.kcal', '$$kcal')})`}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </PurchasedProductInfoWrapperCollapsed>
        </div>

        <PurchasedProductInfoWrapperExpanded>
          <div tw="flex flex-col h-full">
            <div tw="p-3">
              <div className="row">
                <div className="col">
                  <PurchasedProductName
                    name={dish?.name}
                    styles={{ css: tw`text-xs` }}
                  />
                  <div tw="pt-4 flex -my-0.5 -mx-1 flex-wrap">
                    <ProductTags tags={dish?.tags ?? []} />
                  </div>
                </div>
                <div className="col-auto">
                  <div tw="flex flex-col items-center">
                    <div onClick={toggleOpenMenu}>
                      <button
                        className="group"
                        tw="focus-visible:(text-primary) hover:(text-primary) w-6 flex justify-center"
                      >
                        <ArrowDownIcon
                          className="group-focus:(fill-current)"
                          tw="w-4 transform transition-colors rotate-180 duration-300 hover:(duration-300)"
                          title={t('$*common.hideDetails', '$$Ukryj szczegóły')}
                        />
                      </button>
                    </div>
                    <div>
                      <PurchasedProductOptionsPopover />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              tw="relative px-3 pb-3 flex-auto overflow-auto max-h-52"
              onClick={e => e.stopPropagation()}
            >
              <PurchasedProductSizes sizes={sizes} />
            </div>
          </div>
        </PurchasedProductInfoWrapperExpanded>
      </ProductWrapper>
    </AnimatePresence>
  );
};

export default PurchasedProductTileMobile;
