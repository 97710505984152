import { useRouter } from 'next/router';

import { useState } from 'react';
import Cookies from 'js-cookie';

import { useLanguages } from '@hooks';

const withLanguageSelector = Component => {
  const LanguageSelector = props => {
    const { data: apiLanguages = [] } = useLanguages({ enabled: false });
    const languages = apiLanguages.map(({ id, isoCode, image, name }) => {
      return { flagCode: isoCode, id, image, label: name, value: isoCode };
    });

    const router = useRouter();
    const defaultLanguage =
      languages.find(({ value }) => value === router.locale) ?? {};
    const [language, setLanguage] = useState(defaultLanguage);

    const changeLanguage = languageOption => {
      router.push(router.asPath, router.asPath, {
        locale: languageOption.value,
      });

      setLanguage(languageOption);

      window.__localeId__ = languageOption.value;
      Cookies.set('userLanguage', JSON.stringify(languageOption));
    };

    const allProps = {
      ...props,
      changeLanguage,
      language,
      languages,
    };

    return <Component {...allProps} />;
  };

  LanguageSelector.displayName = 'LanguageSelector';
  return LanguageSelector;
};

export default withLanguageSelector;
