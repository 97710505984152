import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectOrderForm } from '@store/orderForm/orderForm.slice';
import tw from 'twin.macro';

import { ApiLoadingBoundary, Tag } from '@components';
import { useDishTags } from '@hooks';

import ProductsTilesWrapper from './ProductsTilesWrapper';
import { useSelectDishesSectionContext } from './SelectDishesSection.context';

const SelectDishesSection = ({ optionsPopoverScope }) => {
  const { t } = useTranslation();
  const { selectedDate } = useSelector(selectOrderForm);
  const {
    data: tags = [],
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useDishTags({ date: selectedDate });
  const { selectedTags, handleResetTags, handleToggleTag } =
    useSelectDishesSectionContext();

  return (
    <Fragment>
      <ApiLoadingBoundary
        isError={isError}
        isLoading={isLoading || isFetching}
        refetch={refetch}
        justify="left"
        withTimeoutInfo={false}
        styles={{
          loading: {
            wrapper: tw`pt-1.5`,
            spinner: { icon: { css: tw`w-4 h-4` } },
          },
        }}
      >
        <div tw="-m-1">
          {tags?.map(({ '@id': iri, value }) => {
            const isSelected =
              selectedTags.findIndex(item => item === iri) > -1;

            return (
              <Tag
                label={value}
                key={iri}
                onClick={() => handleToggleTag(iri)}
                isSelected={isSelected}
                styles={{ css: tw`m-1` }}
                variant="outline"
                data-cy="tag"
              />
            );
          })}

          {selectedTags.length > 0 && (
            <Tag
              label={t(
                '$*newOrderCreatePage.orderForm.dishes.clearFilters',
                '$$Wyczyść filtry'
              )}
              onClick={handleResetTags}
              isRemovable={true}
              color="primary"
              styles={{ css: tw`m-1` }}
              data-cy="tag--clear"
            />
          )}
        </div>
      </ApiLoadingBoundary>

      <ProductsTilesWrapper optionsPopoverScope={optionsPopoverScope} />
    </Fragment>
  );
};

SelectDishesSection.displayName = 'SelectDishesSectionView';

export default SelectDishesSection;
