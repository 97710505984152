import { useQuery } from 'react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/http';

const getAllowedCities = async postCode => {
  const response = await axios.get(ENDPOINT.ALLOWED_CITIES, {
    params: { postCode },
    errorHandle: false,
  });

  return response?.data ?? {};
};

const useAllowedCities = (postCode, options = {}) => {
  return useQuery(
    ['allowedCities', postCode],
    () => getAllowedCities(postCode),
    {
      initialStale: true,
      ...options,
    }
  );
};

export default useAllowedCities;
