import { useTranslation } from 'next-i18next';

import { useEffect } from 'react';
import Masonry from 'react-masonry-css';
import tw from 'twin.macro';

import { ApiLoadingBoundary, Button, ProductTile } from '@components';
import { useBasketMethods, useDishProducts } from '@hooks';
import {
  selectModuleShop,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { screensInt } from '@utils/screens';

import { useSelectDishesSectionContext } from './SelectDishesSection.context';

const ProductsTilesWrapper = ({ optionsPopoverScope }) => {
  const { t } = useTranslation();

  const {
    checkIsProductSelected,
    handleChangeProductQuantity,
    selectedDate,
    selectedTags,
    selectedDietIri,
  } = useSelectDishesSectionContext();
  const { basketStore } = useBasketMethods();
  const basketItems = basketStore?.items;
  const { allowDishesFromPlaning } = useAppConfigSelector(selectModuleShop);

  const {
    items: products = [],
    isError,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    totalItems,
    isInit,
  } = useDishProducts({
    payload: {
      tags: selectedTags,
      ...(allowDishesFromPlaning && { date: selectedDate }),
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedTags]);

  useEffect(() => {
    if (allowDishesFromPlaning) {
      refetch();
    }
  }, [allowDishesFromPlaning, selectedDate]);

  if (!isInit && totalItems === 0) {
    return (
      <div tw="flex justify-center">
        {t(
          '$*newOrderCreatePage.orderForm.dishes.emptyDishProducts',
          '$$Brak produktów'
        )}
      </div>
    );
  }

  return (
    <ApiLoadingBoundary
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
      refetch={refetch}
      justify="left"
      styles={{
        loading: { wrapper: tw`pt-6` },
        error: { wrapper: { css: tw`pt-6` } },
      }}
    >
      <Masonry
        breakpointCols={{
          default: 3,
          [screensInt.xxl]: 2,
          [screensInt.xl]: 3,
          [screensInt.lg]: 2,
          [screensInt.sm]: 1,
        }}
        className="masonry-grid"
        columnClassName="masonry-grid-column"
        tw="pt-6"
      >
        {products.map(product => (
          <ProductTile
            key={product['@id']}
            isSelected={checkIsProductSelected({
              product,
              dietIri: selectedDietIri,
              selectedDate,
            })}
            onChangeQuantity={handleChangeProductQuantity}
            selectedDate={selectedDate}
            dishSizesQuantity={
              basketItems?.[selectedDietIri]?.[selectedDate] || {}
            }
            selectedDietIri={selectedDietIri}
            optionsPopoverScope={optionsPopoverScope}
            {...product}
          />
        ))}
      </Masonry>

      <div tw="flex justify-center mt-6">
        <Button
          onClick={fetchNextPage}
          disabled={!hasNextPage || isFetchingNextPage}
          isLoading={isFetching}
        >
          {isFetchingNextPage &&
            t('$*common.isDataDownloading', '$$Trwa pobieranie danych...')}
          {!isFetchingNextPage &&
            hasNextPage &&
            t('$*common.loadMoreData', '$$Załaduj więcej danych')}
          {!isFetchingNextPage &&
            !hasNextPage &&
            t('$*common.allDataLoaded', '$$Wszystkie dane zostały załadowane')}
        </Button>
      </div>
    </ApiLoadingBoundary>
  );
};

ProductsTilesWrapper.displayName = 'ProductsTilesWrapper';

export default ProductsTilesWrapper;
