import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useFormikContext } from 'formik';

import INPUT_IDS from '@constants/inputs/loginInputs';

const useLoginFields = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const loginFields = useMemo(() => {
    const fields = [
      [
        {
          id: INPUT_IDS.USERNAME,
          type: 'text',
          label: t('$*input.email.label', '$$E-mail'),
          autoComplete: 'username',
          required: true,
          onChange: ({ target }) => {
            const newValue = target.value.toLowerCase();

            formik.setFieldValue(target.name, newValue);
          },
        },
        {
          id: INPUT_IDS.PASSWORD,
          type: 'password',
          label: t('$*input.password.label', '$$Hasło'),
          autoComplete: 'current-password',
          required: true,
        },
      ],
      [
        {
          id: INPUT_IDS.KEEP_ME_LOGGED_IN,
          type: 'checkbox',
          label: t('$*input.keepMeLoggedIn.label', '$$Nie wylogowuj mnie'),
          required: false,
        },
      ],
    ];

    return fields;
  }, [t]);

  return loginFields;
};

export default useLoginFields;
