import 'regenerator-runtime/runtime';

import Head from 'next/head';
import Router from 'next/router';
import { appWithTranslation, i18n } from 'next-i18next';

import { Fragment, useEffect } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { dehydrate } from 'react-query';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { Provider } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import store from '@store/store';
import cookie from 'cookie';
import deepmerge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';
import NProgress from 'nprogress'; //nprogress module
import { compose } from 'redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import initQueryStore from '@app/initQueryStore';
import {
  GtmScript,
  HotJarScript,
  PayUScript,
  PollsterTrackScript,
  SalesManagoScript,
} from '@components';
import TailwindGlobalStyles from '@components/TailwindGlobalStyles';
import { getAppConfig } from '@hooks/app/useAppConfig';
import { getLanguages } from '@hooks/app/useLanguages';
import { pushGTMPageViewEvent } from '@utils/gtm';
import { isServer } from '@utils/helpers';
import { setMyLeadTrackingParams } from '@utils/myLead';
import App from '@views/App/App.container';

import '../scripts/wdyr';

import packageJson from '../package.json';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import '@assets/reactDayPicker.css';

console.log(`APP VERSION: ${packageJson.version}`);

export const reportWebVitals = metric => {
  if (process.env.MEASURE_PERFORMANCE) {
    console.table(metric);
  }
};

const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,

      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
};

const queryClient = new QueryClient(queryClientOptions);
const asyncStoragePersistor = createAsyncStoragePersistor({
  storage: AsyncStorage,
});

persistQueryClient({
  queryClient,
  persistor: asyncStoragePersistor,
});

const MyApp = ({ Component, pageProps, router }) => {
  useEffect(() => {
    //Capturing myLead tracking params
    const myLeadUserId = router?.query?.['mylead_user_id'];
    const myLeadClickId = router?.query?.['mylead_click_id'];

    if (myLeadUserId && myLeadClickId) {
      setMyLeadTrackingParams({ clickId: myLeadClickId, userId: myLeadUserId });
    }

    NProgress.configure({
      showSpinner: false,
    });

    Router.onRouteChangeStart = () => {
      NProgress.start();
    };

    Router.onRouteChangeComplete = () => {
      NProgress.done();
    };

    Router.onRouteChangeError = () => {
      NProgress.done();
    };
  }, []);

  useEffect(() => {
    pushGTMPageViewEvent(router?.pathname);
  }, [router?.pathname]);

  const {
    appDehydratedState = {},
    customCSS = null,
    customScripts = null,
    dehydratedState = {},
    gtmConfig = {},
    hotjarConfig = {},
    payUConfig = {},
    salesManago = {},
    pollsterTrack = {},
    ...restPageProps
  } = pageProps;

  const mergedDehydratedState = deepmerge(appDehydratedState, dehydratedState);

  return (
    <Fragment>
      {gtmConfig?.enabled && <GtmScript {...gtmConfig} />}
      {hotjarConfig?.enabled && <HotJarScript {...hotjarConfig} />}
      {payUConfig?.enabled && payUConfig?.cardPayments && (
        <PayUScript {...payUConfig} />
      )}
      {salesManago?.enabled && <SalesManagoScript {...salesManago} />}
      {pollsterTrack?.enabled && <PollsterTrackScript />}
      <Head>
        {customScripts && (
          <script id="custom-script" type="text/javascript">
            {customScripts}
          </script>
        )}
        {customCSS && <style id="custom-css">{customCSS}</style>}
      </Head>

      <TailwindGlobalStyles />
      <Provider store={store}>
        <PersistGate persistor={store.__persistor} loading={null}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={mergedDehydratedState}>
              <App Component={Component} pageProps={restPageProps} />
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Fragment>
  );
};

const enhance = compose(appWithTranslation);

MyApp.getInitialProps = async ({ ctx, router }) => {
  try {
    router.locales.map(locale => i18n?.removeResourceBundle(locale, 'default'));

    const queryClient = new QueryClient();
    const [response, responseLanguages] = await Promise.all([
      getAppConfig(),
      getLanguages(),
    ]);

    initQueryStore(queryClient);
    await queryClient.prefetchQuery('appConfig', () => response);
    await queryClient.prefetchQuery('languages', () => responseLanguages);

    const cookies = ctx?.req?.headers?.cookie
      ? cookie.parse(ctx.req.headers.cookie)
      : {};
    const userLanguage = cookies?.userLanguage
      ? JSON.parse(cookies.userLanguage)
      : {};

    if (
      ctx.res &&
      !isEmpty(response) &&
      (userLanguage?.value === undefined || userLanguage?.value !== ctx.locale)
    ) {
      const { defaultLanguage } = response.multinational;
      if (ctx.locale !== defaultLanguage && isServer) {
        const location = `/${defaultLanguage}${ctx.asPath}`;

        ctx.res.writeHead(302, { Location: location });
        ctx.res.end();
      }
    }

    return {
      pageProps: {
        gtmConfig: response?.modules?.GoogleTagManager ?? {},
        payUConfig: response?.modules?.PayU ?? {},
        hotjarConfig: response?.modules?.HotJar ?? {},
        salesManago: response?.modules?.SalesManago ?? {},
        pollsterTrack: response?.modules?.PollsterTrack ?? {},
        appDehydratedState: dehydrate(queryClient),
        customCSS: response?.branding?.customCSS ?? null,
        customScripts: response?.branding?.customScripts ?? null,
        configError: false,
      },
    };
  } catch (error) {
    console.error(error);

    return {
      pageProps: {
        configError: true,
      },
    };
  }
};

export default enhance(MyApp);
